import { useQuery, UseQueryResult } from 'react-query';
import { SummaryReport } from '_types';
import { targets } from '../../services/nestApiService';

export const useSummaryReportsQuery = (
  targetId: string,
): UseQueryResult<SummaryReport[]> =>
  useQuery(
    ['target', targetId, 'summary'],
    () => targets.getSummaryReports(targetId),
    {
      // TODO: we might want to refetch periodically instead of focus
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
  );
