import classNames from 'classnames';
import { FC, HTMLAttributes } from 'react';
import { CustomData, Headline, Highlight, Paragraph } from '_atoms';
import { HighlightChunks } from '../../../_atoms/Highlight/types';

export type CompanyCardTableColumn = {
  key: string;
  label: string;
  render?: (value: any) => string;
  props?: HTMLAttributes<HTMLTableCellElement>;
};

export type CompanyCardTableProps = {
  data: Array<any>;
  highlight?: HighlightChunks;
  columns: CompanyCardTableColumn[];
};

export const CompanyCardTable: FC<CompanyCardTableProps> = ({
  data,
  columns,
  highlight,
}) => {
  const defaultColumnRender = (value: any) => value;

  return data?.length ? (
    <table className="w-full">
      <thead>
        <tr>
          {columns.map(({ key, label, props = {} }, index) => (
            <th
              key={key + index}
              {...{
                ...props,
                className: classNames(
                  'text-left uppercase align-bottom',
                  props.className,
                ),
              }}
            >
              <Headline Level="h6" weight="semibold">
                {label}
              </Headline>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="mt-4">
        {data.map((item, i) => (
          <tr key={i}>
            {columns.map(({ key, render = defaultColumnRender }, index) => (
              <td key={i + key + index} className="py-1">
                <Paragraph color="dark">
                  <Highlight
                    textToHighlight={item[key] ? render(item[key]) : 'n.a.'}
                    searchWords={highlight}
                  />
                </Paragraph>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <div className="h-28 w-full flex items-center justify-center bg-gray-50 pt-2">
      <CustomData headline="" width="w-fit" />
    </div>
  );
};
