import { FC, ReactNode } from 'react';
import { Paragraph } from '_atoms';
import { ReactComponent as Logo } from '../../../images/logo.svg';
import { nonProdDataTestId } from '_utils';

type LoadingSpinnerProps = {
  message?: ReactNode;
};

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  message,
}: LoadingSpinnerProps) => (
  <figure
    className="flex flex-col items-center p-2 w-full"
    data-testid={nonProdDataTestId('loading icon')}
  >
    <Logo
      className="animate-spin-slow text-blue-400 fill-current"
      style={{ maxHeight: 30, maxWidth: 30 }}
    />
    {message && (
      <figcaption>
        <Paragraph color="blue">{message}</Paragraph>
      </figcaption>
    )}
  </figure>
);
