import { FC } from 'react';
import { Headline, Paragraph, Card } from '_atoms';
import { FaUsers } from 'react-icons/fa';
import { HiOutlineBriefcase } from 'react-icons/hi';
import { BiUserCircle } from 'react-icons/bi';
import { IconType } from 'react-icons';
import classNames from 'classnames';

const positionClassNames = {
  left: 'border-r rounded-r-none',
  middle: 'border-r border-l rounded-r-none rounded-l-none',
  right: 'border-l rounded-l-none',
};

export type OptionType = 'option1' | 'option2' | 'option3';

const optionIcon: Record<OptionType, IconType> = {
  option1: HiOutlineBriefcase,
  option2: BiUserCircle,
  option3: FaUsers,
};

interface TileRadioButtonProps {
  type?: OptionType;
  checked: boolean;
  title: string;
  descriptionItems: Array<string>;
  position: 'left' | 'middle' | 'right';
}

export const TileRadioButton: FC<TileRadioButtonProps> = ({
  checked,
  title,
  type = 'option1',
  position,
  descriptionItems,
}: TileRadioButtonProps) => (
  <Card
    borderColor="border-l-gray-900"
    backgroundColor={checked ? 'bg-blue-400' : 'bg-white'}
    className={`py-4 px-6 min-h-full ${positionClassNames[position]}`}
  >
    <div className="mb-3">
      <div className="flex justify-between">
        <div></div>
        {optionIcon[type]({
          className: classNames(
            'w-10 h-10 self-center',
            checked ? 'text-white' : 'text-blue-400',
          ),
        })}
        <input type="radio" checked={checked} />
      </div>
    </div>
    <Headline
      Level="h6"
      weight="bold"
      color={checked ? 'white' : 'dark'}
      className="mb-3"
    >
      {title}
    </Headline>
    <ul className="list-disc mx-3">
      {descriptionItems.map((item) => (
        <li key={item} className={checked ? 'text-white' : 'text-gray-500'}>
          <Paragraph
            key={item}
            weight="normal"
            color={checked ? 'white' : 'default'}
          >{`${item}`}</Paragraph>
        </li>
      ))}
    </ul>
  </Card>
);
