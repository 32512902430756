import { Dispatch } from 'react';
import { IFilterFactory } from 'src/interfaces/filter-factory.interface';
import {
  DateRangeFilterFactory,
  SelectFilterFactory,
  OptionsFilterFactory,
  OptionsRangeFilterFactory,
} from '../components/_atoms/Filter/factories';
import { SearchState, SearchAction } from '../hooks/useSearch';

class FiltersFactoryMapper {
  protected factories: Record<string, IFilterFactory> = {};

  constructor() {
    const dateRangeFilterFactory = new DateRangeFilterFactory();
    const selectFilterFactory = new SelectFilterFactory();
    const optionsFilterFactory = new OptionsFilterFactory();
    const optionsRangeFilterFactory = new OptionsRangeFilterFactory();

    this.factories[dateRangeFilterFactory.type] = dateRangeFilterFactory;
    this.factories[selectFilterFactory.type] = selectFilterFactory;
    this.factories[optionsFilterFactory.type] = optionsFilterFactory;
    this.factories[optionsRangeFilterFactory.type] = optionsRangeFilterFactory;
  }

  factory = (type: string): IFilterFactory | undefined => {
    if (type) {
      return this.factories[type];
    }
  };
}

class FiltersFactory {
  protected factoryMapper: FiltersFactoryMapper;

  constructor() {
    this.factoryMapper = new FiltersFactoryMapper();
  }

  create(
    item: any,
    searchState: SearchState<any>,
    dispatcher: Dispatch<SearchAction<any>>,
  ) {
    const { type, field } = item;
    const factory = this.factoryMapper.factory(type);
    return factory?.create({ field, item, searchState, dispatcher });
  }
}

export default FiltersFactory;
