import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Paragraph, Tag } from '_atoms';
import { mapSourceToText } from '_utils';
import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip';

interface InfoBlockProps {
  title: string;
  infoList: {
    value: string;
    icon?: React.ReactElement;
    sources?: string[];
    additionalValue?: string;
  }[];
  containerClassName?: string;
}

export const InfoBlock = (props: InfoBlockProps): React.ReactElement => {
  const { title, infoList, containerClassName } = props;
  const { t } = useTranslation();
  const filteredInfoList = infoList.filter(({ value }) => value);

  return (
    <div className={classNames(containerClassName)}>
      <Paragraph size="label" weight="bold" color="light" className="p-1">
        {title}
      </Paragraph>
      {filteredInfoList.length ? (
        filteredInfoList.map(
          ({ sources = [], value, icon, additionalValue }, index) => (
            <Tooltip key={index} disabled={sources.length === 0}>
              <TooltipTrigger asChild={true}>
                <div className="group mb-1">
                  <Paragraph
                    key={index}
                    size="default"
                    lineHeight="default"
                    weight="medium"
                    color="dark"
                    className={classNames(
                      'break-words p-1 rounded-md flex items-center',
                      sources.length &&
                        'cursor-pointer group-hover:bg-gray-100',
                    )}
                  >
                    {icon ? <span className="mr-2">{icon}</span> : null}
                    {value + (additionalValue ?? '')}
                  </Paragraph>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <Paragraph color="dark" weight="medium" size="small">
                  {`${t('sources')}:`}
                </Paragraph>
                {sources.map((source, index) => (
                  <Paragraph
                    color="dark"
                    weight="medium"
                    size="small"
                    key={index}
                  >
                    {`- ${t(mapSourceToText(source))}`}
                  </Paragraph>
                ))}
              </TooltipContent>
            </Tooltip>
          ),
        )
      ) : (
        <Tag>{t('noData')}</Tag>
      )}
    </div>
  );
};
