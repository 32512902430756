import { FC, ReactNode, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Highlight, Paragraph, SourceInfo, Tag } from '_atoms';
import { TargetReportSourceType } from '@indicium/common';
import { HighlightChunks } from '../../_atoms/Highlight/types';

export type ContentType = {
  value: string | string[];
  sources?: TargetReportSourceType[];
};

export interface InfoBlockProps {
  content?: ContentType[];
  headline: string;
  highlightMatch?: HighlightChunks;
  icon?: ReactElement;
  className?: string;
  containerClassName?: string;
  children?: (content?: ContentType[]) => ReactNode;
}

export const InfoBlock: FC<InfoBlockProps> = ({
  headline,
  highlightMatch,
  content,
  icon,
  className,
  containerClassName,
  children,
}: InfoBlockProps) => {
  const { t } = useTranslation();

  return (
    <div className={containerClassName}>
      <Paragraph size="label" weight="bold" color="light" className="p-1">
        {headline}
      </Paragraph>
      {!content ||
      content.length === 0 ||
      !content.some(({ value }) => value) ? (
        <Tag>{t('noData')}</Tag>
      ) : children && typeof children === 'function' ? (
        children(content)
      ) : (
        content.map(({ sources, value }, contentIndex) => {
          const text = Array.isArray(value) ? value : [value];
          return (
            <div
              key={contentIndex}
              className="relative group flex flex-col mb-1 break-all"
            >
              {icon}
              <Paragraph
                size="default"
                lineHeight="default"
                weight="medium"
                color="dark"
                className={classnames(
                  'break-words p-1 rounded-t-md',
                  {
                    'ml-2.5': icon,
                    'cursor-pointer group-hover:bg-gray-50 ': sources,
                  },
                  className,
                )}
              >
                {text.map((textToHighlight, textIndex) =>
                  highlightMatch ? (
                    <Highlight
                      key={textIndex}
                      searchWords={highlightMatch}
                      textToHighlight={textToHighlight}
                    />
                  ) : (
                    <span
                      key={textIndex}
                      dangerouslySetInnerHTML={{ __html: textToHighlight }}
                    />
                  ),
                )}
              </Paragraph>
              {sources && (
                <div className="p-1 absolute opacity-0 group-hover:opacity-100 bg-gray-100 top-6 w-full z-10 rounded-b-md">
                  <SourceInfo color="dark" sources={sources} />
                </div>
              )}
            </div>
          );
        })
      )}
    </div>
  );
};
