import { useMemo } from 'react';
import { DynamicFormValue, DynamicFormValueStatus } from '@indicium/common';

type KeywordEntry = [string, DynamicFormValue[]];
type KeywordEntries = KeywordEntry[];

interface Keywords {
  included: KeywordEntries;
  excluded: KeywordEntries;
  totalIncluded: number;
  totalExcluded: number;
}

export const useKeywords = (
  formValues: Record<string, DynamicFormValue[]>,
): Keywords => {
  const keywordEntries = useMemo(
    () => Object.entries<DynamicFormValue[]>(formValues),
    [formValues],
  );

  const included = useMemo(
    () =>
      keywordEntries.map<KeywordEntry>(([key, values]) => [
        key,
        values.filter(
          ({ status }) => status === DynamicFormValueStatus.Confirmed,
        ),
      ]),
    [keywordEntries],
  );

  const excluded = useMemo(
    () =>
      keywordEntries.map<KeywordEntry>(([key, values]) => [
        key,
        values.filter(
          ({ status }) => status === DynamicFormValueStatus.Ignored,
        ),
      ]),
    [keywordEntries],
  );

  const totalIncluded = included.flatMap(([, value]) => value).length;
  const totalExcluded = excluded.flatMap(([, value]) => value).length;

  return {
    included,
    excluded,
    totalIncluded,
    totalExcluded,
  };
};
