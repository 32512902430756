import { LocalizationService } from '@indicium/common';
import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RegisterDataArticle } from 'src/services/searchService';
import { CollapsibleCard } from '_molecules';
import { normalizeName } from '_utils';
import { CompanyCardTable } from './CompanyCardTable';
import { HighlightChunks } from '../../../_atoms/Highlight/types';

export type OwnersAndManagersProps = {
  data: RegisterDataArticle;
  highlight?: HighlightChunks;
};

type CompanyOwnerManager = {
  actuality: string | null;
  name: string;
  directlyOwnedPercent?: string;
  ultimatelyOwnedPercent?: string;
  dateOfBirth?: string;
  placeOfBirth?: string;
  address?: string;
};

export const OwnersAndManagers: FC<OwnersAndManagersProps> = ({
  data,
  highlight,
}) => {
  const { t } = useTranslation();

  const currentPrevious: Record<string, string> = t('companyCard', {
    returnObjects: true,
  });

  const generateRowData = (
    data:
      | RegisterDataArticle['companyShareowners']
      | RegisterDataArticle['companyManagers'],
    type: string,
  ) =>
    (data || []).map((item: any) => {
      let address = item.workAddress || item.addressLine1 || item.addressLine2;
      if (!address && item.address) {
        const { street, city, zip, country: isoCountry } = item.address;
        const country = isoCountry
          ? LocalizationService.getCountry(isoCountry)?.name
          : undefined;
        address = normalizeName(
          [street, city, zip, country].filter(Boolean).join(', '),
        );
      }

      return {
        actuality: item.role?.currentPrevious
          ? [currentPrevious[item.role.currentPrevious], type].join(' ')
          : null,
        name: item.name,
        dateOfBirth: item.birthdate,
        placeOfBirth: item.placeOfBirth,
        directlyOwnedPercent: item.directlyOwnedPercent,
        ultimatelyOwnedPercent: item.ultimatelyOwnedPercent,
        address,
      };
    });

  const shareholders: CompanyOwnerManager[] = generateRowData(
    data.companyShareowners,
    t('companyCard.shareholder'),
  );

  const managers: CompanyOwnerManager[] = generateRowData(
    data.companyManagers,
    t('companyCard.manager'),
  );

  const columns = [
    {
      key: 'actuality',
      label: t('companyCard.actuality'),
      props: {
        className: 'w-56',
      },
    },
    {
      key: 'name',
      label: t('companyCard.name'),
      props: {
        className: 'w-96',
      },
    },
    {
      key: 'dateOfBirth',
      label: t('companyCard.dob'),
      props: {
        className: 'w-40',
      },
    },
    {
      key: 'placeOfBirth',
      label: t('companyCard.pob'),
      props: {
        className: 'w-64',
      },
    },
    {
      key: 'directlyOwnedPercent',
      label: t('companyCard.directlyOwned'),
      props: {
        className: 'w-32',
      },
      render: (value: any) => t('intlNumber', { val: value }) + '%',
    },
    {
      key: 'ultimatelyOwnedPercent',
      label: t('companyCard.ultimatelyOwned'),
      props: {
        className: 'w-32',
      },
      render: (value: any) => t('intlNumber', { val: value }) + '%',
    },
    {
      key: 'address',
      label: t('companyCard.address'),
      props: {
        className: 'w-96',
      },
    },
  ];

  if (shareholders.length && managers.length) {
    return (
      <CollapsibleCard
        className="py-4"
        borderColor="border-transparent"
        header={
          <div className="flex flex-col gap-2">
            {/* Row 1  */}
            <CompanyCardTable
              columns={columns}
              data={shareholders}
              highlight={highlight}
            />
          </div>
        }
        initiallyExpanded={false}
      >
        <CompanyCardTable
          columns={columns}
          data={managers}
          highlight={highlight}
        />
      </CollapsibleCard>
    );
  }

  return (
    <div
      className={classNames(
        'flex flex-col gap-2',
        shareholders.length || managers.length ? 'p-4' : null,
      )}
    >
      <CompanyCardTable
        columns={columns}
        data={shareholders.length ? shareholders : managers}
        highlight={highlight}
      />
    </div>
  );
};
