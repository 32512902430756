import { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { truncate } from 'lodash';
import { Error, LoadingSpinner, Headline } from '_atoms';
import {
  Alert,
  WorkflowError,
  GraphLink,
  NetworkGraph,
  GraphNode,
} from '_molecules';
import { AsyncFeedbackWrapper } from '_organisms';
import { useTargetResultsQuery } from '../../../hooks/queries/useTargetResultsQuery';
import { ResultsProps } from '../Results';
import { NodeEntry } from '@indicium/common';
import { useParams } from 'react-router-dom';
import { RedFlagItem } from 'src/types';
import { useNetworkS3Query } from '../../../hooks/queries/useNetworkQuery';

export const transformEdges = (
  edges: GraphLink[],
  defaultLabel: string,
): GraphLink[] =>
  edges.map(({ label, ...edge }) => {
    const roles = [...new Set(label.split(','))]
      .filter((role) => role !== 'unknown role')
      .join(', ');

    if (roles.length === 0) {
      return {
        ...edge,
        label: defaultLabel,
      };
    }

    return {
      ...edge,
      label: truncate(roles, { length: 30, omission: '...' }),
      title: roles.length >= 28 ? roles : undefined,
    };
  });

const isNodeRedFlagged = (
  node: NodeEntry,
  redFlagIds: string[] | undefined,
): boolean =>
  (['company', 'target', 'person', 'family'].includes(node.type) &&
    redFlagIds?.includes(node.id)) ||
  false;

export const transformNodes = (
  nodes: NodeEntry[],
  redFlags: RedFlagItem[],
): GraphNode[] => {
  const redFlagIds: string[] = redFlags
    .filter((flag) => !!flag.apiId)
    .map((flag) => flag.apiId);

  return nodes
    .filter(({ id }) => Boolean(id))
    .map((node) => ({ ...node, type: node.lvl === 0 ? 'target' : node.type }))
    .map(({ type, ...node }) => ({
      ...node,
      group: isNodeRedFlagged({ ...node, type }, redFlagIds)
        ? `${type}Flagged`
        : type,
    }));
};

export const NetworkGraphSection: FC<ResultsProps> = ({
  targetData,
  targetDataPromise,
  redFlags,
}: ResultsProps) => {
  const { t } = useTranslation();
  const { caseId, targetId } =
    useParams<{ caseId: string; targetId: string }>();

  const targetResults = useTargetResultsQuery(caseId, targetId);
  const networkResult = useNetworkS3Query(targetResults?.data?.network_url);
  const data = networkResult?.data;
  const isLoading = targetResults?.isLoading || networkResult?.isLoading;

  const transformedData = useMemo(() => {
    if (data) {
      // Make sure the renderer only gets a maximum of 5000 nodes to handle
      const nodes = (data.nodes || [])
        .sort(({ lvl: aLvl }, { lvl: bLvl }) => aLvl - bLvl)
        .slice(0, 5000);
      // Make sure the renderer only get edges for which both ends still have a node after reducing them to a maximum of 5000
      const edges = (data.edges || []).filter(
        ({ from, to }) =>
          nodes.some(({ id }) => id === from) &&
          nodes.some(({ id }) => id === to),
      );
      return {
        edges: transformEdges(edges, t('networkGraph.unknownRole')),
        nodes: transformNodes(nodes, redFlags),
      };
    }
    return undefined;
  }, [data, t, redFlags]);

  // TODO: Extract and reuse these components in profile, raw data, press and social media
  const profileNetworkGraphDataMissingAlert = useMemo(
    () => (
      <Alert
        alertType="warning"
        headline={t('profileDataNotFinal')}
        className="mb-2"
      />
    ),
    [t],
  );

  const profileDataError = useMemo(
    () => (
      <Error
        headline={t('profileErrorHeadline')}
        message={t('profileErrorMessage')}
      />
    ),
    [t],
  );

  const profileDataLoadingSpinner = useMemo(
    () => <LoadingSpinner message={t('profileLoading')} />,
    [t],
  );

  return (
    <div className="flex-1 px-5 ml-0">
      <Headline Level="h1" color="dark" className="my-8" weight="bold">
        {t('networkGraph.title')}
      </Headline>

      <WorkflowError errors={targetData?.workflowErrors} path="network" />

      <AsyncFeedbackWrapper
        alert={{
          loading: profileDataLoadingSpinner,
          rejected: profileDataError,
          resolvedWithWarning: profileNetworkGraphDataMissingAlert,
        }}
        promises={[
          {
            promise: targetDataPromise,
            condition: (result: unknown) =>
              (result as { [key: string]: unknown }).status ===
              'HasInitialProfile'
                ? 'resolvedWithWarning'
                : 'resolved',
          },
        ]}
      >
        <div className="relative rounded-md mt-16 bg-gray-50">
          {isLoading ? (
            <LoadingSpinner message={t('profileLoading')} />
          ) : transformedData ? (
            <NetworkGraph data={transformedData} />
          ) : (
            <Error
              headline={
                targetData?.status === 'HasInitialProfile'
                  ? t('profileErrorRetryHeadline')
                  : t('profileErrorHeadline')
              }
              message={
                targetData?.status === 'HasInitialProfile'
                  ? t('profileErrorRetryMessage')
                  : t('profileErrorMessage')
              }
            />
          )}
        </div>
      </AsyncFeedbackWrapper>
    </div>
  );
};
