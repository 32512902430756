import { useQuery, UseQueryResult } from 'react-query';
import { customers } from '../../services/nestApiService';
import { CustomerUsageResponse, ErrorResponse } from 'src/types/NestAPI';

export const useCustomerUsageQuery = (
  customerId: string,
): UseQueryResult<CustomerUsageResponse, ErrorResponse> =>
  useQuery(
    ['customers', customerId, 'usage'],
    () => customers.usage(customerId).then((r) => r.data),
    {
      enabled: !!customerId,
    },
  );
