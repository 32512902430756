export const HighlightType = {
  SearchQuery: 'searchQuery',
  SuspicionKeywords: 'suspicionKeywords',
  CaseKeywords: 'caseKeywords',
  TargetPerson: 'targetPerson',
  TargetCompanies: 'targetCompanies',
} as const;

// eslint-disable-next-line no-redeclare
export type HighlightType = typeof HighlightType[keyof typeof HighlightType];

export type SearchTerms = Array<{
  type: HighlightType;
  words: string[];
}>;

export type HighlightChunks = {
  [k in HighlightType]?: string[];
};
