import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoBlock } from '_molecules';
import { CVInfoEntry } from '_types';
import { ProfileEducation } from 'src/services/dataService';
import { TableBody, TableHeaders, TableRow, TableCell } from './Table';
import classNames from 'classnames';
import { formatDate } from '_utils';

interface CvTableProps {
  companies: CVInfoEntry[];
  educations: ProfileEducation[];
}

const companyHeaders = ['company', 'role', 'from', 'to'];
const educationHeaders = ['education', 'role', 'from', 'to'];
const columnWidth = ['w-5/12', 'w-3/12', 'w-2/12', 'w-2/12'];

const isRepeatableCompanyName = (
  currentCompany: CVInfoEntry,
  prevCompany?: CVInfoEntry,
): boolean =>
  prevCompany?.companyName.toLowerCase().trim() ===
  currentCompany.companyName.toLowerCase().trim();

export const CvTable = ({
  companies,
  educations,
}: CvTableProps): React.ReactElement => {
  const { t } = useTranslation();

  const sortedCompanies = useMemo(
    () => companies.sort((a, b) => a.companyName.localeCompare(b.companyName)),
    [companies],
  );

  const sortedEducation = useMemo(
    () => educations.sort((a, b) => a.display.localeCompare(b.display)),
    [educations],
  );

  if (!sortedCompanies.length && !sortedEducation.length) {
    return <InfoBlock headline={t('')} content={undefined} />;
  }

  return (
    <div className="mt-5 w-full">
      {sortedCompanies.length ? (
        <div className={classNames(sortedEducation.length && 'mb-10')}>
          <TableHeaders columnsWidth={columnWidth} headers={companyHeaders} />
          <TableBody>
            {sortedCompanies.map((company, index) => (
              <TableRow key={index} index={index}>
                <TableCell width={columnWidth[0]}>
                  {isRepeatableCompanyName(company, sortedCompanies[index - 1])
                    ? ''
                    : company.companyName}
                </TableCell>
                <TableCell width={columnWidth[1]}>{company.role}</TableCell>
                <TableCell width={columnWidth[2]}>
                  {company.from ? formatDate(company.from) : '-'}
                </TableCell>
                <TableCell width={columnWidth[3]}>
                  {company.to
                    ? formatDate(company.to)
                    : company.from && company.isCurrentPosition
                    ? t('present')
                    : '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </div>
      ) : null}
      {sortedEducation.length ? (
        <>
          <TableHeaders columnsWidth={columnWidth} headers={educationHeaders} />
          <TableBody>
            {sortedEducation.map((education, index) => (
              <TableRow key={index} index={index}>
                <TableCell width={columnWidth[0]}>
                  {education.school ?? '-'}
                </TableCell>
                <TableCell width={columnWidth[1]}>
                  {education.degree ?? '-'}
                </TableCell>
                <TableCell width={columnWidth[2]}>
                  {education.date_range?.start
                    ? formatDate(education.date_range?.start)
                    : '-'}
                </TableCell>
                <TableCell width={columnWidth[3]}>
                  {education.date_range?.end
                    ? formatDate(education.date_range?.end)
                    : '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </>
      ) : null}
    </div>
  );
};
