import { useQuery, UseQueryResult } from 'react-query';
import { CustomerUsageResponse, ErrorResponse } from 'src/types/NestAPI';
import { customers } from '../../services/nestApiService';

export const useUserQuotaQuery = (
  customerId: string,
): UseQueryResult<CustomerUsageResponse, ErrorResponse> =>
  useQuery(
    ['userQuota'],
    async () =>
      customers.usage(customerId).then((r) => ({
        ...r.data,
        limitOfReports:
          r.data.limitOfReports === 0 ? Infinity : r.data.limitOfReports,
      })),
    {
      refetchInterval: 60000,
      enabled: !!customerId,
    },
  );
