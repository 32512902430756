import React, { useRef } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  arrow,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
} from '@floating-ui/react';
import type { Placement, UseFloatingReturn } from '@floating-ui/react';

type Interactions = ReturnType<typeof useInteractions>;

export interface TooltipContextReturn extends Interactions, UseFloatingReturn {
  open: boolean;
  setOpen: (open: boolean) => void;
  arrowRef: React.RefObject<SVGSVGElement>;
}

const TooltipContext =
  React.createContext<TooltipContextReturn | undefined>(undefined);

export interface TooltipProps {
  children: React.ReactNode;
  initialOpen?: boolean;
  placement?: Placement;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  edgeOffset?: number;
  triggerOffset?: number;
  disabled?: boolean;
}

export function Tooltip(props: TooltipProps): React.ReactElement {
  const {
    children,
    initialOpen = false,
    placement = 'bottom-start',
    open: controlledOpen,
    onOpenChange: setControlledOpen,
    edgeOffset = 5,
    triggerOffset = 0,
    disabled = false,
  } = props;

  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

  const isControlled = typeof controlledOpen === 'boolean';

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;
  const arrowRef = useRef<SVGSVGElement>(null);

  const floatingConfig = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(triggerOffset),
      flip({
        fallbackAxisSideDirection: 'start',
        padding: edgeOffset,
      }),
      shift({ padding: edgeOffset }),
      arrow({ element: arrowRef }),
    ],
  });

  const { context } = floatingConfig;

  const hover = useHover(context, {
    move: false,
    enabled: !disabled && !isControlled,
  });
  const focus = useFocus(context, {
    enabled: !disabled && !isControlled,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  const tooltipContext = {
    open,
    setOpen,
    arrowRef,
    ...interactions,
    ...floatingConfig,
  };

  return (
    <TooltipContext.Provider value={tooltipContext}>
      {children}
    </TooltipContext.Provider>
  );
}

export const useTooltipContext = (): TooltipContextReturn => {
  const context = React.useContext(TooltipContext);

  if (!context) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};
