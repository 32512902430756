import { ProcessTarget } from '@indicium/common';
import { useQuery, UseQueryResult } from 'react-query';
import { ErrorResponse } from 'src/types/NestAPI';
import { targets } from '../../services/nestApiService';

export const useTargetInputQuery = (
  caseId: string,
  targetId: string,
): UseQueryResult<ProcessTarget, ErrorResponse> =>
  useQuery(
    ['cases', caseId, 'target', targetId, 'input'],
    () => targets.getInputData(caseId, targetId).then((r) => r.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );
