import { Auth, CognitoUser } from '@aws-amplify/auth';
import config from '../backendConfig.json';

Auth.configure({
  Auth: {
    region: config.awsRegion,
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolClientId,
  },
});

// A Customer user with administrative privileges for the Customer, create Case
export const USER_GROUP_ADMINISTRATORS = 'Administrators'; // config.userPoolGroupAdminstrators as UserGroup;
// An Indicium user, who will be able to create Customers and their first Administrator
export const USER_GROUP_SUPER_USERS = 'SuperUsers'; // config.userPoolGroupSuperUsers as UserGroup;

export type UserGroup = 'Administrators' | 'SuperUsers' | null;

export interface RawUser extends CognitoUser {
  challengeName?: 'NEW_PASSWORD_REQUIRED' | string;
  signInUserSession: {
    accessToken: {
      payload: {
        'cognito:groups': UserGroup[];
      };
    };
  };
  attributes?: { [key: string]: string };
}

export type User = RawUser & {
  attributes: {
    email?: string;
    sub?: string;
    firstname?: string;
    lastname?: string;
  };
  staySignedIn?: boolean;
  isVerified?: boolean;
  preferredMFA: 'TOTP' | 'SMS' | 'NOMFA';
  username: string;
};

const transformUser = (rawUser: RawUser): User => {
  const user = Object.assign(rawUser);
  const rawUserAttributes = rawUser.attributes || {};
  user.attributes = Object.fromEntries(
    Object.entries(rawUserAttributes).map(([key, value]) => [
      key.replace(/^custom:/, ''),
      value,
    ]),
  );
  return user;
};

export const getAccessToken: () => Promise<string | undefined> = async () => {
  const session = await Auth.currentSession();
  return session.getAccessToken().getJwtToken();
};

export const getUserGroups: (user: User) => UserGroup[] = (user) =>
  user.signInUserSession?.accessToken?.payload['cognito:groups'] ?? [];

export const changePasswordOnFirstSgnin = async (
  newPassword: string,
  user: User,
): Promise<User> => {
  const attributes = {};

  try {
    if (user && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return await new Promise((resolve, reject) => {
        user.completeNewPasswordChallenge(newPassword, attributes, {
          onSuccess: async () => {
            const user: RawUser | undefined =
              await Auth.currentAuthenticatedUser();

            if (user) {
              resolve(transformUser(user));
            } else {
              reject('user not found after password reset');
            }
          },
          onFailure: function (error) {
            reject(error);
          },
        });
      });
    } else {
      throw "User doesn't require a new password";
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    throw error.code || error;
  }
};

export const signin: (email: string, password: string) => Promise<User> =
  async (email, password) => {
    try {
      const rawUser: RawUser = await Auth.signIn(email.toLowerCase(), password);
      return transformUser(rawUser);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error);

      // the error codes for incorrect and expired passwords are the same.
      // also, as of now, there is now way to check a passwords validity other than attempting to signin
      if (
        error.message ===
        'Temporary password has expired and must be reset by an administrator.'
      ) {
        throw 'PasswordExpiredException';
      } else {
        throw error.code;
      }
    }
  };

export const setupTOTP: (user: User) => Promise<string> = async (user) => {
  try {
    return await Auth.setupTOTP(user);
  } catch (error) {
    console.error(error);
    console.error(error.code);

    return error.code;
  }
};

export const verifyTOTP: (
  user: User,
  challengeAnswer: string,
) => Promise<void> = async (user, challengeAnswer) => {
  await Auth.verifyTotpToken(user, challengeAnswer);
};

export const confirmTOTPSetup: (
  user: User,
  challengeAnswer: string,
) => Promise<User> = async (user, challengeAnswer) => {
  await Auth.confirmSignIn(user, challengeAnswer, 'SOFTWARE_TOKEN_MFA');

  const authenticatedUser = await Auth.currentAuthenticatedUser();

  return transformUser(authenticatedUser);
};

export const setPreferredMFA: (
  user: User,
  preferredMFA: 'TOTP' | 'SMS' | 'NOMFA',
) => Promise<void> = async (user, preferredMFA) => {
  await Auth.setPreferredMFA(user, preferredMFA);
};

export const signout: () => Promise<void> = async () => {
  try {
    await Auth.signOut();
    return;
  } catch (error) {
    console.error(error);
  }
};

export const forgotPassword: (email: string) => Promise<void> = async (
  email,
) => {
  try {
    await Auth.forgotPassword(email.toLowerCase());
    return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    throw error.code;
  }
};

export const updateUserAttributes = async (attributes: {
  [key: string]: string;
}): Promise<void> => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const newAttributes = Object.fromEntries(
      Object.entries(attributes).map(([key, value]) =>
        ['email', 'sub'].includes(key)
          ? [key, value]
          : [`custom:${key}`, value],
      ),
    );

    await Auth.updateUserAttributes(user, newAttributes);
  } catch (error) {
    console.error(error);
    throw error.code;
  }
};

export const updateUserPassword = async (
  oldPassword: string,
  newPassword: string,
): Promise<void> => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
  } catch (error) {
    console.error(error);
    throw error.code;
  }
};

export const forgotPasswordSubmit: (
  email: string,
  code: string,
  newPassword: string,
) => Promise<void> = async (email, code, newPassword) => {
  try {
    await Auth.forgotPasswordSubmit(email.toLowerCase(), code, newPassword);
    return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    throw error.code;
  }
};

export const isUserSignedIn: () => Promise<boolean> = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (error) {
    return false;
  }
};

export const getSignedInUser = async (): Promise<User> => {
  const rawUser = await Auth.currentAuthenticatedUser();
  return transformUser(rawUser);
};
