import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ResultsProps } from '../Results';
import {
  SearchInput,
  Pagination,
  WorkflowError,
  Alert,
  InfiniteScroller,
} from '_molecules';
import type { AllDataArticle } from '../../../services/searchService';
import { useElasticSearch } from '../../../hooks/useElasticSearch';
import { SearchFilterValue } from '_types';
import { Error, LoadingSpinner } from '_atoms';
import {
  WordStratus,
  SocialMediaCard,
  PressCard,
  PictureCard,
  ResultCounters,
  SearchResultCard,
  CompanyCard,
} from '_organisms';

import allDataFilters from './filters.json';
import { nonProdDataTestId } from '_utils';

const mockKeywords = [
  { value: 'Millionen Dollar', count: 4 },
  { value: 'Geldauflage', count: 23 },
  { value: 'Dating-Riese', count: 5 },
  { value: 'Dresden', count: 5 },
  { value: 'ghitubfui', count: 5 },
  { value: 'ghitubfui', count: 5 },
  { value: 'ghitubfui', count: 5 },
  { value: 'tubfui', count: 5 },
  { value: 'itubfui', count: 5 },
  { value: 'hitubfui', count: 5 },
  { value: 'ghitubfui', count: 5 },
  { value: 'bfui', count: 5 },
];

const initialValues = {
  items: [],
};

const pagingEnabled = false;

type AllDataFilters = {
  publishedAt: SearchFilterValue;
  sentiment: SearchFilterValue;
  language: SearchFilterValue;
};

export const AllDataSearch: FC<ResultsProps> = ({
  accessToken,
  targetData,
  caseData,
}: ResultsProps) => {
  const { t } = useTranslation();
  const { caseId, targetId } =
    useParams<{ caseId: string; targetId: string }>();

  const {
    searchInputProps: { renderedFilters, ...searchInputProps },
    data: { items, paging, count } = initialValues,
    isLoading,
    isError,
    handlePageChange,
  } = useElasticSearch<AllDataFilters, AllDataArticle[]>({
    caseId,
    targetId,
    searchFilters: allDataFilters,
    pagingEnabled: pagingEnabled,
  });

  return (
    <div className="px-5">
      <SearchInput {...searchInputProps}>{renderedFilters}</SearchInput>
      <ResultCounters count={count} />
      <WordStratus
        keywords={mockKeywords}
        onKeywordClick={(i) => {
          searchInputProps.onSearchInput(
            `${searchInputProps.value} ${mockKeywords[i].value}`,
          );
        }}
        className="hidden"
      />

      <WorkflowError errors={targetData?.workflowErrors} path="profile" />

      {targetData?.status === 'HasInitialProfile' && (
        <Alert
          alertType="warning"
          headline={t('profileDataNotFinal')}
          className="mb-2"
        />
      )}

      <div
        className="relative mt-5 z-10"
        data-testid={nonProdDataTestId('all-data-search')}
      >
        {isLoading ? (
          <div className="absolute top-0 flex justify-center w-full z-0">
            <LoadingSpinner message={t('profileLoading')} />
          </div>
        ) : isError ? (
          <Error
            headline={t('profileErrorHeadline')}
            message={t('profileErrorRetryMessage')}
          />
        ) : null}
        {items.length ? (
          <Fragment>
            {items
              ? items.map((item: AllDataArticle) => {
                  switch (item.type) {
                    case 'press':
                      return (
                        <PressCard
                          key={item.id}
                          data={item}
                          query={searchInputProps.value}
                          targetData={targetData}
                          caseData={caseData}
                        />
                      );
                    case 'SocialAccountConnection':
                      return (
                        <SocialMediaCard
                          key={item.id}
                          data={item}
                          accessToken={accessToken}
                          targetData={targetData}
                          caseData={caseData}
                        />
                      );
                    case 'SocialAccountPost':
                      return (
                        <SocialMediaCard
                          key={item.id}
                          data={item}
                          query={searchInputProps.value}
                          accessToken={accessToken}
                          targetData={targetData}
                          caseData={caseData}
                        />
                      );
                    case 'Image':
                      return (
                        <PictureCard
                          key={item.id}
                          data={item}
                          query={searchInputProps.value}
                          targetData={targetData}
                          caseData={caseData}
                        />
                      );
                    case 'WebPage':
                      return (
                        <SearchResultCard
                          key={item.id}
                          data={item}
                          query={searchInputProps.value}
                          targetData={targetData}
                          caseData={caseData}
                        />
                      );
                    case 'TargetCompany':
                      return (
                        <CompanyCard
                          key={item.id}
                          data={item}
                          query={searchInputProps.value}
                          targetData={targetData}
                          caseData={caseData}
                        />
                      );
                  }
                })
              : null}

            {paging?.pagesCount > 1 &&
              (pagingEnabled ? (
                <div className="px-5">
                  <Pagination
                    paging={paging}
                    setPageNumber={(page) => handlePageChange(page)}
                  />
                </div>
              ) : (
                <InfiniteScroller
                  paging={paging}
                  isLoading={isLoading}
                  setPageNumber={(page) => handlePageChange(page)}
                />
              ))}
          </Fragment>
        ) : isLoading ? null : (
          <Alert alertType="info" message={t('noData')} className="mb-5" />
        )}
      </div>
    </div>
  );
};
