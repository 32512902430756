import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { Target } from '../../services/dataService';

const fetchTarget = async (caseId: string, targetId: string): Promise<Target> =>
  (
    await axios.get(
      `https://${backendBaseUrl}/cases/${caseId}/targets/${targetId}`,
    )
  ).data;

export const useTargetQuery = (
  caseId: string,
  targetId: string,
): UseQueryResult<Target> =>
  useQuery(['target', caseId, targetId], () => fetchTarget(caseId, targetId), {
    refetchInterval: 3000,
    enabled: !!targetId,
    cacheTime: 0,
  });
