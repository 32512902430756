/* istanbul ignore file */
export const en = {
  translation: {
    schemaValidation: {
      invalidDate: 'Invalid date',
      invalidEmail: 'Invalid email address',
      invalidPhone: 'Invalid phone number',
      invalidUrl: 'Invalid URL',
      invalidFacebookUrl: 'Invalid Facebook URL',
      invalidInstagramUrl: 'Invalid Instagram URL',
      invalidLinkedinUrl: 'Invalid Linkedin URL',
      invalidTwitterUrl: 'Invalid Twitter URL',
      tooFewCharacters: 'Too few characters',
      invalidNumber: 'Invalid number',
      invalidInteger: 'Invalid integer',
      invalidPositiveNumber: 'Number must be greater than 0',
      invalidCharacter: 'Invalid character',
      required: 'Field is required',
      maxLength: 'Max length of the field exceeded ({{maxLength}} characters)',
    },
    passwordValidation: {
      min: 'Minimum {{minLength}} characters',
      oneNumber: 'Contains at least one number',
      oneSpecialChar: 'Contains at least one special character',
      oneUppercase: 'Contains at least one capital letter',
      oneLowercase: 'Contains at least one lowercase letter',
      passwordMatch: 'Passwords match',
    },
    targetNewForm: {
      invalidBirthdayDate: 'Invalid date of birth',
      targetCreationInProgress: 'Target is being created',
      targetDeletionNotAllowed: 'Target deletion not allowed',
      dateOfBirthLabel: 'Date of birth or birth year',
      dateOfBirthPlaceholder: 'DD.MM.YYYY or YYYY',
    },
    others: 'Others',
    required: 'Required field',
    requiredNameError: 'First and last name must be provided',
    emailAlreadyExists: 'The provided email address is already taken.',
    recommended: 'Recommended',
    back: 'Back',
    continue: 'Continue',
    save: 'Save',
    showMore: 'Show more',
    showLess: 'Show less',
    download: 'Download',
    archive: 'Archive',
    delete: 'Delete',
    open: 'Open',
    edit: 'Edit',
    admin: 'Admin',
    cases: 'Cases',
    user: 'User',
    profileImage: 'Profile Image',
    editProfile: 'Edit Profile',
    changePassword: 'Change Password',
    fallbackProfileImage: 'Fallback Profile Image',
    loading: {
      searchingCandidates:
        'Searching data sources and compiling potential profiles.\nThis can take several minutes',
      collectingData:
        'Selection successfully submitted.\nChecking all sources and compiling final report.\nThis usually takes about an hour, you will be notified by email once the report is ready.',
    },
    moreInfo: 'More information',

    // Network Graph
    networkGraph: {
      title: 'Network Graph',
      graphDepth: 'Network depth',
      graphLevels: 'Level',
      unknownRole: 'Unknown Role',
    },

    timeline: {
      title: 'Timeline',
    },

    cvData: {
      title: 'CV data',
    },

    geoLocations: {
      title: 'Notes on locations',
    },

    persons: {
      title: 'Persons',
    },

    // Navigation
    overview: 'Overview',
    personalData: 'Personal Data',
    socialMediaSection: 'Social Media',
    socialMediaConnectionSection: 'Social Media Connections',
    socialMediaPostSection: 'Social Media Posts',
    pressSection: 'Press',
    companySection: 'Register data',
    searchEngineSection: 'Search engines',
    pictureSection: 'Visuals',
    noData: 'No data',
    noDataFound: 'No data available',
    network: 'Network',
    downloads: 'Download',
    indicium: 'Indicium',
    signOut: 'Logout',
    searchAndFilter: 'Search & Filter',
    inputDataAndSources: 'Input data & sources',
    inputData: 'Input data',
    targetPersonSelection: 'Target person selection',
    sourceOverview: 'Source overview',
    caseData: 'Case data',
    backToOverview: 'Back to the overview',

    rawData: {
      title: 'Raw Data',
      search: 'Free text search',
      socialMedia: 'Social Media',
      press: 'Press',
      registerData: 'Commercial register data',
      searchEngines: 'Search engines',
      imageSlashVideos: 'Images/videos',
      disabledTooltip: 'The raw data was deleted in accordance with the GDPR',
    },

    // newTarget: data input
    targetPersonDataHeadline: 'Target person data',
    targetPersonDataSubHeadline: 'What do we know about the target person?',
    basisData: 'Basic data',
    title: 'Title',
    titles: {
      dr: 'Dr.',
      prof: 'Prof.',
      profDr: 'Prof. Dr',
    },
    gender: 'Gender',
    genders: {
      male: 'male',
      female: 'female',
      // TODO add support for other
      // other: 'other',
    },
    name: 'Surname',
    firstname: 'First name',
    middlename: 'Middle name',
    lastname: 'Last name',
    nickname: 'Nickname',
    nicknameAlternative: 'Nickname / stage name',
    dateOfBirth: 'Birthday',
    nationality: 'Nationality',
    placeOfBirth: 'Place of birth',
    countryOfBirth: 'Country of birth',
    occupation: 'Job role',
    placesLived: 'Places of residence',
    contactData: 'Contact details',
    phone: 'Phone',
    phonePlaceholder: '+49 1234 567890',
    mobile: 'Mobile phone',
    email: 'Email',
    emailPlaceholder:
      'john.doe@example.com (person specific address (no info@, contact@, etc.)',
    address: 'Address',
    companyWebsite: 'Company website',
    websites: 'Websites',
    roles: 'Roles',
    shareholders: 'Shareholders',
    bankDetails: 'Bank details',
    company: 'Company',
    companyRole: {
      label: 'Role within the company',
      optionLabels: {
        Unknown: 'Unknown',
        Employee: 'Employee',
        ManagingDirector: 'Managing Director',
        Shareholder: 'Shareholder',
        AuthorisedSignatory: 'Authorized signatory',
        Other: 'Other',
      },
    },
    unused: 'This field will be available in future releases',
    employee: 'Employee',
    companyEducation: 'Company / Education',
    companyOwner: "Target person's company",
    companyName: 'Company name',
    companyLocation: 'Headquarters',
    commercialRegisterNumber: 'Commercial Register Number',
    commercialRegisterNumberPlaceholder:
      'The commercial register number must consist of at least 5 characters',
    companyVatNumber: 'VAT number',
    companyVatNumberPlaceholder:
      'The sales tax number must consist of at least 5 characters',
    website: 'Website',
    websitePlaceholder: 'https://www.example.com',
    socialMediaProfiles: 'Social media profiles',
    socialMediaPrivacyHint:
      'Privacy notice: social media profiles may only be included in the analysis if there is a legitimate interest',
    socialMediaFormatHint: 'Link + Handle / Handle',
    targetPersonDetails: 'Narrow down the target person',
    targetPersonDetailsHint:
      'You can improve the quality of the results by entering keywords for the target person',
    targetPersonDetailsKeywords: 'Keywords for identifying the target person',
    targetPersonAmbiguityKeywords:
      'Keywords to exclude persons with the same name',
    targetPersonAmbiguityKeywordsHint:
      "Are there any prominent people with the target person's name, or people in the same industry?",
    invalidNationalityMessage:
      'The entry could not be assigned to a nationality',
    invalidCountryMessage: 'The input could not be assigned to a country',
    invalidServerResponse:
      'An error has occurred. Please check your input and try again.',

    //newTarget: resolve ambiguity
    age: 'Age',
    // TODO: IND-460 Check usage of duplicate keys on global level
    // Some of the keys inside `candidateInfo` like `age` already existed at the
    // root level before the `candidateInfo` nesting group was added. I haven't
    // removed them from the root level as it is currently unclear whether some
    // other component of the application has been reusing them.
    // Once IND-460 has been completed and other translation usage has been
    // migrated to use context / feature specific nesting, they keys from
    // candidateInfo that also exist at the root level should be checked for
    // obsolescence.
    candidateInfo: {
      placeOfResidency: 'Residence',
      age: 'Age',
      company: 'Company',
      nationality: 'Nationality',
      phone: 'Telephone',
      email: 'Email',
      website: 'Website',
      url: 'URLs',
      placeOfBirth: 'Place of birth',
      dateOfBirth: 'Date of birth',
      username: 'Username',
      gender: 'Gender',
      language: 'Language',
      originCountry: 'Country of origin',
      education: 'Education',
      city: 'City',
      state: 'State',
      country: 'Country',
      scientificTitle: 'Profession',
    },
    placeOfResidency: 'Residence',
    resolveTargetCandidatesHeadline: 'Resolve ambiguities',
    // TODO 'resolveTargetCandidatesSubHeadline' needs German approval
    resolveTargetCandidatesSubHeadline:
      'Please select all tiles with your target person',
    selectCandidate: 'Select person',
    excludeCandidate: 'Exclude person',
    startAnalysis: 'Request Report',

    // Dashboard: Overview
    dashboardOverviewHeadline: 'Overview',
    targetNameSubHeadline: 'Indicium Analysis',
    overviewAnalysis: 'Analysis',
    overviewAnalysisBadge: 'beta',
    overviewAnalysisResult:
      'Potentially problematic content found - please check',
    overviewAnalysisNoResult: 'No suspicious information found',
    dashboardOverview: {
      subHeadline: 'Overall rating',
    },

    // Red Flags
    redFlags: {
      heading: 'Examination required',
      cardHeadline: 'Critical issues',
      cardSubHeadline: 'Results found',
      headers: {
        name: 'Entity name',
        role: 'Company role(s)',
        detail: 'Red Flag Details',
        country: 'Country',
        source: 'Source',
        description: 'Description',
      },
      types: {
        pep: {
          detail: 'Politically exposed person',
          description:
            'The target person finds himself on a list for high-ranking public active persons (Politically Exposed Persons)',
        },
        sanction: {
          detail: 'Sanction list',
          description: 'The target was found on a global sanctions list',
        },
        enforcement: {
          detail: 'Law enforcement',
          description: 'The target was found on a global law enforcement list',
        },
        soe: {
          detail: 'SOE',
          description:
            'The target has links to state-owned property or governments and through their employees (State Owned Enterprises)',
        },
        government_link: {
          detail: 'Government Linked',
          description:
            'The target has links to government property as well as their employees',
        },
        adverse_media: {
          detail: 'Adverse Media',
          description:
            'The target is possibly linked to illegal money laundering and terrorist financing activities, based on news published by credible media',
        },
        associated_entity: {
          detail: 'Associated Entity',
          description:
            'The target is associated with at least one sanctioned entity',
        },
        registrations: {
          detail: 'Registration',
          description:
            'The target is associated with surveillance material published by official government agencies, international organisations, industry regulators and event disciplinary committees that meet qualified standards and definitions.',
        },
        generic_red_flag: {
          detail: 'General conspicuousness',
          description: 'The target is on a global law enforcement list',
          sources: {
            orbis:
              'Global sanctions lists, watchlist and politically exposed person lists (via "BvD Compliance Catalyst")',
          },
        },
      },
      rawTypes: {
        'PEP/Former PEP': 'Current or former Politically Exposed Person (PEP)',
      },
      reportsHeading: 'Detailed Report',
      reportsItem: 'Report',
      roleNotAvailable: 'N/A',
      positionIsCurrent: 'Present',
      unknownTimeframe: 'Unknown timeframe',
      unknownRole: 'Unknown role',
    },

    // Profile: Overview
    source: 'Source',
    sources: 'Sources',
    profileHeadline: 'Profile',
    downloadPDF: 'PDF',
    profileLoading: 'Data being loaded',
    profileDataNotFinal:
      'It may take up to 1 hour for the displayed data to be complete',
    profileErrorHeadline: 'An error has occurred',
    profileErrorMessage: 'Your request could not be processed',
    profileErrorRetryHeadline: 'No data is available yet',
    profileErrorRetryMessage: 'Please try again in a few minutes',
    workflowRunningMessage:
      'The process is still running, please come back later. (This may take up to half an hour)',
    complianceIndication: 'Indications of compliance violations',
    searchTerms: 'Own search terms',
    profileSize: 'Profile size',
    networkSize: 'Network size',
    from: 'From',
    to: 'To',
    role: 'Activity',
    cv: 'Resume',

    // profiles: Raw data
    data: 'Data',
    excluded: 'Excluded',
    includeCandidate: 'Include person',
    backToDataInput: 'To data input',
    cancelCreateTarget: 'End analysis',
    noPersonHeadline: 'No persons found',
    noPersonText:
      'Unfortunately, we have not identified a possible target person based on your input. Please check your entry.',
    summaryBetaTooltip: `This is a beta feature using Large Language Models (LLMs) for summarization. \nFor any questions, please reach out to your account manager.`,

    // Admin: User Admin
    overviewOfAllUsers: 'Overview of all users',
    createNewUser: 'Create new user',
    addButton: 'Add',
    closeButton: 'Close',
    addMoreUsers: 'Create another user',
    assignedCases: 'Active assigned cases',
    activeReports: 'Active Target Reports',
    totalReports: 'Total target reports (including deleted)',
    userTableFooter: 'Total',
    createdOn: 'Created on',
    createdAt: 'Created on',
    changedOn: 'Changed on',
    updatedAt: 'Changed on',
    notAvailable: 'N/A',

    // Admin: Customer Admin
    overviewOfAllCustomers: 'Overview of all customers',
    createNewCustomer: 'Create new customer',
    customerName: 'Customer name',
    numberOfReports: 'Reports',
    limitOfReports: 'Report limit',
    expiresAt: 'Contract ends on',
    updateCustomer: {
      action: 'Edit customers',
      formHeadline: 'Available fields',
    },
    lastReportCreatedAt: 'Last report created',

    // Admin: Cases Admin
    overviewOfAllCases: 'Overview of all cases',
    createNewCase: 'Create new case',
    createNewTarget: 'Create new target',
    openCases: 'Open cases',
    archivedCases: 'Archived cases',
    caseName: 'Case name',
    analysisType: 'Analysis Type',
    analysisValue: {
      unknown: 'UNKNOWN',
      naturalPerson: 'Natural person',
      unnaturalPerson: 'Unnatural person',
    },
    mustBeUnique: 'Must be unique',
    caseNameMustBeUnique: 'Case name must be unique',

    subject: 'Subject',
    targetPerson: 'Target person',
    targetPersonPress: '{{name}} (target person)',
    targetNetworkPress: '{{name}} (network level {{networkLevel}})',
    editor: 'Editor',
    creator: 'Creator',
    dateChanged: 'Changed',
    dateCreated: 'Created',
    titleOfCase: 'Title of the case',
    exampleTitle: 'Example title',
    assignUserToCase: 'Assign user to case',
    assignUserToCaseSubtext:
      'Select here which staff members you want to give access rights to work on this case. Administrators in your organisation have access to all cases',

    thematicFieldsOfAnalysis: {
      headline: 'Thematic fields of analysis',
      redFlags: 'Critical Themes',
      redFlagsSubtext:
        'Matching the name with national and international lists',
      rawData:
        'Raw data for potential compliance violations and criminal behaviour',
      rawDataSubtext:
        'Compilation of material on the target, based on trade register information, press sources and entries on social media platforms.',
      tip: 'Note',
      tipSubtext: 'For the DACH region, the results are focused.',
    },
    currentResults: 'Current results',
    archivedResults: 'Archived results',
    noCaseFoundHeadline: 'No case found',
    noCaseFoundText:
      'Unfortunately, we have not identified a possible case based on your input. Please check your entry',
    deleteCase: {
      action: 'Delete case',
      modalTitle: 'Are you sure you want to delete the selected case?',
      modalText:
        'Are you sure you want to delete the selected case? Please make sure that you have exported and saved all reports relevant to you beforehand, as these can no longer be made available for download after deletion.',
      radioOptions: [
        'Yes, I have downloaded the relevant target person reports in advance and would like to delete the selected case',
        'Yes, I want to delete the case and all data irrevocably, but have not downloaded the reports as I do not need them',
      ],
      cancelButton: 'Cancel deletion',
      confirmButton: 'Confirm deletion',
    },
    editCaseAction: 'Edit case',
    editCaseFormHeadline: 'Available fields',
    addAdminPermissionsAction: 'Add admin rights',
    removeAdminPermissionsAction: 'Revoke admin rights',
    deleteTarget: {
      action: 'Delete target',
      modalTitle: 'Are you sure you want to delete this target irrevocably?',
      modalText:
        'Are you sure you want to delete the selected target person irrevocably? Please make sure that you have exported and saved the report of the target person beforehand, as it can no longer be made available for download after the deletion.',
      radioOptions: [
        'Yes, I have downloaded the target person report in advance and would like to delete the selected target person',
        'Yes, I want to delete the target person and their data irrevocably, but have not downloaded their report as I do not need it',
      ],
      cancelButton: 'Cancel deletion',
      confirmButton: 'Confirm deletion',
    },
    targetStatus: {
      Created: 'Created',
      Completed: 'Completed',
      FetchingCandidates: 'Fetching Candidates',
      CandidateSelectionPending: 'Candidate Selection Pending',
      CandidateSelectionCompleted: 'Candidate Selection Completed',
      FetchingDetails: 'Fetching Details',
      HasInitialProfile: 'Has Initial Profile',
    },
    deleteUser: {
      action: 'Delete user',
      modalTitle: 'Are you sure you want to delete this user?',
      modalText: 'Are you sure you want to delete this user irrevocably?',
      radioOptions: [
        'Yes, I would like to irrevocably delete the selected user',
      ],
      cancelButton: 'Cancel deletion',
      confirmButton: 'Confirm deletion',
    },
    deleteCustomer: {
      action: 'Delete customer',
      modalTitle: 'Are you sure you want to delete this customer account?',
      modalText:
        'Are you really sure you want to delete the selected customer account? Please make sure that all relevant target person reports have been exported and saved beforehand, as these will be deleted together with the customer account and can no longer be made available for download after deletion.',
      radioOptions: [
        'Yes, I have pre-downloaded the relevant target person reports and would like to delete the selected client',
        'Yes, I would like to irrevocably delete the client and all associated data, but have not downloaded the reports as I do not need them',
      ],
      cancelButton: 'Cancel deletion',
      confirmButton: 'Confirm deletion',
    },
    editCustomerAction: 'Edit customer',
    editCustomerFormHeadline: 'Available fields',
    quotaExceeded: 'Your allowed volume of reports has been reached',
    quota: 'Quota',

    // Press
    press: 'Press',
    position: 'Position',
    date: 'Date',
    publicationDate: 'Publication date',
    retrievalDate: 'Retrieval date',
    preview: 'Preview',
    author: 'Author',
    copyright: 'Copyright ©',
    previous: 'Previous',
    next: 'Next',
    managers: 'Manager',

    // SocialMedia
    socialMedia: {
      contactsHeadline: 'Social media contacts',
      contactsNoAccounts: 'No social media accounts were found',
      postsHeadline: 'Social media posts',
      media: 'Media',
      url: 'Source',
      itemType: 'Item type',
      commentCount: 'Comments',
      likeCount: 'Likes',
      date: 'Date',
      datePublished: 'Date',
      tags: 'Tags',
      links: 'Links',
      mentions: 'Mentions',
      profileName: 'Profile Name',
      profileId: 'Profile ID',
      profileImageUrl: 'Profile Image URL',
      profileImageWebAddress: 'Profile Image Web Address',
      profileLocation: 'Profile Location',
      inLanguage: 'Language',
      postingPlatform: 'Posted via',
      profileFollowers: 'Profile Subscribers',
      profileFollowing: 'Profile subscribed',
      source: 'Platform',
      favoriteCount: 'Favoured',
      retweetCount: 'Retweeted',
      userName: 'Username',
      contentId: 'Content ID',
      dateRecorded: 'Date of Entry',
      fullName: 'Full Name',
      mediaWebAddress: 'Media URL',
      isSharedContent: 'Shared Content',
      connectionType: 'Connection Type',
      username: 'User Name',
      mediaType: 'Media Type',
      body: 'Content',
      inReplyToUsername: 'In reply to username',
      inReplyToContentId: 'In reply to Content ID',
      inReplyToProfileId: 'In reply to Profile ID',
      friend: 'Friends',
      noFriends: 'No friends',
      follower: 'Followers',
      noFollowers: 'Does not follow any profile',
      followed: 'Following',
      nofollowed: 'No followers',
      noContacts: 'No contacts',
      connections: 'Connections',
      profileUrl: 'Profile URL',
      posts: 'Post',
      profile: 'Profile',
      friendsAndInteractants: 'Friends & interactants',
    },

    // Socials
    facebook: 'Facebook',
    facebookPlaceholder: 'https://facebook.com/johndoe',
    instagram: 'Instagram',
    instagramPlaceholder: 'https://instagram.com/johndoe',
    linkedin: 'LinkedIn',
    linkedinPlaceholder: 'https://linkedin.com/in/johndoe',
    twitter: 'Twitter',
    twitterPlaceholder: 'https://twitter.com/johndoe',
    vkontakte: 'VKontakte',
    pinterest: 'Pinterest',
    flickr: 'Flickr',
    skype: 'Skype',
    whatsapp: 'WhatsApp',

    // signIn
    signIn: {
      headline: 'Sign in',
      passwordForgotHeadline: 'Reset password',
      passwordResetRequiredHeadline: 'Reset password',
      emailAddressLabel: 'Email address',
      emailAddressInputPlaceholder: 'Email address',
      passwordLabel: 'Password',
      passwordInputPlaceholder: 'Password',
      signInButton: 'Sign in',
      rememberMe: 'Stay logged in',
      forgotPassword: 'Forgot your password?',
      imprint: 'Imprint',
    },

    authentication: {
      headline: 'Multi-factor Authentication',
      description:
        'Please enter the code from your authentication app to complete the login process.',
      instructions: `
        1. Install an authentication app on your mobile device: If you haven't already, download and install an authentication app on your mobile device. Some popular options are Google Authenticator (available for Android and iOS) and Authy (available for Android and iOS).\n
        2. Scan the QR code: Open the authentication app and scan the QR code below. If you can't scan the QR code, you can manually enter the code and key below the QR code.\n
        3. Enter the authentication code: Enter the authentication code generated by the authentication app and click "Submit".\n
      `,
      codeInputPlaceholder: 'Authentication code',
      submitButton: 'Submit',
      oneTimePassword: 'One-time password',
    },

    passwordReset: {
      password: 'Password',
      oldPassword: 'Old password',
      newPassword: 'New password',
      newPasswordRepeat: 'Repeat password',
      newPasswordConfirm: 'Confirm new password',
      verifyAccount: 'Confirm email address',
      updatePassword: 'Update password',
    },

    // Error
    NotAuthorizedException:
      'You are not logged in. Email address or password are incorrect',
    UserNotFoundException:
      'You are not logged in. Email address or password are not correct',
    PasswordEmptyOrMismatchException:
      'The passwords entered are blank or do not match.',
    InvalidPasswordException:
      'The selected password must meet the following requirements:',
    passwordRequirements: [
      'Min. 8 characters',
      'Upper and lower case letters',
      'Numbers and special characters',
    ],
    PasswordResetRequiredException:
      'This is the first time you are logging in. Please set a new password',
    PasswordResetExpiredCodeException: 'The code you have entered has expired.',
    InvalidParameterException: 'The entered code is not correct.',
    CodeMismatchException: 'The entered code is not correct.',
    LimitExceededException:
      'You have tried to change your password too many times. Wait a few minutes before trying again.',
    PasswordChallengeException:
      'Currently your password cannot be reset. Please use the password you received by email.',
    PasswordExpiredException:
      'Your temporary password is no longer valid. Please contact support to get a new password.',
    WrongPasswordException: 'The entered password is not correct.',
    uhOh: 'Uh-oh!',
    noPageFound: 'We can not find the page you are looking for',
    goBack: 'Please go back',
    noUsers: 'No users',
    noResultHeadline: 'No results found',
    noResultText:
      'Unfortunately, we did not find a possible result based on your input. Please check your entry',
    noTradeRegisterDataText:
      'We have checked over 400 million companies globally: a person with the specified name could not be identified with register-relevant, entrepreneurial activity',
    caseAgentsUpdated:
      'The user(s) have been successfully assigned to the case',
    caseAgentsAssignError: 'The user(s) could not be assigned to the case',
    firstPage: '<< first page',
    previousPage: '< previous page',
    nextPage: 'next page >',
    lastPage: 'last page >>',
    workflowErrors: {
      headline:
        'An error occurred while collecting the information on the target person',
      candidates:
        'Error while searching for potential candidates matching the specified target person data',
      profile: "Error while collecting the target's profile and company data.",
      press: "Error collecting the target's press data.",
      network: "Error collecting or creating the target's network data.",
      socialmedia: 'Error collecting social media data of the target.',
      appendix: 'Therefore, no or incomplete data may be displayed.',
    },
    pageNotFound: {
      title: 'Something went wrong here',
      subtitle:
        'This page does not exist or you lack permission to access this page',
      description:
        'Please check the URL for correctness or try again at a later time. If the problem persists, contact support.',
      button: 'Back to the overview',
    },
    organization: 'Organisation',
    privilegeLevel: 'Role',
    CustomerOwner: 'Owner',
    CustomerAdmin: 'Administrator',
    CustomerUser: 'Employee',
    administrators: 'Administrators',

    companyList: {
      roleStatus: {
        current: 'Current',
        previous: 'Former',
      },
    },
    caseNew: {
      stepOne: 'Create case',
      stepTwo: 'Set case parameters',
      topicsTitle: 'Topics of the analysis',
      topicsParagraph: 'The following analyses are supported by the system',
      redFlagsTitle: 'Potential critical issues',
      redFlagsParagraph:
        'Matching of the name of the target, its related companies and the persons related to these companies with national and international politically exposed person and sanctions lists',
      complianceTitle: 'Notes in connection with compliance or criminal issues',
      complianceParagraph:
        'Keyword-based analysis and identification of anomalies in text material relating to the target person. We examine terms from the area of classical crime (organised crime, terrorism, extremism) as well as, in particular, from the area of white-collar crime',
      keywordsLink: 'List of keywords and topics fields',
      businessRegistryTitle:
        'Flagging conspicuous characteristics in business registry data',
      sources: {
        title: 'Sources',
        searchEngines: 'Search engines',
        commercialRegisters: 'Commercial Register',
        sanctionsList: 'Politically exposed person/sanctions lists',
        webSites: 'Websites',
        pressArchive: 'Press Archives',
        businessSocialMedia: 'Business Social Media',
        privatePublicSocialMedia: 'PRIVATE, PUBLIC Social Media',
      },
      selectOption: 'Cause of analysis',
      title: {
        option1: 'Integrity check business initiation ¹',
        option2: "Integrity check with target's consent ²",
        option3: 'Suspicion-based research ³',
      },
      option1Asterisk: `1) Searches are carried out out of economic interest based on planned business initiation, but without concrete suspicion. Indicium only processes publicly available information online that suggests a business connection.
      In principle, you are obliged under the GDPR to inform the data subjects about the planned processing. You may refrain from doing so if the planned processing would jeopardise the purpose of the processing (e.g. closing the deal). If you have any further questions, please feel free to contact the Indicium team at fallanlage@indicium.ag.`,
      option1Description1:
        'Business-related online publicly available sources (trade registers, press, social media with a business connection and search engines)',
      option1Description2: 'Exclusion of purely private social media portals',
      option2Asterisk:
        '2) Searches are conducted with the information and consent of the target person. Consent must be obtained along the information clusters offered (All information publicly available on the internet, private site social media, specific search terms, possibly credit rating). If you have any further questions, please feel free to contact the Indicium team at fallanlage@indicium.ag.',
      option2Description1: 'Individual selection of sources',
      option2Description2: 'Individual option for keyword search',
      option3Asterisk:
        '3) Suspicion-related searches are carried out on the named person(s). There are indications of a reasonable suspicion that the persons investigated have violated internal company regulations, have committed a criminal offence or have committed a misdemeanour. The legitimate interests of the persons concerned do not outweigh your interest in clarifying the violation. The data subjects have not been informed about the intended processing because this would jeopardise the purpose of the processing. Case-specific search terms must be provided. If you have any further questions, please feel free to contact the Indicium team at fallanlage@indicium.ag.',
      option3Description1:
        'All online publicly available sources (trade register, press, social media and search engines)',
      option3Description2: 'Keywords related to the case should be provided',
      backgroundCheckTitle:
        'Background check - business initiation without concrete suspicion',
      backgroundCheckDescription1:
        'Compliance searches are carried out out of economic interest based on planned business initiation, but without concrete suspicion. Indicium only processes information that is publicly available online.',
      targetPersonInformationTitle: 'Information of the data subject',
      targetPersonInformation: {
        yes: 'The data subjects have been informed about the planned processing.',
        no: 'The data subjects have not been informed about the intended processing because this would jeopardise the purpose of the processing',
      },
      osintCheckWithConsent:
        'OSINT check with consent of all targeted individuals',
      publicInfoDesc:
        'All information publicly available on the internet (text, image), including information pursuant to Art. 9(1) of the GDPR, which is related to the individual, even if made public by third parties',
      privateSocialMedia:
        'Information in the purely private sphere on social media platforms',
      creditRating: 'Credit rating information ("Schufa")',
      customSearchTerms: 'Specific search terms can be added',
      suspCompInvestigation: {
        optionDesc: 'Suspicion-related compliance research',
        section2MainTitle: 'Add case-specific keyword to analysis',
        section3Title: 'Individual search terms',
        section2Description1:
          'With your own case-related keywords you can give the analysis an additional depth. We will identify your or similar keywords in the found data.',
      },
      addNewKeyword: 'Add more keywords',
      leftFooter: {
        title: 'Comprehensive analysis on your dataset',
        bullet0:
          'Check for Critical Issues: politically exposed person check, sanctions list check and other watch lists of affiliates and contractors up to 2nd degree',
        bullet1:
          'Matching of all found texts associated with the person with critical keywords or negative content (Based on artificial intelligence analysis)',
        bullet2:
          'Automatic checking of suspicious factual connections from the perspective of a professional OSINT analyst',
        bullet3:
          'For case-related keywords: analysis on occurrence of keywords and keyword extensions (via machine learning).',
      },
      rightFooter: {
        title: ' Included sources for your analysis',
        bullet0:
          'Access to data from over 400 million companies globally across > 270 different providers',
        bullet1:
          'Special resolution with a further ~ 10 million datasets in Germany, Austria and Switzerland through connection of specialist providers',
        bullet2:
          'Daily updated, real-time global information via use of LexisNexis, access to more than 100,000 sources of national and international news and company information from over 100 countries in 90 languages.',
        bullet3:
          'Machine access to search engine results on your target person (texts; articles; documents, images)',
        bullet4:
          'Coverage of the public parts of the main social media channels such as Linked-in, Facebook, Twitter, Instagram.',
      },
      reasonForTheAnalysisPopup: {
        title: 'Set up case parameters',
        content:
          'For the GDPR-compliant selection of the analysis tools and sources, the framework conditions of the present case must be defined in the following. The respective consequences of the settings are explained again in detail in each point. Indicium is obliged to check the accuracy of the information on a random basis. If you have any further questions, please feel free to contact the Indicium team at ',
        contentEmail: 'fallanlage@indicium.ag.',
      },
      keywordsTitleGerman: 'Enter a keyword (German)',
      keywordsTitleEnglish: 'English',
      noTranslationsFound: 'No translation found',
      suggestSynonyms: 'Suggest synonyms',
      clearSynonyms: 'Clear synonyms',
      noSynonymsFound: 'No synonym found',
      keywordType: {
        topic: 'Topic',
      },
      gdprPopover: {
        title: '100% GDPR compliant',
        infoText1: 'All analyses are compliant with the GDPR',
        infoText2: 'Secure connection via SSL server',
        infoText3: 'Encrypted data',
      },
    },
    yes: 'Yes',
    no: 'No',

    consolidatedView: 'Consolidated View',
    present: 'Today',
    seeMore: 'Show more',
    seeLess: 'Show less',
    moreDetails: 'More details',

    image: 'Image',
    imageUpload: 'Upload image',
    uploadTheImage: 'Upload the image',
    browse: 'Browse',
    documentType: 'Document type',
    mdCeo: 'Managing Director/Executive Board',
    country: 'Country',
    zipCode: 'Postcode',
    street: 'Street',

    registrationDate: 'Registration date',
    registrationNumber: 'Registration number',

    keyword: 'Keyword',
    keywords: 'Keywords',
    /**
     * @todo: Clean-up and organize the keys better
     * Some of these translations exist on root level
     * Also there are duplicated because of naming inconsistencies between candidate tiles and target profile
     */
    infoBlockItem: {
      placeOfResidency: 'Place of residence',
      age: 'Age',
      company: 'Company',
      nationality: 'Nationality',
      phone: 'Phone',
      phones: 'Telephone',
      email: 'Email',
      emails: 'Email',
      website: 'Website',
      websites: 'Website',
      url: 'URLs',
      placeOfBirth: 'Place of birth',
      birthPlace: 'Place of birth',
      dateOfBirth: 'Birthday',
      birthday: 'Birthday',
      username: 'User name',
      gender: 'Gender',
      language: 'Language',
      originCountry: 'Country of origin',
      education: 'Education',
      city: 'City',
      state: 'State',
      country: 'Country',
      scientificTitle: 'Profession',
      firstname: 'Firstname',
      middlename: 'Middlename',
      lastname: 'Surname',
      placesLived: 'Places of residence',
      occupation: 'Job role',
    },
    yearsOld: '{{ age }} years old',
    pagination: {
      previous: 'Previous',
      next: 'Next',
    },
    filters: {
      dateFrom: 'Date from',
      dateTo: 'Date to',
      language: 'Language',
      sentiment: 'Mood',
      neutral: 'Neutral',
      positive: 'Positive',
      mixed: 'Mixed',
      negative: 'Negative',
      source: 'Source',
      type: 'Type',
      socialMedia: 'Social media',
      mainSocialMediaProfiles: 'Profiles',
      potentialSocialMediaProfiles: 'Potential Profiles',
      connectionType: 'Connection Type',
      status: 'Status',
      post: 'Post',
      connection: 'Connection',
      press: 'Press',
      registerData: 'Register data',
      searchEngines: 'Search engines',
      image: 'Image',
      sources: 'Sources',
      active: 'Active',
      inactive: 'Inactive',
      Orbis: 'Trade Register (via BvD Orbis)',
      Pipl: 'Internet research (via Pipl)',
      CompanyHouse: 'Commercial Register (via Companyhouse)',
      userInput: 'User input',
      Instagram: 'Instagram',
      Facebook: 'Facebook',
      Twitter: 'Twitter',
      LinkedIn: 'LinkedIn',
      Flickr: 'Flickr',
      Google: 'Google',
      Bing: 'Bing',
      English: 'English',
      German: 'Deutsch',
      confidence: 'Data confidence level',
      highConfidence: 'Target person with high confidence',
      indiferrent: 'Potentially target person, but unclear',
      highNegativeConfidence: 'Different person with high confidence',
      selected: 'Selected',
    },
    exactSearch: 'Exact search',
    hitsCount: '{{hits}} hits',
    sortedBy: 'Sorted by',
    suspicionsCount: '{{hits}} found',
    confirmedSuspicionsCount: '{{hits}} confirmed',
    reports: {
      redFlags: 'Potential critical topics',
      anomaliesCount: '{{count}} anomalies',
      sourcesCount: '{{count}} Sources checked',
      resultsCount: '{{count}} Results found',
      sections: {
        compliance_violations: 'Notices of compliance violations',
        general_crime: 'Indications of crime',
        own_keywords: 'Own keywords',
        general_anomalies: 'General abnormalities',
        problematic_content_detected: 'Potentially problematic content',
      },
      showAll: 'Show all',
      showLess: 'Show less',
    },
    relevanceScore: 'Relevance score',
    suspicion: 'Suspicion',
    relatedSuspicion: 'Content relates to',
    entity: 'Entity',
    googleCandidates: {
      groupTitle: 'Advanced search engine search',
      selectGroup: 'Selection',
      excludeGroup: 'Exclude',
      results: 'Results',
      groupTopics: 'Group Topics',
      moreDetails: 'More details',
      lessDetails: 'Less details',
    },
    addToReport: 'Add to Report',
    changeSuspicion: 'Change to other suspicion',
    changeSuspicionButton: 'Change',
    selectSuspicion: 'Select Suspicion',
    linkedSuspicions: 'Linked Suspicion(s)',
    suspicionItemEntityType: {
      SocialAccountConnection: 'Public connections on social media',
      SocialAccountPost: 'Public posts on social media',
      press: 'Press',
      TargetCompany: 'Registry data',
      WebPage: 'Search engines',
      Image: 'Visuals',
    },
    suspicionItemStatus: {
      problem: 'Problem',
      no_problem: 'Unobjectionable',
      pending: 'Pending',
      wrong_target: 'Wrong person',
    },
    setAsProfilePhoto: 'Use as profile picture',
    apiErrors: {
      403: 'Agreed volume of reports reached',
      default: 'An error has occurred. Please check your input and try again.',
    },
    companyStatus: {
      active: 'Active',
      'active (default of payment)': 'Active (default of payment)',
      'active (insolvency proceedings)':
        'Active (insolvency proceedings in progress)',
      'active (dormant)': 'Active (dormant)',
      insolvency: 'Insolvent',
      dissolved: 'Dissolved',
      'status unknown': 'Status unknown',
      inactive: 'Inactive',
      'in liquidation': 'In liquidation',
      ak: 'Active',
      ia: 'Inactive',
      'active (reorganization)': 'Active (reorganization)',
      bankruptcy: 'Bankruptcy',
      'dissolved (bankruptcy)': 'Dissolved (bankruptcy)',
      'dissolved (liquidation)': 'Dissolved (liquidation)',
      'dissolved (merger or take-over)': 'Dissolved (after take-over)',
      'inactive (no precision)': 'Inactive',
      'administratively suspended': 'Administratively suspended',
      default: 'Status unknown',
    },
    companyCardTabs: {
      basicData: 'Basic Data',
      subsidiaryCompany: 'Subsidiary Company',
      ownersAndManagers: 'Owners and Managers',
      financialInformation: 'Financial Information',
      publications: 'Publications',
    },
    companyMutations: {
      kapitalaenderung: 'Capital change',
      kapitalherabsetzung: 'Capital reduction',
      kapitalerhoehung: 'Capital increase',
      aenderungorgane: 'Change of organs',
      adressaenderung: 'Address change',
      status: 'Status',
      firmenaenderung: 'Company change',
    },
    sourceName: {
      orbis: 'Commercial Register (via BvD Orbis)',
      pipl: 'Internet research (via Pipl)',
      companyhouse: 'Commercial Register (via Companyhouse)',
      complianceCatalyst:
        'Global sanctions lists, watchlist and politically exposed person lists (via "BvD Compliance Catalyst")',
      firmenabc: 'Commercial Register (via FirmenABC)',
      lexisnexis: 'Press archives (via Lexis Nexis)',
      google: 'Internet research (via Google)',
      facebook: 'Social Media (via DC)',
      twitter: 'Social Media (via DC)',
      instagram: 'Social Media (via DC)',
      linkedin: 'Social Media (via DC)',
      flickr: 'Social media (via DC)',
      digitalclues: 'Social media (via DC)',
      zefix: 'Commercial Register (via Zefix)',
      userinput: 'User input',
    },
    inputDataDisclamer:
      'Here you can view your original input data. You also have the option to change the data and thus create another report (which will be subject to charge).',
    maintenance: {
      title: 'We are currently under maintenance',
      subtitle: 'Please try again later',
      reload: 'Reload',
    },
    companyCard: {
      dateOfIncorporation: 'Date of incorporation',
      companyActivity: 'Company activity',
      previousNames: 'Previous names',
      identifiers: 'Identifiers',
      tradeRegisterNumber: 'Trade register number',
      vatTaxNumber: 'USt-IdNr. (VAT/Tax number)',
      leiCompanyId: 'LEI(Company ID)',
      europeanVatNumber: 'European VAT number',
      subjectText: 'Subject text',
      subsidiaryName: 'Subsidiary Name',
      informationDate: 'Since',
      country: 'Country',
      shareholder: 'Shareholder',
      manager: 'Manager',
      actuality: 'Actuality',
      name: 'Name',
      dob: 'DOB',
      pob: 'POB',
      directlyOwned: 'Directly Owned %',
      ultimatelyOwned: 'Ultimately Owned %',
      address: 'Address',
      financials: 'Financials / Key data',
      turnover: 'Turnover',
      profit: 'Profit',
      employees: 'Employees',
      previous: 'Previous',
      current: 'Current',
    },
    dynamicForm: {
      title: 'more keywords',
      selectField: 'Field',
      fields: {
        occupations: 'Job role',
        relatedPersons: 'Person',
        websites: 'Website',
        organizations: 'Organisation',
        topics: 'Topic',
        locations: 'Location',
        nicknames: 'Nicknames',
        default: 'Word',
      },
      buttons: {
        include: 'Include',
        exclude: 'Exclude',
      },
      description:
        'Please enter further keywords that describe the target person (in German and English): places (country or city - in reference to place of birth / work / residence / ...), occupations (job title, position, ...), organizations (other than companies), affiliated websites, people (first and last name) or topics (hobbies, events, etc.) that are known to be associated with the target individual',
      totalKeywords: 'Total keywords:',
      maxKeywords: '(Max: {{maxLength}})',
    },
    confidenceScore: {
      score10:
        'high confidence that it is NOT the target but someone else’s data',
      score20:
        'indifferent whether it belongs to the target or another person with the same name',
      score30: 'high confidence that it is the target',
    },
    suspicionKeywords: {
      arson: 'Arson',
      assault: 'Assault',
      asset_misuse_misdemeanors: 'Misuse of assets',
      assets_misuse_offenses: 'Data breach',
      banking_payment_offenses:
        'Potential Offences in the Area of Banking & Payment Services Law',
      black_market: 'Black market',
      bmf_fiu_risk_country: 'BMF/FIU risk country',
      capital_market_offenses: 'Violation of capital market law',
      capital_offenses: 'Capital offences',
      child_pornography: 'Child pornography',
      clans: 'Clans',
      company_blacklist: 'Company blacklist',
      copyright_infringement: 'Copyright infringement',
      corporate_governance_code: 'Corporate governance code infringement',
      corporate_insurance_offenses: 'Insurance fraud',
      corporate_law_violations: 'Corporate law violations',
      corporate_logistics_offenses: 'Issues at operational logistics',
      corporate_obligations: 'Violation of corporate obligations',
      corporate_operations: 'Issues related to corporate operations ',
      corruption: 'Corruption',
      cybercrime: 'Cybercrime',
      data_breach: 'Data privacy breach',
      data_security_breaches: 'Lack of Data Protection',
      debt_law_offenses: 'Violation of debt law',
      digital_black_markets: 'Digital black markets',
      employment_relationships:
        'Problems in the design of employment relationships',
      eu_third_country_with_increased_: 'EU third country with increased risk',
      exhibitionism: 'Exhibitionism',
      exploiting_leading_position: 'Exploiting leading position',
      explosives: 'Explosives',
      extortion: 'Extortion',
      extremism_terror: 'Extremism / Terror',
      fair_trading_offenses: 'Violation of fair trading law',
      fatf_increased_monitoring: 'FATF increased monitoring',
      financial_reporting: 'Fraudulent financial reporting',
      firearms: 'Firearms',
      foreign_trade_law: 'Violations of foreign trade law',
      fraud_consumer: 'Fraud / Consumer',
      hacking_attacks: 'Hacking attacks',
      health_safety_offenses:
        'Violation of occupational safety / health protection',
      human_rights_violations: 'Potential human rights offenses',
      identity_theft: 'Identity theft',
      indications_for_crime: 'Indications for crime',
      information_protection: 'Lack of information protection',
      insolvency_law_offenses: 'Insolvency law violations',
      islamist_terror: 'Islamist terror',
      labor_law_offenses: 'Labour law offences',
      left_wing_extremism: 'Left-Wing extremism',
      malware: 'Malicious programs',
      money_laundering: 'Money laundering offenses',
      money_laundering_country_list: 'Money laundering country list',
      murder_manslaughter: 'Murder / Manslaughter',
      negative_sentiment: 'Negative sentiment',
      no_internet_presence: 'No internet presence',
      offenses: 'Offenses',
      offshore_leak: 'Offshore leak',
      organized_crime: 'Organized crime',
      other_criminal_activities: 'Other criminal activities',
      potential_compliance_violation: 'Potential compliance violation',
      procurement_law_offenses: 'Violation of public procurement law',
      product_safety: 'Violations against product safety and liability',
      property_damage: 'Property damage',
      property_offenses: 'Violation of company land and real estate laws',
      ransomware: 'Ransomware',
      rape: 'Rape',
      red_light_milieu: 'Red light district',
      right_wing_extremism: 'Right-Wing extremism',
      robbery_kidnapping: 'Robbery / Kidnapping / Human trafficking',
      rockers: 'Rockers / MCs',
      scam_fraud: 'Scam / Fraud',
      sex_offenses: 'Sex offenses',
      sexual_assault: 'Sexual assault',
      social_security_fraud: 'Social security fraud',
      substance_abuse: 'Substance abuse',
      sunsidies_funding_offenses: 'Subsidy fraud',
      sustainability_environment:
        'Violations against sustainability and environment',
      tax_offenses: 'Tax law violations',
      theft: 'Theft / Embezzlement / Stolen property',
      trade_secrets: 'Trade secrets',
      transactions: 'Issues in connection with transactions',
      violent_crimes: 'Violent crimes',
      weapon_offenses: 'Weapon offences',
      white_collar_crime: 'White-collar crime',
      wrong_notices: 'Wrong notices',
    },
  },
};
