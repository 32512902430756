import { FC, Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { HiCheck, HiSelector } from 'react-icons/hi';
import classnames from 'classnames';
import { clone } from 'lodash';
import { nonProdDataTestId } from '_utils';

export type ColorLevel =
  | 'default'
  | 'red'
  | 'darkRed'
  | 'green'
  | 'yellow'
  | 'blue'
  | 'white';
export type WeightLevel = 'normal' | 'medium' | 'semibold' | 'bold';

export const backgroundColor: Record<ColorLevel, string> = {
  default: 'bg-white',
  green: 'bg-green-200',
  red: 'bg-red-400',
  darkRed: 'bg-red-900',
  yellow: 'bg-yellow-400',
  blue: 'bg-blue-400',
  white: 'bg-white',
};

export const textColor: Record<ColorLevel, string> = {
  default: 'text-gray-700',
  red: 'text-red-900',
  darkRed: 'text-white',
  green: 'text-green-600',
  yellow: 'text-yellow-900',
  blue: 'text-white',
  white: 'text-blue-400',
};

export const selBackgroundColor: Record<ColorLevel, string> = {
  default: 'bg-blue-400',
  green: 'bg-green-600',
  red: 'bg-red-900',
  darkRed: 'bg-white',
  yellow: 'bg-yellow-900',
  blue: 'bg-white',
  white: 'bg-blue-400',
};

export const selTextColor: Record<ColorLevel, string> = {
  default: 'text-white',
  red: 'text-red-400',
  darkRed: 'text-red-900',
  green: 'text-green-200',
  yellow: 'text-yellow-400',
  blue: 'text-blue-400',
  white: 'text-white',
};

export const checkedColor: Record<ColorLevel, string> = {
  default: 'text-blue-400',
  green: 'text-green-600',
  red: 'text-red-900',
  darkRed: 'text-white',
  yellow: 'text-yellow-900',
  blue: 'text-white',
  white: 'text-blue-400',
};

export const focusRingColor: Record<ColorLevel, string> = {
  default: 'focus:ring-blue-500',
  red: 'focus:ring-red-900',
  darkRed: 'focus:ring-white',
  green: 'focus:ring-green-600',
  yellow: 'focus:ring-yellow-900',
  blue: 'focus:ring-white',
  white: 'focus:ring-blue-400',
};

export const focusBorderColor: Record<ColorLevel, string> = {
  default: 'focus:border-blue-500',
  red: 'focus:border-red-900',
  darkRed: 'focus:border-white',
  green: 'focus:border-green-600',
  yellow: 'focus:border-yellow-900',
  blue: 'focus:border-white',
  white: 'focus:border-blue-400',
};

export const textWeight: Record<WeightLevel, string> = {
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
};

export type SelectOption = {
  id: number | string;
  label: string;
  value?: string;
};
type SelectProps = {
  label?: string;
  selected?: any;
  options: SelectOption[];
  onChange: (value: any) => void;
  className?: string;
  addEmptyOption?: boolean;
  disabled?: boolean;
  color?: ColorLevel;
  weight?: WeightLevel;
  dataTestId?: string;
};

const emptyOption = {
  id: 0,
  label: '-',
  value: undefined,
};

export const SelectV2: FC<SelectProps> = ({
  label,
  selected,
  options,
  onChange,
  className,
  addEmptyOption = false,
  disabled = false,
  color = 'default',
  weight = 'normal',
  dataTestId,
}: SelectProps) => {
  const { t } = useTranslation();

  const [selectionOptions, setSelectionOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    const clonedOptions = clone(options);
    if (addEmptyOption) {
      clonedOptions.unshift(emptyOption);
    }
    setSelectionOptions(clonedOptions);
  }, [options, addEmptyOption]);

  return (
    <Listbox
      value={selected?.value}
      onChange={(selection) => {
        const selectedOption = selectionOptions.find(
          (option) => option.value === selection,
        );
        onChange(selectedOption?.value);
      }}
      disabled={disabled}
    >
      {({ open }) => (
        <div className={className}>
          {label && (
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              {t(label)}
            </Listbox.Label>
          )}
          <div className="relative">
            <Listbox.Button
              className={classnames(
                'relative w-max min-w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm',
                disabled
                  ? 'bg-gray-200'
                  : [backgroundColor[color], textColor[color]],
                textWeight[weight],
                [focusRingColor[color], focusBorderColor[color]],
              )}
              data-testid={nonProdDataTestId(dataTestId)}
            >
              <span className="block truncate">
                {t(
                  selected?.label ??
                    (addEmptyOption ? emptyOption.label : null),
                )}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <HiSelector
                  className={classnames('h-5 w-5', [textColor[color]])}
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className={classnames(
                  'absolute z-10 mt-1 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm',
                  [backgroundColor[color], textColor[color]],
                )}
              >
                {selectionOptions.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classnames(
                        active
                          ? [selTextColor[color], selBackgroundColor[color]]
                          : [textColor[color], backgroundColor[color]],
                        'cursor-default select-none relative py-2 pl-3 pr-9',
                      )
                    }
                    value={option.value}
                    data-testid={nonProdDataTestId('select level')}
                  >
                    {({ selected, active }) => (
                      <Fragment>
                        <span
                          className={classnames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          )}
                        >
                          {t(option.label)}
                        </span>

                        {selected ? (
                          <span
                            className={classnames(
                              active
                                ? selTextColor[color]
                                : checkedColor[color],
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <HiCheck className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </Fragment>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};
