/* istanbul ignore file */
export const de = {
  translation: {
    intlNumber: '{{val, number}}',
    intlDateTime: '{{val, datetime}}',
    schemaValidation: {
      invalidDate: 'Ungültiges Datum',
      invalidEmail: 'Ungültige E-Mail-Adresse',
      invalidPhone: 'Ungültige Telefonnummer',
      invalidUrl: 'Ungültige URL',
      invalidFacebookUrl: 'Ungültige Facebook-URL',
      invalidInstagramUrl: 'Ungültige Instagram-URL',
      invalidLinkedinUrl: 'Ungültige Linkedin-URL',
      invalidTwitterUrl: 'Ungültige Twitter-URL',
      tooFewCharacters: 'Zu wenige Zeichen',
      invalidNumber: 'Ungültige Zahl',
      invalidInteger: 'Ungültige Ganzzahl',
      invalidPositiveNumber: 'Zahl muss größer als 0 sein',
      invalidCharacter: 'Ungültiges Zeichen',
      required: 'Pflichtfeld muss angegeben werden',
      maxLength:
        'Die maximale Länge des Feldes wurde überschritten ({{maxLength}} Zeichen)',
    },
    passwordValidation: {
      min: 'Mindestens {{minLength}} Zeichen',
      oneNumber: 'Enthält mindestens eine Ziffer',
      oneSpecialChar: 'Enthält mindestens ein Sonderzeichen',
      oneUppercase: 'Enthält mindestens einen Großbuchstaben',
      oneLowercase: 'Enthält mindestens einen Kleinbuchstaben',
      passwordMatch: 'Passwörter sind gleich',
    },
    targetNewForm: {
      invalidBirthdayDate: 'Geburtsdatum ungültig',
      targetCreationInProgress: 'Target wird angelegt',
      targetDeletionNotAllowed: 'Target löschen nicht erlaubt',
      dateOfBirthLabel: 'Geburtstag oder -jahr',
      dateOfBirthPlaceholder: 'TT.MM.JJJJ oder JJJJ',
    },
    others: 'Andere',
    required: 'Pflichtfeld',
    requiredNameError: 'Vor- und Nachname müssen angegeben werden.',
    emailAlreadyExists: 'Die eingegebene E-Mail-Adresse ist bereits vergeben.',
    recommended: 'Empfohlen',
    back: 'Zurück',
    continue: 'Weiter',
    save: 'Speichern',
    showMore: 'Mehr anzeigen',
    showLess: 'Weniger anzeigen',
    download: 'Herunterladen',
    archive: 'Archivieren',
    delete: 'Löschen',
    open: 'Öffnen',
    edit: 'Bearbeiten',
    admin: 'Admin',
    cases: 'Fälle',
    user: 'Nutzer',
    profileImage: 'Profilbild',
    editProfile: 'Profil bearbeiten',
    changePassword: 'Passwort ändern',
    fallbackProfileImage: 'Fallback Profilbild',
    loading: {
      searchingCandidates:
        'Datenquellen werden durchsucht und mögliche Profile werden zusammengestellt.\nDies kann einige Minuten dauern.',
      collectingData:
        'Auswahl erfolgreich übermittelt.\nAlle Quellen werden überprüft und der endgültige Report wird zusammengestellt.\nDies dauert in der Regel etwa eine Stunde. Sie werden per E-Mail benachrichtigt, sobald der Bericht fertig ist.',
    },
    moreInfo: 'Weitere Angaben',

    // Network Graph
    networkGraph: {
      title: 'Netzwerk Graph',
      graphDepth: 'Netzwerktiefe',
      graphLevels: 'Ebene',
      unknownRole: 'Unbekannte Rolle',
    },

    timeline: {
      title: 'Zeitleiste',
    },

    cvData: {
      title: 'Lebenslaufdaten',
    },

    geoLocations: {
      title: 'Hinweise auf Standorte',
    },

    persons: {
      title: 'Personen',
    },

    // Navigation
    summary: 'Zusammenfassung',
    overview: 'Übersicht',
    personalData: 'Persönliche Daten',
    socialMediaSection: 'Soziale Medien',
    socialMediaConnectionSection: 'Soziale Medien Verbindungen',
    socialMediaPostSection: 'Soziale Medien Beiträge',
    pressSection: 'Presse',
    companySection: 'Registerdaten',
    searchEngineSection: 'Suchmaschinen',
    pictureSection: 'Bildmaterial',
    noData: 'Keine Daten',
    noDataFound: 'Keine Daten vorhanden',
    network: 'Netzwerk',
    downloads: 'Herunterladen',
    indicium: 'Indicium',
    signOut: 'Ausloggen',
    searchAndFilter: 'Suche & Filter',
    inputDataAndSources: 'Eingabedaten & Quellen',
    inputData: 'Eingabedaten',
    targetPersonSelection: 'Auswahl Zielperson',
    sourceOverview: 'Quellenübersicht',
    caseData: 'Falldaten',
    backToOverview: 'Zurück zur Übersicht',

    rawData: {
      title: 'Rohdaten',
      search: 'Freitextsuche',
      socialMedia: 'Soziale Medien',
      press: 'Presse',
      registerData: 'Registerdaten',
      searchEngines: 'Suchmaschinen',
      imageSlashVideos: 'Bildmaterial/Videos',
      disabledTooltip: 'Die Rohdaten wurden aufgrund der DSGVO gelöscht',
    },

    // newTarget: data input
    targetPersonDataHeadline: 'Daten zur Zielperson',
    targetPersonDataSubHeadline: 'Welche Daten zur Person sind bekannt?',
    basisData: 'Basisdaten',
    title: 'Titel',
    titles: {
      dr: 'Dr.',
      prof: 'Prof.',
      profDr: 'Prof. Dr.',
    },
    gender: 'Geschlecht',
    genders: {
      male: 'Männlich',
      female: 'Weiblich',
      // TODO add support for other
      // other: 'Divers',
    },
    name: 'Name',
    firstname: 'Vorname',
    middlename: 'Zweiter Vorname',
    lastname: 'Nachname',
    nickname: 'Spitzname',
    nicknameAlternative: 'Spitzname / Künstlername',
    dateOfBirth: 'Geburtstag',
    nationality: 'Nationalität',
    placeOfBirth: 'Geburtsort',
    countryOfBirth: 'Geburtsland',
    occupation: 'Position',
    placesLived: 'Aufenthaltsorte',
    contactData: 'Kontaktdaten',
    phone: 'Telefon',
    phonePlaceholder: '+49 1234 567890',
    mobile: 'Mobil',
    email: 'E-Mail',
    emailPlaceholder:
      'max.mustermann@example.com (personenbezogene E-Mail Adresse (kein info@, kontakt@, o.Ä)',
    address: 'Adresse',
    companyWebsite: 'Unternehmenswebseite',
    websites: 'Webseiten',
    roles: 'Rollen von',
    shareholders: 'Aktionäre',
    bankDetails: 'Bankverbindung',
    company: 'Unternehmen',
    companyRole: {
      label: 'Rolle im Untenehmen',
      optionLabels: {
        Unknown: 'Nicht bekannt',
        Employee: 'Mitarbeiter:in',
        ManagingDirector: 'Geschäftsführer:in',
        Shareholder: 'Anteilseigner:in',
        AuthorizedSignatory: 'Unterschriftsberechtigt (Prokurist:in)',
        Other: 'Sonstiges',
      },
    },
    unused: 'Dieses Feld steht in künftigen Releases zur Verfügung',
    employee: 'Angestellter',
    companyOwner: 'Firma der Zielperson',
    companyName: 'Firmenname',
    companyEducation: 'Firmenname / Ausbildung',
    companyLocation: 'Firmensitz',
    commercialRegisterNumber: 'Handelsregisternummer',
    commercialRegisterNumberPlaceholder:
      'Die Handelsregisternummer muss aus mindestens 5 Zeichen bestehen',
    companyVatNumber: 'Umsatzsteuernummer',
    companyVatNumberPlaceholder:
      'Die Umsatzsteuernummer muss aus mindestens 5 Zeichen bestehen',
    website: 'Webseite',
    websitePlaceholder: 'https://www.example.com',
    socialMediaProfiles: 'Soziale Medien Profile',
    socialMediaPrivacyHint:
      'Hinweis zum Datenschutz: Soziale Medien Profile dürfen nur bei berechtigtem Interesse in der Analayse einbezogen werden.',
    socialMediaFormatHint: 'Link + Handle / Handle',
    targetPersonDetails: 'Zielperson eingrenzen',
    targetPersonDetailsHint:
      'Sie können die Qualität der Ergebnisse erhöhen, indem sie Stichworte zur Zielperson eingeben.',
    targetPersonDetailsKeywords:
      'Stichworte zur Identifizierung der Zielperson',
    targetPersonAmbiguityKeywords:
      'Stichworte um namensgleiche Personen auszuschließen',
    targetPersonAmbiguityKeywordsHint:
      'Gibt es prominente Personen mit dem Namen der Zielperson, oder Personen in der gleichen Branche?',
    invalidNationalityMessage:
      'Die Eingabe konnte keiner Nationalität zugeordnet werden.',
    invalidCountryMessage: 'Die Eingabe konnte keine Land zugeordnet werden.',
    invalidServerResponse:
      'Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingabe und probieren Sie es erneut.',
    //newTarget: resolve ambiguity
    age: 'Alter',
    // TODO: IND-460 Check usage of duplicate keys on global level
    // Some of the keys inside `candidateInfo` like `age` already existed at the
    // root level before the `candidateInfo` nesting group was added. I haven't
    // removed them from the root level as it is currently unclear whether some
    // other component of the application has been reusing them.
    // Once IND-460 has been completed and other translation usage has been
    // migrated to use context / feature specific nesting, they keys from
    // candidateInfo that also exist at the root level should be checked for
    // obsolescence.
    candidateInfo: {
      placeOfResidency: 'Wohnort',
      age: 'Alter',
      company: 'Unternehmen',
      nationality: 'Nationalität',
      phone: 'Telefon',
      email: 'E-Mail',
      website: 'Webseite',
      url: 'URLs',
      placeOfBirth: 'Geburtsort',
      dateOfBirth: 'Geburtstag',
      username: 'Benutzernamen',
      gender: 'Geschlecht',
      language: 'Sprache',
      originCountry: 'Herkunftsland',
      education: 'Ausbildung',
      city: 'Stadt',
      state: 'Bundesland',
      country: 'Land',
      scientificTitle: 'Profession',
    },
    placeOfResidency: 'Wohnort',
    resolveTargetCandidatesHeadline: 'Mehrdeutigkeiten auflösen',
    // TODO 'resolveTargetCandidatesSubHeadline' needs German approval
    resolveTargetCandidatesSubHeadline:
      'Bitte wählen Sie alle Kacheln mit Ihrer Zielperson aus.',
    selectCandidate: 'Person wählen',
    excludeCandidate: 'Person ausschließen',
    startAnalysis: 'Report anfordern',

    // Dashboard: Overview
    dashboardOverviewHeadline: 'Übersicht',
    targetNameSubHeadline: 'Indicium Analyse',
    overviewAnalysis: 'Analyse',
    overviewAnalysisBadge: 'beta',
    overviewAnalysisResult:
      'Potentiell problematische Inhalte gefunden - bitte prüfen',
    overviewAnalysisNoResult: 'Keine verdächtigen Informationen gefunden',
    dashboardOverview: {
      subHeadline: 'Gesamtbewertung',
    },

    // Red Flags
    redFlags: {
      heading: 'Prüfung erforderlich',
      cardHeadline: 'Kritische Themen',
      cardSubHeadline: 'Ergebnisse gefunden',
      headers: {
        name: 'Name',
        role: 'Firmenposition(en)',
        detail: 'Einzelheiten zur Roten Flagge',
        land: 'Land',
        source: 'Quelle',
        description: 'Beschreibung',
      },
      types: {
        pep: {
          detail: 'Politisch exponierte Person',
          description:
            'Die Zielperson findet sich auf einer Liste für hochrangig öffentliche tätige Personen wieder (Politically Exposed Persons)',
        },
        sanction: {
          detail: 'Sanktionsliste',
          description:
            'Das Ziel findet sich in einer weltweiten Sanktionsliste wieder',
        },
        enforcement: {
          detail: 'Strafverfolgung',
          description:
            'Das Ziel findet sich in einer weltweiten Strafverfolgungsliste wieder',
        },
        soe: {
          detail: 'SOE',
          description:
            'Das Ziel weist Verbindungen zu staatlichen Besitz oder Regierungen sowie über zu deren Mitarbeitern auf (State Owned Enterprises)',
        },
        government_link: {
          detail: 'Government Linked',
          description:
            'Das Ziel weist Verbindungen zu staatlichen Besitz sowie zu deren Mitarbeitern auf',
        },
        adverse_media: {
          detail: 'Adverse Media',
          description:
            'Das Ziel steht in möglicher Verbindung mit illegalen Aktivitäten im Bereich Geldwäsche und Terrorismusfinanzierung, basierend auf Nachrichten, die von glaubwürdigen Medien veröffentlicht wurden',
        },
        associated_entity: {
          detail: 'Associated Entity',
          description:
            'Das Ziel steht mit mindestens einer sanktionierten Einrichtung in Verbindung',
        },
        registrations: {
          detail: 'Registration',
          description:
            'Das Ziel steht im Zusammenhang mit Überwachungsmaterial, das von offiziellen Regierungsbehörden, internationalen Organisationen, Branchenregulierungsbehörden und Disziplinarausschüssen für Ereignisse veröffentlicht wird, die qualifizierte Standards und Definitionen erfüllen.',
        },
        generic_red_flag: {
          detail: 'Allgemeine Auffälligkeit',
          description:
            'Die Zielperson ist auf einer weltweiten Strafverfolgungsliste vermerkt',
          sources: {
            orbis:
              'Globale Sanktionslisten, Watchlisten und Listen politisch exponierter Personen (via "BvD Compliance Catalyst")',
          },
        },
      },
      rawTypes: {
        'PEP/Former PEP': 'Current or former Politically Exposed Person (PEP)',
      },
      reportsHeading: 'Detailreport',
      reportsItem: 'Report',
      roleNotAvailable: '-',
      positionIsCurrent: 'bis heute',
      unknownTimeframe: 'Unbekannter Zeitraum',
      unknownRole: 'Unbekannte Rolle',
    },

    // Profile: Overview
    source: 'Quelle',
    sources: 'Quellen',
    profileHeadline: 'Profil',
    downloadPDF: 'PDF',
    profileLoading: 'Daten werden geladen.',
    profileDataNotFinal:
      'Es kann bis zu 1 Stunde dauern, bis die angezeigten Daten vollständig sind.',
    profileErrorHeadline: 'Ein Fehler ist aufgetreten.',
    profileErrorMessage: 'Ihre Anfrage konnte nicht verarbeitet werden.',
    profileErrorRetryHeadline: 'Noch sind keine Daten verfügbar.',
    profileErrorRetryMessage:
      'Bitte versuchen Sie es in einigen Minuten erneut',
    workflowRunningMessage:
      'Der Prozess läuft noch, bitte kommen Sie später wieder. (Dies kann bis zu einer halben Stunde dauern).',
    complianceIndication: 'Hinweise auf Complianceverstöße',
    searchTerms: 'Eigene Suchbegriffe',
    profileSize: 'Profilgröße',
    networkSize: 'Netzwerkgröße',
    from: 'von',
    to: 'bis',
    role: 'Tätigkeit',
    cv: 'Lebenslauf',

    // Profile: raw data
    data: 'Daten',
    excluded: 'Ausgeschlossen',
    includeCandidate: 'Person einbeziehen',
    backToDataInput: 'Zur Dateneingabe',
    cancelCreateTarget: 'Analyse beenden',
    noPersonHeadline: 'Keine Personen gefunden',
    noPersonText:
      'Leider haben wir auf Basis Ihrer Angaben keine mögliche Zielperson identifiziert. Bitte überprüfen Sie Ihre Eingabe.',
    summaryBetaTooltip: `Dies ist eine Beta-Funktion, die Große Sprachmodelle (LLMs) zur Zusammenfassung verwendet.\n 
      Bei Fragen wenden Sie sich bitte an Ihren Kundenbetreuer.`,

    // Admin: User Admin
    overviewOfAllUsers: 'Übersicht aller Nutzer',
    createNewUser: 'Neuen Nutzer anlegen',
    addButton: 'Hinzufügen',
    closeButton: 'Schließen',
    addMoreUsers: 'Weiteren Nutzer anlegen',
    assignedCases: 'Aktive zugewiesene Fälle',
    activeReports: 'Aktive Zielpersonen Reports',
    totalReports: 'Zielpersonen Reports Gesamt (inkl. Gelöschter)',
    userTableFooter: 'Gesamt',
    createdOn: 'Erstellt am',
    createdAt: 'Erstellt am',
    changedOn: 'Geändert am',
    updatedAt: 'Geändert am',
    notAvailable: '-',

    // Admin: Customer Admin
    overviewOfAllCustomers: 'Übersicht aller Kunden',
    createNewCustomer: 'Neuen Kunden anlegen',
    customerName: 'Kundenname',
    numberOfReports: 'Reports',
    limitOfReports: 'Report-Limit',
    expiresAt: 'Vertrag endet',
    updateCustomer: {
      action: 'Kunden bearbeiten',
      formHeadline: 'Verfügbare Felder',
    },
    lastReportCreatedAt: 'Letzter Report erstellt',

    // Admin: Cases Admin
    overviewOfAllCases: 'Übersicht aller Fälle',
    createNewCase: 'Neuen Fall anlegen',
    createNewTarget: 'Neue Zielperson anlegen',
    openCases: 'Offene Fälle',
    archivedCases: 'Archivierte Fälle',
    caseName: 'Fallname',
    analysisType: 'Analyseart',
    analysisValue: {
      unknown: 'UNKNOWN',
      naturalPerson: 'Natürliche Person',
      unnaturalPerson: 'Unnatürliche Person',
    },
    mustBeUnique: 'Muss einzigartig sein',
    caseNameMustBeUnique: 'Fallname muss einzigartig sein.',

    subject: 'Betreff',
    targetPerson: 'Zielperson',
    targetPersonPress: '{{name}} (Zielperson)',
    targetNetworkPress: '{{name}} (Netzwerkebene {{networkLevel}})',
    editor: 'Bearbeiter',
    creator: 'Ersteller',
    dateChanged: 'Geändert',
    dateCreated: 'Erstellt',
    titleOfCase: 'Titel des Falles',
    exampleTitle: 'Beispieltitel',
    assignUserToCase: 'Nutzer dem Fall zuweisen',
    assignUserToCaseSubtext:
      'Wählen Sie hier aus welchen Mitarbeitern Sie Zugriffsrechte für die Bearbeitung dieses Falls erteilen möchten. Administratoren Ihrer Organisation haben Zugriff auf alle Fälle.',

    thematicFieldsOfAnalysis: {
      headline: 'Themenfelder der Analyse',
      redFlags: 'Kritische Themen',
      redFlagsSubtext:
        'Abgleich des Namens mit nationalen und internationalen Listen.',
      rawData:
        'Rohdaten für potentielle Complianceverstöße und kriminelles Verhalten',
      rawDataSubtext:
        'Zusammenstellung von Material zur Zielperson, basierend auf Handelsregisterinformationen, Pressequellen und Einträgen auf Soziale Medienplattformen.',
      tip: 'Hinweis',
      tipSubtext: 'Für die DACH-Region werden die Ergebisse fokussiert.',
    },
    currentResults: 'Aktuelle Ergebnisse',
    archivedResults: 'Archivierte Ergebnisse',
    noCaseFoundHeadline: 'Keinen Fall gefunden',
    noCaseFoundText:
      'Leider haben wir auf Basis Ihrer Angaben keinen möglichen Fall identifiziert. Bitte überprüfen Sie Ihre Eingabe.',
    deleteCase: {
      action: 'Fall löschen',
      modalTitle:
        'Sind Sie sicher, dass Sie den ausgewählten Fall löschen möchten?',
      modalText:
        'Sind Sie sicher, dass Sie den ausgewählten Fall löschen möchten? Bitte achten Sie darauf, das sie vorab alle für Sie relevanten Reports exportiert und abgespeichert haben, da diese nach der Löschung nicht mehr zum Herunterladen zur Verfügung gestellt werden können.',
      radioOptions: [
        'Ja, ich habe die relevanten Zielpersonen Reports vorab heruntergeladen und möchte den ausgewählten Fall löschen',
        'Ja, ich möchte den Fall und alle Daten unwiderruflich löschen, aber habe die Reports nicht heruntergeladen, da ich sie nicht benötige',
      ],
      cancelButton: 'Löschen abbrechen',
      confirmButton: 'Löschen bestätigen',
    },
    editCaseAction: 'Fall bearbeiten',
    editCaseFormHeadline: 'Verfügbare Felder',
    addAdminPermissionsAction: 'Administratorrechte hinzufügen',
    removeAdminPermissionsAction: 'Administratorrechte widerrufen',
    deleteTarget: {
      action: 'Zielperson löschen',
      modalTitle:
        'Sind Sie sich sicher, dass Sie diese Zielperson unwiderruflich löschen möchten?',
      modalText:
        'Sind Sie sicher, dass Sie die ausgewählte Zielperson unwiderruflich löschen wollen? Bitte achten Sie darauf, das sie vorab den Report der Zielperson exportiert und abgespeichert haben, da dieser nach der Löschung nicht mehr zum Herunterladen zur Verfügung gestellt werden kann.',
      radioOptions: [
        'Ja, ich habe den Zielpersonen Reports vorab heruntergeladen und möchte die ausgewählte Zielperson löschen',
        'Ja, ich möchte die Zielperson und deren Daten unwiderruflich löschen, aber habe deren Report nicht heruntergeladen, da ich sie nicht benötige',
      ],
      cancelButton: 'Löschen abbrechen',
      confirmButton: 'Löschen bestätigen',
    },
    targetStatus: {
      Created: 'Erstellt',
      Completed: 'Fertig gestellt',
      FetchingCandidates: 'Zielpersonen werden abgerufen',
      CandidateSelectionPending: 'Auswahl der Zielperson ausstehend',
      CandidateSelectionCompleted: 'Auswahl der Zielperson abgeschlossen',
      FetchingDetails: 'Details werden abgerufen',
      HasInitialProfile: 'Initiales Profil erstellt',
    },
    deleteUser: {
      action: 'Nutzer löschen',
      modalTitle:
        'Sind Sie sich sicher, dass Sie diesen Nutzer löschen möchten?',
      modalText:
        'Sind Sie sicher, dass Sie diesen Nutzer unwiderruflich löschen möchten?',
      radioOptions: [
        'Ja, ich möchte den ausgewählten Nutzer unwiderruflich löschen',
      ],
      cancelButton: 'Löschen abbrechen',
      confirmButton: 'Löschen bestätigen',
    },
    deleteCustomer: {
      action: 'Kunden löschen',
      modalTitle:
        'Sind Sie sich sicher, dass Sie diesen Kunden-Account löschen möchten?',
      modalText:
        'Sind Sie wirklich sicher, dass Sie den ausgewählten Kunden-Account löschen wollen? Bitte achten Sie darauf, das vorab alle relevanten Zielpersonen-Reports exportiert und abgespeichert wurden, da diese zusammen mit dem Kunden-Account gelöscht werden und nach der Löschung nicht mehr zum Herunterladen zur Verfügung gestellt werden können.',
      radioOptions: [
        'Ja, ich habe die relevanten Zielpersonen Reports vorab heruntergeladen und möchte den ausgewählten Kunden löschen',
        'Ja, ich möchte den Kunden und alle dazugehörigen Daten unwiderruflich löschen, aber habe die Reports nicht heruntergeladen, da ich sie nicht benötige',
      ],
      cancelButton: 'Löschen abbrechen',
      confirmButton: 'Löschen bestätigen',
    },
    editCustomerAction: 'Kunden bearbeiten',
    editCustomerFormHeadline: 'Verfügbare Felder',
    quotaExceeded: 'Ihr erlaubtes Volumen an Reports ist erreicht.',
    quota: 'Kontingent',

    // Press
    press: 'Presse',
    position: 'Position',
    date: 'Datum',
    publicationDate: 'Erscheinungsdatum',
    retrievalDate: 'Abrufdatum',
    preview: 'Vorschau',
    author: 'Autor/In',
    copyright: 'Urheberrechte ©',
    previous: 'Vorherige',
    next: 'Weiter',
    managers: 'Manager',

    // SocialMedia
    socialMedia: {
      contactsHeadline: 'Soziale Medien Kontakte',
      contactsNoAccounts: 'Es wurden keine Soziale Medien Konto gefunden.',
      postsHeadline: 'Soziale Medien Beiträge',
      media: 'Medien',
      url: 'Quelle',
      itemType: 'Artikeltyp',
      commentCount: 'Kommentare',
      likeCount: 'Likes',
      date: 'Datum',
      datePublished: 'Datum',
      tags: 'Tags',
      links: 'Links',
      mentions: 'Erwähnungen',
      profileName: 'Profilname',
      profileId: 'Profil ID',
      profileImageUrl: 'Profilbild-URL',
      profileImageWebAddress: 'Profilbild Webadresse',
      profileLocation: 'Profilstandort',
      inLanguage: 'Sprache',
      postingPlatform: 'Gepostet via',
      profileFollowers: 'Profil Abonnenten',
      profileFollowing: 'Profil abonniert',
      source: 'Plattform',
      favoriteCount: 'Favorisiert',
      retweetCount: 'Retweeted',
      userName: 'Username',
      contentId: 'Content ID',
      dateRecorded: 'Erfassungsdatum',
      fullName: 'Vollständiger Name',
      mediaWebAddress: 'Media URL',
      isSharedContent: 'Geteilte Inhalte',
      connectionType: 'Verbindungstyp',
      username: 'Nutzername',
      mediaType: 'Medientyp',
      body: 'Inhalt',
      inReplyToUsername: 'Als Antwort auf Username',
      inReplyToContentId: 'Als Antwort auf Content ID',
      inReplyToProfileId: 'Als Antwort auf Profile ID',
      friend: 'Freunde',
      noFriends: 'Keine Freunde',
      follower: 'Followers',
      noFollowers: 'Folgt keinem Profil',
      followed: 'Following',
      nofollowed: 'Keine Follower',
      noContacts: 'Keine Kontakte',
      connections: 'Verbindungen',
      profileUrl: 'Profil-URL',
      posts: 'Beitrag',
      profile: 'Profile',
      friendsAndInteractants: 'Freunde & Interakteure',
    },

    // Socials
    facebook: 'Facebook',
    facebookPlaceholder: 'https://facebook.com/maxmustermann',
    instagram: 'Instagram',
    instagramPlaceholder: 'https://instagram.com/maxmustermann',
    linkedin: 'LinkedIn',
    linkedinPlaceholder: 'https://linkedin.com/in/maxmustermann',
    twitter: 'Twitter',
    twitterPlaceholder: 'https://twitter.com/maxmustermann',
    vkontakte: 'VKontakte',
    pinterest: 'Pinterest',
    flickr: 'Flickr',
    skype: 'Skype',
    whatsapp: 'WhatsApp',

    // Signin
    signIn: {
      headline: 'Melden Sie sich an',
      passwordForgotHeadline: 'Passwort zurücksetzen',
      passwordResetRequiredHeadline: 'Passwort neu setzen',
      emailAddressLabel: 'Email Adresse',
      emailAddressInputPlaceholder: 'Email Adresse',
      passwordLabel: 'Passwort',
      passwordInputPlaceholder: 'Passwort',
      signInButton: 'Anmelden',
      rememberMe: 'Angemeldet bleiben',
      forgotPassword: 'Passwort vergessen?',
      imprint: 'Impressum',
    },

    authentication: {
      headline: 'Multi-Faktor-Authentifizierung',
      description:
        'Bitte geben Sie den Code aus Ihrer Authentifizierungs-App ein, um den Anmeldevorgang abzuschließen.',
      instructions: `
        1. Installieren Sie eine Authentifizierungs-App auf Ihrem Mobilgerät: Wenn Sie dies noch nicht getan haben, laden Sie eine Authentifizierungs-App auf Ihr Mobilgerät herunter und installieren Sie sie. Beliebte Optionen sind Google Authenticator (verfügbar für Android und iOS) und Authy (verfügbar für Android und iOS).\n\n
        2. Scannen Sie den QR-Code: Öffnen Sie die Authentifizierungs-App und scannen Sie den untenstehenden QR-Code. Wenn Sie den QR-Code nicht scannen können, können Sie den Code und den Schlüssel unter dem QR-Code manuell eingeben.\n
        3. Geben Sie den Authentifizierungscode ein: Geben Sie den von der Authentifizierungs-App generierten Authentifizierungscode ein und klicken Sie auf "Absenden".\n
      `,
      codeInputPlaceholder: 'Authentifizierungscode',
      submitButton: 'Absenden',
      oneTimePassword: 'Einmalpasswort',
    },

    passwordReset: {
      password: 'Passwort',
      newPassword: 'Neues Passwort',
      oldPassword: 'Altes Passwort',
      newPasswordRepeat: 'Passwort wiederholen',
      newPasswordConfirm: 'Neues Passwort bestätigen',
      verifyAccount: 'Email Adresse bestätigen',
      updatePassword: 'Passwort aktualisieren',
    },

    // Error
    NotAuthorizedException:
      'Sie sind nicht angemeldet. E-Mail Adresse oder Passwort sind nicht korrekt.',
    UserNotFoundException:
      'Sie sind nicht angemeldet. E-Mail Adresse oder Passwort sind nicht korrekt.',
    PasswordEmptyOrMismatchException:
      'Die eingegebenen Passwörter sind leer oder stimmen nicht überein.',
    InvalidPasswordException:
      'Das gewählte Passwort muss folgende Anforderungen erfüllen:',
    passwordRequirements: [
      'Min. 8 Zeichen',
      'Groß- und Kleinbuchstaben',
      'Ziffern und Sonderzeichen',
    ],
    PasswordResetRequiredException:
      'Das ist das erste Mal, dass Sie sich einloggen. Bitte legen Sie ein neues Passwort fest.',
    PasswordResetExpiredCodeException: 'Der eingegebene Code ist abgelaufen.',
    InvalidParameterException: 'Der eingegebene Code ist nicht korrekt.',
    CodeMismatchException: 'Der eingegebene Code ist nicht korrekt.',
    LimitExceededException:
      'Sie haben zu oft versucht, ihr Passwort zu ändern. Warten sie einige Minuten bevor Sie es erneut versuchen.',
    PasswordChallengeException:
      'Aktuell kann ihr Passwort nicht zurückgesetzt werden. Bitte verwenden sie das Passwort, welches sie per Email zugeschickt bekommen haben.',
    PasswordExpiredException:
      'Ihr vorläufiges Passwort ist nicht mehr gültig. Bitte wenden sie sich an den Support, um ein neues Passwort zu erhalten.',
    WrongPasswordException: 'Das eingegebene Passwort ist nicht korrekt.',
    uhOh: 'Uh-oh!',
    noPageFound: 'Wir können die Seite, die Sie suchen, nicht finden.',
    goBack: 'Bitte gehen Sie zurück.',
    noUsers: 'Keine Benutzer',
    noResultHeadline: 'Keine Ergebnisse gefunden',
    noResultText:
      'Leider haben wir anhand Ihrer Angaben kein mögliches Ergebnis ermittelt. Bitte überprüfen Sie Ihre Eingabe.',
    noTradeRegisterDataText:
      'Wir haben über 400 Millionen Unternehmen global geprüft: eine Person mit dem angegebenen Namen konnte nicht mit registerrelevanter, unternehmerischer Tätigkeit identifiziert werden.',
    caseAgentsUpdated: 'Der/Die Nutzer wurden dem Fall erfolgreich zugewiesen',
    caseAgentsAssignError:
      'Der/Die Nutzer konnten dem Fall nicht zugewiesen werden.',
    firstPage: '<< erste Seite',
    previousPage: '< vorherige Seite',
    nextPage: 'nächste Seite >',
    lastPage: 'letzte Seite >>',
    workflowErrors: {
      headline:
        'Beim Sammeln der Informationen zur Zielperson ist ein Fehler aufgetreten',
      candidates:
        'Fehler bei der Suche nach potentzielle Kandidaten, die zu den angegeben Zielperson-Daten passen.',
      profile:
        'Fehler beim Sammeln der Profil- und Unternehmens-Daten der Zielperson.',
      press: 'Fehler beim Sammeln der Presse-Daten der Zielperson.',
      network:
        'Fehler beim Sammeln bzw. Erstellen der Netzwerk-Daten der Zielperson.',
      socialmedia: 'Fehler beim Sammeln Soziale Medien Daten der Zielperson.',
      appendix:
        'Daher können unter Umständen keine oder nur unvollständige Daten angezeigt werden.',
    },
    pageNotFound: {
      title: 'Hier ist etwas schief gelaufen',
      subtitle:
        'Diese Seite existiert nicht oder Ihnen fehlt die Berechtigung auf diese Seite zuzugreifen.',
      description:
        'Bitte überprüfen Sie die URL auf Korrektheit oder versuchen Sie es zu einem späteren Zeitpunkt erneut. Sollte das Problem weiterhin auftreten, kontaktieren Sie den Support.',
      button: 'Zurück zur Übersicht',
    },
    organization: 'Organisation',
    privilegeLevel: 'Rolle',
    CustomerOwner: 'Eigentümer',
    CustomerAdmin: 'Administrator',
    CustomerUser: 'Mitarbeiter',
    administrators: 'Administratoren',

    companyList: {
      roleStatus: {
        current: 'aktuell',
        previous: 'ehemalig',
      },
    },
    caseNew: {
      stepOne: 'Fall anlegen',
      stepTwo: 'Fallparameter bestimmen',
      topicsTitle: 'Themenfelder der Analyse',
      topicsParagraph: 'Folgende Analysen werden durch das System unterstützt',
      redFlagsTitle: 'Pot. kritische Themen',
      redFlagsParagraph:
        'Abgleich des Namens der Zielperson, der mit Ihr verbundenen Unternehmen sowie der mit diesen Unternehmen verbundenen Personen mit nationalen und internationalen Sanktionslisten und Listen politisch exponierter Personen.',
      complianceTitle:
        'Hinweise im Zusammenhang mit Compliance - oder Kriminellen Thematiken',
      complianceParagraph:
        'Schlüsselwort-basierte Analyse und Identifikation von Auffälligkeiten in Textmaterial zur Zielperson. Wir prüfen Begriffe aus dem Bereich klassischer Kriminalität (Organisierte Kriminalität, Terrorismus, Extremismus) sowie insbesondere auch aus dem Bereich Wirtschaftskriminalität.',
      keywordsLink: 'Liste der Stichworte und Themen Felder',
      businessRegistryTitle:
        'Markierung auffälliger Merkmale in den Unternehmensregisterdaten',
      sources: {
        title: 'Quellen',
        searchEngines: 'Suchmaschinen',
        commercialRegisters: 'Handelsregister',
        sanctionsList: 'Listen politisch exponierte Person/Sanktionslisten',
        webSites: 'Webseiten',
        pressArchive: 'Pressearchive',
        businessSocialMedia: 'Geschäft Soziale Medien',
        privatePublicSocialMedia: 'PRIVATE, ÖFFENTLICHE Soziale Medien',
      },
      selectOption: 'Anlass der Analyse',
      title: {
        option1: 'Integritätscheck Geschäftsanbahnung ¹',
        option2: 'Integritätscheck mit Einverständnis der Zielperson ²',
        option3: 'Verdachtsbezogene Recherche ³',
      },
      option1Asterisk: `1) Recherchen werden aus wirtschaftlichem Interesse basierend auf geplanter Geschäftsanbahnung, jedoch ohne konkrete Verdachtsmomente durchgeführt. Indicium verarbeitet nur online öffentlich verfügbare Informationen, die einen Geschäftsbezug nahelegen.
      Grundsätzlich sind Sie nach DSGVO dazu verpflichtet, die betreffenden Personen über die geplante Verarbeitung zu informieren. Sie können davon absehen, wenn die geplante Verarbeitung den Zweck der Verarbeitung (z.B. den Geschäftsabschluss) gefährden würde. Wenn Sie weitere Fragen haben, wenden Sie sich gerne an das Indicium Team unter fallanlage@indicium.ag.`,
      option1Description1:
        'Geschäftlich relevante online öffentlich verfügbare Quellen (Handelsregister, Presse, Soziale Medien mit Geschäftsbezug und Suchmaschinen)',
      option1Description2:
        'Exklusion von rein privaten Soziale Medien Portalen',
      option2Asterisk:
        '2) Recherchen werden mit Information und Einverständnis der Zielperson durchgeführt. Das Einverständnis muss entlang der angebotenen Informationscluster (Sämtliche im Internet öffentlich verfügbare Informationen, Privatgelände Soziale Medien, spezifische Suchbegriffe, u.U. Bonität) eingeholt werden. Wenn Sie weitere Fragen haben, wenden Sie sich gerne an das Indicium Team unter fallanlage@indicium.ag.',
      option2Description1: 'Individuelle Auswahl von Quellen',
      option2Description2: 'Individuelle Möglichkeit für Stichwortsuche',
      option3Asterisk:
        '3) Verdachtsbezogene Recherchen werden zur genannten Person/ zu den genannten Personen durchgeführt. Es bestehen Anhaltspunkte für einen begründeten Verdacht, dass die untersuchten Personen gegen unternehmensinterne Vorschriften verstoßen haben, eine Straftat begangen haben oder eine Ordnungswidrigkeit begangen haben. Die berechtigten Interessen der betroffenen Personen überwiegen nicht Ihrem Interesse an der Aufklärung des Verstoßes. Die betreffenden Personen wurden nicht über die geplante Verarbeitung informiert, weil dies den Zweck der Verarbeitung gefährden würde. Fallspezifische Suchbegriffe müssen angegeben werden. Wenn Sie weitere Fragen haben, wenden Sie sich gerne an das Indicium Team unter fallanlage@indicium.ag.',
      option3Description1:
        'Alle online öffentlich verfügbaren Quellen (Handelsregister, Presse, Soziale Medien und Suchmaschinen)',
      option3Description2: 'Stichworte mit Fallbezug sind anzugeben',
      backgroundCheckTitle:
        'Hintergrundprüfung - Geschäftsanbahnung ohne konkreten Verdachtsmoment',
      backgroundCheckDescription1:
        'Compliance-Recherchen werden aus wirtschaftlichem Interesse basierend auf geplanter Geschäftsanbahnung, jedoch ohne konkrete Verdachtsmomente durchgeführt. Indicium verarbeitet nur online öffentlich verfügbare Informationen.',
      targetPersonInformationTitle: 'Information der betroffenen Person',
      targetPersonInformation: {
        yes: 'Die betreffenden Personen wurden über die geplante Verarbeitung informiert.',
        no: 'Die betreffenden Personen wurden nicht über die geplante Verarbeitung informiert, weil dies den Zweck der Verarbeitung gefährden würde.',
      },
      osintCheckWithConsent:
        'OSINT-Check mit Einverständnis aller Zielpersonen',
      publicInfoDesc:
        'Sämtliche im Internet öffentlich verfügbare Informationen (Text, Bild), inklusive Informationen gemäß Art. 9 Abs 1 DSGVO, die im Zusammenhang mit der Person stehen, auch wenn Sie von dritten öffentlich gemacht wurden.',
      privateSocialMedia:
        'Informationen im rein privaten Bereich bei Soziale Medien Plattformen',
      creditRating: 'Bonitätsinformationen ("Schufa")',
      customSearchTerms: 'Spezifische Suchbegriffe können ergänzt werden.',
      suspCompInvestigation: {
        optionDesc: 'Verdachtsbezogen Compliance Recherche',
        section2MainTitle: 'Analyse um Fallbezogene Stichwort ergänzen',
        section3Title: 'Individuelle Suchbegriffe',
        section2Description1:
          'Mit eigenen, fallbezogenen Stichwort können Sie der Analyse eine zusätzliche Vertiefung geben. Wir identifizieren Ihre oder ähnliche Stichwort in den gefundenen Daten.',
      },
      addNewKeyword: 'Weitere Stichwort ergänzen',
      leftFooter: {
        title: 'Umfassende Analysen auf Ihrem Datensatz',
        bullet0:
          'Prüfung auf Kritische Themen: politisch exponierte Personen-Prüfung, Sanktionslistenprüfung und weitere Überwachungslisten von verbundenen Unternehmen und Unternehmern bis zum 2. Grad',
        bullet1:
          'Abgleich sämtlicher gefundener mit der Person verbundenen Texte mit kritischen Stichworten oder negativen Inhalten (Basierend auf Analysen mit künstlicher Intelligenz)',
        bullet2:
          'Automatische Prüfung von verdächtigen Sachzusammenhängen aus Perspektive eines professionellen OSINT Analysten',
        bullet3:
          'Bei Stichworten mit Fallbezug: Analyse auf vorkommen der Stichworte und Stichworterweiterungen (über Maschinelles Lernen).',
      },
      rightFooter: {
        title: ' Inkludierte Quellen für Ihre Analyse',
        bullet0:
          'Zugriff auf Daten von über 400 Millionen Unternehmen global über > 270 verschiedene Anbieter',
        bullet1:
          'Besondere Auflösung mit weiteren ~ 10 Mio. Datensätze in Deutschland, Österreich und der Schweiz durch Anbindung Spezialanbieter',
        bullet2:
          'Tagesaktuelle, weltweite Echtzeit-Informationen über Nutzung LexisNexis, Zugang zu mehr als 100.000 Quellen mit nationalen und internationalen Nachrichten und Firmeninformationen aus über 100 Ländern in 90 Sprachen.',
        bullet3:
          'Maschineller Zugriff auf Suchmaschinenergebnisse zu Ihrer Zielperson (Texte; Artikel; Dokumente, Bildmaterial)',
        bullet4:
          'Abdeckung der öffentlichen Teile der wichtigsten Soziale Medien Kanäle wie Linked-in, Facebook, Twitter, Instagram',
      },
      reasonForTheAnalysisPopup: {
        title: 'Fallparameter aufsetzen',
        content:
          'Zur DSGVO-gerechten Auswahl der Analysewerkzeuge und Quellen müssen im Folgenden die Rahmenbedingungen des vorliegenden Falls definiert werden. Die jeweiligen Konsequenzen der Einstellungen werden in jedem Punkt nochmal detailliert erläutert. Indicium ist dazu verpflichtet, die Richtigkeit der Angaben Stichprobenhaft zu überprüfen. Wenn Sie weitere Fragen haben, wenden Sie sich gerne an das Indicium Team unter ',
        contentEmail: 'fallanlage@indicium.ag.',
      },
      keywordsTitleGerman: 'Geben Sie ein Stichwort an (Deutsch)',
      keywordsTitleEnglish: 'Englisch',
      noTranslationsFound: 'Kein Übersetzung gefunden',
      suggestSynonyms: 'Synonyme vorschlagen',
      clearSynonyms: 'Synonyme entfernen',
      noSynonymsFound: 'Kein Synonym gefunden',
      keywordType: {
        topic: 'Thema',
      },
      gdprPopover: {
        title: '100% DSGVO-Konform',
        infoText1: 'Alle Analysen sind Konform mit der DSGVO',
        infoText2: 'Sichere Verbindung über SSL-Server',
        infoText3: 'Verschlüsselte Daten',
      },
    },
    yes: 'Ja',
    no: 'Nein',

    consolidatedView: 'Konsolidierte Ansicht',
    present: 'Heute',
    seeMore: 'Zeig mehr',
    seeLess: 'Zeige weniger',
    moreDetails: 'Mehr Details',

    image: 'Bild',
    imageUpload: 'Bild hochladen',
    uploadTheImage: 'Lade das Bild hoch',
    browse: 'Durchsuche',
    documentType: 'Dokumentenart',
    mdCeo: 'Geschäftsführer/Vorstand',
    country: 'Land',
    zipCode: 'Postleitzahl',
    street: 'Straße',

    registrationDate: 'Registrierungsdatum',
    registrationNumber: 'Registrationsnummer',

    keyword: 'Stichwort',
    keywords: 'Stichworte',
    /**
     * @todo: Clean-up and organize the keys better
     * Some of these translations exist on root level
     * Also there are duplicated because of naming inconsistencies between candidate tiles and target profile
     */
    infoBlockItem: {
      placeOfResidency: 'Wohnort',
      age: 'Alter',
      company: 'Unternehmen',
      nationality: 'Nationalität',
      phone: 'Telefon',
      phones: 'Telefon',
      email: 'E-Mail',
      emails: 'E-Mail',
      website: 'Webseite',
      websites: 'Webseite',
      url: 'URLs',
      placeOfBirth: 'Geburtsort',
      birthPlace: 'Geburtsort',
      dateOfBirth: 'Geburtstag',
      birthday: 'Geburtstag',
      username: 'Benutzernamen',
      gender: 'Geschlecht',
      language: 'Sprache',
      originCountry: 'Herkunftsland',
      education: 'Ausbildung',
      city: 'Stadt',
      state: 'Bundesland',
      country: 'Land',
      scientificTitle: 'Profession',
      firstname: 'Vorname',
      middlename: 'Mittlerer Vorname',
      lastname: 'Nachname',
      placesLived: 'Aufenthaltsorte',
      occupation: 'Position',
    },
    yearsOld: '{{ age }} Jahre alt',
    pagination: {
      previous: 'Vorherige',
      next: 'Nächste',
    },
    filters: {
      dateFrom: 'Datum von',
      dateTo: 'Datum bis',
      language: 'Sprache',
      sentiment: 'Stimmung',
      neutral: 'Neutral',
      positive: 'Positiv',
      mixed: 'Gemischt',
      negative: 'Negativ',
      source: 'Quelle',
      type: 'Typ',
      socialMedia: 'Soziale Medien',
      mainSocialMediaProfiles: 'Profile',
      potentialSocialMediaProfiles: 'Potentielle Profile',
      connectionType: 'Verbindungstyp',
      status: 'Status',
      post: 'Beitrag',
      connection: 'Verbindung',
      press: 'Presse',
      registerData: 'Registerdaten',
      searchEngines: 'Suchmaschinen',
      image: 'Bild',
      sources: 'Quellen',
      active: 'Aktiv',
      inactive: 'Inaktiv',
      Orbis: 'Handelsregister (via BvD Orbis)',
      Pipl: 'Internetrecherche (via Pipl)',
      CompanyHouse: 'Handelsregister (via Companyhouse)',
      userInput: 'Nutzereingabe',
      Instagram: 'Instagram',
      Facebook: 'Facebook',
      Twitter: 'Twitter',
      LinkedIn: 'LinkedIn',
      Flickr: 'Flickr',
      Google: 'Google',
      Bing: 'Bing',
      English: 'Englisch',
      German: 'Deutsch',
      confidence: 'Konfidenzniveau',
      highConfidence: 'höchstwahrscheinlich Zielperson                ',
      indiferrent: 'eventuell Zielperson, keine klaren Anhaltspunkte',
      highNegativeConfidence:
        'Namensgleich, aber höchstwahrscheinlich andere Person',
      selected: 'Selected',
    },
    exactSearch: 'Genaue Suche',
    hitsCount: '{{hits}} Treffer',
    sortedBy: 'Sortiert nach',
    suspicionsCount: '{{hits}} Funde',
    confirmedSuspicionsCount: '{{hits}} Bestätigt',
    reports: {
      redFlags: 'Potenzielle kritische Themen',
      anomaliesCount: '{{count}} Auffälligkeiten',
      sourcesCount: '{{count}} Quellen überprüft',
      resultsCount: '{{count}} Ergebnisse gefunden',
      sections: {
        compliance_violations: 'Hinweise auf Complianceverstöße',
        general_crime: 'Hinweise auf Kriminalität',
        own_keywords: 'Eigene Stichworte',
        general_anomalies: 'Allgemeine Auffälligkeiten',
        problematic_content_detected: 'Potenzielle problematische Inhalte',
      },
      showAll: 'Alle anzeigen',
      showLess: 'Weniger anzeigen',
    },
    relevanceScore: 'Relevanz Score',
    suspicion: 'Verdacht',
    relatedSuspicion: 'Textinhalt mit Bezug zu',
    entity: 'Entität',
    googleCandidates: {
      groupTitle: 'Erweitete Suchmaschinen suche',
      selectGroup: 'Auswahl',
      excludeGroup: 'Ausschließen',
      results: 'Resultate',
      groupTopics: 'Gruppenthemen',
      moreDetails: 'Weitere Details',
      lessDetails: 'Weniger Details',
    },
    addToReport: 'Zum Report hinzufügen',
    changeSuspicion: 'Ändern auf anderen Verdacht',
    changeSuspicionButton: 'Ändern',
    selectSuspicion: 'Wählen sie einen Verdacht',
    linkedSuspicions: 'Verknüpfte Verdachte',
    suspicionItemEntityType: {
      SocialAccountConnection: 'öffentliche Verbindungen auf Soziale Medien',
      SocialAccountPost: 'öffentliche Beiträge auf Soziale Medien',
      press: 'Presse',
      TargetCompany: 'Registerdaten',
      WebPage: 'Suchmaschinen',
      Image: 'Bildmaterial',
    },
    suspicionItemStatus: {
      problem: 'Problem',
      no_problem: 'Unbedenklich',
      pending: 'Ausstehend',
      wrong_target: 'Falsche Person',
    },
    setAsProfilePhoto: 'als Profilbild verwenden',
    apiErrors: {
      403: 'Vereinbartes Volumen an Reports erreicht',
      default:
        'Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingabe und probieren Sie es erneut.',
    },
    companyStatus: {
      active: 'Aktiv',
      'active (default of payment)': 'Aktiv (Zahlungsausfall)',
      'active (insolvency proceedings)': 'Aktiv (Insolvenzverfahren läuft)',
      'active (dormant)': 'Aktiv (ruhend)',
      insolvency: 'Insolvent',
      dissolved: 'Aufgelöst',
      'status unknown': 'Status unbekannt',
      inactive: 'Inaktiv',
      'in liquidation': 'In Liquidation',
      ak: 'Aktiv',
      ia: 'Inaktiv',
      'active (reorganization)': 'Aktiv (Reorganisation)',
      bankruptcy: 'Bankrott',
      'dissolved (bankruptcy)': 'Aufgelöst (Bankrott)',
      'dissolved (liquidation)': 'Aufgelöst (Liquidation)',
      'dissolved (merger or take-over)': 'Aufgelöst (nach Übernahme)',
      'inactive (no precision)': 'Inaktiv',
      'administratively suspended': 'Verwaltungsseitig inaktiv gesetzt',
      default: 'Status unbekannt',
    },
    companyCardTabs: {
      basicData: 'Basisdaten',
      subsidiaryCompany: 'Tochterunternehmen',
      ownersAndManagers: 'Eigentümer und Manager',
      financialInformation: 'Finanzinformationen',
      publications: 'Publikationen',
    },
    companyMutations: {
      kapitalaenderung: 'Kapitaländerung',
      kapitalherabsetzung: 'Kapitalherabsetzung',
      kapitalerhoehung: 'Kapitalerhöhung',
      aenderungorgane: 'Änderung der Organe',
      adressaenderung: 'Adressänderung',
      status: 'Status',
      firmenaenderung: 'Firmenänderung',
    },
    sourceName: {
      orbis: 'Handelsregister (via BvD Orbis)',
      pipl: 'Internetrecherche (via Pipl)',
      companyhouse: 'Handelsregister (via Companyhouse)',
      complianceCatalyst:
        'Globale Sanktionslisten, Watchlisten und Listen politisch exponierter Personen (via "BvD Compliance Catalyst")',
      firmenabc: 'Handelsregister (via FirmenABC)',
      lexisnexis: 'Pressearchive (via Lexis Nexis)',
      google: 'Internetrecherche (via Google)',
      facebook: 'Soziale Medien (via DC)',
      twitter: 'Soziale Medien (via DC)',
      instagram: 'Soziale Medien (via DC)',
      linkedin: 'Soziale Medien (via DC)',
      flickr: 'Soziale Medien (via DC)',
      digitalclues: 'Soziale Medien (via DC)',
      zefix: 'Handelsregister (via Zefix)',
      userinput: 'Nutzereingabe',
    },
    inputDataDisclamer:
      'Hier können Sie Ihre ursprüngliche Eingabe-Daten einsehen. Ebenso können Sie die Daten verändern und so einen weiteren Report anlegen (kostenpflichtig).',
    maintenance: {
      title: 'Die Seite wird derzeit gewartet',
      subtitle: 'Bitte versuchen Sie es später noch einmal',
      reload: 'Seite erneut laden',
    },
    companyCard: {
      dateOfIncorporation: 'Gründungsdatum',
      companyActivity: 'Unternehmensfokus',
      previousNames: 'vorige Firmierungen',
      identifiers: 'Identifikationsnummern',
      tradeRegisterNumber: 'Registernummer',
      vatTaxNumber: 'Umsatzsteuer ID',
      leiCompanyId: 'LEI Firmen ID',
      europeanVatNumber: 'Europäische Umsatzsteuernummer',
      subjectText: 'Unternehmensgegenstand',
      subsidiaryName: 'Firmierung',
      informationDate: 'Gründung/Kauf',
      country: 'Land',
      shareholder: 'Shareholder',
      manager: 'Manager',
      actuality: 'Status',
      name: 'Name',
      dob: 'Geburtsdatum',
      pob: 'Geburtsort',
      directlyOwned: '% Beteiligung',
      ultimatelyOwned: '% durchgerechnete Beteiligung',
      address: 'Address',
      financials: 'Eckdaten/Finanzinformationen',
      turnover: 'Umsatz',
      profit: 'Gewinn',
      employees: 'Anzahl Mitarbeiter',
      previous: 'Previous',
      current: 'Current',
    },
    dynamicForm: {
      title: 'weitere Stichworte',
      selectField: 'Feld',
      fields: {
        occupations: 'Position',
        relatedPersons: 'Person',
        websites: 'Webseite',
        organizations: 'Organisation',
        topics: 'Thema',
        locations: 'Ort',
        nicknames: 'Spitznamen',
        default: 'Wort',
      },
      buttons: {
        include: 'Einbeziehen',
        exclude: 'Ausschließen',
      },
      description:
        'Bitte geben Sie weitere Stichworte ein, die die Zielperson beschreiben (auf Deutsch und Englisch): Orte (Land oder Stadt von Aufenthaltsorten, Geburtsorten, Arbeitsstätten, ...), Beschäftigungen (Titel, Jobbezeichnung, ...), Organisationen (außer Unternehmen), verbundene Webseiten, Personen (Vor- und Zuname) oder Themen (Hobbies, Events, etc.) die als mit der Zielperson zusammenhängend gesichert bekannt sind',
      totalKeywords: 'Anzahl Stichworte:',
      maxKeywords: '(Maximum: {{maxLength}})',
    },
    confidenceScore: {
      score10: 'Mit hoher Wahrscheinlichkeit FALSCHE Person',
      score20: 'wenig Anhaltspunkte, ob es die Zielperson ist',
      score30: 'Mit hoher Wahrscheinlichkeit die Zielperson',
    },
    suspicionKeywords: {
      arson: 'Brandstiftung',
      assault: 'Körperverletzung',
      asset_misuse_misdemeanors: 'Vermögensmissbrauch',
      assets_misuse_offenses: 'Datenschutzverletzung',
      banking_payment_offenses:
        'Vergehen aus Banken- oder Zahlungsdiensterecht',
      black_market: 'Schwarzhandel',
      bmf_fiu_risk_country: 'BMF/FIU Risikoland',
      capital_market_offenses: 'Kapitalmarktrechtsvergehen',
      capital_offenses: 'Kapitalverbrechen',
      child_pornography: 'Kinderpornographie',
      clans: 'Clans',
      company_blacklist: 'Firma auf Blacklist',
      copyright_infringement: 'Urheberrrechtsverletzung',
      corporate_governance_code: 'Corporate Governance Vergehen',
      corporate_insurance_offenses: 'Versicherungsbetrug',
      corporate_law_violations: 'Gesellschaftsrechtsvergehen',
      corporate_logistics_offenses: 'Vergehen betriebliche Logistik',
      corporate_obligations: 'Verstoß gegen Unternehmerpflichten',
      corporate_operations:
        'Problematiken in Zusammenhang mit Unternehmensabläufen',
      corruption: 'Korruption',
      cybercrime: 'Cyberkriminalität',
      data_breach: 'Datenschutzverletzung',
      data_security_breaches: 'Datenschutzvergehen',
      debt_law_offenses: 'Schuldrechtsvergehen',
      digital_black_markets: 'Digitale Schwarzmärkte',
      employment_relationships: 'Beschäftigungsrechtliche Problematiken',
      eu_third_country_with_increased_: 'EU Drittland mit erhöhtem Risiko',
      exhibitionism: 'Exhibitionismus',
      exploiting_leading_position: 'Ausnutzen führender Postion',
      explosives: 'Sprengstoffe',
      extortion: 'Erpressung',
      extremism_terror: 'Extremismus / Terror',
      fair_trading_offenses: 'Lauterkeitsrechtsvergehen',
      fatf_increased_monitoring: 'Erhöhte FATF-Überwachung',
      financial_reporting: 'Fehlerhafte Finanzberichterstattung',
      firearms: 'Schusswaffen',
      foreign_trade_law: 'Verstöße im Außenwirtschaftsrecht',
      fraud_consumer: 'Betrugsdelikte / Verbraucher',
      hacking_attacks: 'Hacking Attacken',
      health_safety_offenses: 'Arbeitssicherheits / Arbeitsschutzvergehen',
      human_rights_violations: 'Menschenrechtsverletzung',
      identity_theft: 'Identitätsdiebstahl',
      indications_for_crime: 'Hinweise auf Kriminalität',
      information_protection: 'Mangelnder Schutz von Informationen',
      insolvency_law_offenses: 'Insolvenzvergehen',
      islamist_terror: 'Islamistischer Terror',
      labor_law_offenses: 'Arbeitsrechtsvergehen',
      left_wing_extremism: 'Linksextremismus',
      malware: 'Schadprogramme (Erstellung / Nutzung)',
      money_laundering: 'Geldwäsche',
      money_laundering_country_list: 'Auf Liste Geldwäscheländer',
      murder_manslaughter: 'Mord / Totschlag',
      negative_sentiment: 'Negative Ausdrucksweise',
      no_internet_presence: 'Keine Internetpräsenz',
      offenses: 'Beleidigung',
      offshore_leak: 'Genannt in Offshore Leak Datenbanken',
      organized_crime: 'Organisierte Kriminalität',
      other_criminal_activities: 'Sonstige kriminelle Aktivitäten',
      potential_compliance_violation: 'Hinweise auf Complianceverstöße',
      procurement_law_offenses: 'Vergaberechtsversdtöße',
      product_safety: 'Vergehen Produktsicherheit und-haftung',
      property_damage: 'Sachbeschädigung',
      property_offenses:
        'Potentielle Vergehen aus dem Bereich betriebliche Grundstücke und Immobilien',
      ransomware: 'Ransomware',
      rape: 'Vergewaltigung',
      red_light_milieu: 'Rotlichtmilieu',
      right_wing_extremism: 'Rechtsextremismus',
      robbery_kidnapping: 'Raub / Entführung / Menschenhandel',
      rockers: 'Rocker / MCs',
      scam_fraud: 'Scam / Fraud',
      sex_offenses: 'Sexualdelikte',
      sexual_assault: 'Sexuelle Nötigung',
      social_security_fraud: 'Sozialversicherungsbetrug',
      substance_abuse: 'Drogendelikte',
      sunsidies_funding_offenses: 'Subventionsbetrug',
      sustainability_environment: 'Nachhaltigkeits- und Umweltvergehen',
      tax_offenses: 'Steuernvergehen',
      theft: 'Diebstahl / Unterschlagung / Hehlerei',
      trade_secrets: 'Geheimnisverrat',
      transactions: 'Problematiken in Zusammenhang mit Transaktionen',
      violent_crimes: 'Gewaltdelikte',
      weapon_offenses: 'Waffendelikte',
      white_collar_crime: 'Wirtschaftskriminalität',
      wrong_notices: 'Fehlerhafte Kündigungen',
    },
  },
};
