import { FC, useRef, useState } from 'react';
import { MdOutlineMoreVert } from 'react-icons/md';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { Paragraph } from '_atoms';
import { nonProdDataTestId } from '_utils';

export type TableRowAction = {
  id: string;
  label: string | ((id: string) => string);
  callback: (id: string) => void;
};

export type TableRowActionsMenuProps = {
  actions: TableRowAction[];
  id: string;
};

const getTestIdFromAction = (action: string) => {
  switch (action) {
    case 'editCaseAction':
      return 'case edit button';
    case 'deleteCaseAction':
      return 'case delete button';
    default:
      return undefined;
  }
};

export const TableRowActionsMenu: FC<TableRowActionsMenuProps> = ({
  actions,
  id: parentId,
}: TableRowActionsMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLTableCellElement | null>(null);

  const closeDropdown = () => setIsOpen(false);
  useClickOutside(dropdownRef, closeDropdown);

  return (
    <td
      className="px-3.5 py-5 relative"
      ref={dropdownRef}
      data-testid={nonProdDataTestId('case dots button')}
    >
      <button
        className="text-gray-500 hover:text-gray-900 focus:outline-none"
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <MdOutlineMoreVert />
      </button>

      {isOpen ? (
        <ul className="absolute bg-white z-20 shadow-lg border border-gray-200 right-0 rounded-md">
          {actions.map(({ id, label, callback }) => (
            <li
              key={id}
              data-testid={nonProdDataTestId(getTestIdFromAction(id))}
            >
              <a
                className="cursor-pointer inline-block px-3 pt-3 pb-2.5"
                onClick={() => {
                  setIsOpen(false);
                  callback(parentId);
                }}
              >
                <Paragraph
                  size="default"
                  weight="normal"
                  color="dark"
                  className="whitespace-nowrap hover:text-blue-400"
                >
                  {typeof label === 'function' ? label(parentId) : label}
                </Paragraph>
              </a>
            </li>
          ))}
        </ul>
      ) : null}
    </td>
  );
};
