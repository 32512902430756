import { FC } from 'react';
import { Button, Modal, Paragraph, RadioGroup } from '_atoms';
import { nonProdDataTestId } from '_utils';

type ConfirmationModalProps = {
  body: string;
  cancelButtonText: string;
  confirmButtonText: string;
  enableConfirm: boolean;
  isOpen: boolean;
  radioOptions: {
    options: string[];
    handleSelect: (value: string) => void;
  };
  title: string;
  handleButtonClick: (event: 'confirm' | 'cancel') => void;
};

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  body,
  cancelButtonText,
  confirmButtonText,
  enableConfirm,
  isOpen,
  radioOptions: { options, handleSelect },
  title,
  handleButtonClick,
}: ConfirmationModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      title={title}
      data-testid={nonProdDataTestId('popup2')}
    >
      <Paragraph className="mt-4">{body}</Paragraph>
      <RadioGroup
        clasName="mt-2"
        options={options.map((label, optionIdx) => ({
          label,
          value: `delete-option-${optionIdx}`,
        }))}
        onSelect={handleSelect}
      />
      <div className="flex space-x-4 mt-6">
        <Button
          disabled={!enableConfirm}
          type="button"
          onClick={() => handleButtonClick('confirm')}
          data-testid={nonProdDataTestId('confirm delete button')}
        >
          {confirmButtonText}
        </Button>
        <Button
          level="secondary"
          type="button"
          onClick={() => handleButtonClick('cancel')}
          data-testid={nonProdDataTestId('cancel delete button')}
        >
          {cancelButtonText}
        </Button>
      </div>
    </Modal>
  );
};
