import { useState, FC, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Headline, Badge } from '_atoms';

interface WordStratusProps extends HTMLAttributes<HTMLDivElement> {
  keywords: Array<{ value: string; count: number }>;
  onKeywordClick: (i: number) => void;
}

export const WordStratus: FC<WordStratusProps> = ({
  keywords,
  onKeywordClick,
  ...props
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div className="flex mx-3 my-2 w-full" {...props}>
      <Headline Level="h6" className="mr-2 mt-1 flex-none">{`${t(
        'keywords',
      )}: `}</Headline>
      <div
        className={classNames(
          'content-start',
          expanded ? 'flex-auto space-x-2' : 'flex-1 space-x-4',
          // 'w-1/2',
        )}
      >
        {keywords
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .reduce(
            (acc, keyword, index) =>
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              !expanded && index > 5 ? acc : [...acc, keyword],
            [],
          )
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .map((keyword, index) => (
            <Badge
              key={`${keyword.value}:${index}`}
              background="white"
              paddingClassName="pl-2 pr-1 py-1"
              marginClassName="my-1"
              className="border-blue-400 border-solid border-2 font-semibold"
              onClick={() => ((i) => onKeywordClick?.(i))(index)}
            >
              {keyword.value}
              <Badge
                background="blue"
                paddingClassName="px-2 py-1"
                marginClassName="mx-0 ml-2"
                className="font-semibold"
              >
                {keyword.count}
              </Badge>
            </Badge>
          ))}
      </div>
      <button
        className="flex-none pr-7 text-blue-400 font-semibold text-sm focus:border-0"
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? `${t('seeLess')}   ⌃` : `${t('seeMore')}   ⌄`}
      </button>
    </div>
  );
};
