import { FC, HTMLAttributes } from 'react';
import { CustomData } from '_atoms';
import classNames from 'classnames';

interface ImageDataProps extends HTMLAttributes<HTMLDivElement> {
  headline: string;
  src: string;
  alt?: string;
  width?: string;
}

export const ImageData: FC<ImageDataProps> = ({
  headline,
  src,
  alt = '/',
  width = 'w-1/4',
  className = '',
  ...props
}) => (
  <CustomData
    headline={headline}
    width={width}
    className={className}
    {...props}
  >
    {src ? (
      <img
        src={src}
        alt={alt}
        className={classNames(
          'mt-2 mr-4 mb-3 h-40 transform z-0 hover:z-50 relative z-20',
          'hover:scale-300',
        )}
      />
    ) : null}
  </CustomData>
);
