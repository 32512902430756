import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FormikHelpers } from 'formik';
import { Button, DatePicker, Headline, Paragraph, StyledField } from '_atoms';
import { Link, Redirect } from 'react-router-dom';
import { routes } from '../../../routes';
import { useCreateCustomerMutation } from '../../../hooks/queries';
import {
  UserInputForCreateCustomer,
  UserInputForCreateCustomerSchema,
} from '@indicium/common';

export const initialValues: UserInputForCreateCustomer = {
  name: '',
  limitOfReports: 0,
  owner: {
    email: '',
    firstname: '',
    lastname: '',
  },
  expiresAt: null,
};

export const CustomerAdminNew: FC = () => {
  const { t } = useTranslation();

  const createCustomerMutation = useCreateCustomerMutation();

  const handleSubmit = async (
    values: unknown,
    actions: FormikHelpers<UserInputForCreateCustomer>,
  ) => {
    const formData = (await UserInputForCreateCustomerSchema.validate(
      values,
    )) as UserInputForCreateCustomer;

    createCustomerMutation.mutate(formData);
    actions.setSubmitting(false);
  };

  if (createCustomerMutation.isSuccess) {
    return <Redirect to={routes.customerAdmin.path} />;
  }

  if (createCustomerMutation.isError) {
    console.error(createCustomerMutation.error);
  }

  const ErrorMessage = {
    409: t('emailAlreadyExists'),
  } as const;

  return (
    <Fragment>
      <div className="container-fluid mb-20 px-16">
        <div className="row">
          <Headline Level="h1" color="dark" className="mt-8 mb-4" weight="bold">
            {t('createNewCustomer')}
          </Headline>
        </div>

        <Formik<UserInputForCreateCustomer>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={UserInputForCreateCustomerSchema}
          validateOnMount
        >
          {({ isValid }) => (
            <Form className="space-y-6">
              <div className="bg-white rounded-md py-5 px-6">
                <section className="row space-y-7">
                  <div className="col-12">
                    <Headline Level="h4" className="mt-0" color="dark">
                      {t('targetPersonDataSubHeadline')}
                    </Headline>
                    <Headline Level="h6" color="dark" className="my-3.5">
                      {t('basisData')}
                    </Headline>
                  </div>
                  <div className="col-12 md:col-6">
                    <StyledField
                      type="text"
                      name="name"
                      label={t('companyName')}
                      required
                    />
                  </div>
                  <div className="col-12 md:col-6">
                    <StyledField
                      type="text"
                      name="limitOfReports"
                      label={t('limitOfReports')}
                      required
                    />
                  </div>
                  <div className="col-12 md:col-4 lg:col-3">
                    <span className="block text-sm font-medium text-gray-700 mb-1">
                      {t('expiresAt')}
                    </span>
                    <StyledField name="expiresAt">
                      {({ field, form: { setFieldValue } }) => {
                        return (
                          <DatePicker
                            selected={
                              field.value
                                ? new Date(field.value as string)
                                : null
                            }
                            dateFormat="dd.MM.yyyy"
                            onChange={(date) => setFieldValue(field.name, date)}
                            minDate={new Date()}
                            isClearable
                          />
                        );
                      }}
                    </StyledField>
                  </div>
                </section>
                <section className="row mt-12">
                  <div className="col-12">
                    <Headline Level="h6" color="dark" className="my-3.5">
                      {t('contactData')}
                    </Headline>
                  </div>
                  <div className="col-12 md:col-6 my-3.5">
                    <StyledField
                      type="text"
                      name="owner.email"
                      label={t('email')}
                      required
                    />
                  </div>
                  <div className="col-12 md:col-6 my-3.5">
                    <StyledField
                      type="text"
                      name="owner.firstname"
                      label={t('firstname')}
                      required
                    />
                  </div>
                  <div className="col-12 md:col-6 my-3.5">
                    <StyledField
                      type="text"
                      name="owner.lastname"
                      label={t('lastname')}
                      required
                    />
                  </div>
                </section>
              </div>
              <div className="row">
                <div className="col-12 flex justify-end space-x-4">
                  <Link
                    className="h-fit-content"
                    to={routes.customerAdmin.path}
                  >
                    <Button level="secondary" className="w-52" type="button">
                      {t('back')}
                    </Button>
                  </Link>
                  <div>
                    <Button
                      disabled={!isValid || createCustomerMutation.isLoading}
                      level="primary"
                      className="w-52"
                      type="submit"
                    >
                      {t('continue')}
                    </Button>
                    {createCustomerMutation.isError && (
                      <Paragraph
                        size="default"
                        weight="normal"
                        lineHeight="default"
                        className="mt-4 w-52 text-red-700"
                      >
                        {ErrorMessage[
                          createCustomerMutation.error.error
                            .code as keyof typeof ErrorMessage
                        ] ?? t('invalidServerResponse')}
                      </Paragraph>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Fragment>
  );
};
