import { differenceInDays, format, isBefore } from 'date-fns';
import { ProcessTarget, TargetReportSourceType } from '@indicium/common';
import config from '../backendConfig.json';
import { Profile } from '../hooks/queries/useTargetResultsQuery';
import i18n from '../i18n';
import { TargetCandidateInfoItem, TargetProfileData } from '_types';
import { TargetPersonSelectionState } from '_enums';
import classNames from 'classnames';
import countries from '_assets/countries.json';

const CDN_URL = 'https://' + config.DomainNameHttp.replace('api.', 'cdn.');

export const parseRem: (value: string) => number = (value) =>
  value.indexOf('rem') ? parseFloat(value.split('rem')[0]) * 16 : 0;

export const SourcesMap: Record<TargetReportSourceType, string> = {
  companyhouse: 'Company House',
  orbis: 'Orbis',
  pipl: 'Pipl',
  digitalclues: 'Digital Clues',
  userInput: 'Nutzereingabe',
  firmenabc: 'FirmenABC',
  indicium: 'Indicium',
  zefix: 'Zefix',
  complianceCatalyst: 'Compliance Catalyst',
  icijLeaks: 'Icij Leaks',
} as const;

export const mapSourceToText = (source: string): string =>
  `sourceName.${source.toLowerCase()}`;

export const mapSourcesToText: (value: TargetReportSourceType[]) => string = (
  value,
) => {
  const sources = value.map((v) => i18n.t(mapSourceToText(v)));
  return sources.join(', ');
};

type Umlauts = 'Ä' | 'ä' | 'Ö' | 'ö' | 'Ü' | 'ü' | 'ß';

const UmlautsMap: Record<Umlauts, string> = {
  Ä: 'ae',
  ä: 'ae',
  Ö: 'oe',
  ö: 'oe',
  Ü: 'ue',
  ü: 'ue',
  ß: 'ss',
} as const;

export const replaceUmlauts = (value: string): string =>
  value.replace(/Ä|ä|Ö|ö|Ü|ü|ß/gi, (match) => UmlautsMap[match as Umlauts]);

export type BirthdateRange = {
  value: {
    end: string;
    start: string;
  };
  sources: TargetReportSourceType[];
};

export const formatDate = (date: string | Date): string =>
  format(typeof date === 'string' ? new Date(date) : date, 'dd.MM.yyyy');

export const formatDateTime = (date: string | Date): string =>
  format(typeof date === 'string' ? new Date(date) : date, 'dd.MM.yyyy, HH:mm');

export const transformBirthdayRangeToString = (
  data: BirthdateRange[] = [],
): {
  value: string;
  sources: string[];
}[] =>
  data.map(({ value: { start, end }, sources }) => ({
    value: start === end ? formatDate(start) : checkBirthdateRange(start, end),
    sources,
    additionalValue:
      start === end
        ? ' (' + i18n.t('yearsOld', { age: calculateAge(start) }) + ')'
        : undefined,
  }));

const checkBirthdateRange = (start: string, end: string): string => {
  if (
    format(new Date(start), 'dd.MM') === '01.01' &&
    format(new Date(end), 'dd.MM') === '31.12' &&
    format(new Date(start), 'yyyy') === format(new Date(end), 'yyyy')
  ) {
    return format(new Date(start), 'yyyy');
  }
  return [formatDate(start), formatDate(end)].join(' - ');
};

const calculateAge = (birthday: string): string => {
  const today = new Date();
  const birthDate = new Date(birthday);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
    age--;
  }
  return age.toString();
};

/**
 * Takes url or digitalclues asset filepath.
 * Returns url to the image, running filepaths through assetproxy if needed
 */
export const proxifyDigitalcluesAssetFilepathIfNeeded = (
  dcFilepathOrUrl: string,
  accessToken: string,
): string => {
  if (!dcFilepathOrUrl) {
    return '';
  }
  // As long as it starts with https:// we can use that directly as the proper url
  if (dcFilepathOrUrl.startsWith('https://')) {
    return dcFilepathOrUrl;
  }
  // Otherwise we have to assume it is a filepath from DC which needs to be proxied because of DC is forcing us to use a VPC to access their cached media assets. Additionally media 'urls' provided by DC may contain `\`, i.e. Windows path separators we need to replace into proper `/`, i.e. path separators for urls
  return `https://${
    config.backendBaseUrl
  }/assetproxy/${dcFilepathOrUrl.replaceAll('\\', '/')}?token=${accessToken}`;
};

export const prepareInfoBlockContent = (
  text: string | string[],
): { value: string }[] =>
  Array.isArray(text) ? text.map((value) => ({ value })) : [{ value: text }];

function editDistance(s1: string, s2: string) {
  const string1 = s1.toLowerCase();
  const string2 = s2.toLowerCase();

  const costs = [];
  for (let i = 0; i <= string1.length; i += 1) {
    let lastValue = i;
    for (let j = 0; j <= string2.length; j += 1) {
      if (i === 0) costs[j] = j;
      else if (j > 0) {
        let newValue = costs[j - 1];
        if (string1.charAt(i - 1) !== string2.charAt(j - 1))
          newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
        costs[j - 1] = lastValue;
        lastValue = newValue;
      }
    }
    if (i > 0) costs[string2.length] = lastValue;
  }
  return costs[string2.length];
}

export const prepareCompanyNamesForComparison = (name: string): string => {
  const companyTypes = [
    'Gesellschaft mit beschränkter Haftung',
    'Aktiengesellschaft',
    'Kommanditgesellschaft',
    'Offene Handelsgesellschaft',
    'Handelsgesellschaft',
    'haftungsbeschränkt',
    'Unternehmergesellschaft',
    'INVESTMENTS LIMITED',
    'INTERNATIONAL LIMITED',
    'SERVICES LIMITED',
    'HOLDINGS LIMITED',
    'HOLDING LIMITED',
    'COMMERCIAL LIMITED',
    'ENTERPRISES LIMITED',
    'LIMITED',
    'INVESTMENTS LTD',
    'INTERNATIONAL LTD',
    'SERVICES LTD',
    'HOLDINGS LTD',
    'HOLDING LTD',
    'COMMERCIAL LTD',
    'ENTERPRISES LTD',
    'Mini-GmbH',
    'Small GmbH',
    '1-Euro-GmbH',
    'UGmbH',
    'gmbh',
    'KGaA',
    'LTD',
    'SCE',
    'SE',
    'SA',
    'REIT-AG',
    'AG',
    'eG',
    'B.V.',
  ];

  return companyTypes.reduce((companyName, companyType) => {
    const index = companyName.indexOf(` ${companyType.toLowerCase()}`);
    return index !== -1
      ? companyName.split(companyType.toLowerCase()).join('').trim()
      : companyName;
  }, name.toLowerCase());
};

export function similarity(
  s1: string,
  s2: string,
  lengthCoeficient: number,
): number {
  // based on Levenshtein distance
  // - added a coeficcient which trucates the end of the longer string
  const preppedS1 = prepareCompanyNamesForComparison(s1);
  const preppedS2 = prepareCompanyNamesForComparison(s2);

  const [shorter, longer] =
    preppedS1.length > preppedS2.length
      ? [preppedS2, preppedS1]
      : [preppedS1, preppedS2];

  if (longer.length === 0) {
    return 1.0;
  }

  const longerTrimmed =
    longer.length > shorter.length * lengthCoeficient
      ? longer.slice(0, Math.floor(shorter.length * lengthCoeficient))
      : longer;

  return (
    (longerTrimmed.length - editDistance(longerTrimmed, shorter)) /
    longerTrimmed.length
  );
}

export const transformResidenceToString = (
  data: {
    value: {
      city?: string;
      street?: string;
      country?: string;
      zip?: string;
    };
    sources: TargetReportSourceType[];
  }[] = [],
): {
  value: string;
  sources: TargetReportSourceType[];
}[] =>
  data.map((item) => ({
    value: [
      item.value.street,
      item.value.city,
      item.value.zip,
      item.value.country,
    ]
      .filter(Boolean)
      .join(', '),
    sources: item.sources,
  }));

export const capitalize = (value: string): string =>
  value.charAt(0).toUpperCase() + value.slice(1);

const denormalizeName = (value: string): string => {
  let name = value ? value.toLocaleLowerCase() : '';
  name = replaceUmlauts(name);

  return name;
};

export const normalizeName = (value: string): string => {
  let name = value ? value.toLocaleLowerCase() : '';
  name = name.split(',').map(capitalize).join(', ');
  name = name.split(' ').map(capitalize).join(' ');

  return name;
};

export const transformProfileFieldsToInfoBlockItem = (
  profile: TargetProfileData | undefined,
  keys: (keyof Omit<TargetProfileData, 'name'> | 'firstname' | 'lastname')[],
): TargetCandidateInfoItem[] => {
  if (!profile) {
    return [];
  }

  const data: TargetCandidateInfoItem[] = [];
  keys.forEach((key) => {
    switch (key) {
      case 'firstname':
        data.push({
          key,
          values: [
            ...new Set(
              profile.name
                ?.map((field) => denormalizeName(field.value.first))
                .filter(Boolean),
            ),
          ].map(normalizeName),
          sources: [...new Set(...profile.name.map((name) => name.sources))],
        });
        break;
      case 'lastname':
        data.push({
          key,
          values: [
            ...new Set(
              profile.name
                ?.map((field) => denormalizeName(field.value.last))
                .filter(Boolean),
            ),
          ].map(normalizeName),
          sources: [...new Set(...profile.name.map((name) => name.sources))],
        });
        break;
      case 'birthday': {
        const birthdays = profile.birthday?.map((birthday) => {
          const { value } = birthday;
          let { start, end } =
            typeof value === 'string' ? { start: value, end: value } : value;
          if (start === end && start.length === 4 && end.length === 4) {
            start = [start, '01', '01'].join('-');
            end = [end, '12', '31'].join('-');
          }

          return {
            ...birthday,
            value: { start, end },
          };
        });
        const transformedBirthdayData =
          transformBirthdayRangeToString(birthdays);
        data.push({
          key,
          values:
            transformedBirthdayData?.map((field) => field.value.toString()) ||
            [],
          sources: [
            ...new Set(
              ...(birthdays || []).map((birthday) => birthday.sources),
            ),
          ],
        });
        break;
      }
      case 'age':
        data.push({
          key,
          values:
            profile.age?.map((field) =>
              i18n.t('yearsOld', { age: field.value }),
            ) || [],
          sources: [
            ...new Set(...(profile.age || []).map((field) => field.sources)),
          ],
        });
        break;
      case 'residence': {
        const transformedResidenceData = transformResidenceToString(
          profile.residence,
        );
        data.push({
          key: 'placesLived',
          values: transformedResidenceData?.map((field) => field.value) || [],
          sources: [
            ...new Set(
              ...(transformedResidenceData || []).map((field) => field.sources),
            ),
          ],
        });
        break;
      }
      case 'wocoEntities':
      case 'educations':
        break;
      default:
        data.push({
          key,
          values: profile[key]?.map((field) => field?.value) || [],
          sources: [
            ...new Set(...(profile[key]?.map((field) => field?.sources) || [])),
          ],
        });
    }
  });

  return data;
};

/**
 *
 * @param url: string - Social media public URL to be send to the CDN
 */
export const getCdnUrl = (url: string): string => {
  if (!url) return url;
  return url.slice(0, 4) === 'http' ? url : `${CDN_URL}/${url}`;
};

export const getSelectionStateBorderClass = (
  selectionState?: TargetPersonSelectionState,
  defaultClass = 'border-transparent',
): string =>
  !selectionState
    ? defaultClass
    : selectionState === TargetPersonSelectionState.Confirmed
    ? 'border-blue-400'
    : 'border-red-500';

export const withinSpanOfDays = (
  inputDate: string | number,
  daySpan = 30,
): boolean =>
  differenceInDays(new Date().getTime(), new Date(inputDate).getTime()) <=
  daySpan;

const companyStatusColors: Record<string, string[]> = {
  active: ['text-green-400'],
  'active (reorganization)': ['text-green-400'],
  ak: ['text-green-400'],
  insolvency: ['text-red-400'],
  bankruptcy: ['text-red-400'],
  'administratively suspended': ['text-red-400'],
  'in liquidation': ['text-yellow-400'],
  'active (dormant)': ['text-lime-400'],
  'active (default of payment)': ['from-green-400', 'to-red-400'],
  'active (insolvency proceedings)': ['from-green-400', 'to-red-400'],
  'dissolved (bankruptcy)': ['from-gray-400', 'to-red-400'],
  'dissolved (liquidation)': ['from-gray-400', 'to-red-400'],
  dissolved: ['text-gray-400'],
  'status unknown': ['text-gray-400'],
  inactive: ['text-gray-400'],
  ia: ['text-gray-400'],
  'dissolved (merger or take-over)': ['text-gray-400'],
  'inactive (no precision)': ['text-gray-400'],
  default: ['text-gray-400'],
};

export const getCompanyStatusClass = (status: string): string => {
  const colors = companyStatusColors[status.toLowerCase()];
  if (!colors) return `text-gray-400`;
  const baseClasses =
    colors.length > 1 ? ['bg-gradient-to-r bg-clip-text text-transparent'] : [];
  return classNames(baseClasses, colors);
};

export const isCustomerExpired = (expiresAt?: Date | null): boolean =>
  !!expiresAt && isBefore(new Date(expiresAt), new Date());

export const nonProdDataTestId = (testId?: string): string | undefined => {
  // TODO: move the data-testid removal on production to webpack (there are babel plugins that do that)
  if (!testId || process.env.REACT_APP_STAGE === 'production') {
    return undefined;
  }

  return testId;
};

const normalizeCountryCodes = (code: string): string => {
  if (code.toUpperCase() in countries) {
    return countries[code.toUpperCase() as keyof typeof countries];
  }

  return code;
};

export function removeLegalSuffix(company: string): string {
  const COMPANY_SUFFIXES = [
    'GmbH',
    'Inc.',
    'Ltd.',
    'SARL',
    'AG',
    'GmbH & Co. KG',
    'BV',
    'AS',
    'SA',
    'AB',
    'DE',
    'LLC',
    'GbR',
    'KG',
    'AG & Co. KG',
    'GmbH & Co. KG',
    'Limited & Co. KG',
    'Stiftung & Co. KG',
    'Stiftung GmbH & Co. KG',
    'UG (haftungsbeschränkt) & Co. KG',
    'OHG',
    'GmbH & Co. OHG',
    'AG & Co. OHG',
    'Partenreederei',
    'PartG',
    'PartG mbB',
    'Stille Gesellschaft',
    'AG',
    'gAG',
    'GmbH',
    'gGmbH',
    'InvAG',
    'KGaA',
    'AG & Co. KGaA',
    'SE & Co. KGaA',
    'GmbH & Co. KGaA',
    'Stiftung & Co. KGaA',
    'REIT-AG',
    'UG (haftungsbeschränkt)',
    'AöR',
    'eG',
    'Eigenbetrieb',
    'Einzelunternehmen',
    'e.V.',
    'KöR',
    'Regiebetrieb',
    'Stiftung',
    'VVaG',
    'EWIV',
    'SE',
    'SCE',
    'Gesellschaft mit beschränkter Haftung',
    'Aktiengesellschaft',
    'Kommanditgesellschaft',
    'Offene Handelsgesellschaft',
    'Handelsgesellschaft',
    'haftungsbeschränkt',
    'Unternehmergesellschaft',
    'INVESTMENTS LIMITED',
    'INTERNATIONAL LIMITED',
    'SERVICES LIMITED',
    'HOLDINGS LIMITED',
    'HOLDING LIMITED',
    'COMMERCIAL LIMITED',
    'ENTERPRISES LIMITED',
    'LIMITED',
    'INVESTMENTS LTD',
    'INTERNATIONAL LTD',
    'SERVICES LTD',
    'HOLDINGS LTD',
    'HOLDING LTD',
    'COMMERCIAL LTD',
    'ENTERPRISES LTD',
    'Mini-GmbH',
    'Small GmbH',
    '1-Euro-GmbH',
    'UGmbH',
    'gmbh',
    'KGaA',
    'LTD',
    'SCE',
    'SE',
    'SA',
    'REIT-AG',
    'AG',
    'eG',
    'B.V.',
    'Technologies',
    's.a.',
    'global',
    'eu',
    'ltd',
  ];

  const pattern = new RegExp(
    `\\b(${COMPANY_SUFFIXES.map((suffix) =>
      suffix.toLowerCase().replace(/\./g, '\\.?'),
    ).join('|')})\\b`,
    'g',
  );

  return company
    .toLowerCase()
    .replace(pattern, '')
    .trim()
    .replace(/ {2,}|\./g, ' ');
}

function normalize_caseless(text?: string): string | null {
  return text?.trim().toLowerCase().normalize('NFKD') ?? null;
}

type KeywordsMap = {
  highest: string[];
  high: string[];
  medium: string[];
  low: string[];
};

export const generateKeywords = (profile: Profile | undefined): KeywordsMap => {
  const keywords_map: KeywordsMap = {
    highest: [],
    high: [],
    medium: [],
    low: [],
  };
  if (!profile || !profile.targetPerson || !profile.companies) {
    return keywords_map;
  }

  const { targetPerson, companies } = profile;

  const {
    name,
    residence,
    nationality,
    birthPlace,
    phones,
    emails,
    websites,
    usernames,
    relatedPersons,
    locations,
    topics,
    nicknames,
    organizations,
    occupations,
  } = targetPerson;

  name?.forEach((item: any) => {
    keywords_map.highest.push(`${item?.value?.first} ${item?.value?.last}`);
  });

  residence?.forEach((item: any) => {
    if (item.value.city) {
      keywords_map.medium.push(item.value.city.trim().toLowerCase());
    }

    if (item.value.country) {
      keywords_map.medium.push(
        normalizeCountryCodes(
          item.value.country.trim().toLowerCase(),
        ).toLowerCase(),
      );
    }

    if (item.value.street) {
      keywords_map.high.push(item.value.street.trim().toLowerCase());
    }
  });

  nationality?.forEach((item: any) => {
    keywords_map.medium.push(
      normalizeCountryCodes(item.value.trim()).toLowerCase(),
    );
  });

  birthPlace?.forEach((item: any) => {
    keywords_map.medium.push(
      normalizeCountryCodes(item.value.trim()).toLowerCase(),
    );
  });

  phones?.forEach((item: any) => {
    keywords_map.highest.push(item.value.trim().toLowerCase());
  });

  emails?.forEach((item: any) => {
    keywords_map.highest.push(item.value.trim().toLowerCase());
  });

  websites?.forEach((item: any) => {
    keywords_map.highest.push(item.value.trim().toLowerCase());
  });

  usernames?.forEach((item: any) => {
    keywords_map.highest.push(item.value.name.trim().toLowerCase());
  });

  relatedPersons?.forEach((item: any) => {
    keywords_map.highest.push(item.value.trim().toLowerCase());
  });

  locations?.forEach((item: any) => {
    keywords_map.medium.push(item.value.trim().toLowerCase());
  });

  topics?.forEach((item: any) => {
    keywords_map.low.push(item.value.trim().toLowerCase());
  });

  nicknames?.forEach((item: any) => {
    keywords_map.medium.push(item.value.trim().toLowerCase());
  });

  organizations?.forEach((item: any) => {
    keywords_map.highest.push(
      normalize_caseless(
        removeLegalSuffix(item.value.trim().toLowerCase()).trim(),
      ) ?? '',
    );
  });

  occupations?.forEach((item: any) => {
    keywords_map.medium.push(item.value.trim().toLowerCase());
  });

  for (const company of companies) {
    keywords_map.highest.push(
      normalize_caseless(
        removeLegalSuffix(company.name.value.trim().toLowerCase()).trim(),
      ) ?? '',
    );

    company?.roles?.forEach((item: any) => {
      keywords_map.medium.push(item.value.trim().toLowerCase());
    });

    company?.address?.forEach((item: any) => {
      keywords_map.medium.push(item.value.city.trim().toLowerCase());
    });

    company?.managers?.forEach((item: any) => {
      keywords_map.medium.push(item.value.name.trim().toLowerCase());
    });

    company?.shareholders?.forEach((item: any) => {
      keywords_map.medium.push(item.value.name.trim().toLowerCase());
    });

    company?.country?.forEach((item: any) => {
      keywords_map.medium.push(
        normalizeCountryCodes(item.value.trim().toLowerCase()).toLowerCase(),
      );
    });
  }

  keywords_map.highest = Array.from(new Set(keywords_map.highest));
  keywords_map.high = Array.from(new Set(keywords_map.high));
  keywords_map.medium = Array.from(new Set(keywords_map.medium));
  keywords_map.low = Array.from(new Set(keywords_map.low));

  return keywords_map;
};

// TODO: this is temporary; we only computing the confidence score here,
// because we don't have one source of truth for data
export const computeConfidence = (
  positiveKeywords: KeywordsMap,
  negativeKeywords: KeywordsMap,
  rawUserInput: any | undefined,
  content: string,
): number => {
  const positive = [];
  const negative = [];

  if (!rawUserInput) {
    return 0;
  }

  const scoreMap = {
    highest: 40,
    high: 20,
    medium: 10,
    low: 5,
  };

  const userInputFields = [
    'nicknames',
    'occupations',
    'related_persons',
    'websites',
    'organizations',
    'topics',
    'locations',
  ];

  let score = 0;
  let totalScore = 1;

  for (const [field, fieldValue] of Object.entries(scoreMap)) {
    const positiveFieldKeywords = positiveKeywords[field as keyof KeywordsMap];
    const negativeFieldKeywords = negativeKeywords[field as keyof KeywordsMap];

    if (positiveFieldKeywords) {
      for (const value of positiveFieldKeywords) {
        if (content.toLowerCase().includes(value.toLowerCase())) {
          score += fieldValue;
          totalScore += fieldValue;
          positive.push(value);
        }
      }
    }

    if (negativeFieldKeywords) {
      for (const value of negativeFieldKeywords) {
        if (content.toLowerCase().includes(value.toLowerCase())) {
          score -= fieldValue;
          totalScore += fieldValue;
          negative.push(value);
        }
      }
    }
  }

  // if fewer positive keywords occurrences got cancelled out by
  // the negative keywords occurrences, we give a higher score
  score += (score / totalScore) * 10;
  for (const field of userInputFields) {
    const userKeywords = rawUserInput[field as keyof ProcessTarget];
    if (userKeywords) {
      for (const keyword of userKeywords) {
        if (
          keyword.status === 'confirmed' &&
          content.toLowerCase().includes(keyword.value.toLowerCase())
        ) {
          positive.push(keyword.value);
          score += 20;
        }

        if (
          keyword.status === 'ignored' &&
          content.toLowerCase().includes(keyword.value.toLowerCase())
        ) {
          negative.push(keyword.value);
          score -= 20;
        }
      }
    }
  }

  return Math.round(score);
};
