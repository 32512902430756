import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { KeywordTranslationSuggestions } from '_types';
import config from '../../backendConfig.json';

// Keywords translation suggestions fetch
const fetchTranslationSuggestions = async (
  keywords: Array<string>,
): Promise<KeywordTranslationSuggestions> =>
  (
    await axios.post(
      `https://${config.backendBaseUrl}/analysis/suggest-translations`,
      {
        keywords,
      },
    )
  ).data;

export const useKeywordTranslationQuery = (
  keyword: string,
): UseQueryResult<KeywordTranslationSuggestions> =>
  useQuery(['keyword', keyword], () => fetchTranslationSuggestions([keyword]), {
    enabled: !!keyword.length,
  });
