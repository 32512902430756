import { FC, Fragment, useState } from 'react';
import { MdOutlineDescription, MdOutlineDownload } from 'react-icons/md';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import classnames from 'classnames';
import { Headline, Paragraph, Tooltip } from '_atoms';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Target } from '../../services/dataService';
import { nonProdDataTestId } from '_utils';
import { ReactComponent as ExclamationIcon } from '_assets/icons/exclamation.svg';

export type NavigationItem = {
  name: string;
  href?: string;
  count?: number;
  children?: NavigationItem[];
  open?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
  dataTestId?: string;
};

export type SubNavigationBarProps = {
  caseData: { title: string; caseId: string };
  title: string;
  navigationItems: NavigationItem[];
  downloadItems: NavigationItem[];
  targetData?: Target | null;
};

const hasHref = (item: NavigationItem) =>
  item.href && !['', '#'].includes(item.href);

interface InactiveLinkProps {
  name: string;
  tooltip?: string;
}

const InactiveLink: FC<InactiveLinkProps> = ({
  name,
  tooltip,
}: InactiveLinkProps) =>
  tooltip ? (
    <Tooltip content={tooltip}>
      <span className="flex-1 px-2 py-2 group flex items-center text-gray-500 text-sm font-medium rounded-md cursor-auto">
        {name}
        <ExclamationIcon className="w-4 h-4 ml-2" />
      </span>
    </Tooltip>
  ) : (
    <span className="flex-1 px-2 py-2 group flex items-center text-gray-500 text-sm font-medium rounded-md cursor-auto">
      {name}
    </span>
  );

export const SubNavigationBar: FC<SubNavigationBarProps> = ({
  caseData,
  title,
  navigationItems,
  downloadItems,
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  const isLocationAmongChildren = (children: Array<any>): boolean =>
    children.some((child) => child.href === location.pathname);

  const [drawersState, setDrawersState] = useState<Record<string, boolean>>(
    navigationItems
      .filter((item): item is NavigationItem => !!item.children)
      .reduce(
        (acc, item) => ({
          ...acc,
          [item.name]:
            isLocationAmongChildren(item.children || []) ||
            location.pathname === item.href,
        }),
        {},
      ),
  );

  return (
    <div className="fixed h-screen w-64 text-white bg-blue-800">
      <div className="flex flex-col flex-grow border-r border-gray-200 pt-2.5 h-full">
        <div className="flex items-center flex-shrink-0 h-12 px-5 space-x-3">
          <Link to={`/cases/${caseData.caseId}`}>
            <Paragraph size="medium" color="blue" weight="medium">
              {caseData.title}
            </Paragraph>
          </Link>
        </div>
        <div className="flex items-center flex-shrink-0 h-12 px-5 space-x-3">
          <MdOutlineDescription className="text-blue-400 fill-current" />
          <Headline Level="h4" color="blue">
            {title}
          </Headline>
        </div>
        <div className="flex flex-col flex-grow mt-3 h-full overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-blue-600 scrollbar-track-blue-800">
          <nav
            className="px-5 space-y-1 bg-blue-800 flex-2"
            aria-label="Sidebar"
            data-testid={nonProdDataTestId('analysis menu')}
          >
            {navigationItems.map((item, index) =>
              item.children ? (
                <Fragment key={index}>
                  <div>
                    {item.href ? (
                      <Link
                        to={item.href || ''}
                        className={classnames(
                          location.pathname === item.href
                            ? 'bg-blue-900'
                            : 'hover:bg-blue-900',
                          'group flex items-center justify-between px-2 py-2 text-sm font-medium rounded-md',
                        )}
                      >
                        <span>{item.name}</span>
                        <span
                          onClick={(event) => {
                            event.preventDefault();

                            setDrawersState((ds) => ({
                              ...ds,
                              [item.name]: !ds[item.name],
                            }));
                          }}
                        >
                          {drawersState[item.name] ? (
                            <AiOutlineMinus />
                          ) : (
                            <AiOutlinePlus />
                          )}
                        </span>
                      </Link>
                    ) : item.disabled ? (
                      <InactiveLink
                        name={t(item.name)}
                        tooltip={item.disabledTooltip}
                      />
                    ) : (
                      <div
                        className="group flex items-center justify-between px-2 py-2 text-sm font-medium rounded-md hover:bg-blue-900 cursor-pointer"
                        onClick={() =>
                          setDrawersState((ds) => ({
                            ...ds,
                            [item.name]: !ds[item.name],
                          }))
                        }
                        data-testid={nonProdDataTestId(item.dataTestId)}
                      >
                        <span>{item.name}</span>
                        <span>
                          {drawersState[item.name] ? (
                            <AiOutlineMinus />
                          ) : (
                            <AiOutlinePlus />
                          )}
                        </span>
                      </div>
                    )}
                    <ul
                      className={classnames(
                        'list-disc px-2 space-y-1 text-sm ml-8',
                        drawersState[item.name] ? '' : 'hidden',
                      )}
                    >
                      {item.children.map((subItem, index) => (
                        <li key={index}>
                          {hasHref(subItem) && !subItem.disabled ? (
                            <Link
                              to={subItem.href || ''}
                              className={classnames(
                                location.pathname === subItem.href
                                  ? 'bg-blue-900'
                                  : 'hover:bg-blue-900',
                                'group flex items-center justify-between px-2 py-2 text-sm font-medium rounded-md',
                              )}
                              data-testid={nonProdDataTestId(
                                subItem.dataTestId,
                              )}
                            >
                              <span className="flex-1">{t(subItem.name)}</span>
                              {subItem.count ? (
                                <span className="bg-yellow-500 text-yellow-800 ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full">
                                  {subItem.count}
                                </span>
                              ) : null}
                            </Link>
                          ) : (
                            <InactiveLink name={t(subItem.name)} />
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Fragment>
              ) : hasHref(item) ? (
                <Link
                  key={item.name}
                  to={item.href || ''}
                  className={classnames(
                    location.pathname === item.href
                      ? 'bg-blue-900'
                      : 'hover:bg-blue-900',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                  )}
                  data-testid={nonProdDataTestId(item.dataTestId)}
                >
                  <span className="flex-1">{t(item.name)}</span>
                  {item.count ? (
                    <span className="bg-red-500 text-red-800 ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full">
                      {item.count}
                    </span>
                  ) : null}
                </Link>
              ) : (
                <InactiveLink
                  name={t(item.name)}
                  data-testid={nonProdDataTestId(item.dataTestId)}
                />
              ),
            )}
          </nav>
          {downloadItems?.some((item) => item.href !== '#') ? (
            <div className="px-5 mt-8">
              <hr className="border-blue-600" />
              <Paragraph
                size="label"
                color="blue"
                weight="semibold"
                className="py-3"
              >
                {t('downloads')}
              </Paragraph>
              <div className="flex flex-col gap-3">
                {downloadItems.map((downloadItem) => (
                  <a
                    key={downloadItem.name}
                    href={downloadItem.href}
                    className="text-xs font-normal"
                    onClick={(event) =>
                      !hasHref(downloadItem) && event.preventDefault()
                    }
                  >
                    <span className="py-2 text-blue-400 mr-3">
                      <MdOutlineDownload />
                    </span>
                    <span
                      className={classnames(
                        !hasHref(downloadItem) ? 'text-gray-400' : '',
                      )}
                    >
                      {downloadItem.name}
                    </span>
                  </a>
                ))}
              </div>
            </div>
          ) : null}
        </div>
        <div className="mt-auto px-5 py-2.5">
          <Paragraph size="label" color="blue" weight="semibold">
            {t('indicium')}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};
