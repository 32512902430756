import { FC, MutableRefObject, Fragment } from 'react';
import { Popover } from '@headlessui/react';
import classNames from 'classnames';

export type ActionButtonInjectedChildrenProps = {
  open: boolean;
  close: (
    focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>,
  ) => void;
};

export type ActionButtonProps = {
  icon: JSX.Element;
  children:
    | JSX.Element
    | ((props: ActionButtonInjectedChildrenProps) => JSX.Element);
  label?: string;
};

export const ActionButton: FC<ActionButtonProps> = ({
  icon,
  label,
  children,
}) => {
  return (
    <Popover className="relative">
      {({ open, close }) => (
        <Fragment>
          <Popover.Button className="focus:outline-none flex flex-col items-center">
            {icon}
            {label && <span>{label}</span>}
          </Popover.Button>

          <Popover.Panel
            className={classNames(
              'absolute w-80 bg-white shadow-md p-5 left-0',
              open ? 'z-20' : 'z-10',
            )}
            style={{ marginLeft: '-300px' }}
          >
            {open ? (
              <div tabIndex={0} className="focus:outline-none">
                {typeof children === 'function'
                  ? children({ open, close })
                  : children}
              </div>
            ) : null}
          </Popover.Panel>
        </Fragment>
      )}
    </Popover>
  );
};
