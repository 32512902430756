import { FC, useCallback } from 'react';
import { generatePath, Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Headline, LoadingSpinner } from '_atoms';
import {
  useCreateTargetMutation,
  useTargetInputQuery,
} from '../../../hooks/queries';
import { sanitizeInput } from '@indicium/common/src/utils';
import { useMutationErrors } from '../../../hooks/useMutationErrors';
import { routes } from '../../../routes';
import { TargetForm } from '../../../features/targets/TargetForm/TargetForm';
import { UserInputTarget } from '@indicium/common';
import { Alert } from '_molecules';
import { TargetFormSchema } from '../../../schemas/targetFormSchema';

export const InputData: FC = () => {
  const { t } = useTranslation();

  const { caseId, targetId } =
    useParams<{ caseId: string; targetId: string }>();

  const { data: targetInput, isLoading: isTargetInputLoading } =
    useTargetInputQuery(caseId, targetId);

  const { data: target, ...createTargetMutation } = useCreateTargetMutation();

  const { errors: apiErrors } = useMutationErrors({
    mutations: { createTargetMutation },
  });

  const handleSubmit = useCallback(
    // TODO: IND-945 Fix type issues and set type of values to Yup.TypeOf<typeof UserInputTargetSchema>
    async (values: unknown) => {
      // TODO: IND-945 Remove the cast. Related to "Make this a Yup.SchemaOf<UserInputTargetSchema>" todo in UserInputTargetSchema.ts
      const data = sanitizeInput<UserInputTarget>(
        (await TargetFormSchema.validate(values)) as UserInputTarget,
      );

      if (data) {
        createTargetMutation.mutate({ caseId, data });
      }
    },
    [createTargetMutation, caseId],
  );

  if (createTargetMutation.isSuccess) {
    return (
      <Redirect
        to={generatePath(routes.targetShow.path, {
          caseId,
          targetId: target?.id,
        })}
      />
    );
  }

  if (createTargetMutation.isError) {
    console.error('error in createTarget: ', createTargetMutation.error);
  }

  return (
    <div className="container-fluid mb-20 px-8">
      <div className="row">
        <div className="p-4">
          <Alert alertType="disclamer" message={t('inputDataDisclamer')} />
        </div>
        <Headline Level="h1" className="my-6" color="dark">
          {t('targetPersonDataHeadline')}
        </Headline>
      </div>
      <TargetForm
        targetInput={targetInput}
        onSubmit={handleSubmit}
        isSubmitting={createTargetMutation.isLoading}
        apiErrors={apiErrors}
      />
      {isTargetInputLoading && (
        <div className="z-20 inset-0 absolute bg-black bg-opacity-50 flex justify-center items-center text-gray-400 pointer-events-none">
          <LoadingSpinner message={<span className="invisible">...</span>} />
        </div>
      )}
    </div>
  );
};
