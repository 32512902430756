import { FC } from 'react';
import { generatePath, Link, Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TargetStatus } from '../../../services/dataService';
import { routes } from '../../../routes';
import { Notification } from '_molecules';
import { PageNotFound } from '_organisms';
import { useCaseQuery, useTargetQuery } from '_queries';
import { CaseToProfileBreadcrumbs } from '../../../components/Layout/CaseToProfileBreadcrumbs';
import { Button, LoadingSpinner, Paragraph } from '_atoms';
import { nonProdDataTestId } from '_utils';

export const TargetShow: FC = () => {
  const { caseId, targetId } =
    useParams<{ caseId: string; targetId: string }>();

  const { t } = useTranslation();

  const { data: caseData, ...caseQuery } = useCaseQuery(caseId);

  const { data: targetData, ...targetQuery } = useTargetQuery(caseId, targetId);

  // TODO: pushing to the history here is a quickfix for simplifying the "open"
  //       button logic in the case overview. In the long run we probably would
  //       want TargetShow be the universal entrypoint to the target regardless
  //       of what state it is in. This component should be adapted to render
  //       the appropriate component based on target state instead of rerouting
  //       to other root level components doing that job.
  switch (targetData?.status) {
    case TargetStatus.Created:
    case TargetStatus.FetchingCandidates:
    case TargetStatus.CandidateSelectionCompleted:
    case TargetStatus.FetchingDetails:
      // Do nothing. Show waiting screen and retry on next refresh
      break;
    case TargetStatus.CandidateSelectionPending:
      return (
        <Redirect
          to={generatePath(routes.targetCandidates.path, { caseId, targetId })}
        />
      );
    // !!!: Backend currently transitions directly to completed once result
    //      overview profile is available. This will need some adaption to
    //      separate a "completed" from the "we have at least some data to
    //      display" state at which point the latter also needs to be handled
    //      here.
    case TargetStatus.HasInitialProfile:
    case TargetStatus.Completed:
      return (
        <Redirect
          to={generatePath(routes.dashboardOverview.path, { caseId, targetId })}
        />
      );
  }

  if (caseQuery.isError || targetQuery.isError) {
    return <PageNotFound />;
  }

  const waitingForCandidateGeneration =
    targetQuery.isSuccess &&
    targetData?.status &&
    [TargetStatus.Created, TargetStatus.FetchingCandidates].includes(
      targetData.status,
    );

  const waitingForReportGeneration =
    targetQuery.isSuccess &&
    targetData?.status &&
    [
      TargetStatus.CandidateSelectionCompleted,
      TargetStatus.FetchingDetails,
    ].includes(targetData.status);

  // if it's neither of the above conditions, we just see a blank screen for now

  return (
    <div className="container-fluid px-16 h-full -mt-4 pt-4 flex flex-col">
      <CaseToProfileBreadcrumbs level={2} caseName={caseData?.title} />
      {targetQuery.isLoading && (
        <LoadingSpinner message={t('profileLoading')} />
      )}
      {waitingForCandidateGeneration && (
        <figure
          className="absolute inset-0 -top-10 flex flex-col items-center justify-center pointer-events-none"
          data-testid={nonProdDataTestId('loading icon')}
        >
          <img
            className="h-full w-full"
            src={`${process.env.PUBLIC_URL}/loading-animation.gif`}
            style={{ maxHeight: 300, maxWidth: 300 }}
            alt="loading animation"
          />
          <figcaption className="text-center w-1/2">
            <Paragraph
              color="blue"
              size="medium"
              weight="bold"
              className="whitespace-pre-line"
            >
              {t('loading.searchingCandidates')}
            </Paragraph>
          </figcaption>
        </figure>
      )}
      {waitingForReportGeneration && (
        <div className="flex flex-col items-center justify-center flex-grow text-center">
          <Paragraph className="whitespace-pre-line leading-relaxed">
            {t('loading.collectingData')}
          </Paragraph>
          <div className="flex items-center justify-center mb-2 mt-5">
            <Link to={generatePath(routes.newTargetData.path, { caseId })}>
              <Button className="mx-2">{t('createNewTarget')}</Button>
            </Link>
            <Link to={routes.caseNew.path}>
              <Button className="mx-2">{t('createNewCase')}</Button>
            </Link>
          </div>
          <Link to="/">
            <Button level="secondary" className="my-2">
              {t('backToOverview')}
            </Button>
          </Link>
        </div>
      )}
      {targetData?.workflowErrors?.length ? (
        <Notification
          headline={t('workflowErrors.headline')}
          level="error"
          message={targetData.workflowErrors
            .map(({ type }) => t(`workflowErrors.${type}`))
            .join(', ')}
          link={{
            to: generatePath(routes.caseShow.path, { caseId }),
            text: t('back'),
          }}
        />
      ) : null}
    </div>
  );
};
