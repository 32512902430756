import { useCallback } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { AllDataArticle } from 'src/services/searchService';
import { ActionButton } from '_atoms';
import { AddToReport, UpdateItemStatus } from '_organisms';
import { SuspicionItemEntity } from '_types';
import { SuspicionItemStatus } from '_enums';
import { useAddSuspicionMutation } from './queries/useAddSuspicionMutation';
import { SuspicionStatusIcon } from '../features/dashboard/Overview/SuspicionStatusIcon';
import { useUpdateSuspicionItemMutation } from './queries/useUpdateSuspicionItemMutation';

type ReportTailoringActionButton = 'AddToReport' | 'UpdateItemStatus';

type ReportTailoringActions = Record<
  ReportTailoringActionButton,
  (...args: any[]) => JSX.Element
>;

type ReportTailoringProps = {
  targetId: string;
};

type ReportTailoringExport = {
  ReportTailoringActions: ReportTailoringActions;
};

export const useReportTailoring = ({
  targetId,
}: ReportTailoringProps): ReportTailoringExport => {
  const addSuspicionMutation = useAddSuspicionMutation();
  const updateSuspicionItemMutation = useUpdateSuspicionItemMutation();

  const handleAddToReport = useCallback(
    async (
      reportId: string,
      suspicionIds: string[],
      entity: SuspicionItemEntity,
    ): Promise<unknown> => {
      return addSuspicionMutation.mutateAsync({
        targetId,
        suspicionIds,
        reportId,
        entity,
      });
    },
    [targetId, addSuspicionMutation],
  );

  const handleStatusUpdate = useCallback(
    async (itemId: string, status: SuspicionItemStatus): Promise<unknown> => {
      return updateSuspicionItemMutation.mutate({
        itemId,
        status,
      });
    },
    [updateSuspicionItemMutation],
  );

  const getAddToReportActionButton = useCallback(
    (entity: AllDataArticle): JSX.Element => (
      <ActionButton
        icon={
          <div className="bg-white rounded-lg px-2 py-0">
            <HiDotsHorizontal />
          </div>
        }
      >
        <AddToReport
          entityId={entity.id}
          entity={entity}
          onAddToReport={handleAddToReport}
        />
      </ActionButton>
    ),
    [handleAddToReport],
  );

  const getUpdateItemStatusActionButton = useCallback(
    (itemId: string, status: SuspicionItemStatus): JSX.Element => (
      <ActionButton icon={<SuspicionStatusIcon status={status} />}>
        <UpdateItemStatus
          itemId={itemId}
          status={status}
          onStatusUpdate={handleStatusUpdate}
        />
      </ActionButton>
    ),
    [handleStatusUpdate],
  );

  const ReportTailoringActions: ReportTailoringActions = {
    AddToReport: getAddToReportActionButton,
    UpdateItemStatus: getUpdateItemStatusActionButton,
  };

  return {
    ReportTailoringActions,
  };
};
