import { Dispatch, useReducer } from 'react';

export enum SearchActionTypes {
  SetQuery = 'SET_QUERY',
  SetPage = 'SET_PAGE',
  SetFilter = 'SET_FILTER',
  RemoveFilter = 'REMOVE_FILTER',
}

export type SearchState<T> = {
  query?: string;
  page?: number;
  filters?: T;
  exactMatch?: number;
};

export type SearchAction<T> = {
  type: SearchActionTypes;
  query?: string;
  page?: number;
  filters?: T;
  exactMatch?: number;
};

const initialState = {
  query: '',
  page: 1,
  filters: undefined,
  exactMatch: 0,
};

const createSearchReducer =
  <T extends unknown>() =>
  (state: SearchState<T>, action: SearchAction<T>): SearchState<T> => {
    switch (action.type) {
      case SearchActionTypes.SetQuery:
        return {
          ...state,
          query: action.query || '',
          exactMatch: action.exactMatch || 0,
          page: 1,
        };
      case SearchActionTypes.SetPage:
        return { ...state, page: action.page || 1 };
      case SearchActionTypes.SetFilter:
        return { ...state, filters: action.filters, page: 1 };
      default:
        return state;
    }
  };

export const useSearch = <T extends unknown>(): [
  SearchState<T>,
  Dispatch<SearchAction<T>>,
] => {
  return useReducer(createSearchReducer<T>(), initialState);
};
