import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { SocialMediaCard } from '_organisms';
import { useParams } from 'react-router-dom';
import { Error, LoadingSpinner } from '_atoms';
import { Alert, InfiniteScroller, Pagination, SearchInput } from '_molecules';
import { ResultsProps } from '../../../features/dashboard/Results';
import type { SocialAccountPostArticle } from '../../../services/searchService';
import { SearchFilterValue } from '_types';
import { useElasticSearch } from '../../../hooks/useElasticSearch';

import socialMediaPostsFilters from './SocialMediaPostsFilters.json';
import { nonProdDataTestId } from '_utils';

const initialValues = {
  items: [],
};

const pagingEnabled = false;

type SocialMediaPostsFilters = {
  publishedAt: SearchFilterValue;
  sentiment: SearchFilterValue;
  language: SearchFilterValue;
};

type SocialMediaPostsProps = Pick<
  ResultsProps,
  'accessToken' | 'targetData' | 'caseData'
>;

export const SocialMediaPosts: FC<SocialMediaPostsProps> = ({
  accessToken,
  targetData,
  caseData,
}) => {
  const { t } = useTranslation();

  const { caseId, targetId } =
    useParams<{ caseId: string; targetId: string }>();

  const {
    searchInputProps: { renderedFilters, ...searchInputProps },
    data: { items, paging } = initialValues,
    isLoading,
    isError,
    handlePageChange,
  } = useElasticSearch<SocialMediaPostsFilters, SocialAccountPostArticle[]>({
    caseId,
    targetId,
    entityType: 'socialMediaPost',
    searchFilters: socialMediaPostsFilters,
    pagingEnabled: pagingEnabled,
  });

  return (
    <Fragment>
      <SearchInput {...searchInputProps}>{renderedFilters}</SearchInput>

      <div
        className="relative mt-5 z-10"
        data-testid={nonProdDataTestId('socialmediaposts')}
      >
        {isLoading ? (
          <div className="absolute top-0 flex justify-center w-full z-0">
            <LoadingSpinner message={t('profileLoading')} />
          </div>
        ) : isError ? (
          <Error
            headline={t('profileErrorHeadline')}
            message={t('profileErrorRetryMessage')}
          />
        ) : null}
        {items?.length ? (
          <Fragment>
            {items.map((item: SocialAccountPostArticle) => (
              <SocialMediaCard
                key={item.id}
                data={item}
                query={searchInputProps.value}
                accessToken={accessToken}
                hideArticleType={true}
                caseData={caseData}
                targetData={targetData}
              />
            ))}
            {paging?.pagesCount > 1 &&
              (pagingEnabled ? (
                <div className="px-5">
                  <Pagination
                    paging={paging}
                    setPageNumber={(page) => handlePageChange(page)}
                  />
                </div>
              ) : (
                <InfiniteScroller
                  paging={paging}
                  isLoading={isLoading}
                  setPageNumber={(page) => handlePageChange(page)}
                />
              ))}
          </Fragment>
        ) : isLoading ? null : (
          <Alert alertType="info" message={t('noData')} />
        )}
      </div>
    </Fragment>
  );
};
