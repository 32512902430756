import { useQuery, UseQueryResult } from 'react-query';
// import { Suspicion } from '_types';
import { suspicions } from '../../services/nestApiService';
import { ReportFilters } from '../../features/dashboard/Overview/ReportCard';

export const useSuspicionsReportSuspicionsQuery = (
  targetId: string,
  reportId: string,
  filters?: ReportFilters,
): UseQueryResult<any[]> =>
  useQuery(
    ['target', targetId, 'report', reportId, 'filters', filters],
    () => suspicions.listFiltered(targetId, reportId, filters),
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
  );
