import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { forgotPassword } from '../../services/authenticationService';
import { AuthExceptions, ForgotExceptions } from './types';

interface PasswordResetCodeProps {
  onError: (code: ForgotExceptions) => void;
  onSuccess: (resetCodeEmail: string) => void;
}

export const PasswordResetCode = ({
  onError,
  onSuccess,
}: PasswordResetCodeProps): React.ReactElement => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string | null>(null);
  const emailRef = useRef<HTMLInputElement | null>(null);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    try {
      if (email) {
        await forgotPassword(email);
        onSuccess(email);
      }
    } catch (errorCode) {
      if (errorCode === AuthExceptions.NotAuthorizedException) {
        onError(AuthExceptions.PasswordChallengeException);
      } else {
        onError(errorCode as ForgotExceptions);
      }
    }
  };

  useEffect(() => {
    emailRef.current?.focus();
  }, []);

  return (
    <form className="mt-8 space-y-6" onSubmit={onSubmit}>
      {/* TODO: Check classes for commonalities and extract them into a variable -> see IND-252 */}
      <div className="rounded-md shadow-sm space-y-6">
        <div>
          <div>
            <label htmlFor="email-address" className="sr-only">
              {t('email')}
            </label>
            <input
              name="email"
              type="email"
              autoComplete="email"
              required
              className="appearance-nonerelative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-400 focus:border-blue-400 focus:z-10 sm:text-sm"
              placeholder={t('email')}
              onChange={(e) => setEmail(e.target.value)}
              ref={emailRef}
            />
          </div>
        </div>
      </div>

      <div>
        <button
          type="submit"
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
        >
          {t('passwordReset.verifyAccount')}
        </button>
      </div>
    </form>
  );
};
