import { nonProdDataTestId } from '_utils';
import { useState, FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAddCircleOutline } from 'react-icons/md';
import { UserTable } from '_organisms/UserTable';
import { UserData } from 'src/types/UserData';
import { routes } from '../../../routes';
import {
  Button,
  Checkbox,
  Error,
  Headline,
  Input,
  LoadingSpinner,
  Modal,
  Select,
} from '_atoms';
import { Alert, ConfirmationModal } from '_molecules';
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useCustomerUsageQuery,
} from '_queries';
import { useDeleteMutation } from '../../../hooks/useDeleteMutation';
import { useUserState } from '../../../context/User';
import {
  USER_GROUP_ADMINISTRATORS,
  USER_GROUP_SUPER_USERS,
} from '../../../services/authenticationService';
import { useUpdateUserMutation } from '../../../hooks/queries/useUpdateUserMutation';

export const UserAdmin: FC<{ customerIdOverride: string }> = ({
  customerIdOverride,
}) => {
  const { t } = useTranslation();

  const [{ userRoles, customerId }] = useUserState();

  const editingCustomerUsers = !!customerIdOverride;

  const {
    data: { users, total } = {
      users: [],
      total: { totalCases: 0, activeCreatedTargets: 0, totalTargets: 0 },
    },
    ...usersQuery
  } = useCustomerUsageQuery(customerIdOverride || customerId);

  const createUserMutation = useCreateUserMutation();
  const updateUserMutation = useUpdateUserMutation();
  const deleteUserMutation = useDeleteUserMutation();

  const [{ enableConfirm, showModal }, dispatch] = useDeleteMutation();

  const [showNewEntry, setShowNewEntry] = useState(false);

  const [newUser, setNewUser] = useState<UserData>({ email: '' });

  if (usersQuery.isError) {
    console.error('No results found: ', usersQuery.error);
  }

  const handleModalEvent = (event: 'confirm' | 'cancel') => {
    switch (event) {
      case 'confirm':
        dispatch({
          type: 'CONFIRM_DELETION',
          mutation: deleteUserMutation.mutate,
        });
        break;
      case 'cancel':
        dispatch({ type: 'RESET' });
        break;
    }
  };

  const handleUpdateUser = (userId: string) => {
    const userToBeChanged = users?.find((user) => user.id === userId);
    updateUserMutation.mutate(
      {
        userId,
        customerIdOverride,
        data: {
          isAdmin: userToBeChanged?.privilegeLevel !== 'CustomerAdmin',
        },
      },
      {
        onSuccess: () => {
          if (userToBeChanged) {
            userToBeChanged.isAdmin = !userToBeChanged.isAdmin;
          }
        },
      },
    );
  };

  const tableRowActions =
    userRoles.includes(USER_GROUP_ADMINISTRATORS) ||
    userRoles.includes(USER_GROUP_SUPER_USERS)
      ? [
          {
            id: 'editUser',
            label: (userId: string) => {
              const userToBeChanged = users?.find((user) => user.id === userId);
              if (userToBeChanged?.privilegeLevel === 'CustomerUser') {
                return t('addAdminPermissionsAction');
              }
              return t('removeAdminPermissionsAction');
            },
            callback: (userId: string) => handleUpdateUser(userId),
          },
          {
            id: 'deleteUser',
            label: t('deleteUser.action'),
            callback: (userId: string) => {
              dispatch({ type: 'SET_DELETE_PARAMS', params: { userId } });
            },
          },
        ]
      : undefined;

  const handleAddNewUser = () => {
    createUserMutation.mutate(
      {
        customerIdOverride,
        data: newUser,
      },
      {
        onSuccess: () => setShowNewEntry(false),
      },
    );
  };

  const ErrorMessage = {
    'Error: Request failed with status code 409': t('emailAlreadyExists'),
  } as const;

  if (usersQuery.isError) {
    return (
      <Error
        headline={usersQuery.error?.error?.message || t('noPageFound')}
        message={t('goBack')}
        target={{
          link: routes.casesList.path,
          text: t('back'),
        }}
      />
    );
  }

  const genderOptions = t('genders', {
    returnObjects: true,
  });

  return (
    <div className="container-fluid px-16 mb-20">
      {!editingCustomerUsers && (
        <div className="row">
          <Headline Level="h1" color="dark" className="my-8" weight="bold">
            {t('overviewOfAllUsers')}
          </Headline>
        </div>
      )}

      <div className="row">
        <div className="mb-8">
          <Button onClick={() => setShowNewEntry(true)} className="pl-4">
            <span className="pr-3.5">
              <MdAddCircleOutline />
            </span>
            {t('createNewUser')}
          </Button>
        </div>
      </div>

      <Fragment>
        {createUserMutation.isError && (
          <Alert
            alertType="error"
            headline={
              ErrorMessage[
                createUserMutation.error as keyof typeof ErrorMessage
              ] ?? t('invalidServerResponse')
            }
            className="mb-2"
          />
        )}

        <div className="relative">
          <Modal
            isOpen={showNewEntry}
            title={t('createNewUser')}
            onClose={() => setShowNewEntry(false)}
            data-testid={nonProdDataTestId('popup2')}
          >
            <form className="py-2.5 bg-white text-gray-900 space-y-4">
              <div className="grid grid-cols-1 gap-2">
                <label className="block">
                  <span className="text-gray-700">{t('gender')}:</span>
                  <Select
                    className="w-full mt-1 mb-2"
                    initialSelection={{ id: 1, label: '-', value: undefined }}
                    options={Object.values(genderOptions).map(
                      (title, index) => ({
                        id: index,
                        label: title,
                        value: title,
                      }),
                    )}
                    onChange={(newGender: string) => {
                      if (newGender) {
                        setNewUser({
                          ...newUser,
                          gender: newGender,
                        });
                      }
                    }}
                  />
                </label>

                <label className="block">
                  <span className="text-gray-700">{t('firstname')}:</span>
                  <Input
                    className="mt-1 mb-2"
                    onChange={(value) => {
                      setNewUser((oldUser) => ({
                        ...oldUser,
                        firstname: value.target.value,
                      }));
                    }}
                    value={newUser.firstname ?? ''}
                    placeholder={t('firstname')}
                    required={true}
                  />
                </label>

                <label className="block">
                  <span className="text-gray-700">{t('lastname')}:</span>
                  <Input
                    className="mt-1 mb-2"
                    onChange={(value) => {
                      setNewUser((oldUser) => ({
                        ...oldUser,
                        lastname: value.target.value,
                      }));
                    }}
                    value={newUser.lastname ?? ''}
                    placeholder={t('lastname')}
                    required={true}
                  />
                </label>

                <label className="block">
                  <span className="text-gray-700">{t('email')}:</span>
                  <Input
                    className="mt-1 mb-2"
                    onChange={(value) => {
                      setNewUser((oldUser) => ({
                        ...oldUser,
                        email: value.target.value,
                      }));
                    }}
                    value={newUser.email ?? ''}
                    placeholder={t('emailPlaceholder')}
                    required={true}
                  />
                </label>
                <label className="flex items-center mt-1 mb-2">
                  <span className="text-gray-700 mr-2">{t('admin')}:</span>
                  <Checkbox
                    onChange={(newIsAdmin) =>
                      setNewUser((oldUser) => ({
                        ...oldUser,
                        isAdmin: newIsAdmin,
                      }))
                    }
                    initialIsSelected={newUser.isAdmin}
                  />
                </label>
              </div>
            </form>
            <div className="flex justify-between space-x-4 mt-6">
              <Button
                level="secondary"
                type="button"
                onClick={() => setShowNewEntry(false)}
                data-testid={nonProdDataTestId('cancel user creation')}
              >
                {t('closeButton')}
              </Button>
              <button
                onClick={handleAddNewUser}
                type="submit"
                className="bg-blue-400 border border-transparent disabled:bg-gray-300 disabled:cursor-default focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 font-medium hover:bg-blue-600 inline-flex items-center justify-center leading-5 mt-1 px-5 py-2 rounded-md shadow-sm text-base text-white"
              >
                {t('addButton')}
              </button>
            </div>
          </Modal>

          <UserTable
            headlines={
              editingCustomerUsers
                ? [t('name'), t('email'), t('privilegeLevel')]
                : [
                    t('name'),
                    t('email'),
                    t('privilegeLevel'),
                    t('assignedCases'),
                    t('activeReports'),
                    t('totalReports'),
                  ]
            }
            users={users || []}
            layoutOptions={{
              highlightColumns: {
                firstColBold: true,
                secondColBold: true,
              },
              textAlign: { 3: 'center', 4: 'center', 5: 'center' },
            }}
            tableRowActions={tableRowActions}
            footer={
              editingCustomerUsers
                ? undefined
                : [
                    t('userTableFooter') as string,
                    undefined,
                    undefined,
                    total.totalCases,
                    total.activeCreatedTargets,
                    total.totalTargets,
                    undefined,
                  ]
            }
            editingCustomerUsers={editingCustomerUsers}
          />

          <ConfirmationModal
            body={t('deleteUser.modalText')}
            cancelButtonText={t('deleteUser.cancelButton')}
            confirmButtonText={t('deleteUser.confirmButton')}
            enableConfirm={enableConfirm}
            isOpen={showModal}
            radioOptions={{
              options: t('deleteUser.radioOptions', {
                returnObjects: true,
              }),
              handleSelect: () => dispatch({ type: 'ENABLE_CONFIRM_BUTTON' }),
            }}
            title={t('deleteUser.modalTitle')}
            handleButtonClick={handleModalEvent}
          />

          {usersQuery.isLoading && <LoadingSpinner />}
        </div>
      </Fragment>
    </div>
  );
};
