import { FC, ReactNode } from 'react';
import classnames from 'classnames';

type ColoredBarProps = {
  children: ReactNode;
  background: string;
  border?: string;
  className?: string;
};

export const ColoredBar: FC<ColoredBarProps> = ({
  background,
  children,
  className,
  border,
}: ColoredBarProps) => {
  return (
    <div
      className={classnames(
        className,
        `${background} flex items-center justify-between`,
        border ? ['border-l-4', border].join(' ') : '',
      )}
    >
      {children}
    </div>
  );
};
