import { FC, Fragment } from 'react';
import {
  SearchInput,
  Pagination,
  Alert,
  WorkflowError,
  InfiniteScroller,
} from '_molecules';
import { ResultsProps } from '../Results';
import { ImageItem } from './ImageItem';
import { useParams } from 'react-router-dom';
import { SearchFilterValue } from '_types';
import { LoadingSpinner, Error } from '_atoms';
import { ImageArticle } from '../../../services/searchService';
import { useTranslation } from 'react-i18next';
import { useElasticSearch } from '../../../hooks/useElasticSearch';

import imagesFilters from './filters.json';
import { nonProdDataTestId } from '_utils';

const initialValues = {
  items: [],
};

const pagingEnabled = false;

type ImagesFilters = {
  createdDate: SearchFilterValue;
  imageSources: SearchFilterValue;
};

export type ImagesProps = ResultsProps & { accessToken: string };

export const Images: FC<ImagesProps> = ({ targetData }: ImagesProps) => {
  const { t } = useTranslation();

  const { caseId, targetId } =
    useParams<{ caseId: string; targetId: string }>();

  const {
    searchInputProps: { renderedFilters, ...searchInputProps },
    data: { items, paging } = initialValues,
    isLoading,
    isError,
    handlePageChange,
  } = useElasticSearch<ImagesFilters, ImageArticle[]>({
    caseId,
    targetId,
    entityType: 'images',
    searchFilters: imagesFilters,
    pagingEnabled: pagingEnabled,
    pageSize: 36,
  });

  return (
    <div className="px-5 relative">
      <SearchInput {...searchInputProps}>{renderedFilters}</SearchInput>

      <WorkflowError errors={targetData?.workflowErrors} path="profile" />
      {targetData?.status === 'HasInitialProfile' && (
        <Alert
          alertType="warning"
          headline={t('profileDataNotFinal')}
          className="mb-2"
        />
      )}

      <div
        className="relative mt-5 z-10"
        data-testid={nonProdDataTestId('images')}
      >
        {isLoading ? (
          <div className="absolute top-0 flex justify-center w-full z-0">
            <LoadingSpinner message={t('profileLoading')} />
          </div>
        ) : isError ? (
          <Error
            headline={t('profileErrorHeadline')}
            message={t('profileErrorRetryMessage')}
          />
        ) : null}
        {items.length ? (
          <Fragment>
            <div className="grid grid-cols-2 2xl:grid-cols-6 md:grid-cols-4 gap-5 pt-5 pb-10">
              {items?.map((item: ImageArticle, index: number) => (
                <ImageItem
                  key={index}
                  {...item}
                  onTagClicked={(query) =>
                    searchInputProps.onSearchInput(query)
                  }
                  query={searchInputProps.value}
                />
              ))}
            </div>
            {paging?.pagesCount > 1 &&
              (pagingEnabled ? (
                <div className="px-5">
                  <Pagination
                    paging={paging}
                    setPageNumber={(page) => handlePageChange(page)}
                  />
                </div>
              ) : (
                <InfiniteScroller
                  paging={paging}
                  isLoading={isLoading}
                  setPageNumber={(page) => handlePageChange(page)}
                />
              ))}
          </Fragment>
        ) : isLoading ? null : (
          <Alert alertType="info" message={t('noData')} className="mb-5" />
        )}
      </div>
    </div>
  );
};
