import { BaseFilterProps } from '_types';
import { Paragraph } from '../../Paragraph/Paragraph';
import { SelectV2, SelectOption } from '../../Select/Selectv2';
import { withTranslation, WithTranslation } from 'react-i18next';
import Filter from '../Filter';

type SelectFilterState = {
  options: SelectOption[];
  value?: SelectOption;
};

type SelectFilterProps = {
  item: {
    title?: string;
    options: SelectOption[];
  };
};

class SelectFilter extends Filter<
  BaseFilterProps & SelectFilterProps & WithTranslation,
  SelectFilterState
> {
  constructor(props: any) {
    super(props);
    const options = props.item.options.map((option: SelectOption) => ({
      ...option,
      value: option.value || undefined,
    }));
    this.state = {
      options,
      value: options[0],
    };
  }

  componentDidMount() {
    const { searchState, field } = this.props;
    if (searchState.filters && searchState.filters[field]) {
      const values = searchState.filters[field].value;
      const option = this.state.options.find(
        (option: SelectOption) => option.value === values[0],
      );
      this.setState({
        options: this.state.options,
        value: option,
      });
      this.forceUpdate();
    }
  }

  handleValueChange(value?: string) {
    const { field } = this.props;
    const valueJson = this.getFilterValueJson(value ? [value] : undefined);
    this.handleFilterChange(field, valueJson);
  }

  render() {
    const { item, t } = this.props;
    const { options, value } = this.state;

    return (
      <div className="py-2">
        {item.title && (
          <Paragraph color="light" size="small" className="uppercase mb-1">
            {t(`filters.${item.title}`)}
          </Paragraph>
        )}
        <div className="flex gap-0">
          <SelectV2
            options={options}
            selected={value}
            className="w-1/4"
            onChange={(value) => this.handleValueChange(value)}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(SelectFilter);
