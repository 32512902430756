import { FC, ReactNode, LegacyRef } from 'react';
import { TargetCandidateInfoItem } from '_types';
import { Paragraph, SizeLevel } from '_atoms';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { hasFlag } from 'country-flag-icons';
import { getCode, getName } from 'country-list';
import Flags from 'country-flag-icons/react/1x1';
import { SourceInfo } from '_atoms';
import { TargetReportSourceType } from '@indicium/common';

type InfoBlockItemProps = {
  info: TargetCandidateInfoItem;
  collapsed?: boolean;
  className?: string;
  size?: SizeLevel;
  noDataComponent?: ReactNode;
  onOverflow?: () => void;
};

type InfoBlockItemValuesProps = {
  values: string[];
  sources?: TargetReportSourceType[];
  collapsed?: boolean;
  size?: SizeLevel;
  handleOverflow?: LegacyRef<HTMLSpanElement>;
};

const InfoBlockItemValues: FC<InfoBlockItemValuesProps> = ({
  values,
  sources = [],
  collapsed,
  size,
  handleOverflow,
}: InfoBlockItemValuesProps) => (
  <>
    {values.map((value, index) =>
      typeof value === 'string' ? (
        <li key={index} className="relative group">
          <Paragraph
            size={size}
            weight="normal"
            lineHeight="default"
            color="dark"
            title={value}
            className="relative group"
            HtmlTag="div"
          >
            <span
              className={classnames('block', collapsed ? 'truncate' : '')}
              ref={handleOverflow}
            >
              {value}
            </span>
            {sources.length > 0 && (
              <div className="p-1 absolute opacity-0 group-hover:opacity-100 bg-gray-100 top-5 w-full z-10 rounded-b-md">
                <SourceInfo color="dark" sources={sources} />
              </div>
            )}
          </Paragraph>
        </li>
      ) : null,
    )}
  </>
);

const NationalityInfoBlockItemValues: FC<InfoBlockItemValuesProps> = ({
  values,
  sources = [],
  size,
}: InfoBlockItemValuesProps) => (
  <>
    {values.map((value, index) => {
      const isoCode = hasFlag(value) ? value : getCode(value);
      const Flag = isoCode && (Flags as any)[isoCode];
      const country = isoCode ? getName(isoCode) : value;

      return (
        <li key={index} className="relative group">
          <Paragraph
            size={size}
            weight="normal"
            lineHeight="default"
            color="dark"
            title={value}
            className="flex relative group"
          >
            <span className="w-6">
              {Flag && <Flag className="w-5 h-5 rounded-full" />}
            </span>
            {country}
            {sources.length > 0 && (
              <div className="p-1 absolute opacity-0 group-hover:opacity-100 bg-gray-100 top-5 w-full z-10 rounded-b-md">
                <SourceInfo color="dark" sources={sources} />
              </div>
            )}
          </Paragraph>
        </li>
      );
    })}
  </>
);

const customInfoBlockValueComponents: Record<string, FC<any>> = {
  nationality: NationalityInfoBlockItemValues,
  default: InfoBlockItemValues,
};

export const InfoBlockItem: FC<InfoBlockItemProps> = ({
  info: { key, values, sources },
  className,
  onOverflow,
  collapsed = false,
  size = 'default',
  noDataComponent,
}: InfoBlockItemProps) => {
  const { t } = useTranslation();
  const displayedValues = collapsed ? values.slice(0, 6) : values;

  const detectOverflow = (ref: HTMLSpanElement) => {
    if (!onOverflow || !ref) return;
    const isOverflowing = ref.scrollWidth > ref.clientWidth;
    if (isOverflowing) onOverflow();
  };

  const props = {
    values: displayedValues,
    sources,
    collapsed,
    size,
    handleOverflow: detectOverflow,
  };

  const valuesComponentKey =
    !key || !customInfoBlockValueComponents[key] ? 'default' : key;
  const ValuesComponent = customInfoBlockValueComponents[valuesComponentKey];

  return (
    <div className={className}>
      {key && (
        <Paragraph size="label" weight="bold">
          {t(`infoBlockItem.${key}`)}
        </Paragraph>
      )}
      <ul className="break-all space-y-1">
        <ValuesComponent {...props} />
        {displayedValues.length === 0 && noDataComponent}
      </ul>
    </div>
  );
};
