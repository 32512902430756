import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from '_atoms';
import { useSuspicionsReportSuspicionsQuery } from '_queries';
import { CaseData, Target } from '../../../services/dataService';
import { ArticlesBySuspicion } from './ArticlesBySuspicion';
import { ReportFilters } from './ReportCard';
import { SummaryReport } from '_types';

type ReportCardProps = {
  reportId: string;
  targetId: string;
  accessToken?: string;
  filters?: ReportFilters;
  className?: string;
  targetData?: Target;
  caseData?: CaseData;
  report?: SummaryReport;
};

export const ReportCardBySuspicion: FC<ReportCardProps> = ({
  reportId,
  targetId,
  filters,
  accessToken = '',
  targetData,
  caseData,
  report,
}) => {
  const { t } = useTranslation();

  const {
    data: suspicionList,
    isSuccess,
    isLoading,
  } = useSuspicionsReportSuspicionsQuery(targetId, reportId, filters);

  //TODO: switch back to using only the data from the backend once fetching own_keywords suspicions from the backend is fixed
  const suspicions = suspicionList?.length ? suspicionList : report?.suspicions;

  return (
    <div className="py-4">
      <div className="flex flex-col gap-1">
        {isLoading && (
          <LoadingSpinner message={<span className="invisible">...</span>} />
        )}

        {!isLoading && isSuccess && suspicions?.length === 0 && (
          <div className="bg-white rounded-md p-3">{t('noData')}</div>
        )}

        {!isLoading &&
          isSuccess &&
          suspicions?.map(({ id, name, label }) => (
            <ArticlesBySuspicion
              key={id}
              id={id}
              name={name}
              label={label}
              targetId={targetId}
              filters={filters}
              accessToken={accessToken}
              targetData={targetData}
              caseData={caseData}
            />
          ))}
      </div>
    </div>
  );
};
