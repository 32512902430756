import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CaseData, Target } from 'src/services/dataService';
import { DynamicSVG, LoadingSpinner } from '_atoms';
import { CollapsibleCard, Pagination } from '_molecules';
import {
  CompanyCard,
  PictureCard,
  PressCard,
  SearchResultCard,
  SocialMediaCard,
} from '_organisms';
import { useSuspicionsReportItemBySuspicionQuery } from '_queries';
import { ReportFilters } from './ReportCard';
import { EntityBySuspicion } from '_types';

const PAGE_SIZE = 10;

interface ArticlesBySuspicionProps {
  id: string;
  name: string;
  label: string;
  targetId: string;
  filters?: ReportFilters;
  targetData?: Target;
  caseData?: CaseData;
  accessToken: string;
}

export const ArticlesBySuspicion: FC<ArticlesBySuspicionProps> = ({
  id,
  name,
  label,
  targetId,
  filters,
  targetData,
  caseData,
  accessToken,
}) => {
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(1);

  const { data, isLoading, isError } = useSuspicionsReportItemBySuspicionQuery(
    targetId,
    id,
    {
      ...filters,
      page,
      pageSize: PAGE_SIZE,
    },
  );

  const entities = data?.entities;

  const uniqueEntities: EntityBySuspicion[] | undefined = useMemo(() => {
    const visited: Record<string, boolean> = {};
    return (entities ?? [])?.filter(({ id }) => {
      if (id in visited) {
        return false;
      }

      visited[id] = true;
      return true;
    });
  }, [entities]);

  useEffect(() => {
    setPage(1);
  }, [filters]);

  return (
    <CollapsibleCard
      key={['report', 'suspicion', id].join('-')}
      header={
        <div className="flex items-center gap-3 w-1/2">
          <div className="flex justify-center items-center w-10 h-10 rounded-full bg-yellow-400 p-3 text-yellow-700">
            <DynamicSVG src={`icons/suspicions/${label}`} width={16} />
          </div>
          <span className="font-bold">{name}</span>
        </div>
      }
      headerColor="bg-gray-200"
      bodyClasses="bg-gray-200 p-3"
      className="border-0"
      initiallyExpanded={false}
    >
      <div className="flex flex-col gap-3">
        {isLoading ? (
          <LoadingSpinner message={<span className="invisible">...</span>} />
        ) : isError ? (
          <div>Error</div>
        ) : null}

        {uniqueEntities?.length ? (
          <>
            {uniqueEntities.map(
              ({ value, suspicionItemId: itemId, score, status }) => {
                switch (value.type) {
                  case 'press':
                    return (
                      <PressCard
                        key={`${value.id}, status: ${status}`}
                        data={value}
                        score={score}
                        status={status}
                        suspicionItemId={itemId}
                        targetData={targetData}
                        caseData={caseData}
                      />
                    );
                  case 'SocialAccountConnection':
                  case 'SocialAccountPost':
                    return (
                      <SocialMediaCard
                        key={`${value.id}, status: ${status}`}
                        data={value}
                        accessToken={accessToken}
                        score={score}
                        status={status}
                        suspicionItemId={itemId}
                        targetData={targetData}
                        caseData={caseData}
                      />
                    );
                  case 'Image':
                    return (
                      <PictureCard
                        key={`${value.id}, status: ${status}`}
                        data={value}
                        score={score}
                        status={status}
                        suspicionItemId={itemId}
                        targetData={targetData}
                        caseData={caseData}
                      />
                    );
                  case 'WebPage':
                    return (
                      <SearchResultCard
                        key={`${value.id}, status: ${status}`}
                        data={value}
                        score={score}
                        status={status}
                        suspicionItemId={itemId}
                        targetData={targetData}
                        caseData={caseData}
                      />
                    );
                  case 'TargetCompany':
                    return (
                      <CompanyCard
                        key={`${value.id}, status: ${status}`}
                        data={value}
                        score={score}
                        status={status}
                        suspicionItemId={itemId}
                        targetData={targetData}
                        caseData={caseData}
                      />
                    );
                }
              },
            )}

            {data?.paging?.pagesCount && data?.paging?.pagesCount > 1 ? (
              <div className="px-5">
                <Pagination paging={data.paging} setPageNumber={setPage} />
              </div>
            ) : null}
          </>
        ) : (
          <div className="bg-white rounded-md p-3">{t('noData')}</div>
        )}
      </div>
    </CollapsibleCard>
  );
};
