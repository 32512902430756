import { FC, useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { DynamicFormValue, Gender, Role, Title } from '@indicium/common';
import { Button, Headline, Paragraph, StyledField } from '_atoms';
import { Link, useParams } from 'react-router-dom';
import {
  getCountrySelectionOptions,
  getNationalitySelectionOptions,
  getReducedCountrySelectionOptions,
} from '../TargetNew/countryCodeOptions';
import { useDeleteTargetMutation } from '_queries';
import './TargetForm.css';
import {
  getCompanyEntriesfromCompanyInput,
  getContact,
  getFormDateFromDateRange,
  getSocialMediaProfiles,
} from './targetFormUtils';
import { ProcessTarget } from '@indicium/common/src/types/Target/TargetTypes';
import { useUserQuota } from '../../../hooks/useUserQuota';
import { nonProdDataTestId } from '_utils';
import { DynamicForm } from '_molecules';
import { TargetFormSchema } from '../../../schemas/targetFormSchema';

interface TargetFormProps {
  targetInput?: ProcessTarget;
  showBackButton?: boolean;
  onSubmit: (values: unknown) => Promise<void>;
  isSubmitting: boolean;
  apiErrors: string[];
}

export const initialValues = {
  title: '',
  gender: '',
  firstname: '',
  lastname: '',
  middlename: '',
  nickname: '',
  nicknames: [] as DynamicFormValue[],
  dateOfBirth: '',
  nationalities: [''],
  placeOfBirth: '',
  countryOfBirth: '',
  contact: getContact(),
  companyEntries: getCompanyEntriesfromCompanyInput(),
  socialMediaProfiles: getSocialMediaProfiles(),
  occupations: [] as DynamicFormValue[],
  relatedPersons: [] as DynamicFormValue[],
  websites: [] as DynamicFormValue[],
  organizations: [] as DynamicFormValue[],
  topics: [] as DynamicFormValue[],
  locations: [] as DynamicFormValue[],
};

export const TargetForm: FC<TargetFormProps> = ({
  targetInput,
  showBackButton = false,
  onSubmit,
  isSubmitting,
  apiErrors,
}) => {
  const { t, i18n } = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);
  const [selectedImage, _setSelectedImage] = useState<any>(null);
  const _handleImageUpload = useCallback(() => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append('targetImage', selectedImage, selectedImage?.name);

    // Details of the uploaded file
    console.log(selectedImage);

    // TODO: enable when connecting to the BE
    // Request made to the backend api
    // Send formData object
    // axios.post('api/uploadfile', formData);
    // After successful upload, set the state for the for field "image" to the returned url
  }, [selectedImage]);

  const { isError: isDeleteTargetError } = useDeleteTargetMutation();

  const { caseId } = useParams<{ caseId: string }>();

  const { quota } = useUserQuota();

  useEffect(() => {
    if (!targetInput) {
      return;
    }
    setFormValues({
      ...initialValues,
      title: targetInput.title || '',
      gender: targetInput.gender || '',
      firstname: targetInput.firstname || '',
      lastname: targetInput.lastname || '',
      middlename: targetInput.middlename || '',
      nickname: targetInput.nickname || '',
      nicknames: targetInput.nicknames || [],
      dateOfBirth: getFormDateFromDateRange(targetInput.dateOfBirth, ''),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      nationalities: targetInput.nationalities
        ? typeof targetInput.nationalities === 'string'
          ? [targetInput.nationalities]
          : targetInput.nationalities
        : [''],
      placeOfBirth: targetInput.placeOfBirth || '',
      countryOfBirth: targetInput.countryOfBirth || '',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      contact: getContact(targetInput.contact),
      companyEntries: getCompanyEntriesfromCompanyInput(
        targetInput.companyEntries,
      ),
      socialMediaProfiles: getSocialMediaProfiles(
        targetInput.socialMediaProfiles,
      ),
      occupations: targetInput.occupations || [],
      relatedPersons: targetInput.relatedPersons || [],
      websites: targetInput.websites || [],
      organizations: targetInput.organizations || [],
      topics: targetInput.topics || [],
      locations: targetInput.locations || [],
    });
  }, [targetInput]);

  const nationalitySelectionOptions = getNationalitySelectionOptions(
    i18n.language,
  );

  const countrySelectionOptions = getCountrySelectionOptions(i18n.language);

  const reducedCountrySelectionOptions = getReducedCountrySelectionOptions(
    i18n.language,
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formValues}
      onSubmit={onSubmit}
      validationSchema={TargetFormSchema}
      validateOnMount
    >
      {({ errors, values, setFieldValue }) => (
        <Form className="space-y-6" noValidate>
          <div className="bg-white rounded-md py-5 px-6">
            <section className="row space-y-7">
              <div className="col-12">
                <Headline Level="h4" className="mt-0" color="dark">
                  {t('targetPersonDataSubHeadline')}
                </Headline>
                <Headline Level="h6" color="dark" className="my-3.5">
                  {t('basisData')}
                </Headline>
              </div>
              <div className="col-6 md:col-3">
                <StyledField
                  as="select"
                  name="title"
                  label={t('title')}
                  data-testid={nonProdDataTestId('title')}
                >
                  <option value="">-</option>
                  {Object.values(Title).map((title) => (
                    <option value={title} key={title}>
                      {t(`titles.${title}`)}
                    </option>
                  ))}
                </StyledField>
              </div>
              <div className="col-6 md:col-3 mr-0 md:mr-10">
                <StyledField
                  as="select"
                  name="gender"
                  label={t('gender')}
                  data-testid={nonProdDataTestId('gender')}
                >
                  <option value="">-</option>
                  {Object.values(Gender).map((title) => (
                    <option value={title} key={title}>
                      {t(`genders.${title}`)}
                    </option>
                  ))}
                </StyledField>
              </div>
              <div className="col-12 md:col-6">
                <StyledField
                  type="text"
                  name="firstname"
                  label={t('firstname')}
                  required
                  autoFocus
                  data-testid={nonProdDataTestId('firstname')}
                />
              </div>
              <div className="col-12 md:col-6">
                <StyledField
                  type="text"
                  name="lastname"
                  label={t('lastname')}
                  required
                  data-testid={nonProdDataTestId('lastname')}
                />
              </div>
              <div className="col-12 md:col-6">
                <StyledField
                  type="text"
                  name="middlename"
                  label={t('middlename')}
                  data-testid={nonProdDataTestId('middlename')}
                />
              </div>
              <div className="col-12 md:col-6">
                <StyledField
                  unused
                  type="text"
                  name="nickname"
                  label={t('nicknameAlternative')}
                  data-testid={nonProdDataTestId('nickname')}
                />
              </div>
              <div className="col-12 md:col-6">
                <StyledField
                  type="text"
                  name="dateOfBirth"
                  label={t('targetNewForm.dateOfBirthLabel')}
                  placeholder={t('targetNewForm.dateOfBirthPlaceholder')}
                  recommended
                  data-testid={nonProdDataTestId('dateOfBirth')}
                />
              </div>
              <div className="col-12 md:col-6">
                {values.nationalities?.map((_, index) => (
                  <StyledField
                    as="select"
                    name={`nationalities.${index}`}
                    label={t('nationality')}
                    key={index}
                    data-testid={nonProdDataTestId('nationality')}
                  >
                    <option value="">-</option>
                    {nationalitySelectionOptions.map(({ code, name }) => (
                      <option value={code} key={code}>
                        {name}
                      </option>
                    ))}
                  </StyledField>
                ))}
              </div>
              <div className="col-12 md:col-6">
                <StyledField
                  unused
                  type="text"
                  name="placeOfBirth"
                  label={t('placeOfBirth')}
                  data-testid={nonProdDataTestId('placeOfBirth')}
                />
              </div>
              <div className="col-12 md:col-6">
                <StyledField
                  as="select"
                  name="countryOfBirth"
                  label={t('countryOfBirth')}
                  data-testid={nonProdDataTestId('countryOfBirth')}
                >
                  <option value="">-</option>
                  {countrySelectionOptions.map(({ code, name }) => (
                    <option value={code} key={`country-of-birth-${code}`}>
                      {name}
                    </option>
                  ))}
                </StyledField>
              </div>
              {/* hidden until backend implementation is done
              <div className="col-12 md:col-6">
                <StyledField name="image" label={t('imageUpload')} type="text">
                  {() => (
                    <div className="flex items-starts">
                      <label
                        htmlFor="file-upload"
                        className={classNames(
                          'form-control inline-block w-auto px-3 py-1.5',
                          'text-base font-normal bg-white',
                          // TODO: enable when connecting to the BE
                          // 'text-gray-700 cursor-pointer', //enabled
                          'text-gray-300', //disabled
                          'bg-clip-padding border border-solid border-gray-300',
                          'rounded transition ease-in-out m-0',
                          'focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none',
                        )}
                      >
                        <i className="fa fa-cloud-upload"></i>
                        {t('browse')}
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        onChange={
                          (event: ChangeEvent<HTMLInputElement>) =>
                            event.target?.files?.length &&
                            // TODO: enable when connecting to the BE
                            setSelectedImage(null) //event?.target?.files[0])
                        }
                        disabled
                      />
                      <span className="px-3 pt-2">{selectedImage?.name}</span>
                      <Button
                        disabled={!selectedImage?.name}
                        level="secondary"
                        className="w-22 h-10 ml-auto"
                        onClick={handleImageUpload}
                      >
                        {t('uploadTheImage')}
                      </Button>
                    </div>
                  )}
                </StyledField>
              </div> */}
            </section>

            <section className="mt-12">
              <DynamicForm values={values} setFieldValue={setFieldValue} />
            </section>

            <section className="mt-12 row">
              <div className="col-12 my-3.5">
                <Headline Level="h6" color="dark">
                  {t('contactData')}
                </Headline>
              </div>
              <div className="col-12 md:col-6 my-3.5">
                <StyledField
                  name="contact.0.value"
                  label={t('phone')}
                  type="tel"
                  placeholder={t('phonePlaceholder')}
                  data-testid={nonProdDataTestId('phone')}
                />
              </div>

              {['1', '2', '3'].map((fieldNumber) => (
                <div key={fieldNumber} className="col-12 md:col-6 my-3.5">
                  <StyledField
                    name={`contact.${fieldNumber}.value`}
                    label={`${t('email')} ${fieldNumber}`}
                    placeholder={t('emailPlaceholder')}
                    type="email"
                    data-testid={nonProdDataTestId(`email-${fieldNumber}`)}
                  />
                </div>
              ))}
            </section>

            <section className="row mt-12">
              <div className="col-12 mt-3.5">
                <Headline Level="h3" color="dark">
                  {t('company')}
                </Headline>
              </div>
              <div className="space-y-14">
                {values.companyEntries?.map((company, index) => {
                  const hasCompanyName = Boolean(company?.name?.length);
                  return (
                    <div key={index} className="row">
                      <div className="mt-7 col-12 md:col-6">
                        <StyledField
                          type="text"
                          name={`companyEntries.${index}.name`}
                          label={t('companyName')}
                          data-testid={nonProdDataTestId(
                            `companyName-${index + 1}`,
                          )}
                        />
                      </div>
                      <div className="mt-7 col-12 md:col-6">
                        <StyledField
                          as="select"
                          name={`companyEntries.${index}.location`}
                          label={t('companyLocation')}
                          required={hasCompanyName}
                          disabled={!hasCompanyName}
                          data-testid={nonProdDataTestId(
                            `companyLocation-${index + 1}`,
                          )}
                        >
                          <option value="" disabled defaultValue={'selected'}>
                            -
                          </option>
                          {reducedCountrySelectionOptions.map(
                            ({ code, name }) => (
                              <option
                                value={code}
                                key={`company-location-${code}`}
                              >
                                {name}
                              </option>
                            ),
                          )}
                        </StyledField>
                      </div>
                      <div className="mt-7 col-12 md:col-6">
                        <StyledField
                          type="text"
                          name={`companyEntries.${index}.commercialRegisterNumber`}
                          label={t('commercialRegisterNumber')}
                          placeholder={t('commercialRegisterNumberPlaceholder')}
                          disabled={!hasCompanyName}
                          data-testid={nonProdDataTestId(
                            `commercialRegisterNumber-${index + 1}`,
                          )}
                        />
                      </div>
                      <div className="mt-7 col-12 md:col-6">
                        <StyledField
                          type="text"
                          name={`companyEntries.${index}.vatNumber`}
                          label={t('companyVatNumber')}
                          placeholder={t('companyVatNumberPlaceholder')}
                          disabled={!hasCompanyName}
                          data-testid={nonProdDataTestId(
                            `companyVatNumber-${index + 1}`,
                          )}
                        />
                      </div>
                      <div className="mt-7 col-12 md:col-6">
                        <StyledField
                          type="text"
                          name={`companyEntries.${index}.website`}
                          label={t('website')}
                          placeholder={t('websitePlaceholder')}
                          disabled={!hasCompanyName}
                          data-testid={nonProdDataTestId(
                            `company-website-${index + 1}`,
                          )}
                        />
                      </div>
                      <div className="mt-7 col-12 md:col-6">
                        <StyledField
                          as="select"
                          name={`companyEntries.${index}.role`}
                          label={t('companyRole.label')}
                          disabled={!hasCompanyName}
                          data-testid={nonProdDataTestId(
                            `companyRole-${index + 1}`,
                          )}
                        >
                          {Object.values(Role).map((role, roleIdx) => (
                            <option value={role} key={roleIdx}>
                              {t(`companyRole.optionLabels.${role}`)}
                            </option>
                          ))}
                        </StyledField>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>

            <section className="row mt-12 space-y-7">
              <div className="col-12 mt-3.5">
                <Headline Level="h3" weight="medium" color="dark">
                  {t('socialMediaProfiles')}
                </Headline>
                <Paragraph
                  size="default"
                  weight="normal"
                  lineHeight="default"
                  color="default"
                  className="mt-1"
                >
                  {t('socialMediaPrivacyHint')}
                </Paragraph>
              </div>
              <div className="row">
                {values.socialMediaProfiles?.map(({ type }, index) => (
                  <div className="col-12 md:col-6 mb-7" key={type}>
                    <StyledField
                      type="text"
                      name={`socialMediaProfiles.${index}.value`}
                      label={t(type)}
                      placeholder={t(`${type}Placeholder`)}
                      data-testid={nonProdDataTestId(
                        `socialMediaProfile-${type}`,
                      )}
                    />
                    <Paragraph
                      size="default"
                      weight="normal"
                      lineHeight="default"
                      color="default"
                      className="mt-2"
                    >
                      {t('socialMediaFormatHint')}
                    </Paragraph>
                  </div>
                ))}
              </div>
            </section>
          </div>
          <div className="row">
            <div className="col-12 flex justify-end space-x-4">
              {showBackButton && (
                <Link className="h-fit-content" to={`/cases/${caseId}`}>
                  <Button type="button" className="w-52" level="secondary">
                    {t('back')}
                  </Button>
                </Link>
              )}
              <div>
                <Button
                  className="w-52"
                  level="primary"
                  disabled={isSubmitting || !quota?.remaining}
                  type="submit"
                  data-testid={nonProdDataTestId('submit button')}
                >
                  {t('moreInfo')}
                </Button>
                {isDeleteTargetError ? (
                  <Paragraph
                    size="default"
                    weight="normal"
                    lineHeight="default"
                    color="blue"
                    className="mt-4 w-52"
                  >
                    {t('targetNewForm.targetDeletionNotAllowed')}
                  </Paragraph>
                ) : null}
                {isSubmitting ? (
                  <Paragraph
                    size="default"
                    weight="normal"
                    lineHeight="default"
                    color="default"
                    className="mt-4 w-52"
                  >
                    {t('targetNewForm.targetCreationInProgress')}
                  </Paragraph>
                ) : null}
                {(values.firstname.length === 0 ||
                  values.lastname.length === 0) && (
                  <Paragraph
                    size="default"
                    weight="normal"
                    lineHeight="default"
                    color="default"
                    className="mt-4 w-52"
                  >
                    {t('requiredNameError')}
                  </Paragraph>
                )}
                {/* TODO: Replace error list down here with an active Next button that jumps to
                  any invalid fields */}
                {errors.dateOfBirth && (
                  <Paragraph
                    size="default"
                    weight="normal"
                    lineHeight="default"
                    color="default"
                    className="mt-4 w-52"
                  >
                    {t('targetNewForm.invalidBirthdayDate')}
                  </Paragraph>
                )}

                {apiErrors.length > 0
                  ? apiErrors.map((error, index) => (
                      <Paragraph
                        key={[error, index].join(':')}
                        size="default"
                        weight="normal"
                        lineHeight="default"
                        className="mt-4 w-52 text-red-700"
                      >
                        {error}
                      </Paragraph>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
