import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';
import classnames from 'classnames';

type ButtonLevel =
  | 'primary'
  | 'secondary'
  | 'primarySlim'
  | 'secondarySlim'
  | 'danger'
  | 'custom';

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  level?: ButtonLevel;
  children: ReactNode;
}

const commonStyles =
  'inline-flex items-center justify-center border shadow-sm text-base font-medium font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-300 disabled:cursor-default';

const styles: Record<ButtonLevel | 'custom', string> = {
  primary:
    'px-6 py-3 border-transparent text-white bg-blue-400 hover:bg-blue-600 focus:ring-blue-400',
  secondary:
    'px-6 py-3  border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-blue-400',
  custom:
    'px-6 py-3  border-transparent disabled:bg-gray-300 focus:ring-blue-400',
  primarySlim:
    'px-4 py-3  border-transparent text-white bg-blue-400 hover:bg-blue-600 focus:ring-blue-400',
  secondarySlim:
    'px-4 py-3  border-gray-300 text-gray-700 bg-white hover:bg-gray-50',
  danger:
    'px-6 py-3 border-transparent text-white bg-red-500 hover:bg-red-600 focus:ring-red-400',
};

export const Button: FC<ButtonProps> = ({
  level = 'primary',
  className,
  children,
  ...rest
}: ButtonProps) => (
  <button
    className={classnames(commonStyles, styles[level], className)}
    {...rest}
  >
    {children}
  </button>
);
