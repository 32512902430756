import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { SocialIcon } from 'react-social-icons';
import { normalizeUrl } from '../../_atoms/ResultTileDataColumns/LinkData';
import {
  Headline,
  SocialProfilePictureBubble,
  TextData,
  ImageData,
  LinkData,
  Highlight,
  CustomData,
} from '_atoms';
import { BaseCard } from '_organisms';
import { trimConnectionUserName } from '_organisms/SocialMedia/SocialMediaContacts';
import { getCdnUrl } from '_utils';
import {
  SocialAccountPostArticle,
  SocialAccountConnectionArticle,
} from '../../../services/searchService';
import { CaseData, Target } from '../../../services/dataService';
import { EntitySuspicion } from '_types';
import { HighlightChunks } from '../../_atoms/Highlight/types';
import { buildHighlightChunks } from '../../_atoms/Highlight/public-utils';

type SocialMediaData =
  | SocialAccountPostArticle
  | SocialAccountConnectionArticle;

interface SocialMediaCardProps extends HTMLAttributes<HTMLDivElement> {
  data: SocialMediaData;
  query?: string;
  accessToken: string;
  hideArticleType?: boolean;
  score?: number;
  status?: string;
  suspicionItemId?: string;
  targetData?: Target;
  caseData?: CaseData;
  suspicionItems?: Array<EntitySuspicion>;
  highlight?: HighlightChunks;
}

const SocialMediaContent: FC<SocialMediaCardProps> = ({
  data,
  highlight,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className="p-4" {...props}>
      {data.type === 'SocialAccountPost' ? (
        <>
          {data.postImageCdnUrl ? (
            <ImageData
              headline={t('socialMedia.media')}
              src={getCdnUrl(data.postImageCdnUrl)}
              width="w-1/5"
            />
          ) : null}
          <div className="w-4/5 inline-block align-top">
            <CustomData headline={t('socialMedia.url')} width="w-1/2">
              <SocialIcon
                className="float-left"
                network={data.accountType?.toLowerCase()}
                style={{
                  width: '18px',
                  height: '18px',
                  marginRight: '8px',
                }}
              />
              <a
                key={data.postUrl}
                href={normalizeUrl(data.postUrl)}
                rel="noopener noreferrer"
                target="_blank"
                className="text-blue-400 block overflow-hidden overflow-ellipsis whitespace-nowrap"
              >
                {data.postUrl}
              </a>
            </CustomData>
            <TextData
              headline={t('socialMedia.datePublished')}
              text={data.postDatePublished || t('noData')}
              highlight={highlight}
              width="w-1/5"
            />
            <TextData
              headline={t('socialMedia.likeCount')}
              text={data.postLikeCount?.toString(10) || t('noData')}
              highlight={highlight}
              width="w-1/5"
            />
            <p
              className="mx-6 py-7 line-clamp-3 overflow-auto"
              style={{ display: 'block' }}
            >
              <pre className="whitespace-pre-wrap break-words">
                {highlight ? (
                  <Highlight
                    searchWords={highlight}
                    textToHighlight={data.postBody || ''}
                  />
                ) : (
                  data.postBody
                )}
              </pre>
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="w-1/6 inline-block align-top">
            <Headline
              className="pl-3 pb-1 uppercase"
              Level="h6"
              weight="bold"
              color="light"
            >
              {t('socialMedia.profile')}
            </Headline>
            <SocialProfilePictureBubble
              data={{
                imageUrl: getCdnUrl(data.connectionProfileImageCdnUrl),
                network: data.accountType?.toLowerCase(),
              }}
              size="small"
              className="w-16 h-20 ml-2"
            />
          </div>
          <TextData
            headline={t('socialMedia.date')}
            text={t('noData')}
            highlight={highlight}
            width="w-1/6"
          />
          <TextData
            headline={t('socialMedia.connectionType')}
            text={data.connectionType}
            highlight={highlight}
            width="w-1/5"
          />
          <TextData
            headline={t('socialMedia.username')}
            text={trimConnectionUserName(data.connectionUsername)}
            highlight={highlight}
            width="w-1/5"
          />
          <LinkData
            headline={t('socialMedia.profileUrl')}
            href={data.connectionProfileUrl}
            highlight={highlight}
            width="w-1/5"
          />
        </>
      )}
    </div>
  );
};

export const SocialMediaCard: FC<SocialMediaCardProps> = ({
  data,
  query,
  accessToken,
  hideArticleType = false,
  suspicionItems,
  caseData,
  ...props
}) => {
  const { targetData } = props;

  const highlight = buildHighlightChunks({
    searchQuery: query,
    suspicions: suspicionItems,
    target: targetData,
    caseKeywords: caseData?.keywords,
  });

  return (
    <BaseCard
      entityType={
        data.type === 'SocialAccountPost' ? 'socialPost' : 'socialConnection'
      }
      title={
        data.type === 'SocialAccountPost'
          ? data.postProfileName
          : data.connectionProfileName
      }
      item={data}
      hideArticleType={hideArticleType}
      suspicionItems={suspicionItems}
      highlight={highlight}
      {...props}
    >
      <SocialMediaContent
        data={data}
        accessToken={accessToken}
        highlight={highlight}
      />
    </BaseCard>
  );
};
