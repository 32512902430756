import { FC, Fragment } from 'react';
import classnames from 'classnames';
import {
  PersonInfo,
  TableRowActionsMenu,
  TableData,
  CaseInfo,
} from '_organisms/Table';
import { Checkbox } from '_atoms';
import { generatePath, Link } from 'react-router-dom';
import { TableRowAction } from '_organisms/Table';
import { LinkOption } from '../Table';
import { nonProdDataTestId } from '_utils';

type TableRowProps = {
  result: PersonInfo | CaseInfo;
  backgroundColor: string;
  firstColBold?: boolean;
  secondColBold?: boolean;
  tableRowActions?: TableRowAction[];
  linkOption?: LinkOption;
  checkbox?: boolean;
  isSelected?: boolean;
  squashLayout?: boolean;
  onChangeRowSelection?: (args: { id: string; isSelected: boolean }) => void;
};

const getTestIdFromColumn = (column: string) => {
  switch (column) {
    case 'caseName':
      return 'case title';
    case 'analysisType':
      return 'case analysis type';
    case 'createdOn':
      return 'case created on';
    case 'fullName':
      return 'target name';
    default:
      return undefined;
  }
};

export const TableRow: FC<TableRowProps> = ({
  result,
  backgroundColor,
  firstColBold,
  secondColBold,
  tableRowActions,
  linkOption,
  checkbox,
  isSelected = false,
  squashLayout,
  onChangeRowSelection,
}: TableRowProps) => {
  const { id = '', ...displayData } = result;

  const handleChange = (isSelected: boolean) => {
    onChangeRowSelection?.({
      id,
      isSelected,
    });
  };

  return (
    <Fragment>
      <tr
        className={classnames(
          'relative',
          isSelected ? 'bg-blue-100' : `${`bg-${backgroundColor}`}`,
        )}
        data-testid={nonProdDataTestId('table row')}
      >
        {checkbox && (
          <td className="relative">
            <label className="absolute inset-0 cursor-pointer" htmlFor={id} />
            <Checkbox
              id={id}
              initialIsSelected={isSelected}
              onChange={handleChange}
            />
          </td>
        )}

        {Object.entries(displayData).map(
          // TODO: this is not linked to the order in which the headers are rendered https://produktmacher.atlassian.net/browse/IND-894
          ([column, item], index) => (
            <TableData
              text={item}
              key={index}
              textWeight={
                (firstColBold && index === 0) ||
                (secondColBold && index === 1) ||
                isSelected
                  ? 'bold'
                  : 'normal'
              }
              textColor={
                (firstColBold && index === 0) ||
                (secondColBold && index === 1) ||
                isSelected
                  ? 'dark'
                  : 'default'
              }
              dataTestId={getTestIdFromColumn(column)}
            />
          ),
        )}

        {squashLayout && <td className="px-3.5 py-5 w-1/5"></td>}

        {linkOption && (
          <td
            className="px-6 py-5 text-right"
            data-testid={nonProdDataTestId('table row open button')}
          >
            <button className="text-sm text-blue-400 hover:text-blue-600 font-normal focus:outline-none">
              {/*

              // DIFFERENT ROUTE USE CASES
              // CustomerAdmin: =>  /admin/customer/:customerId
              // CasesAdmin: =>  /cases/:caseId
              // ListTarget: =>  /dashboard/profile/:userId

               */}
              <Link
                to={generatePath(
                  linkOption.href,
                  linkOption.param ? { [linkOption.param]: id } : undefined,
                )}
              >
                {linkOption.name}
              </Link>
            </button>
          </td>
        )}

        {tableRowActions ? (
          <TableRowActionsMenu actions={tableRowActions} id={id} />
        ) : null}
      </tr>
    </Fragment>
  );
};
