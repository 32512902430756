import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Keyword } from '../../cases/CaseNew/CaseNew';
import { useCaseKeywordsQuery, useCaseQuery } from '_queries';
import { CaseNewStepTwo } from '../../cases/CaseNew/CaseNewSteps/StepTwo';
import { LoadingSpinner } from '_atoms';

export const CaseData: FC = () => {
  const { caseId } = useParams<{ caseId: string }>();

  const { data: caseData, ...caseQuery } = useCaseQuery(caseId);
  const { data: caseKeywordsData, ...caseKeywordsQuery } =
    useCaseKeywordsQuery(caseId);

  const keywords: Keyword = caseKeywordsData?.map((keyword: any) => ({
    value: keyword.suspicion.name,
    engTranslations: keyword.translations.map(
      (translation: any) => translation.value,
    ),
  }));

  const optionValue = caseData?.option?.option;

  const optionProps = {
    ...caseData?.option,
    backgroundCheck: caseData?.option.backgroundCheck ? 'yes' : 'no',
    op2keywords: optionValue === 'option2' ? keywords : undefined,
    op3keywords: optionValue === 'option3' ? keywords : undefined,
  };

  const option = {
    value: optionValue || '',
    props: optionProps,
  };

  return (
    <div className="container-fluid mb-20 px-10">
      {caseQuery?.isLoading || caseKeywordsQuery?.isLoading ? (
        <LoadingSpinner />
      ) : (
        <CaseNewStepTwo option={option} setOption={() => null} readOnly />
      )}
    </div>
  );
};
