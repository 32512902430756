import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '_atoms';
import { WorkflowError, Alert } from '_molecules';
import { SocialMediaContacts, SocialMediaPosts } from '_organisms';
import { ResultsProps } from '../Results';

export const SocialMedia: FC<ResultsProps> = ({
  accessToken,
  targetData,
  caseData,
}: ResultsProps) => {
  const { t } = useTranslation();
  const [selectedData, setSelectedData] =
    useState<'posts' | 'connections'>('posts');

  return (
    <div className="flex-1 px-5">
      {targetData?.workflowErrors?.length ? (
        <WorkflowError
          className="my-8"
          errors={targetData?.workflowErrors}
          path="socialmedia"
        />
      ) : null}

      {targetData?.status === 'HasInitialProfile' && (
        <Alert
          alertType="warning"
          headline={t('profileDataNotFinal')}
          className="my-8"
        />
      )}

      <div className="flex-grow h-13 flex justify-begin space-x-3 mb-5">
        <div className="w-18">
          <Button
            level={selectedData === 'posts' ? 'primary' : 'secondary'}
            className="w-full py-4 uppercase"
            onClick={() => setSelectedData('posts')}
          >
            {t('socialMedia.posts')}
          </Button>
        </div>

        <div className="w-18">
          <Button
            level={selectedData === 'connections' ? 'primary' : 'secondary'}
            className="w-full py-4 uppercase"
            onClick={() => setSelectedData('connections')}
          >
            {t('socialMedia.friendsAndInteractants')}
          </Button>
        </div>
      </div>

      {selectedData === 'connections' ? (
        <SocialMediaContacts targetData={targetData} />
      ) : selectedData === 'posts' ? (
        <SocialMediaPosts
          accessToken={accessToken}
          targetData={targetData}
          caseData={caseData}
        />
      ) : null}
    </div>
  );
};
