import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { CaseData } from '../../services/dataService';

const fetchCases = async (): Promise<CaseData[]> =>
  (await axios.get(`https://${backendBaseUrl}/cases`)).data.cases;

export const useCasesQuery = (): UseQueryResult<CaseData[]> =>
  useQuery('cases', fetchCases);
