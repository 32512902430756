import { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { TFunction, useTranslation } from 'react-i18next';

const getConfidenceBlock = (
  confidenceScore: number,
  t: TFunction<'translation'>,
) => {
  if (confidenceScore > 40) {
    return {
      text: t('confidenceScore.score30'),
      color: 'text-green-500',
    };
  }

  if (confidenceScore < 0) {
    return {
      text: t('confidenceScore.score10'),
      color: 'text-red-500',
    };
  }

  return {
    text: t('confidenceScore.score20'),
    color: 'text-gray-400',
  };
};

interface ConfidenceBarProps extends HTMLAttributes<HTMLDivElement> {
  confidenceScore: number;
}

export const ConfidenceBar: FC<ConfidenceBarProps> = ({
  confidenceScore,
  ...props
}) => {
  const { t } = useTranslation();

  const { text, color } = getConfidenceBlock(confidenceScore, t);

  return (
    <div {...props}>
      <div
        className={classNames(
          'text-sm w-max px-3 py-1 ',
          'bg-gray-100 rounded-xl font-semibold',
          color,
        )}
      >
        {text}
      </div>
    </div>
  );
};
