import { FC } from 'react';
import { TableRow } from './TableRow/TableRow';
import { TableRowAction } from './TableRowActionsMenu/TableRowActionsMenu';

export type CaseInfo = Record<string, string | undefined>;

export type PersonInfo = Record<string, string | undefined>;

export type LinkOption = { name: string; href: string; param?: string };

type TableProps = {
  headlines: string[];
  items: PersonInfo[] | CaseInfo[];
  tableRowActions?: TableRowAction[];
  linkOption?: LinkOption;
  additions?: {
    checkbox?: boolean;
    showNewFields?: number;
  };
  layoutOptions: {
    highlightColumns?: {
      firstColBold: boolean;
      secondColBold: boolean;
    };
    squashLayout?: boolean;
  };
  selectedItems?: string[];
  setSelectedItems?: (selectedItems: string[]) => void;
};

export const Table: FC<TableProps> = ({
  headlines,
  items,
  tableRowActions,
  linkOption,
  additions,
  layoutOptions,
  selectedItems = [],
  setSelectedItems,
}: TableProps) => {
  const handleOnChangeRowSelection = ({
    id,
    isSelected,
  }: {
    id: string;
    isSelected: boolean;
  }) => {
    const items = isSelected
      ? // add selected id
        [...selectedItems, id].filter(
          // but keep each id only once
          (i, index, array) => array.indexOf(i) === index,
        )
      : [...selectedItems].filter((i) => i !== id); // remove id if not isSelected

    if (setSelectedItems) {
      setSelectedItems(items);
    }
  };

  return (
    <div>
      <table
        cellPadding="0"
        cellSpacing="0"
        className="w-full p-0 text-white bg-blue-900 rounded-md"
      >
        <thead className="text-white">
          <tr>
            {additions?.checkbox && <th className="px-6 py-4">{''}</th>}
            {headlines.map((header) => (
              <th
                key={header}
                className="text-left text-sm leading-4 font-medium tracking-wider uppercase px-6 py-4"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((result, index) => (
            <TableRow
              firstColBold={layoutOptions?.highlightColumns?.firstColBold}
              secondColBold={layoutOptions?.highlightColumns?.secondColBold}
              backgroundColor={index % 2 === 0 ? 'white' : 'gray-50'}
              key={result.id}
              result={result}
              tableRowActions={tableRowActions}
              linkOption={linkOption}
              checkbox={additions?.checkbox}
              isSelected={
                additions?.checkbox &&
                selectedItems.indexOf(result.id ?? 'NOID') !== -1
              }
              squashLayout={layoutOptions?.squashLayout}
              onChangeRowSelection={handleOnChangeRowSelection}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
