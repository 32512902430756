import { FC, cloneElement, ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import type { NavigationItem } from '../../Layout/SubNavigationBar';

const getSelectedTabIndex = (
  currentSubPages: NavigationItem[] | undefined,
  location: any,
) => currentSubPages?.findIndex((page) => page.href === location.pathname) || 0;

const hasHref = (link: string | undefined): boolean =>
  !!link && link !== '#' && link !== '';

interface TabbedNavigatorProps {
  navigationItems: NavigationItem[];
  className?: string;
  contentClassName?: string;
}

export const TabbedNavigator: FC<TabbedNavigatorProps> = ({
  children,
  navigationItems,
  className = 'ml-64',
  contentClassName = 'mt-0',
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  const currentSubPages = navigationItems.find((item) =>
    item?.children?.some((child) => child.href === location.pathname),
  )?.children;
  const links = currentSubPages?.map((page) => page.href);

  const [selectedIndex, setSelectedIndex] = useState(
    getSelectedTabIndex(currentSubPages, location),
  );
  useEffect(() => {
    setSelectedIndex(getSelectedTabIndex(currentSubPages, location));
  }, [location, currentSubPages]);

  return currentSubPages ? (
    <div className={classNames('px-2 sm:px-0', className ? className : '')}>
      <Tab.Group selectedIndex={selectedIndex}>
        <Tab.List className="flex p-2 mx-2 bg-blue-900/20 rounded-xl overflow-x-auto">
          {currentSubPages
            .map((page) => page.name)
            .map((title, index) => (
              <Tab
                key={index}
                className={({ selected }) =>
                  classNames(
                    'text-center mx-1  border-solid border-gray-200 w-full py-2.5 px-5 text-sm leading-5 font-medium focus:outline-none',
                    selected
                      ? 'border-blue-400 text-blue-400 border-b-2'
                      : !hasHref(links?.[index])
                      ? 'text-gray-300'
                      : 'border-b-2 text-gray-500 hover:border-blue-200 hover:text-blue-200 ',
                  )
                }
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                disabled={!hasHref(links?.[index])}
                {...(hasHref(links?.[index])
                  ? { as: Link, to: links?.[index] }
                  : {})}
              >
                {t(`${title}`)}
              </Tab>
            ))}
        </Tab.List>
        <Tab.Panels className={classNames(contentClassName, 'mt-2 w-full')}>
          {currentSubPages?.map((tab: NavigationItem) => (
            <Tab.Panel key={tab.name} className="ml-0">
              {cloneElement(children as ReactElement<any>, {
                className: 'ml-0',
              })}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  ) : (
    (children as ReactElement<any>)
  );
};
