import { FC, useState } from 'react';
import { HiMenuAlt2 } from 'react-icons/hi';
import {
  MdLibraryBooks,
  MdOutlineManageAccounts,
  MdPeopleOutline,
  MdPostAdd,
} from 'react-icons/md';
import { routes } from '../../routes';
import { NavigationBar, Navigation } from './NavigationBar';
import { useUserState } from '../../context/User';
import {
  USER_GROUP_ADMINISTRATORS,
  USER_GROUP_SUPER_USERS,
} from '../../services/authenticationService';
import { useCustomerQuery } from '_queries';
import { isCustomerExpired } from '_utils';
import { Maintenance } from './Maintenance';
import { LiveChatLoaderProvider, Userlike } from 'react-live-chat-loader';

export const Layout: FC = ({ children }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [{ user, userRoles, customerId }] = useUserState();
  const { data: customer } = useCustomerQuery(customerId, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const NavigationItems: Navigation = [
    {
      id: '_cases',
      name: 'Cases',
      href: routes.casesList.path,
      icon: MdLibraryBooks,
      condition:
        !userRoles.includes(USER_GROUP_SUPER_USERS) || userRoles.length !== 1,
    },
    {
      id: '_case_new',
      name: 'Add Case',
      href: routes.caseNew.path,
      icon: MdPostAdd,
      condition: userRoles.includes(USER_GROUP_ADMINISTRATORS),
      disabled: isCustomerExpired(customer?.expiresAt),
    },
    {
      id: '_users',
      name: 'User Administration',
      href: routes.users.path,
      icon: MdPeopleOutline,
      condition: userRoles.includes(USER_GROUP_ADMINISTRATORS),
    },
    {
      id: '_admin_customer',
      name: 'Customer Administration',
      href: routes.customerAdmin.path,
      icon: MdOutlineManageAccounts,
      condition: userRoles.includes(USER_GROUP_SUPER_USERS),
    },
  ] as const;

  const availableNavigationItems = NavigationItems.filter(
    ({ condition }) => condition,
  );

  return (
    <LiveChatLoaderProvider
      providerKey="6c51380a58a34b72b3ce128d56ab345c71711c78c8aa451396ca59714b634466"
      provider="userlike"
    >
      <div className="flex h-screen overflow-hidden bg-gray-100">
        {user && <NavigationBar navigationItems={availableNavigationItems} />}
        <div className="flex flex-col flex-1 overflow-hidden">
          <div className="md:h-0 fixed flex justify-end w-full h-16">
            <button
              type="button"
              className={`${
                mobileMenuOpen && 'transform translate-x-16'
              } transition-all duration-300 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden`}
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <HiMenuAlt2 className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>

          {/* Main content */}
          <div className="flex items-stretch flex-1 overflow-hidden">
            <Maintenance>
              <main className="flex-1 overflow-y-auto relative">
                {children}
              </main>
            </Maintenance>
          </div>
        </div>
      </div>
      {user && <Userlike backgroundColor="#012232" />}
    </LiveChatLoaderProvider>
  );
};
