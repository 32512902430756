import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { forgotPasswordSubmit } from '../../services/authenticationService';
import { usePasswordValidation } from './use-password-validation';
import { AuthSteps, ForgotExceptions } from './types';
import { PasswordValidations } from './PasswordValidations';

interface PasswordForgotProps {
  onError: (code: ForgotExceptions) => void;
  resetCodeEmail: string;
  onChangeStep: (step: AuthSteps) => void;
}

export const PasswordForgot = ({
  onError,
  resetCodeEmail,
  onChangeStep,
}: PasswordForgotProps): React.ReactElement => {
  const { t } = useTranslation();
  const [code, setCode] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [newPasswordConfirmation, setNewPasswordConfirmation] =
    useState<string | null>(null);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    try {
      if (resetCodeEmail && code && newPassword) {
        await forgotPasswordSubmit(resetCodeEmail, code, newPassword);

        onChangeStep(AuthSteps.SignIn);
      }
    } catch (errorCode) {
      onError(errorCode as ForgotExceptions);
    }
  };

  const validations = usePasswordValidation({
    password: newPassword,
    confirmedPassword: newPasswordConfirmation,
  });

  return (
    <form className="mt-8 space-y-6" onSubmit={onSubmit}>
      {/* TODO: Check classes for commonalities and extract them into a variable -> see IND-252 */}
      <div>
        <label htmlFor="code" className="sr-only">
          {t('code')}
        </label>
        <input
          name="code"
          type="number"
          required
          minLength={6}
          maxLength={6}
          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-400 focus:border-blue-400 focus:z-10 sm:text-sm"
          placeholder={t('code')}
          onChange={(e) => setCode(e.target.value)}
        />
      </div>

      <div className="rounded-md shadow-sm space-y-6">
        <div>
          <div className="relative">
            <label htmlFor="password" className="sr-only">
              {t('password')}
            </label>
            <input
              name="password"
              type="password"
              autoComplete="password"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-400 focus:border-blue-400 focus:z-10 sm:text-sm"
              placeholder={t('passwordReset.newPassword')}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <PasswordValidations validations={validations} />
          </div>
          <div>
            <label htmlFor="passwordConfirm" className="sr-only">
              {t('password')}
            </label>
            <input
              name="passwordConfirm"
              type="password"
              autoComplete="password"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-400 focus:border-blue-400 focus:z-10 sm:text-sm"
              placeholder={t('passwordReset.newPasswordRepeat')}
              onChange={(e) => setNewPasswordConfirmation(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div>
        <button
          disabled={!validations.isValid}
          type="submit"
          className={classNames(
            (!validations.isValid || !code) &&
              'opacity-30 disabled:bg-blue-400 disabled:cursor-not-allowed',
            'group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400',
          )}
        >
          {t('passwordReset.newPasswordConfirm')}
        </button>
      </div>
    </form>
  );
};
