import * as Yup from 'yup';
import moment from 'moment';
import { UserInputTargetSchema } from '@indicium/common';

export const TargetFormSchema = UserInputTargetSchema.shape({
  dateOfBirth: Yup.string()
    .transform((value) => value.trim())
    .test({
      name: 'dateOfBirthValidator',
      message: 'targetNewForm.invalidBirthdayDate',
      test: (value, context) => {
        if (!value) return true;

        const currentDate = moment();

        const format = ['DD.MM.YYYY', 'YYYY'];
        const inputDate = moment(value, format, true);

        const isValidDate =
          inputDate.isValid() && inputDate.isSameOrBefore(currentDate);

        return isValidDate ?? context.createError();
      },
    }),
});
