/* eslint-env commonjs */
/* eslint-disable @typescript-eslint/no-var-requires */
/* istanbul ignore file */
module.exports = {
  // mode: 'jit', // TODO: can be used with postcss 8, which CRA does not support yet: https://github.com/iansu/create-react-app-meta/issues/56
  purge: {
    content: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
    safelist: [],
  },
  darkMode: false, // or 'media' or 'class'
  theme: {
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
      '3xl': '1920px',
      '4xl': '2560px',
    },
    spacing: {
      0: '0px', //        0px
      0.5: '0.125rem', // 2px
      1: '0.25rem', //    4px
      1.5: '0.375rem', // 6px
      2: '0.5rem', //     8px
      2.5: '0.625rem', // 10px
      3: '0.75rem', //    12px
      3.5: '0.875rem', //	14px
      4: '1rem', //       16px
      4.5: '1.175rem', // 18px
      5: '1.25rem', //	  20px
      6: '1.5rem', //	    24px
      7: '1.75rem', //	  28px
      8: '2rem', //       32px
      9: '2.25rem', //	  36px
      10: '2.5rem', //	  40px
      11: '2.75rem', //	  44px
      12: '3rem', //      48px
      14: '3.5rem', //    56px
      16: '4rem', //      64px
      20: '5rem', //      80px
      24: '6rem', //      96px
      25: '6.25rem', //   100px
      28: '7rem', //      112px
      30: '7.5rem', //    120px
      32: '8rem', //      128px
      36: '9rem', //      144px
      40: '10rem', //     160px
      44: '11rem', //     176px
      48: '12rem', //     192px
      52: '13rem', //     208px
      56: '14rem', //     224px
      60: '15rem', //     240px
      64: '16rem', //     256px
      72: '18rem', //     288px
      80: '20rem', //     320px
      96: '24rem', //     384px
      112.5: '28.125rem', //450px
      'fit-content': 'fit-content',
    },
    extend: {
      colors: {
        transparent: 'transparent',
        current: 'currentColor',
        inherit: 'inherit',

        white: '#FFFFFF',
        black: '#111111',

        blue: {
          100: '#DEF5FF',
          400: '#3CA4D4',
          500: '#1E87B7',
          600: '#006796',
          800: '#01364F',
          900: '#012232',
        },

        red: {
          100: '#FEF2F2',
          200: '#FECACA',
          400: '#F87171',
          500: '#EF4444',
          700: '#B91C1C',
          800: '#991B1B',
        },

        yellow: {
          100: '#FFFBEB',
          200: '#FDE68A',
          300: '#FCD34D',
          400: '#FBBF24',
          700: '#B45309',
          800: '#92400E',
        },

        green: {
          100: '#D1FAE5',
          400: '#6EE7B7',
          600: '#059669',
          700: '#047857',
          800: '#065F46',
        },

        gray: {
          50: '#F9FAFB',
          100: '#F3F4F6',
          200: '#E5E7EB',
          300: '#D1D5DB',
          400: '#9CA3AF',
          500: '#6B7280',
          600: '#4B5563',
          700: '#374151',
          800: '#1F2937',
          900: '#111827',
        },
      },
      height: {
        'fit-content': 'fit-content',
        'fill-available': 'webkit-fill-available',
      },
      animation: {
        'spin-slow': 'spin 3s linear infinite',
      },
      lineClamp: {
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        10: '10',
        11: '11',
        12: '12',
        13: '13',
        14: '14',
        15: '15',
        16: '16',
        17: '17',
        18: '18',
        19: '19',
        20: '20',
      },
      fontFamily: {
        sans: ['Lexend', 'Helvetica', 'sans-serif'],
      },
      scale: {
        300: '3',
      },
    },
  },
  variants: {
    extend: {
      backgroundColor: ['disabled', 'odd', 'even'],
      width: ['first', 'last'],
      cursor: ['disabled', 'hover', 'group-hover'],
      borderWidth: ['hover', 'group-hover'],
      borderColor: ['hover', 'group-hover'],
      opacity: ['hover', 'group-hover'],
      textColor: ['hover', 'group-hover'],
      translate: ['hover', 'group-hover'],
      rotate: ['hover', 'group-hover'],
      scale: ['hover', 'group-hover'],
      zIndex: ['hover', 'group-hover'],
    },
    scrollbar: ['rounded'],
  },
  corePlugins: {
    container: false,
  },
  plugins: [
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/forms'),
    require('@tailwindcss/line-clamp'),
    require('tailwind-bootstrap-grid')({}),
    require('tailwind-scrollbar'),
  ],
};
