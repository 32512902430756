import { useEffect, useState, FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { routes } from '../../../routes';
import { useTargetSelectedCandidatesQuery } from '_queries';
import { Card, Error, Headline, LoadingSpinner, Paragraph } from '_atoms';
import { Alert, ImageGalleryModal } from '_molecules';
import { TargetCandidateBase } from '@indicium/common';
import { GoogleCandidateStatus } from '_types';
import { getCdnUrl } from '_utils';
import { TargetCandidateItem } from '../../targets/TargetCandidates/TargetCandidateItem';
import { TargetGoogleCandidateGroup } from '../../targets/TargetCandidates/TargetGoogleCandidateGroup';

export const Candidates: FC = () => {
  const { t } = useTranslation();
  const { caseId, targetId } =
    useParams<{ caseId: string; targetId: string }>();

  const [googleCandidatesSelection, setGoogleCandidatesSelection] = useState<
    Record<string, GoogleCandidateStatus[]>
  >({});

  const [isGalleryOpened, setIsGalleryOpened] = useState(false);
  const [galleryImages, setGalleryImages] = useState<string[]>([]);

  const {
    isError: candidatesError,
    data: { candidates, googleCandidates } = {
      candidates: [],
      googleCandidates: [],
    },
  } = useTargetSelectedCandidatesQuery(caseId, targetId);

  const hasTradeRegisterData = (() => {
    return candidates?.some(
      (group) =>
        group.sources.includes('orbis') ||
        group.sources.includes('companyhouse'),
    );
  })();

  const hasCandidates = (() => {
    return candidates?.length > 0 || googleCandidates?.length > 0;
  })();

  // initial selection
  useEffect(() => {
    if (googleCandidates?.length) {
      setGoogleCandidatesSelection(
        Object.fromEntries(
          googleCandidates.map(({ groupId, candidates }) => [
            groupId,
            candidates.map((candidate) => ({
              id: candidate.id,
              status: candidate.status,
              imageSelectionStatus: [],
            })),
          ]),
        ),
      );
    }
  }, [googleCandidates]);

  const sortTargetInfo = (info: NonNullable<TargetCandidateBase['info']>) => {
    if (info.length) {
      ['placeOfResidency', 'email', 'phone', 'company'].forEach(
        (item: string) => {
          const position = info.findIndex(({ key }) => key === item);
          if (position !== -1) {
            info.splice(0, 0, ...info.splice(position, 1));
          }
        },
      );
    }
    return info;
  };

  const handleImageGalleryOpen = ({ images }: { images: string[] }) => {
    setGalleryImages(images);
    setIsGalleryOpened(true);
  };

  const handleImageGalleryClose = () => {
    setIsGalleryOpened(false);
  };

  return (
    <div className="container-fluid mb-20 px-8">
      <Fragment>
        <div className="row">
          <div className="col-12">
            <Alert
              alertType="disclamer"
              message={t('inputDataDisclamer')}
              className="mt-4"
            />
            <div className="row mb-6">
              <Headline className="mt-6" Level="h1" color="dark">
                {t('resolveTargetCandidatesHeadline')}
              </Headline>
            </div>

            {candidatesError && (
              <Error
                headline={t('noResultHeadline')}
                message={t('noResultText')}
                target={{
                  link: `${routes.casesList.path}`,
                  text: t('cancelCreateTarget'),
                }}
              />
            )}

            {hasCandidates ? (
              <div>
                <div className="space-y-6">
                  {!hasTradeRegisterData ? (
                    <Card
                      borderColor="border-gray-200"
                      className="border-2 p-5 text-center"
                    >
                      <Paragraph>{t('noTradeRegisterDataText')}</Paragraph>
                    </Card>
                  ) : null}
                  <div className="grid grid-cols-1 4xl:grid-cols-2 gap-5">
                    {candidates.map(
                      ({
                        groupId,
                        images,
                        info = [],
                        name,
                        jobTitles,
                        sources,
                        status,
                      }) => {
                        const sortedInfo = sortTargetInfo(info);
                        return (
                          <TargetCandidateItem
                            key={groupId}
                            id={groupId}
                            images={(images || [])?.map((image) =>
                              getCdnUrl(image),
                            )}
                            name={name}
                            jobTitles={jobTitles}
                            info={sortedInfo.map(({ key, values = [] }) => ({
                              key,
                              values,
                            }))}
                            sources={sources}
                            onStateChange={() => null}
                            onGalleryOpen={handleImageGalleryOpen}
                            status={status}
                            disabled={true}
                          />
                        );
                      },
                    )}
                    {googleCandidates.map(
                      ({ groupId, name, candidates, organizations }) => (
                        <TargetGoogleCandidateGroup
                          key={groupId}
                          groupId={groupId}
                          candidates={candidates}
                          name={name}
                          organizations={organizations}
                          groupSelectionState={
                            googleCandidatesSelection[groupId] || []
                          }
                          updateSelectionState={() => null}
                          disabled={true}
                        />
                      ),
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <LoadingSpinner
                message={<span className="invisible">...</span>}
              />
            )}
          </div>
        </div>
        <ImageGalleryModal
          isOpen={isGalleryOpened}
          onClose={handleImageGalleryClose}
          images={galleryImages}
        />
      </Fragment>
    </div>
  );
};
