import { FC } from 'react';
import classnames from 'classnames';
import { TableData } from '_organisms/Table';
import { Customer } from '@indicium/common';
import { UserDataWithStats } from 'src/types/UserData';
import { formatDate, formatDateTime } from '_utils';
import { TableRowAction, TableRowActionsMenu } from '_organisms/Table';
import { getI18n, useTranslation } from 'react-i18next';

type UserTableProps = {
  headlines: string[];
  users: Customer[] | UserDataWithStats[];
  tableRowActions?: TableRowAction[];
  layoutOptions: {
    highlightColumns: {
      firstColBold: boolean;
      secondColBold: boolean;
    };
    textAlign?: { [k: string]: 'left' | 'center' | 'right' };
  };
  footer?: (number | string | undefined)[];
  editingCustomerUsers?: boolean;
};

const translateItem: {
  [key: string]: boolean;
} = {
  privilegeLevel: true,
};

const isCustomerData = (data: Customer | UserDataWithStats): data is Customer =>
  (data as Customer).name !== undefined;

const prepareUserData = (
  {
    firstname = '',
    lastname = '',
    email,
    privilegeLevel = 'CustomerUser',
    activeAssignedCases,
    activeCreatedTargets,
    totalTargets,
  }: UserDataWithStats,
  editingCustomerUsers: boolean,
) => {
  const { t } = getI18n();
  if (editingCustomerUsers) {
    return {
      name: `${firstname} ${lastname}`,
      email,
      privilegeLevel,
    };
  }
  return {
    name: `${firstname} ${lastname}`,
    email,
    privilegeLevel,
    activeAssignedCases: activeAssignedCases ?? t('notAvailable'),
    activeCreatedTargets: activeCreatedTargets ?? t('notAvailable'),
    totalTargets: totalTargets ?? t('notAvailable'),
  };
};

const prepareCustomerData = ({
  name,
  limitOfReports,
  createdAt,
  reportCount,
  lastReportCreation,
}: Customer) => {
  const { t } = getI18n();
  return {
    name,
    numberOfReports: `${reportCount ?? 0} / ${limitOfReports}`,
    createdAt: formatDate(createdAt),
    lastCreated: lastReportCreation
      ? formatDateTime(lastReportCreation)
      : t('notAvailable'),
  };
};

export const UserTable: FC<UserTableProps> = ({
  headlines,
  users,
  tableRowActions,
  layoutOptions,
  footer,
  editingCustomerUsers = false,
}: UserTableProps) => {
  const { t } = useTranslation();
  const {
    highlightColumns: { firstColBold, secondColBold },
    textAlign,
  } = layoutOptions;

  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      className="w-full p-0 text-white bg-blue-900 rounded-md"
    >
      <thead className="text-white">
        <tr>
          {headlines.map((header, index) => (
            <th
              key={header}
              className={classnames(
                'px-6 py-4 text-sm font-medium leading-4 tracking-wider uppercase',
                textAlign?.[index] ? `text-${textAlign[index]}` : 'text-left',
              )}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <tr
            key={user.id}
            className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
          >
            {Object.entries(
              isCustomerData(user)
                ? prepareCustomerData(user)
                : prepareUserData(user, editingCustomerUsers),
            ).map(([key, item], index) => {
              const highlightColumn =
                (firstColBold && index === 0) || (secondColBold && index === 1);
              return (
                <TableData
                  text={translateItem[key] ? `${t(`${item}`)}` : item}
                  key={index}
                  textWeight={highlightColumn ? 'bold' : 'normal'}
                  textColor={highlightColumn ? 'dark' : 'default'}
                  className={classnames(
                    textAlign?.[index]
                      ? `text-${textAlign[index]}`
                      : 'text-left',
                  )}
                />
              );
            })}
            {tableRowActions ? (
              <TableRowActionsMenu
                actions={tableRowActions}
                id={user.id as string}
              />
            ) : null}
          </tr>
        ))}
      </tbody>
      {footer && footer.length && (
        <tfoot>
          <tr>
            {footer.map((v, i) => (
              <td
                key={`footer-cell-${i}`}
                className={classnames('uppercase', {
                  'px-6 py-5': typeof v !== 'undefined',
                  'text-center': typeof v === 'number',
                })}
              >
                {v}
              </td>
            ))}
          </tr>
        </tfoot>
      )}
    </table>
  );
};
