import { CollapsibleCard } from '_molecules';
import { capitalize } from '_utils';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FinancialDataEmployees,
  FinancialDataStats,
} from 'src/services/searchService';
import { HighlightChunks } from '../../../_atoms/Highlight/types';
import { CompanyCardTable } from './CompanyCardTable';

export type FinancialInfoProps = {
  data: {
    companyGrossProfit?: Array<FinancialDataStats>;
    companyOperatingRevenue?: Array<FinancialDataStats>;
    companyEmployeesData?: Array<FinancialDataEmployees>;
  };
  highlight?: HighlightChunks;
};

export const FinancialInfo: FC<FinancialInfoProps> = ({ data, highlight }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const locale = i18n.language === 'de' ? 'de-DE' : 'en-US';

  const getFinancialData = useCallback(() => {
    const financialData: any[] = [];

    const yearNow = new Date().getFullYear();

    const findMatchingFiscalYear = (fiscalYear: string, year: number) => {
      if (!fiscalYear && year === yearNow) return true;
      const fiscalDate = new Date(fiscalYear);
      return year === fiscalDate.getFullYear();
    };

    for (let i = yearNow; i > yearNow - 5; --i) {
      // get employee count per year - companyEmployeeData
      const employeesData = (data.companyEmployeesData || []).filter((ed) =>
        findMatchingFiscalYear(ed.fiscalYear, i),
      );
      // get operating revenue per year - companyOperatingRevenue
      const operatingRevenueData = (data.companyOperatingRevenue || []).filter(
        (or) => findMatchingFiscalYear(or.fiscalYear, i),
      );
      // get gross profit per year - companyGrossProfit
      const grossProfitData = (data.companyGrossProfit || []).filter((gp) =>
        findMatchingFiscalYear(gp.fiscalYear, i),
      );

      const turnover = operatingRevenueData
        .map((ord) => {
          if (ord.revenueTo) {
            return `${ord.revenueTo.toLocaleString(locale, {
              style: 'currency',
              currency: 'EUR',
            })} (${capitalize(ord.sources.toString())})`;
          }
        })
        .join(' / ');

      const profit = grossProfitData
        .map((gpd) => {
          if (gpd.revenueTo) {
            return `${gpd.revenueTo.toLocaleString(locale, {
              style: 'currency',
              currency: 'EUR',
            })} (${capitalize(gpd.sources.toString())})`;
          }
        })
        .join(' / ');

      const employees = employeesData
        .map((ed) => {
          if (ed.numberOfEmployees) {
            return `${ed.numberOfEmployees} (${capitalize(
              ed.sources.toString(),
            )})`;
          }
        })
        .join(' / ');

      financialData.push({
        year: i.toString(),
        turnover,
        profit,
        employees,
      });
    }

    return financialData;
  }, [data, locale]);

  const columns = [
    {
      key: 'year',
      label: t('companyCard.financials'),
    },
    {
      key: 'turnover',
      label: t('companyCard.turnover'),
    },
    {
      key: 'profit',
      label: t('companyCard.profit'),
    },
    {
      key: 'employees',
      label: t('companyCard.employees'),
    },
  ];

  return (
    <CollapsibleCard
      className="py-4"
      borderColor="border-transparent"
      header={
        <div className="flex flex-col gap-2">
          <CompanyCardTable
            columns={columns}
            data={getFinancialData()}
            highlight={highlight}
          />
        </div>
      }
      initiallyExpanded={false}
    />
  );
};
