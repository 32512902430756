import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { MdAddCircleOutline } from 'react-icons/md';
import { Button, Headline, LoadingSpinner } from '_atoms';
import { UserTable } from '_organisms/UserTable';
import { routes } from '../../../routes';
import { useCustomersQuery, useDeleteCustomerMutation } from '_queries';
import { useDeleteMutation } from '../../../hooks/useDeleteMutation';
import { useUserState } from '../../../context/User';
import { ConfirmationModal } from '_molecules';
import { USER_GROUP_SUPER_USERS } from '../../../services/authenticationService';

export const CustomerAdmin: FC = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const [{ userRoles }] = useUserState();

  const { data: customers, ...customersQuery } = useCustomersQuery();
  const deleteCustomerMutation = useDeleteCustomerMutation();

  const [{ enableConfirm, showModal }, dispatch] = useDeleteMutation();

  const tableRowActions = userRoles.includes(USER_GROUP_SUPER_USERS)
    ? [
        {
          id: 'deleteCustomer',
          label: t('deleteCustomer.action'),
          callback: (customerId: string) => {
            dispatch({ type: 'SET_DELETE_PARAMS', params: { customerId } });
          },
        },
        {
          id: 'editCustomerAction',
          label: t('editCustomerAction'),
          callback: (customerId: string) => {
            history.push(
              generatePath(routes.customerAdminUpdate.path, { customerId }),
            );
          },
        },
      ]
    : undefined;

  const handleModalEvent = (event: 'confirm' | 'cancel') => {
    switch (event) {
      case 'confirm':
        dispatch({
          type: 'CONFIRM_DELETION',
          mutation: deleteCustomerMutation.mutate,
        });
        break;
      case 'cancel':
        dispatch({ type: 'RESET' });
        break;
    }
  };

  return (
    <Fragment>
      <div className="container-fluid mb-20 px-16">
        <div className="row">
          <Headline Level="h1" color="dark" className="my-8" weight="bold">
            {t('overviewOfAllCustomers')}
          </Headline>
        </div>

        <div className="row">
          <div className="mb-8">
            <Link to={routes.customerAdminNew.path}>
              <Button className="pl-4">
                <span className="pr-3.5">
                  <MdAddCircleOutline />
                </span>
                {t('createNewCustomer')}
              </Button>
            </Link>
          </div>
        </div>

        <div>
          {/* TODO: this must not be a UserTable we need the Table to be way more generic */}
          <UserTable
            headlines={[
              t('customerName'),
              t('numberOfReports'),
              t('createdAt'),
              t('lastReportCreatedAt'),
            ]}
            users={customers || []}
            layoutOptions={{
              highlightColumns: {
                firstColBold: true,
                secondColBold: true,
              },
              textAlign: { 1: 'center', 2: 'center', 3: 'center' },
            }}
            tableRowActions={tableRowActions}
          />
        </div>

        <ConfirmationModal
          body={t('deleteCustomer.modalText')}
          cancelButtonText={t('deleteCustomer.cancelButton')}
          confirmButtonText={t('deleteCustomer.confirmButton')}
          enableConfirm={enableConfirm}
          isOpen={showModal}
          radioOptions={{
            options: t('deleteCustomer.radioOptions', {
              returnObjects: true,
            }),
            handleSelect: () => dispatch({ type: 'ENABLE_CONFIRM_BUTTON' }),
          }}
          title={t('deleteCustomer.modalTitle')}
          handleButtonClick={handleModalEvent}
        />

        {customersQuery.isLoading && <LoadingSpinner />}
      </div>
    </Fragment>
  );
};
