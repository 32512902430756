import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { CaseData } from '../../services/dataService';

export const useDeleteCaseMutation = (): UseMutationResult<
  CaseData,
  unknown,
  Record<string, string>
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ caseId }) => axios.delete(`https://${backendBaseUrl}/cases/${caseId}`),
    {
      onSuccess: () => queryClient.invalidateQueries('cases'),
    },
  );
};
