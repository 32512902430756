import classNames from 'classnames';
import { FC } from 'react';
import { Paragraph } from '_atoms';

interface TagProps {
  className?: string;
}

export const Tag: FC<TagProps> = ({ children, className }) => (
  <Paragraph
    size="default"
    weight="semibold"
    color="dark"
    className={classNames(
      'bg-gray-100 px-3 py-0.5 rounded-xl inline',
      className,
    )}
  >
    {children}
  </Paragraph>
);
