import { FC, ReactElement, useState } from 'react';
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from 'react-icons/md';
import { Card, ColoredBar } from '_atoms';
import classnames from 'classnames';
import classNames from 'classnames';
import { nonProdDataTestId } from '_utils';

export type CollapsibleCardProps = {
  initiallyExpanded: boolean;
  toggleEnabled?: boolean;
  header: ReactElement;
  children?: ReactElement | ReactElement[];
  headerColor?: string;
  borderColor?: string;
  headerBorder?: string;
  className?: string;
  bodyClasses?: string;
  id?: string;
  additionalHtmlAttributes?: Record<string, string | undefined>;
  expandedState?: boolean;
  onToggleChange?: (isExpanded: boolean) => void;
};

export const CollapsibleCard: FC<CollapsibleCardProps> = ({
  id,
  header,
  children,
  initiallyExpanded,
  toggleEnabled = true,
  headerColor = 'bg-white',
  borderColor = 'border-blue-400',
  headerBorder = '',
  className = '',
  bodyClasses = '',
  additionalHtmlAttributes,
  expandedState,
  onToggleChange,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(initiallyExpanded);

  const currentState =
    toggleEnabled && expandedState !== undefined ? expandedState : isExpanded;

  const toggleExpandState = () => {
    if (toggleEnabled) {
      if (onToggleChange !== undefined) {
        onToggleChange(!currentState);
        return;
      }
      setIsExpanded((prevState) => !prevState);
    }
  };

  return (
    <Card
      id={id}
      borderColor={borderColor}
      className={className}
      additionalHtmlAttributes={additionalHtmlAttributes}
    >
      <ColoredBar background={headerColor} border={headerBorder}>
        <div
          className={
            'flex w-full p-4' + (toggleEnabled ? ' cursor-pointer' : '')
          }
          onClick={toggleExpandState}
        >
          <div className="w-full">{header}</div>
          <span
            className={classNames(
              'flex items-center w-fit ml-5 cursor-pointer',
              !toggleEnabled ? 'text-gray-300' : null,
            )}
            data-testid={nonProdDataTestId('expand card')}
          >
            {children &&
              (currentState ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              ))}
          </span>
        </div>
      </ColoredBar>
      {currentState && (
        <div
          className={classnames('card-body p-4 pr-14', bodyClasses)}
          data-testid={nonProdDataTestId('expanded card contents')}
        >
          {children}
        </div>
      )}
    </Card>
  );
};
