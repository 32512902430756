import { useEffect, useState, FC, Fragment } from 'react';
import { generatePath, Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Headline, LoadingSpinner } from '_atoms';
import { Table } from '_organisms/Table';
import { routes } from '../../../routes';
import {
  useCaseAgentsQuery,
  useCaseQuery,
  useUpdateCaseAgentsMutation,
  useUsersQuery,
} from '../../../hooks/queries';

export const AssignCaseAgents: FC = () => {
  const history = useHistory();
  const { caseId } = useParams<{ caseId: string }>();
  const { t } = useTranslation();

  const { data: caseData, ...caseQuery } = useCaseQuery(caseId);
  const { data: caseAgentsData = [], ...caseAgentsQuery } =
    useCaseAgentsQuery(caseId);
  const { data: usersData = [], ...usersQuery } = useUsersQuery();
  const updateCaseAgentsMutation = useUpdateCaseAgentsMutation();

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  const handleSubmit = () => {
    updateCaseAgentsMutation.mutate({ caseId, userIds: selectedUserIds ?? [] });
  };

  useEffect(() => {
    setSelectedUserIds(caseAgentsData.map(({ userId }) => userId));
  }, [caseAgentsData]);

  if (caseAgentsQuery.isError) {
    history.push(generatePath(routes.caseShow.path, { caseId }), {
      error: t('caseAgentsAssignError'),
    });
  }

  if (updateCaseAgentsMutation.isSuccess) {
    history.push(generatePath(routes.caseShow.path, { caseId }), {
      success: t('caseAgentsUpdated'),
    });
  }

  if (caseQuery.isLoading) {
    return (
      <div className="mt-20">
        <LoadingSpinner message={t('profileLoading')} />
      </div>
    );
  }

  return (
    <Fragment>
      <div className="container-fluid mb-20 px-16">
        <Headline Level="h1" color="dark" className="mt-8 mb-12" weight="bold">
          {caseData?.title}
        </Headline>

        <div className="mb-6 rounded-md bg-white pt-5 px-6 pb-9">
          <Headline Level="h4" color="dark" className="mb-5" weight="bold">
            {t('assignUserToCase')}
          </Headline>
          <div className="border border-gray-200 rounded-md">
            <Table
              headlines={[t('name'), t('email')]}
              items={usersData.map(({ id, firstname, lastname, email }) => ({
                id,
                name: `${firstname} ${lastname}`,
                email,
              }))}
              layoutOptions={{
                highlightColumns: {
                  firstColBold: false,
                  secondColBold: false,
                },
              }}
              additions={{
                checkbox: true,
              }}
              selectedItems={selectedUserIds}
              setSelectedItems={setSelectedUserIds}
            />
            {usersQuery.isLoading && <LoadingSpinner />}
          </div>
        </div>

        <div className="flex justify-end space-x-6">
          <div className="col-4">
            <Link to={generatePath(routes.caseShow.path, { caseId })}>
              <Button level="secondary" className="w-full">
                {t('back')}
              </Button>
            </Link>
          </div>

          <div className="col-4">
            <Button
              disabled={updateCaseAgentsMutation.isLoading}
              level="primary"
              className="w-full"
              onClick={handleSubmit}
            >
              {t('save')}
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
