import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '_atoms';
import { SliderVertical } from '_organisms';
import '../../../i18n';

type ProfileImageProps = {
  images: string[];
};

export const ProfileImage: FC<ProfileImageProps> = ({ images }) => {
  const { t } = useTranslation();

  if (images.length === 0) {
    return (
      <Image
        aspectRatio="portrait"
        alt={t('fallbackProfileImage')}
        src={`${process.env.PUBLIC_URL}/target-person-fallback-image.png`}
      />
    );
  }

  if (images.length === 1) {
    return (
      <Image
        aspectRatio="portrait"
        alt={t('fallbackProfileImage')}
        src={images[0]}
      />
    );
  }

  return <SliderVertical images={images} />;
};
