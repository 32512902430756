import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { UserInputTarget } from '@indicium/common';
import { backendBaseUrl } from '../../backendConfig.json';
import { Target } from '../../services/dataService';
import { getSignedInUser } from '../../services/authenticationService';
import moment from 'moment';

type Payload = {
  caseId: string;
  data: UserInputTarget;
};

const convertToDateRange = (date: string): Record<'start' | 'end', string> => {
  if (date.length == 4)
    return {
      start: `${date}-01-01`,
      end: `${date}-12-31`,
    };
  const formatedDate = moment(date, 'DD.MM.YYYY', true).format('YYYY-MM-DD');
  return {
    start: formatedDate,
    end: formatedDate,
  };
};

const createTarget = async ({ caseId, data }: Payload): Promise<Target> => {
  const user = await getSignedInUser();
  const payload = Object.assign({}, data, {
    dateOfBirth: data.dateOfBirth
      ? convertToDateRange(data.dateOfBirth)
      : undefined,
    currentUserId: user.attributes.sub,
  });
  const response = await axios.post(
    `https://${backendBaseUrl}/v2/cases/${caseId}/targets`,
    payload,
  );
  return response.data;
};

export const useCreateTargetMutation = (): UseMutationResult<
  Target,
  unknown,
  Payload
> => {
  const queryClient = useQueryClient();

  return useMutation(createTarget, {
    onSuccess: () => queryClient.invalidateQueries('targets'),
  });
};
