import axios from 'axios';
import {
  Report,
  Suspicion,
  SuspicionItem,
  EntityBySuspicion,
  SuspicionItemEntity,
  TargetCandidatesGroup,
  TargetGoogleCandidatesGroup,
  SummaryReport,
} from '_types';
import {
  Customer,
  UserInputForCreateCustomer,
  UserInputForUpdateCustomer,
  UserInputTarget,
} from '@indicium/common';
import { SuspicionItemStatus } from '_enums';
import config from '../backendConfig.json';
import { getAccessToken } from './authenticationService';
import {
  ReportFilters,
  ReportFiltersWithPaging,
} from '../features/dashboard/Overview/ReportCard';
import {
  CustomerUsageResponse,
  Paginated,
  ResourceDeleteResponse,
  Response,
  TargetImage,
} from 'src/types/NestAPI';
import { CaseData, Paging } from './dataService';

const client = axios.create({
  baseURL: 'https://' + config.backendBaseUrl + '/api',
});

client.interceptors.request.use(async (config) => {
  try {
    const accessToken = await getAccessToken();
    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken;
    }
  } catch (e) {
    console.error('[nestApiService] error while fetching access token:', e);
  }
  return config;
}, console.error);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('[nestApiService] error:', error);
    return Promise.reject(error);
  },
);

const targets = {
  addSuspicion: async (
    targetId: string,
    suspicionId: string,
    entity: SuspicionItemEntity,
  ): Promise<void> =>
    client.post(`targets/${targetId}/suspicions`, {
      entity,
      items: [{ suspicionId, score: 0 }],
    }),
  getSummaryReports: async (targetId: string): Promise<SummaryReport[]> =>
    client.get(`targets/${targetId}/summary/v2`).then((r) => r.data.data),
  getImages: async (targetId: string): Promise<Response<TargetImage[]>> =>
    client.get(`targets/${targetId}/images`).then((r) => r.data),
  getInputData: async (
    caseId: string,
    targetId: string,
  ): Promise<Response<UserInputTarget>> =>
    client.get(`cases/${caseId}/targets/${targetId}/input`).then((r) => r.data),
  getTargetSelectedCandidates: async (
    caseId: string,
    targetId: string,
  ): Promise<
    Response<{
      candidates: TargetCandidatesGroup[];
      googleCandidates: TargetGoogleCandidatesGroup[];
    }>
  > =>
    client
      .get(`cases/${caseId}/targets/${targetId}/candidates`)
      .then((r) => r.data),
};

const reports = {
  list: async (): Promise<Report> =>
    client
      .get('reports')
      .then((r) =>
        r.data.data.sort((report1: Report, report2: Report) =>
          report1.name.localeCompare(report2.name),
        ),
      ),
};

const suspicions = {
  list: async (reportLabel?: string, caseId?: string): Promise<Suspicion> => {
    const params: Record<string, string | string[]> = {
      limit: '100',
    };
    if (reportLabel) params['filter.report.label'] = reportLabel;
    if (caseId) params['filter.caseId'] = caseId;

    return client
      .get('suspicions', {
        params,
      })
      .then((r) =>
        r.data.data.sort((suspicion1: Suspicion, suspicion2: Suspicion) =>
          suspicion1.name.localeCompare(suspicion2.name),
        ),
      );
  },
  listFiltered: async (
    targetId: string,
    reportId: string,
    filters?: ReportFilters,
  ): Promise<Suspicion[]> =>
    client
      .get(`targets/${targetId}/reports/${reportId}/suspicions/v2`, {
        params: {
          ...(filters?.suspicionItemId
            ? { suspicionId: filters.suspicionItemId }
            : {}),
          ...(filters?.score ? { score: filters.score } : {}),
          ...(filters?.entityType ? { entityType: filters.entityType } : {}),
          ...(filters?.status ? { status: filters.status } : {}),
        },
      })
      .then((r) => r.data.data),
  entities: {
    list: async (
      targetId: string,
      reportId: string,
      filters: ReportFiltersWithPaging,
    ): Promise<{ entities: SuspicionItemEntity[]; page: number }> =>
      client
        .get(`targets/${targetId}/reports/${reportId}/entities/v2`, {
          params: {
            ...(filters?.suspicionItemId
              ? { suspicionId: filters.suspicionItemId }
              : {}),
            ...(filters?.score ? { score: filters.score } : {}),
            ...(filters?.entityType ? { entityType: filters.entityType } : {}),
            ...(filters?.status ? { status: filters.status } : {}),
            ...{ page: filters.page, pageSize: filters.pageSize },
          },
        })
        .then((r) => r.data.data),
    patch: async (
      targetId: string,
      reportId: string,
      suspicionIds: string[],
      entity: SuspicionItemEntity,
    ): Promise<any> =>
      client
        .patch(`suspicions/entities/${entity.id}`, {
          targetId,
          suspicionIds,
          reportId,
          entity,
        })
        .then((r) => r.data.data),
  },
  items: {
    list: async (
      targetId: string,
      reportId: string,
      filters?: ReportFilters,
    ): Promise<SuspicionItem> =>
      client
        .get(`targets/${targetId}/reports/${reportId}/items`, {
          params: {
            ...(filters?.suspicionItemId
              ? { suspicionId: filters.suspicionItemId }
              : {}),
            ...(filters?.score ? { score: filters.score } : {}),
            ...(filters?.entityType ? { entityType: filters.entityType } : {}),
            ...(filters?.status ? { status: filters.status } : {}),
          },
        })
        .then((r) => r.data.data),
    listBySuspicion: async (
      targetId: string,
      suspicionId: string,
      filters: ReportFiltersWithPaging,
    ): Promise<{ entities: EntityBySuspicion[]; paging: Paging }> =>
      client
        .get(`targets/${targetId}/suspicions/${suspicionId}/itemsPaginated`, {
          params: {
            ...(filters?.suspicionItemId
              ? { suspicionId: filters.suspicionItemId }
              : {}),
            ...(filters?.score ? { score: filters.score } : {}),
            ...(filters?.entityType ? { entityType: filters.entityType } : {}),
            ...(filters?.status ? { status: filters.status } : {}),
            ...{ page: filters.page, pageSize: filters.pageSize },
          },
        })
        .then((r) => r.data.data),
    entities: {
      find: async (
        entityId: string,
      ): Promise<SuspicionItemEntity & { suspicions: Suspicion[] }> =>
        client
          .get(`suspicions/items/entities/${entityId}`)
          .then((r) => r.data.data),
    },
    create: async (
      targetId: string,
      suspicionId: string,
      entity: SuspicionItemEntity,
      status = SuspicionItemStatus.Problem,
    ): Promise<SuspicionItem> =>
      client.post(`suspicions/items`, {
        entity,
        targetId,
        suspicionId,
        status,
      }),
    update: async (
      itemId: string,
      status: SuspicionItemStatus,
    ): Promise<void> => client.patch(`suspicions/items/${itemId}`, { status }),
  },
};

const customers = {
  findAll: async (): Promise<Paginated<Customer>> =>
    client.get('customers').then((r) => r.data),

  findOne: async (customerId: string): Promise<Response<Customer>> =>
    client.get(`customers/${customerId}`).then((r) => r.data),

  create: async (
    payload: UserInputForCreateCustomer,
  ): Promise<Response<Customer>> =>
    client.post('customers', payload).then((r) => r.data),

  update: async (
    customerId: string,
    payload: UserInputForUpdateCustomer,
  ): Promise<Response<Customer>> =>
    client.patch(`customers/${customerId}`, payload).then((r) => r.data),
  delete: async (
    customerId: string,
  ): Promise<Response<ResourceDeleteResponse>> =>
    client.delete(`customers/${customerId}`).then((r) => r.data),

  usage: async (customerId: string): Promise<Response<CustomerUsageResponse>> =>
    client.get(`customers/${customerId}/usage`).then((r) => r.data),
};

const cases = {
  findOne: async (caseId: string): Promise<Response<CaseData>> =>
    client.get(`cases/${caseId}`).then((r) => r.data),

  getKeywords: async (caseId: string): Promise<Response<any>> =>
    client.get(`cases/${caseId}/keywords`).then((r) => r.data),
};

const app = {
  version: async (): Promise<any> => client.get('version').then((r) => r.data),
};

export { app, targets, reports, suspicions, customers, cases };
