import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CollapsibleCard } from '_molecules';
import { CompanyCardTable } from './CompanyCardTable';
import { HighlightChunks } from '../../../_atoms/Highlight/types';

export type SubsidiaryCompanyProps = {
  data: Array<Record<string, string | string[]>>;
  highlight?: HighlightChunks;
};

export const SubsidiaryCompany: FC<SubsidiaryCompanyProps> = ({
  data,
  highlight,
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      key: 'name',
      label: t('companyCard.subsidiaryName'),
      props: {
        className: 'w-1/4',
      },
    },
    {
      key: 'informationDate',
      label: t('companyCard.informationDate'),
    },
    {
      key: 'country',
      label: t('companyCard.country'),
    },
    {
      key: 'directPercent',
      label: t('companyCard.directlyOwned'),
      render: (value: string) =>
        value !== 'WO' ? t('intlNumber', { val: value }) + '%' : value,
    },
    {
      key: 'totalPercent',
      label: t('companyCard.ultimatelyOwned'),
      render: (value: string) =>
        value !== 'WO' ? t('intlNumber', { val: value }) + '%' : value,
    },
  ];

  return (
    <CollapsibleCard
      className="py-4"
      borderColor="border-transparent"
      header={
        <div className="flex flex-col gap-2">
          <CompanyCardTable
            data={data}
            highlight={highlight}
            columns={columns}
          />
        </div>
      }
      initiallyExpanded={false}
    />
  );
};
