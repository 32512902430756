import { FC, useEffect, useState, useCallback } from 'react';
import {
  generatePath,
  Redirect,
  useParams,
  useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserInputTarget } from '@indicium/common';
import { Headline, LoadingSpinner } from '_atoms';
import {
  useCaseQuery,
  useCreateTargetMutation,
  useDeleteTargetMutationV2 as useDeleteTargetMutation,
  useTargetInputQuery,
} from '../../../hooks/queries';
import { sanitizeInput } from '@indicium/common/src/utils';
import { CaseToProfileBreadcrumbs } from '../../../components/Layout/CaseToProfileBreadcrumbs';
import { useMutationErrors } from '../../../hooks/useMutationErrors';
import { useUserQuota } from '../../../hooks/useUserQuota';
import { TargetForm } from '../TargetForm';
import { routes } from '../../../routes';
import { TargetFormSchema } from '../../../schemas/targetFormSchema';

const changeTarget = async (
  // eslint-disable-next-line
  deleteTarget: any,
  // eslint-disable-next-line
  createTarget: any,
  caseId: string,
  targetId: string,
  // eslint-disable-next-line
  data: any,
) => {
  await deleteTarget({ caseId, targetId });
  createTarget({ caseId, data });
};

export const TargetNew: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { caseId } = useParams<{ caseId: string }>();

  const oldTargetId = localStorage.getItem('oldTargetId');
  const oldTarget = useTargetInputQuery(caseId, oldTargetId || '');
  const [oldTargetLoaded, setOldTargetLoaded] = useState('');

  const { data: target, ...createTargetMutation } = useCreateTargetMutation();

  const { errors: apiErrors } = useMutationErrors({
    mutations: { createTargetMutation },
  });

  const {
    isLoading: isDeleteTargetLoading,
    isError: _isDeleteTargetError,
    ...deleteTargetMutation
  } = useDeleteTargetMutation();

  const { quota } = useUserQuota();

  useEffect(() => {
    if (quota && quota.remaining <= 0) {
      history.push(generatePath(routes.caseShow.path, { caseId }));
    }
  }, [quota, caseId, history]);

  const caseQuery = useCaseQuery(caseId);
  const caseName = caseQuery.data?.title;

  useEffect(() => {
    if (oldTarget.data) {
      setOldTargetLoaded(localStorage.getItem('oldTargetId') || '');
      localStorage.removeItem('oldTargetId');
    }
  }, [oldTarget]);

  const handleSubmit = useCallback(
    // TODO: IND-945 Fix type issues and set type of values to Yup.TypeOf<typeof UserInputTargetSchema>
    async (values: unknown) => {
      // TODO: IND-945 Remove the cast. Related to "Make this a Yup.SchemaOf<UserInputTargetSchema>" todo in UserInputTargetSchema.ts
      const data = sanitizeInput<UserInputTarget>(
        (await TargetFormSchema.validate(values)) as UserInputTarget,
      );

      if (data) {
        if (oldTargetLoaded) {
          if (JSON.stringify(values) === JSON.stringify(oldTarget.data)) {
            // skip create target and navigate to target candidates
            history.push(
              generatePath(routes.targetShow.path, {
                caseId,
                targetId: oldTargetLoaded,
              }),
            );
          } else {
            changeTarget(
              deleteTargetMutation.mutateAsync,
              createTargetMutation.mutate,
              caseId,
              oldTargetLoaded,
              data,
            );
          }
        } else {
          createTargetMutation.mutate({ caseId, data });
        }
      }
    },
    [
      oldTarget.data,
      oldTargetLoaded,
      caseId,
      createTargetMutation,
      deleteTargetMutation,
      history,
    ],
  );

  if (createTargetMutation.isSuccess) {
    return (
      <Redirect
        to={generatePath(routes.targetShow.path, {
          caseId,
          targetId: target?.id,
        })}
      />
    );
  }

  if (createTargetMutation.isError) {
    console.error('error in createTarget: ', createTargetMutation.error);
  }

  return (
    <div className="container-fluid mb-20 px-16">
      <CaseToProfileBreadcrumbs level={2} caseName={caseName} />

      <div className="row">
        <Headline Level="h1" className="my-6" color="dark">
          {t('targetPersonDataHeadline')}
        </Headline>
      </div>
      <TargetForm
        targetInput={oldTarget.data}
        onSubmit={handleSubmit}
        isSubmitting={createTargetMutation.isLoading}
        apiErrors={apiErrors}
        showBackButton
      />
      {isDeleteTargetLoading && (
        <div className="z-20 inset-0 absolute bg-black bg-opacity-50 flex justify-center items-center text-gray-400 pointer-events-none">
          <LoadingSpinner message={<span className="invisible">...</span>} />
        </div>
      )}
    </div>
  );
};
