import classNames from 'classnames';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionType } from 'src/components/_atoms/TileRadioButton/TileRadioButton';
import { Headline, Paragraph, Card, RadioGroup, Checkbox } from '_atoms';
import { TileRadioGroup, KeywordsInput } from '_molecules';

const KEYWORD_TYPE_OPTIONS = ['noun', 'verb', 'adjective', 'phrase'];
const options: Array<OptionType> = ['option1', 'option2', 'option3'];

const onKeywordChange = (
  newKeyword: {
    value: string;
    engTranslations?: Array<string>;
  },
  index: number,
  keywordsOption: string,
  setOption: any,
) => {
  setOption((option: any) => ({
    ...option,
    props: {
      ...option.props,
      [keywordsOption]: option.props[keywordsOption].map((kw: any, j: number) =>
        j === index ? { ...kw, ...newKeyword } : kw,
      ),
    },
  }));
};

interface CaseNewStepTwoProps {
  option: { value: string; props: Record<string, any> };
  setOption: any;
  readOnly?: boolean;
}

export const CaseNewStepTwo: FC<CaseNewStepTwoProps> = ({
  option,
  setOption,
  readOnly = false,
}: CaseNewStepTwoProps) => {
  const { t } = useTranslation();
  const [hoverTitle, setHoverTitle] = useState<boolean>(false);

  const onAddKeyword = useCallback(
    (keywordsOption: 'op2keywords' | 'op3keywords') =>
      (keyword = '') =>
        setOption((option: any) =>
          option.props[keywordsOption].length < 5
            ? {
                ...option,
                props: {
                  ...option.props,
                  [keywordsOption]: [
                    ...option.props[keywordsOption],
                    { value: keyword },
                  ],
                },
              }
            : option,
        ),
    [setOption],
  );

  const onOption2KeywordChange = useCallback(
    (
      newKeyword: {
        value: string;
        engTranslations?: Array<string>;
      },
      index: number,
    ) => {
      onKeywordChange(newKeyword, index, 'op2keywords', setOption);
    },
    [setOption],
  );

  const onOption3KeywordChange = useCallback(
    (
      newKeyword: {
        value: string;
        engTranslations?: Array<string>;
      },
      index: number,
    ) => {
      onKeywordChange(newKeyword, index, 'op3keywords', setOption);
    },
    [setOption],
  );

  return (
    <>
      <div className="mb-10">
        <Headline
          Level="h2"
          color="dark"
          weight="semibold"
          className="mt-10 mb-5 relative"
        >
          <span
            onMouseEnter={() => setHoverTitle(true)}
            onMouseLeave={() => setHoverTitle(false)}
          >
            {t('caseNew.selectOption')} <span className="text-blue-400">*</span>
          </span>
          <Card
            borderColor="border-white"
            backgroundColor="bg-white"
            className={classNames(
              'py-4 px-6 absolute top-10 left-4 w-4/5 filter drop-shadow-lg',
              hoverTitle ? '' : 'hidden',
            )}
          >
            <Headline
              Level="h3"
              weight="bold"
              color="dark"
              className="mt-2 mb-3"
            >
              {t('caseNew.reasonForTheAnalysisPopup.title')}
            </Headline>

            <Paragraph weight="normal" color="default" className="mb-2">
              {t('caseNew.reasonForTheAnalysisPopup.content')}{' '}
              <span className="text-blue-400">
                {t('caseNew.reasonForTheAnalysisPopup.contentEmail')}
              </span>
            </Paragraph>
          </Card>
        </Headline>

        <TileRadioGroup
          className="mb-6"
          value={option.value}
          setValue={(value: string) =>
            setOption(
              (option: { value: string; props: Record<string, boolean> }) => ({
                ...option,
                value,
                ...(value === 'option3'
                  ? {
                      props: {
                        ...option.props,
                        op3keywords: option.props?.op3keywords || [
                          { value: '' },
                        ],
                      },
                    }
                  : {}),
              }),
            )
          }
          tileValues={options}
          tileTexts={options.reduce(
            (acc, option) => ({
              ...acc,
              [option]: {
                title: t(`caseNew.title.${option}`),
                descriptionItems: [
                  t(`caseNew.${option}Description1`),
                  t(`caseNew.${option}Description2`),
                ],
              },
            }),
            {},
          )}
          disabled={readOnly}
        />
        {option.value === 'option1' && (
          <>
            <Paragraph weight="normal" color="default" className="mb-6">
              {t('caseNew.option1Asterisk')}
            </Paragraph>
            <Card
              borderColor="border-white"
              backgroundColor="bg-white"
              className="py-4 px-6"
            >
              <Headline
                Level="h3"
                weight="bold"
                color="dark"
                className="mt-5 mb-3"
              >
                {t('caseNew.backgroundCheckTitle')}
              </Headline>

              <Paragraph weight="normal" color="default" className="mb-10">
                {t('caseNew.backgroundCheckDescription1')}
              </Paragraph>
              <Headline Level="h3" weight="bold" color="dark">
                {t('caseNew.targetPersonInformationTitle')}
              </Headline>
              <RadioGroup
                clasName="mt-2"
                options={['yes', 'no'].map((label) => ({
                  title: t(label),
                  label: t(`caseNew.targetPersonInformation.${label}`),
                  value: label,
                }))}
                preselected={option.props.backgroundCheck}
                onSelect={(value: string) =>
                  setOption((option: any) => ({
                    ...option,
                    props: {
                      ...option.props,
                      backgroundCheck: value,
                    },
                  }))
                }
                disabled={readOnly}
              />
            </Card>
          </>
        )}
        {option.value === 'option2' && (
          <>
            <Paragraph weight="normal" color="default" className="mb-6">
              {t('caseNew.option2Asterisk')}
            </Paragraph>
            <Card
              borderColor="border-white"
              backgroundColor="bg-white"
              className="py-4 px-6"
            >
              <Headline Level="h3" weight="bold" color="dark">
                {t('caseNew.osintCheckWithConsent')}
              </Headline>
              <Checkbox
                onChange={(value: boolean) =>
                  setOption((option: any) => ({
                    ...option,
                    props: {
                      ...option.props,
                      publicInfoDesc: value,
                    },
                  }))
                }
                label={t('caseNew.publicInfoDesc')}
                initialIsSelected={option.props.publicInfoDesc}
                centered={false}
                labelPosition="append"
                className="my-2"
                disabled={readOnly}
              />
              <Checkbox
                onChange={(value: boolean) =>
                  setOption((option: any) => ({
                    ...option,
                    props: {
                      ...option.props,
                      privateSocialMedia: value,
                    },
                  }))
                }
                label={t('caseNew.privateSocialMedia')}
                initialIsSelected={option.props.privateSocialMedia}
                centered={false}
                labelPosition="append"
                className="my-2"
                disabled={readOnly}
              />
              <Checkbox
                onChange={(value: boolean) =>
                  setOption((option: any) => ({
                    ...option,
                    props: {
                      ...option.props,
                      creditRating: value,
                    },
                  }))
                }
                label={t('caseNew.creditRating')}
                initialIsSelected={option.props.creditRating}
                centered={false}
                labelPosition="append"
                disabled={readOnly || true}
                className="my-2 hidden"
              />
              <Checkbox
                onChange={(value: boolean) =>
                  setOption((option: any) => ({
                    ...option,
                    props: {
                      ...option.props,
                      customSearchTerms: value,
                      op2keywords: option.props.op2keywords || [{ value: '' }],
                    },
                  }))
                }
                label={t('caseNew.customSearchTerms')}
                initialIsSelected={option.props.customSearchTerms}
                centered={false}
                labelPosition="append"
                className="my-2"
                disabled={readOnly}
              />
            </Card>
            {option.props.customSearchTerms ? (
              <KeywordsInput
                margins="mt-6"
                keywords={option.props?.op2keywords}
                options={KEYWORD_TYPE_OPTIONS}
                onChangeValue={onOption2KeywordChange}
                onAddKeyword={onAddKeyword('op2keywords')}
                onRemoveKeyword={(index: number) =>
                  setOption((option: any) =>
                    option.props.op2keywords.length > 1
                      ? {
                          ...option,
                          props: {
                            ...option.props,
                            op2keywords: [
                              ...option.props.op2keywords.slice(0, index),
                              ...option.props.op2keywords.slice(index + 1),
                            ],
                          },
                        }
                      : option,
                  )
                }
                readOnly={readOnly}
              />
            ) : null}
          </>
        )}
        {option.value === 'option3' && (
          <>
            <Paragraph weight="normal" color="default" className="mb-6">
              {t('caseNew.option3Asterisk')}
            </Paragraph>
            <KeywordsInput
              keywords={option.props?.op3keywords}
              options={KEYWORD_TYPE_OPTIONS}
              onChangeValue={onOption3KeywordChange}
              onAddKeyword={onAddKeyword('op3keywords')}
              onRemoveKeyword={(index: number) =>
                setOption((option: any) =>
                  option.props.op3keywords.length > 1
                    ? {
                        ...option,
                        props: {
                          ...option.props,
                          op3keywords: [
                            ...option.props.op3keywords.slice(0, index),
                            ...option.props.op3keywords.slice(index + 1),
                          ],
                        },
                      }
                    : option,
                )
              }
              readOnly={readOnly}
            />
          </>
        )}
      </div>
    </>
  );
};
