import { useEffect, useMemo, useState, FC, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  CaseData,
  CompanyData,
  getTargetResultsData,
  Target,
  TargetStatus,
} from '../../../services/dataService';
import { getAccessToken } from '../../../services/authenticationService';
import { SubNavigationBar } from '../../../components/Layout/SubNavigationBar';
import { generatePath, Redirect, Switch } from 'react-router-dom';
import { ProtectedRoute } from '../../../Router';
import {
  CountrySelectOption,
  getCountrySelectionOptions,
} from '../../targets/TargetNew/countryCodeOptions';
import { resultsRoutes } from '../../../routes';
import { backendBaseUrl } from '../../../backendConfig.json';
import { useCaseQuery, useCustomerQuery } from '_queries';
import { LoadingSpinner } from '_atoms';
import { PageNotFound, TabbedNavigator } from '_organisms';
import { getCdnUrl, isCustomerExpired, withinSpanOfDays } from '_utils';
import { RedFlagItem } from 'src/types';
import { useUserState } from '../../../context/User';
import { zipObject, map } from 'lodash';
import { TargetReportAttribute, WocoEntity } from '@indicium/common';
import { useCaseKeywordsQuery } from '../../../hooks/queries/useCaseQuery';

export const getRedFlags = (
  { companies, profile }: Target,
  countrySelectionOptions: CountrySelectOption[],
  {
    caseId,
    targetId,
    accessToken,
  }: Record<'caseId' | 'targetId' | 'accessToken', string>,
): RedFlagItem[] => {
  const countriesMap = zipObject(
    map(countrySelectionOptions, 'code'),
    map(countrySelectionOptions, 'name'),
  );

  const transformWocoEntityToRedFlagItem = (
    {
      value: {
        country: countryCode,
        positionOrCas,
        category,
        subCategory,
        name,
        externalId,
      },
      sources,
    }: TargetReportAttribute<WocoEntity>,
    apiIds: TargetReportAttribute<string>[],
    roles?: CompanyData['data']['role'],
  ): RedFlagItem => {
    const country = countryCode ? countriesMap[countryCode] : undefined;
    const type = `${category}/${subCategory}`;
    const reportUrl = `https://${backendBaseUrl}/redflagpdfproxy/${caseId}/${targetId}/${externalId}?token=${accessToken}`;

    return {
      apiId: apiIds.find((id) => id.sources.includes('orbis'))?.value || '',
      description: positionOrCas || '',
      name,
      type,
      sources,
      country,
      reportUrl,
      roles,
    };
  };

  const redFlagsFromCompanies = (companies || []).flatMap(
    ({ data: { wocoEntities, apiIds, role } }) => {
      return (wocoEntities || []).map((entity) =>
        transformWocoEntityToRedFlagItem(entity, apiIds, role),
      );
    },
  );

  const redFlagsFromProfiles = (profile?.data?.wocoEntities || []).map(
    (entity) =>
      transformWocoEntityToRedFlagItem(entity, profile?.data?.apiIds || []),
  );

  return [...redFlagsFromProfiles, ...redFlagsFromCompanies];
};

export type ResultsProps = {
  accessToken: string;
  caseData: CaseData;
  redFlags: RedFlagItem[];
  targetData?: Target;
  targetDataPromise: Promise<Target> | null;
  targetImages: string[];
};

const getTestIdFromName = (name: string) => {
  switch (name) {
    case 'summary':
      return 'analysis menu summary';
    case 'overview':
      return 'analysis menu overview';
    case 'personalData':
      return 'analysis menu personal data';
    case 'overviewAnalysis':
      return 'analysis menu analysis';
    case 'networkGraph.title':
      return 'analysis menu network graph';
    case 'timeline.title':
      return 'analysis menu timeline';
    case 'cvData.title':
      return 'analysis menu resume data';
    case 'geoLocations.title':
      return 'analysis menu notes on locations';
    case 'persons.title':
      return 'analysis menu persons';
    case 'rawData.title':
      return 'analysis menu raw data';
    case 'rawData.search':
      return 'analysis menu free text search';
    case 'rawData.socialMedia':
      return 'analysis menu social media';
    case 'rawData.press':
      return 'analysis menu press';
    case 'rawData.registerData':
      return 'analysis menu register data';
    case 'rawData.searchEngines':
      return 'analysis menu search engines';
    case 'rawData.imageSlashVideos':
      return 'analysis menu images videos';
    default:
      return undefined;
  }
};

export const Results: FC = () => {
  const { t, i18n } = useTranslation();
  const { caseId, targetId } =
    useParams<{ caseId: string; targetId: string }>();

  const [{ customerId }] = useUserState();
  const { data: customer } = useCustomerQuery(customerId, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const [accessToken, setAccessToken] = useState<string>();
  const [targetData, setTargetData] = useState<Target | null>(null);
  const [targetDataPromise, setTargetDataPromise] =
    useState<Promise<Target> | null>(null);
  const [targetDataStatus, setTargetDataStatus] = useState(targetData?.status);

  const { data: caseData, ...caseQuery } = useCaseQuery(caseId);
  const { data: caseKeywords, ...caseKeywordsQuery } =
    useCaseKeywordsQuery(caseId);
  const isFetching = useRef(false);

  useEffect(() => {
    (async () => {
      setAccessToken(await getAccessToken());
    })();
  }, []);

  useEffect(() => {
    if (!isFetching.current && targetDataStatus !== 'Completed') {
      isFetching.current = true;
      (async () => {
        try {
          const targetResultDataPromise = getTargetResultsData(
            caseId,
            targetId,
          );
          setTargetDataPromise(targetResultDataPromise);
          const result = await targetResultDataPromise;
          setTargetData(result);
          setTargetDataStatus(result.status);
        } catch (error) {
          if (
            axios.isAxiosError(error) &&
            error.message === 'Request failed with status code 404'
          ) {
            setTargetDataStatus(TargetStatus.Error);
          }
          console.error('No results found: ', error);
        }
        isFetching.current = false;
      })();
    }
  }, [caseId, targetDataStatus, targetId]);

  const countrySelectionOptions = getCountrySelectionOptions(i18n.language);

  const redFlags = useMemo(
    () =>
      targetData && accessToken
        ? getRedFlags(targetData, countrySelectionOptions, {
            caseId,
            targetId,
            accessToken,
          })
        : [],
    [targetData, countrySelectionOptions, caseId, targetId, accessToken],
  );

  const isRawDataDisabled =
    process.env.REACT_APP_STAGE === 'production' &&
    ((!!targetData?.updatedAt && !withinSpanOfDays(targetData.updatedAt)) ||
      isCustomerExpired(customer?.expiresAt));

  const handleSubNavigationItems = useMemo(() => {
    return {
      caseData: { title: caseData?.title || '', caseId },
      title: targetData
        ? [targetData.firstname, targetData.lastname].join(' ')
        : '',
      navigationItems: [
        {
          name: t('overview'),
          dataTestId: getTestIdFromName('overview'),
          href: generatePath(resultsRoutes.dashboardOverview.path, {
            caseId,
            targetId,
          }),
        },
        {
          name: t('personalData'),
          dataTestId: getTestIdFromName('personalData'),
          href: generatePath(resultsRoutes.dashboardProfile.path, {
            caseId,
            targetId,
          }),
        },
        {
          name: t('overviewAnalysis'),
          dataTestId: getTestIdFromName('overviewAnalysis'),
          children: [
            {
              name: t('networkGraph.title'),
              dataTestId: getTestIdFromName('networkGraph.title'),
              href: generatePath(resultsRoutes.networkGraph.path, {
                caseId,
                targetId,
              }),
            },
            // {
            //   name: t('timeline.title'),
            //   dataTestId: getTestIdFromName('timeline.title'),
            //   href: '#',
            // },
            {
              name: t('cvData.title'),
              dataTestId: getTestIdFromName('cvData.title'),
              href: generatePath(resultsRoutes.cV.path, {
                caseId,
                targetId,
              }),
            },
            // {
            //   name: t('geoLocations.title'),
            //   dataTestId: getTestIdFromName('geoLocations.title'),
            //   href: '#',
            // },
            // {
            //   name: t('persons.title'),
            //   dataTestId: getTestIdFromName('persons.title'),
            //   href: '#',
            // },
          ],
        },
        {
          name: t('rawData.title'),
          dataTestId: getTestIdFromName('rawData.title'),
          disabled: isRawDataDisabled,
          disabledTooltip: t('rawData.disabledTooltip'),
          children: [
            {
              name: t('rawData.search'),
              dataTestId: getTestIdFromName('rawData.search'),
              href: generatePath(resultsRoutes.dashboardAllDataSearch.path, {
                caseId,
                targetId,
              }),
              disabled: isRawDataDisabled,
            },
            {
              name: t('rawData.socialMedia'),
              dataTestId: getTestIdFromName('rawData.socialMedia'),
              href: generatePath(resultsRoutes.dashboardSocialMedia.path, {
                caseId,
                targetId,
              }),
              disabled: isRawDataDisabled,
            },
            {
              name: t('rawData.press'),
              dataTestId: getTestIdFromName('rawData.press'),
              href: generatePath(resultsRoutes.dashboardPress.path, {
                caseId,
                targetId,
              }),
              disabled: isRawDataDisabled,
            },
            {
              name: t('rawData.registerData'),
              dataTestId: getTestIdFromName('rawData.registerData'),
              href: generatePath(resultsRoutes.registerData.path, {
                caseId,
                targetId,
              }),
              disabled: isRawDataDisabled,
            },
            {
              name: t('rawData.searchEngines'),
              dataTestId: getTestIdFromName('rawData.searchEngines'),
              href: generatePath(resultsRoutes.searchEngines.path, {
                caseId,
                targetId,
              }),
              disabled: isRawDataDisabled,
            },
            {
              name: t('rawData.imageSlashVideos'),
              dataTestId: getTestIdFromName('rawData.imageSlashVideos'),
              href: generatePath(resultsRoutes.images.path, {
                caseId,
                targetId,
              }),
              disabled: isRawDataDisabled,
            },
          ],
        },
        {
          name: t('inputDataAndSources'),
          children: [
            {
              name: t('caseData'),
              href: generatePath(resultsRoutes.dashboardCaseData.path, {
                caseId,
                targetId,
              }),
            },
            {
              name: t('inputData'),
              href: generatePath(resultsRoutes.dashboardInput.path, {
                caseId,
                targetId,
              }),
            },
            {
              name: t('targetPersonSelection'),
              href: generatePath(resultsRoutes.dashboardCandidates.path, {
                caseId,
                targetId,
              }),
            },
            // {
            //   name: t('sourceOverview'),
            //   disabled: true,
            // },
          ],
        },
      ],
      downloadItems: [
        // {
        //   name: 'PDF',
        //   href: '#',
        // },
        // {
        //   name: 'XLS',
        //   href: '#',
        // },
      ],
    };
  }, [targetData, t, caseId, targetId, caseData, isRawDataDisabled]);

  if (
    caseQuery.isError ||
    caseKeywordsQuery.isError ||
    targetDataStatus === TargetStatus.Error
  ) {
    return <PageNotFound />;
  }

  if (
    caseQuery.isLoading ||
    caseKeywordsQuery.isLoading ||
    targetDataStatus === undefined
  ) {
    return (
      <div className="mt-20">
        <LoadingSpinner message={t('profileLoading')} />
      </div>
    );
  }

  return (
    <div className="relative flex flex-col p-0 mb-6 container-fluid">
      <SubNavigationBar {...handleSubNavigationItems} targetData={targetData} />
      <TabbedNavigator
        navigationItems={handleSubNavigationItems.navigationItems}
      >
        <Switch>
          <Redirect
            to={resultsRoutes.dashboardOverview.path}
            path="/cases/:caseId/targets/:targetId/results"
            exact={true}
          />
          {Object.values(resultsRoutes)
            .sort((a, b) => b.weight - a.weight)
            .map((route, index) => (
              <ProtectedRoute
                key={index}
                {...route}
                props={{
                  accessToken,
                  targetDataPromise,
                  targetData,
                  targetImages: (targetData?.profileImages || []).map((image) =>
                    getCdnUrl(image.url),
                  ),
                  caseData: {
                    ...caseData,
                    keywords: caseKeywords,
                  },
                  redFlags,
                  disabled: isRawDataDisabled,
                }}
              />
            ))}
        </Switch>
      </TabbedNavigator>
    </div>
  );
};
