import { FC, ReactNode } from 'react';
import classnames from 'classnames';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { noop } from 'lodash';

type CardProps = {
  borderColor: string;
  backgroundColor?: string;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  id?: string;
  additionalHtmlAttributes?: Record<string, string | undefined>;
};

export const Card: FC<CardProps> = ({
  id,
  borderColor,
  backgroundColor = 'bg-white',
  children,
  className,
  onClick,
  additionalHtmlAttributes,
}: CardProps) => {
  const defaultClassNames = ['rounded-md', backgroundColor];

  if (className?.indexOf('border-') === -1) {
    defaultClassNames.push('border-solid', 'border-l-4');
  }

  if (!className || className?.indexOf('overflow-') === -1) {
    defaultClassNames.push('overflow-hidden');
  }

  if (onClick) {
    defaultClassNames.push('cursor-pointer');
  }
  const cardClassNames = classnames(
    ...defaultClassNames,
    borderColor,
    className,
  );

  return (
    <div
      id={id}
      className={cardClassNames}
      onClick={onClick || noop}
      {...additionalHtmlAttributes}
    >
      {children}
    </div>
  );
};
