import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { SuspicionItemStatus } from '_enums';
import { suspicions } from '../../services/nestApiService';

type Payload = {
  itemId: string;
  status: SuspicionItemStatus;
};

export const useUpdateSuspicionItemMutation = (): UseMutationResult<
  unknown,
  unknown,
  Payload
> => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ itemId, status }: Payload) =>
      suspicions.items.update(itemId, status),
    {
      onSuccess: () => {
        const invalidationKeys = [
          'summary-report',
          'entities',
          'suspicion-reports-entities',
          'suspicion-reports-items',
          'suspicion-reports',
        ];
        queryClient.invalidateQueries({
          predicate: (query) => {
            const shouldInvalidate = invalidationKeys.some((key) =>
              query.queryKey.includes(key),
            );
            return shouldInvalidate;
          },
        });
      },
    },
  );
};
