import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { ColorLevel, Paragraph, SizeLevel } from '_atoms';
import { TargetReportSourceType } from '@indicium/common';
import { mapSourcesToText } from '../../../services/utils';

type SourceInfoProps = {
  className?: string;
  color?: ColorLevel;
  sources: TargetReportSourceType[];
  size?: SizeLevel;
};

export const SourceInfo: FC<SourceInfoProps> = ({
  className,
  color,
  sources,
  size = 'small',
}) => {
  const { t } = useTranslation();

  return (
    <Paragraph
      className={classnames('break-normal', className)}
      color={color}
      size={size}
      weight="medium"
    >
      {`${t(sources.length === 1 ? 'source' : 'sources')}: ${mapSourcesToText(
        sources,
      )}`}
    </Paragraph>
  );
};
