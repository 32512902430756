import { FC, Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Headline, Card, Paragraph, LoadingSpinner, Badge } from '_atoms';
import { CollapsibleCard } from '_molecules';
import { ProfileImage } from '_molecules';
import { ProfileData } from '../Profile/ProfileData';
import { ResultsProps } from '../Results';
import { useSummaryReportsQuery } from '_queries';
import { useParams } from 'react-router-dom';
import { ReportCard } from './ReportCard';
import { SmallReportCard } from './SmallReportCard';
import { VscFilePdf } from 'react-icons/vsc';
import { nonProdDataTestId } from '_utils';
import { RedFlagItem } from '_types';
import { ReactComponent as ExclamationIcon } from '_assets/icons/exclamation.svg';
import { ReactComponent as CheckIcon } from '_assets/icons/check.svg';
import { localizeDate } from '../../../translations/utils';
import classNames from 'classnames';

const getTestIdForReportCard = (reportType: string) => {
  switch (reportType) {
    case 'compliance_violations':
      return 'analysis overview notice of compliance violations';
    case 'general_crime':
      return 'analysis overview indications of crime';
    case 'own_keywords':
      return 'analysis overview own keywords';
    case 'general_anomalies':
      return 'analysis overview general abnormalities';
    default:
      return undefined;
  }
};

const scrollToElementAfterDataLoad = (elementId: string) => {
  setTimeout(() => {
    requestAnimationFrame(() => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }, 0.3 * 1000);
};

export const Overview: FC<ResultsProps & { accessToken: string }> = ({
  targetData,
  accessToken,
  redFlags = [],
  targetImages,
  caseData,
}: ResultsProps & { accessToken: string }) => {
  const { t } = useTranslation();
  const [isRedFlagsExpanded, setIsRedFlagsExpanded] = useState<boolean>(false);
  const [sectionInView, setSectionInView] = useState<{
    sectionId: string;
    suspicionId: string;
  }>({ sectionId: '', suspicionId: '' });
  const { targetId } = useParams<{ targetId: string; caseId: string }>();

  const summaryReports = useSummaryReportsQuery(targetId);

  const redFlagHeaders = Object.values(
    t<string, Record<string, string>>('redFlags.headers', {
      returnObjects: true,
    }),
  );

  const sourceNames: Record<string, string> = t('sourceName', {
    returnObjects: true,
  });

  const hasSuspicions =
    summaryReports.data?.some(
      (summarySection) => summarySection.suspicionsCount,
    ) || redFlags.length;

  const scrollToReport = (id: string, suspicionId?: string) => {
    setSectionInView({
      sectionId: id,
      suspicionId: suspicionId ?? '',
    });
    id === 'red_flags' && setIsRedFlagsExpanded(true);
    scrollToElementAfterDataLoad(id);
  };

  const redFlagKeywords = useMemo(() => {
    const uniqueKeywords: Record<string, number> = {};
    redFlags.forEach(({ type }) => {
      uniqueKeywords[type] = (uniqueKeywords[type] ?? 0) + 1;
    });

    return Object.entries(uniqueKeywords).map(([type, count]) => ({
      name: type,
      label: type,
      count,
    }));
  }, [redFlags]);

  const ReportStatusIcon = hasSuspicions ? ExclamationIcon : CheckIcon;

  if (summaryReports.isLoading) {
    return (
      <div className="absolute top-0 flex justify-center w-full z-0">
        <LoadingSpinner message={t('profileLoading')} />
      </div>
    );
  }

  return (
    <Fragment>
      <div className="flex-1 px-5 ml-64 my-8 space-y-5">
        <CollapsibleCard
          header={
            <Fragment>
              <Headline
                Level="h2"
                color="dark"
                weight="bold"
                className="mt-0 capitalize"
              >
                {targetData
                  ? `${targetData.firstname} ${targetData.lastname}`
                  : ''}
              </Headline>
              <Paragraph size="default">{t('targetNameSubHeadline')}</Paragraph>
            </Fragment>
          }
          borderColor="border-blue-400"
          bodyClasses="p-4"
          initiallyExpanded
        >
          <Fragment>
            <div className="row">
              <div className="col-12 my-4 row">
                <div className="col-12 lg:col-5 flex justify-center items-center">
                  <div className="w-full 2xl:w-4/6 3xl:w-3/6 4xl:w-2/6 shadow-md rounded p-2 relative">
                    <ProfileImage images={targetImages} />
                  </div>
                </div>
                <div className="col-12 lg:col-7 relative space-y-2">
                  <Card
                    borderColor="border-blue-900"
                    backgroundColor="bg-blue-900"
                    className="py-3 pl-4 pr-4 flex items-center"
                  >
                    <div className="mr-4">
                      <ReportStatusIcon
                        className={classNames(
                          'w-14 h-14',
                          hasSuspicions ? 'text-yellow-400' : 'text-green-500',
                        )}
                      />
                    </div>
                    <div>
                      <Headline Level="h3" color="white">
                        {t('overviewAnalysis')}{' '}
                        <span className="align-super text-sm text-blue-400">
                          {t('overviewAnalysisBadge')}
                        </span>
                      </Headline>
                      <Paragraph size="default" color="light">
                        {t(
                          hasSuspicions
                            ? 'overviewAnalysisResult'
                            : 'overviewAnalysisNoResult',
                        )}
                      </Paragraph>
                    </div>
                  </Card>
                  <SmallReportCard
                    borderColor={
                      redFlags.length ? 'border-red-500' : 'border-green-400'
                    }
                    backgroundColor={
                      redFlags.length ? 'bg-red-200' : 'bg-white'
                    }
                    keywords={redFlagKeywords}
                    name={t('reports.redFlags')}
                    onClick={scrollToReport}
                    reportId="red_flags"
                    additionalHtmlAttributes={{
                      'data-testid': nonProdDataTestId(
                        'analysis overview critical issues',
                      ),
                    }}
                  />
                  {summaryReports.isSuccess &&
                    summaryReports.data?.map(
                      ({ id, label, suspicionsCount, suspicions }) => (
                        <SmallReportCard
                          key={label}
                          borderColor={
                            suspicionsCount > 0
                              ? 'border-yellow-400'
                              : 'border-green-400'
                          }
                          backgroundColor={
                            suspicionsCount > 0 ? 'bg-yellow-200' : 'bg-white'
                          }
                          keywords={suspicions.map(
                            ({ id: suspicionItemId, name, label, items }) => ({
                              name,
                              label,
                              suspicionItemId,
                              count: items.length,
                            }),
                          )}
                          reportId={id}
                          name={t(`reports.sections.${label}`)}
                          onClick={scrollToReport}
                          additionalHtmlAttributes={{
                            'data-testid': nonProdDataTestId(
                              getTestIdForReportCard(label),
                            ),
                          }}
                        />
                      ),
                    )}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center w-full p-2 pt-4">
              <ProfileData data={targetData?.profile?.data} />
            </div>
          </Fragment>
        </CollapsibleCard>

        <CollapsibleCard
          id="red_flags"
          header={
            <div className="flex justify-between items-center">
              <div>
                <Headline Level="h6" weight="bold" color="dark">
                  {t('reports.redFlags')}
                </Headline>
                <Paragraph size="small">
                  {t('reports.anomaliesCount', { count: redFlags.length })}
                </Paragraph>
              </div>
              <div>
                <Paragraph color="light" size="default">
                  {t('reports.resultsCount', { count: redFlags.length })}
                </Paragraph>
              </div>
            </div>
          }
          headerColor={redFlags.length > 0 ? 'bg-red-200' : 'bg-white'}
          headerBorder={
            redFlags.length > 0 ? 'border-red-500' : 'border-green-400'
          }
          className="border-0"
          bodyClasses={
            redFlags.length > 0 ? 'p-4 border-t-2 border-gray-200' : ''
          }
          initiallyExpanded={false}
          expandedState={isRedFlagsExpanded}
          onToggleChange={setIsRedFlagsExpanded}
          toggleEnabled={redFlags.length > 0}
          additionalHtmlAttributes={{
            'data-testid': nonProdDataTestId(
              'analysis overview critical issues card',
            ),
          }}
        >
          <div className="space-y-3 divide-y">
            {redFlags.length > 0 ? (
              <div>
                <div className="grid grid-cols-6 gap-3 items-center w-full">
                  {redFlagHeaders.map((header, index) => (
                    <Paragraph
                      key={index}
                      size="label"
                      weight="bold"
                      color="light"
                    >
                      {header}
                    </Paragraph>
                  ))}
                </div>
                <div className="divide-y">
                  {redFlags.map(
                    (
                      {
                        country = '',
                        name,
                        type,
                        sources,
                        description,
                        reportUrl,
                        roles,
                      },
                      index,
                    ) => (
                      <div
                        key={index}
                        className="grid grid-cols-6 gap-3 items-center py-3 break-words"
                      >
                        <Paragraph size="default" weight="bold" color="dark">
                          {name}
                        </Paragraph>
                        <Paragraph size="default" color="dark" HtmlTag="div">
                          <RedFlagCompanyRoles roles={roles} />
                        </Paragraph>
                        <Paragraph size="default" weight="bold" color="dark">
                          {t(`redFlags.rawTypes.${type}`, type)}
                        </Paragraph>
                        <Paragraph size="default" color="dark">
                          {country}
                        </Paragraph>
                        <Paragraph size="default" color="dark">
                          {sources
                            .map((source) => sourceNames[source] ?? source)
                            .join(', ')}
                        </Paragraph>
                        <Paragraph
                          size="default"
                          color="dark"
                          className="flex items-center"
                        >
                          {description}
                        </Paragraph>
                        {reportUrl ? (
                          <a
                            key={index}
                            className="block text-sm text-blue-400 hover:text-blue-600 font-normal focus:outline-none mb-1"
                            href={reportUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Badge className="gap-2">
                              <VscFilePdf />
                              {t('redFlags.reportsItem')}
                            </Badge>
                          </a>
                        ) : null}
                      </div>
                    ),
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </CollapsibleCard>

        {summaryReports.isSuccess &&
          summaryReports?.data?.map((report) => (
            <ReportCard
              key={report.id}
              report={report}
              targetId={targetId}
              accessToken={accessToken}
              targetData={targetData}
              caseData={caseData}
              additionalHtmlAttributes={{
                'data-testid': nonProdDataTestId(
                  getTestIdForReportCard(report.label) + ' card',
                ),
              }}
              activeReportSection={sectionInView}
            />
          ))}
      </div>
    </Fragment>
  );
};

const RedFlagCompanyRoles: FC<{ roles: RedFlagItem['roles'] }> = ({
  roles,
}) => {
  const { t } = useTranslation();

  if (!roles || roles.length === 0) {
    return <>{t('redFlags.roleNotAvailable')}</>;
  }

  if (roles.length === 1) {
    return <RedFlagCompanyRole {...roles[0]} />;
  }

  return (
    <ul className="list-disc pl-4">
      {roles.map((role, index) => (
        <li key={index}>
          <RedFlagCompanyRole {...role} />
        </li>
      ))}
    </ul>
  );
};

const RedFlagCompanyRole = (role: Required<RedFlagItem>['roles'][0]) => {
  const { t } = useTranslation();

  return (
    <>
      <span className="block">
        {role.value.role || t('redFlags.unknownRole')}
      </span>
      <Paragraph size="small" color="light" HtmlTag="span">
        {role.value.from ? (
          <>
            {`${localizeDate(role.value.from)} - ${
              role.value.to
                ? localizeDate(role.value.to)
                : t('redFlags.positionIsCurrent')
            }`}
          </>
        ) : (
          <>{t('redFlags.unknownTimeframe')}</>
        )}
      </Paragraph>
    </>
  );
};
