import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { CaseAgentData } from '../../services/dataService';

const fetchCaseAgents = async (caseId: string): Promise<CaseAgentData[]> =>
  (await axios.get(`https://${backendBaseUrl}/cases/${caseId}/agents`)).data
    .caseAgents;

export const useCaseAgentsQuery = (
  caseId: string,
): UseQueryResult<CaseAgentData[], string> =>
  useQuery(['caseAgents', caseId], () => fetchCaseAgents(caseId));
