import { FC, useState, useRef, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { useWindowSize } from '../../../hooks/useWindowSize';
export type TooltipPosition = 'top' | 'bottom' | 'left' | 'right' | 'top-left';

export interface TooltipProps extends HTMLAttributes<HTMLSpanElement> {
  content: string;
  position?: TooltipPosition;
}

const getClassesForPosition = (position: TooltipPosition): string[] => {
  switch (position) {
    case 'top':
      return [
        'bottom-full',
        'left-1/2',
        'transform',
        '-translate-x-1/2',
        'mb-2',
      ];
    case 'left':
      return ['right-full', 'top-1/2', 'transform', '-translate-y-1/2', 'mr-2'];
    case 'right':
      return ['left-full', 'top-1/2', 'transform', '-translate-y-1/2', 'ml-2'];
    case 'top-left':
      return ['right-full', 'top-1', 'transform', '-translate-y-1/2'];
  }

  return [];
};

const getTooltipPosition = (
  position: TooltipPosition,
  rect: DOMRect,
  [innerWidth, innerHeight]: number[],
): TooltipPosition => {
  switch (position) {
    case 'top':
      return rect.top < 0 ? 'bottom' : position;
    case 'bottom':
      return rect.bottom > innerHeight ? 'top' : position;
    case 'left':
      return rect.left < 0 ? 'right' : position;
    case 'right':
      return rect.right > innerWidth ? 'left' : position;
    default:
      return position;
  }
};

export const Tooltip: FC<TooltipProps> = ({
  className,
  content,
  children,
  position: desiredPosition = 'top',
}) => {
  const containerRef = useRef<HTMLSpanElement | null>(null);

  const [active, setActive] = useState<boolean>(false);
  const [position, setPosition] = useState<TooltipPosition>(desiredPosition);

  const windowSize = useWindowSize();

  const tooltipNode = containerRef.current?.querySelector(
    'div[role="tooltip"]',
  );

  if (tooltipNode) {
    const boundingRect = tooltipNode.getBoundingClientRect();
    const newPosition = getTooltipPosition(position, boundingRect, windowSize);

    position !== newPosition && setPosition(newPosition);
  }

  return (
    <span className={classNames('relative')} ref={containerRef}>
      <span
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
      >
        {children}
      </span>
      {active && content && (
        <div
          role="tooltip"
          className={classNames(
            'absolute z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm w-60 flex justify-center',
            getClassesForPosition(position),
            className,
          )}
        >
          <span className="items-center break-normal">{content}</span>
        </div>
      )}
    </span>
  );
};
