import { nonProdDataTestId } from '_utils';
import React, { FC, FormEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineLockClosed } from 'react-icons/hi';
import { signin, User } from '../../services/authenticationService';
import {
  AuthExceptions,
  AuthSteps,
  NEW_PASSWORD_REQUIRED,
  SignInExceptions,
} from './types';

interface SigninProps {
  setLocalUser: (user: User) => void;
  onSuccess: (user: User) => void;
  onError: (code: SignInExceptions) => void;
  currentStep: AuthSteps;
  onChangeStep: (step: AuthSteps) => void;
}

export const SignIn: FC<SigninProps> = ({
  setLocalUser,
  onError,
  onChangeStep,
}: SigninProps) => {
  const { t } = useTranslation();
  const [username, setUsername] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (username && password) {
        try {
          const currentUser = await signin(username, password);

          setLocalUser(currentUser);

          if (currentUser.challengeName === NEW_PASSWORD_REQUIRED) {
            throw AuthExceptions.PasswordResetRequiredException;
          }

          if (currentUser.preferredMFA === 'NOMFA') {
            onChangeStep(AuthSteps.SetupMFA);
            return;
          }

          onChangeStep(AuthSteps.ConfirmationCode);
        } catch (errorCode) {
          onError(errorCode as SignInExceptions);
        }
      }
    },
    [username, password, setLocalUser, onChangeStep, onError],
  );

  return (
    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
      <input type="hidden" name="remember" defaultValue="true" />
      <div className="rounded-md shadow-sm -space-y-px">
        {/* TODO: Check classes for commonalities and extract them into a variable -> see IND-252 */}
        <div>
          <label htmlFor="email-address" className="sr-only">
            {t('signIn.emailAddressLabel')}
          </label>
          <input
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-400 focus:border-blue-400 focus:z-10 sm:text-sm"
            placeholder={t('signIn.emailAddressInputPlaceholder')}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password" className="sr-only">
            {t('signIn.passwordLabel')}
          </label>
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-400 focus:border-blue-400 focus:z-10 sm:text-sm"
            placeholder={t('signIn.passwordInputPlaceholder')}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>

      <div>
        <button
          type="submit"
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
          data-testid={nonProdDataTestId('submit button')}
        >
          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
            <HiOutlineLockClosed
              className="h-5 w-5 text-blue-500 group-hover:text-blue-400"
              aria-hidden="true"
            />
          </span>
          {t('signIn.signInButton')}
        </button>
        <div className="flex justify-between text-sm items-end pt-1 pb-2 font-medium">
          <a
            href="https://www.indicium.ag/impressum"
            target="_blank"
            rel="noreferrer"
            className="text-blue-400 hover:text-blue-500 transition-colors"
          >
            {t('signIn.imprint')}
          </a>
          <button
            type="button"
            onClick={() => {
              onChangeStep?.(AuthSteps.ResetCode);
            }}
            className="text-blue-400 hover:text-blue-500 transition-colors"
          >
            {t('signIn.forgotPassword')}
          </button>
        </div>
      </div>
    </form>
  );
};
