import { FC, Fragment, ReactNode, useEffect, useMemo, useState } from 'react';

type RequestState = 'loading' | 'resolvedWithWarning' | 'resolved' | 'rejected';
type AsyncFeedbackWrapperProps = {
  promises: {
    promise: Promise<unknown> | null;
    condition?: (
      promiseResult: unknown,
      prevPromiseResult: RequestState | null,
    ) => RequestState;
  }[];
  alert: {
    loading?: string | JSX.Element;
    resolved?: string | JSX.Element;
    resolvedWithWarning?: string | JSX.Element;
    rejected?: string | JSX.Element;
  };
  children: ReactNode;
  showWhileLoading?: boolean;
};

export const AsyncFeedbackWrapper: FC<AsyncFeedbackWrapperProps> = ({
  alert,
  promises,
  children,
  showWhileLoading = false,
}: AsyncFeedbackWrapperProps) => {
  const [requestStatus, setRequestStatus] = useState<RequestState>();

  useEffect(() => {
    setRequestStatus('loading');
    (async () => {
      try {
        const results = await Promise.all(
          promises.map(({ promise }) => promise),
        );

        const promiseStates = results.reduce(
          (prevResult: RequestState[], result, index) => {
            const condition = promises[index].condition;
            const prevStatus = index > 0 ? prevResult[index - 1] : null;
            const status = condition
              ? condition(result, prevStatus)
              : 'resolved';
            return [...prevResult, status];
          },
          [],
        );

        setRequestStatus(
          promiseStates.reduce(
            (acc, status) => (status !== 'resolved' ? status : acc),
            'resolved',
          ),
        );
      } catch (error) {
        setRequestStatus('rejected');
      }
    })();
  }, [promises]);

  const feedback = useMemo(() => {
    switch (requestStatus) {
      case 'loading':
        return alert.loading;
      case 'resolved':
        return alert.resolved;
      case 'resolvedWithWarning':
        return alert.resolvedWithWarning;
      case 'rejected':
        return alert.rejected;
    }
  }, [requestStatus, alert]);

  return (
    <Fragment>
      {feedback}
      {requestStatus &&
        requestStatus !== 'rejected' &&
        (showWhileLoading || requestStatus !== 'loading') &&
        children}
    </Fragment>
  );
};
