import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityIcon } from '_atoms';

import { EntityType } from '_types';

interface ResultCountersProps extends HTMLAttributes<HTMLDivElement> {
  count?: Record<string, number>;
}

type TileEntityType = EntityType | 'socialPost' | 'socialConnection';

const getEntityIconType = (tet: TileEntityType): EntityType =>
  ['SocialAccountPost', 'SocialAccountConnection'].includes(tet)
    ? 'social'
    : (tet.toLowerCase() as EntityType);

const entityTypeLabel: Record<string, string> = {
  SocialAccountPost: 'socialMediaPostSection',
  SocialAccountConnection: 'socialMediaConnectionSection',
  press: 'pressSection',
  TargetCompany: 'companySection',
  Image: 'pictureSection',
  WebPage: 'searchEngineSection',
};

export const ResultCounters: FC<ResultCountersProps> = ({
  count,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex mx-2 mb-2 w-full bg-gray-100" {...props}>
      {count
        ? Object.keys(count).map((key) => (
            <div key={key} className="pb-2">
              <div className="inline-block">
                <EntityIcon
                  type={getEntityIconType(key as TileEntityType)}
                  className="pr-0 ml-2 mt-2"
                  bgColor="bg-gray-100"
                  iconColor="text-gray-500"
                  width="h-6"
                  height="w-6"
                  logoPadding="p-2"
                />
                <div className="text-center w-14 text-sm text-gray-600 font-bold tracking-widest">
                  {count[key]}
                </div>
              </div>
              <div className="pt-4 pr-8 inline-block align-top text-md text-gray-700 font-semibold">
                {key in entityTypeLabel ? t(entityTypeLabel[key]) : '?'}
              </div>
            </div>
          ))
        : null}
    </div>
  );
};
