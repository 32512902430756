import { FC } from 'react';
import { Link } from 'react-router-dom';
import { MdChevronRight } from 'react-icons/md';

type BreadcrumbsProps = {
  breadcrumbs: {
    name: string;
    route: string;
  }[];
};

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  breadcrumbs,
}: BreadcrumbsProps) => {
  return (
    <div className="flex items-center text-sm mt-4">
      {breadcrumbs?.map(({ route, name }, index) => {
        return (
          <div key={index} className="flex items-center">
            {index > 0 && (
              <div className="px-6 text-gray-500">
                <MdChevronRight />
              </div>
            )}
            <Link className="text-gray-400 hover:text-blue-400" to={route}>
              {name}
            </Link>
          </div>
        );
      })}
    </div>
  );
};
