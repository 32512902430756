import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { TargetStatus } from '../../services/dataService';
import { TargetCandidatesGroup, TargetGoogleCandidatesGroup } from '_types';

export type TargetCandidatesResponse = {
  id: string;
  status: TargetStatus;
  candidates: TargetCandidatesGroup[];
  googleCandidates: TargetGoogleCandidatesGroup[];
};

const fetchTargetCandidates = async (
  caseId: string,
  targetId: string,
): Promise<TargetCandidatesResponse> =>
  (
    await axios.get(
      `https://${backendBaseUrl}/v2/cases/${caseId}/targets/${targetId}/candidates`,
    )
  ).data;

export const useTargetCandidatesQuery = (
  caseId: string,
  targetId: string,
): UseQueryResult<TargetCandidatesResponse, string> =>
  useQuery(
    ['targetCandidates', caseId, targetId],
    () => fetchTargetCandidates(caseId, targetId),
    {
      refetchOnWindowFocus: false,
    },
  );
