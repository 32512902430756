import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import Flags, { FlagComponent } from 'country-flag-icons/react/1x1';
import { ProfileData as ProfileDataType } from '../../../services/dataService';
import {
  replaceUmlauts,
  transformBirthdayRangeToString,
  transformResidenceToString,
} from '_utils';
import { LocalizationService, TargetReportSourceType } from '@indicium/common';
import { InfoBlock } from './InfoBlock';
import { uniqBy } from 'lodash';

export interface ProfileDataProps extends HTMLAttributes<HTMLDivElement> {
  data?: ProfileDataType;
}

interface MergedName {
  sources: TargetReportSourceType[];
  value: string;
}

const mergeNames = (
  nameData: ProfileDataType['name'],
  accessor: (nameData: {
    first: string;
    last: string;
    middle?: string;
  }) => string | undefined,
): MergedName[] => {
  const normalizedData = nameData
    .map((name) => ({
      value: replaceUmlauts(accessor(name.value) || ''),
      sources: name.sources,
    }))
    .filter((name) => Boolean(name.value));

  return uniqBy(normalizedData, 'value')
    .map((name, index) => {
      const duplicateSources = normalizedData
        .filter((n, i) => n.value === name.value && i !== index)
        .flatMap((n) => n.sources);

      return {
        ...name,
        sources: [...new Set([...name.sources, ...duplicateSources])],
      };
    })
    .sort((a, b) => b.sources.length - a.sources.length);
};

const transformNationalities = (
  nationalities: ProfileDataType['nationality'] = [],
) =>
  nationalities.map(({ value, sources }) => {
    const country = LocalizationService.getCountry(value);
    const Flag =
      country && ((Flags as any)[country.iso2] as FlagComponent | undefined);

    return {
      value: LocalizationService.getCountry(value)?.nationality ?? value,
      sources,
      icon: Flag ? <Flag className="w-5 h-5 rounded-full" /> : undefined,
    };
  });

export const ProfileData: FC<ProfileDataProps> = ({
  className,
  data,
}: ProfileDataProps) => {
  const { t } = useTranslation();
  if (!data) return null;

  const { name, birthday, age, occupation, residence, birthPlace } = data;

  const ages = (age || []).map((age) => ({
    ...age,
    value: t('yearsOld', { age: age.value }),
  }));

  const middleName = mergeNames(name, (nd) => nd.middle);

  const birthdays = transformBirthdayRangeToString(birthday);
  const residences = transformResidenceToString(residence);
  const nationalities = transformNationalities(data.nationality);
  const educations = data.educations?.map((education) => ({
    ...education,
    value: education.value.display,
  }));

  return (
    <div className={className}>
      <div className="row">
        <div className="col">
          <InfoBlock
            title={t('infoBlockItem.middlename')}
            infoList={middleName}
          />
          {birthdays?.length ? (
            <InfoBlock
              title={t('infoBlockItem.birthday')}
              infoList={birthdays}
              containerClassName="py-1"
            />
          ) : (
            <InfoBlock title={t('infoBlockItem.age')} infoList={ages} />
          )}
          <InfoBlock
            title={t('infoBlockItem.nationality')}
            infoList={nationalities}
            containerClassName="py-1"
          />
          <InfoBlock
            title={t('infoBlockItem.placeOfBirth')}
            infoList={birthPlace ?? []}
            containerClassName="py-1"
          />
          <InfoBlock
            title={t('infoBlockItem.placesLived')}
            infoList={residences}
            containerClassName="py-1"
          />
        </div>
        <div className="col">
          <InfoBlock
            title={t('infoBlockItem.occupation')}
            infoList={occupation ?? []}
          />
        </div>
        <div className="col">
          <InfoBlock
            title={t('infoBlockItem.education')}
            infoList={educations ?? []}
          />
        </div>
      </div>
    </div>
  );
};
