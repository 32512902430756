import { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Headline, Card, Button, Paragraph, DatePicker } from '_atoms';
import { ResultsProps } from '../Results';
import { CompanySource } from '_types';

import 'react-calendar-timeline/lib/Timeline.css';
import './CV.css';
import classNames from 'classnames';
import { nonProdDataTestId } from '_utils';
import { ProfileEducation } from 'src/services/dataService';
import { CvTable } from './CvTable';
import { CvTimeline, getInitialTimelineStartTime } from './CvTimeline';
import { VALID_SOURCES } from './helpers';

type EducationBySource = Record<CompanySource, ProfileEducation[]>;

export const CV: FC<ResultsProps> = ({ targetData }) => {
  const { t } = useTranslation();
  const [selectedSource, setSelectedSource] =
    useState<CompanySource | 'all'>('all');

  const companies = targetData?.entrepreneurialActivities;
  const educations = targetData?.profile?.data.educations;

  const [startTime, setStartTime] = useState(() =>
    getInitialTimelineStartTime(companies, educations),
  );
  const [endTime, setEndTime] = useState(() => new Date().getTime());

  const educationBySource = useMemo(() => {
    const sources: EducationBySource = {
      pipl: [],
      companyhouse: [],
      orbis: [],
    };

    educations?.forEach((education) => {
      education.sources.forEach((source) => {
        if (source in sources) {
          sources[source as CompanySource].push(education.value);
        }
      });
    });

    return sources;
  }, [educations]);

  return (
    <div style={{ height: `calc(100vh - 180px` }}>
      <Card
        borderColor="border-white"
        backgroundColor="bg-white"
        className="py-4 px-4"
      >
        <div className="w-full flex">
          {selectedSource !== 'all' ? (
            <Headline
              className="pt-3 w-72 flex-grow-0 "
              Level="h4"
              weight="medium"
              color="dark"
            >
              <div className="pt-0 pr-8 inline-block">{t('cv')}</div>
            </Headline>
          ) : (
            <div className="pt-2 w-98 flex-grow-0">
              <div className="flex gap-5">
                {companies || educations ? (
                  <>
                    <div className="date-from w-40">
                      <Paragraph
                        color="dark"
                        size="default"
                        className="uppercase mb-0"
                      >
                        {t('filters.dateFrom')}
                      </Paragraph>
                      <DatePicker
                        dateFormat="dd.MM.yyyy"
                        selected={new Date(startTime)}
                        onChange={(from) => {
                          setStartTime((from || new Date(0)).getTime());
                        }}
                        showYearDropdown={true}
                      />
                    </div>
                    <div className="date-to w-40">
                      <Paragraph
                        color="dark"
                        size="default"
                        className="uppercase mb-0"
                      >
                        {t('filters.dateTo')}
                      </Paragraph>
                      <DatePicker
                        dateFormat="dd.MM.yyyy"
                        selected={new Date(endTime)}
                        onChange={(to) => {
                          setEndTime((to || new Date()).getTime());
                        }}
                        showYearDropdown={true}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          )}
          <div className="flex flex-1 gap-2 justify-end">
            {VALID_SOURCES.map((sourceOption) => {
              const source = t(`sourceName.${sourceOption}`);
              const sourceType = source
                .slice(0, source.indexOf('(') - 1)
                .trim();
              const sourceName = source.slice(source.indexOf('(')).trim();
              return (
                <div key={`button-${sourceOption}`}>
                  <Button
                    level="custom"
                    className={classNames(
                      'min-h-full',
                      `button-${sourceOption}`,
                      selectedSource === sourceOption
                        ? `button-ring-${sourceOption}`
                        : '',
                    )}
                    onClick={() => setSelectedSource(sourceOption)}
                    data-testid={nonProdDataTestId('cv data button')}
                  >
                    {sourceType}
                    <br />
                    {sourceName}
                  </Button>
                </div>
              );
            })}
            <div className="w-42">
              <Button
                level={selectedSource === 'all' ? 'primary' : 'secondary'}
                className="w-40 py-0 uppercase font-semibold"
                onClick={() => setSelectedSource('all')}
                data-testid={nonProdDataTestId('cv data button')}
              >
                {t('consolidatedView')}
              </Button>
            </div>
          </div>
        </div>

        {selectedSource !== 'all' && companies ? (
          <CvTable
            companies={companies[selectedSource]}
            educations={educationBySource[selectedSource]}
          />
        ) : null}
        {selectedSource === 'all' ? (
          <CvTimeline
            startTime={startTime}
            endTime={endTime}
            companies={companies}
            educations={educations}
          />
        ) : null}
      </Card>
    </div>
  );
};
