import { Schemas } from '@indicium/common/src/utils';
import * as Yup from 'yup';
import { DynamicFormField } from './utils';
import { KEYWORD_LENGTH_LIMIT } from './config';

export const fields: Record<string, DynamicFormField> = {
  occupations: {
    initialValues: {
      occupations: '',
    },
    validationSchema: Yup.object().shape({
      occupations: Schemas.requiredString().max(
        KEYWORD_LENGTH_LIMIT,
        'schemaValidation.maxLength',
      ),
    }),
  },
  relatedPersons: {
    initialValues: {
      relatedPersons: '',
    },
    validationSchema: Yup.object().shape({
      relatedPersons: Schemas.requiredString().max(
        KEYWORD_LENGTH_LIMIT,
        'schemaValidation.maxLength',
      ),
    }),
  },
  websites: {
    initialValues: {
      websites: '',
    },
    validationSchema: Yup.object().shape({
      websites: Schemas.website()
        .required()
        .max(KEYWORD_LENGTH_LIMIT, 'schemaValidation.maxLength'),
    }),
  },
  organizations: {
    initialValues: {
      organizations: '',
    },
    validationSchema: Yup.object().shape({
      organizations: Schemas.requiredString().max(
        KEYWORD_LENGTH_LIMIT,
        'schemaValidation.maxLength',
      ),
    }),
  },
  topics: {
    initialValues: {
      topics: '',
    },
    validationSchema: Yup.object().shape({
      topics: Schemas.requiredString().max(
        KEYWORD_LENGTH_LIMIT,
        'schemaValidation.maxLength',
      ),
    }),
  },
  locations: {
    initialValues: {
      locations: '',
    },
    validationSchema: Yup.object().shape({
      locations: Schemas.requiredString().max(
        KEYWORD_LENGTH_LIMIT,
        'schemaValidation.maxLength',
      ),
    }),
  },
  nicknames: {
    initialValues: {
      nicknames: '',
    },
    validationSchema: Yup.object().shape({
      nicknames: Schemas.requiredString().max(
        KEYWORD_LENGTH_LIMIT,
        'schemaValidation.maxLength',
      ),
    }),
  },
};
