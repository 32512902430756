import { Schemas } from '@indicium/common/src/utils';
import { Formik, Form } from 'formik';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Link, Redirect, useParams } from 'react-router-dom';
import { Button, Headline, StyledField } from '_atoms';
import { useUpdateCaseMutation } from '../../../../hooks/queries';
import { routes } from '../../../../routes';
import { CaseData } from '../../../../services/dataService';
import * as Yup from 'yup';

export const EditCase: FC = () => {
  const { caseId } = useParams<{ caseId: string }>();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const casesData = queryClient.getQueryData<CaseData[]>('cases') ?? [];

  const title = casesData.find(({ id }) => id === caseId)?.title ?? '';

  const validationSchema = Yup.object().shape({
    title: Schemas.requiredString(),
  });

  const { mutate, error, isError, isLoading, isSuccess } =
    useUpdateCaseMutation();

  const handleSubmit = async ({ title }: { title: string }) => {
    mutate({ caseId, title });
  };

  if (isSuccess) {
    return <Redirect to={routes.casesList.path} />;
  }

  if (isError) {
    console.error(`Something went wrong, could not edit Case: ${error}`);
  }

  return (
    <Fragment>
      <div className="container-fluid mb-20 px-16">
        <div className="row">
          <Headline Level="h1" color="dark" className="mt-8 mb-4" weight="bold">
            {`${t('editCaseAction')}: ${title}`}
          </Headline>
        </div>

        <Formik
          initialValues={{ title }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({ isValid }) => (
            <Form>
              <div className="mb-6 rounded-md bg-white pt-5 px-6 pb-9">
                <div className="flex justify-between mb-10">
                  <Headline Level="h4" color="dark" weight="bold">
                    {t('editCaseFormHeadline')}
                  </Headline>
                </div>

                <div className="col-12 md:col-6">
                  <StyledField type="text" name="title" label={t('title')} />
                </div>
              </div>

              <div className="flex justify-end space-x-6">
                <div className="col-4">
                  <Link to={routes.casesList.path}>
                    <Button className="w-full" level="secondary" type="button">
                      {t('back')}
                    </Button>
                  </Link>
                </div>
                <div className="col-4">
                  <Button
                    disabled={isLoading || !isValid}
                    className="w-full"
                    level="primary"
                    type="submit"
                  >
                    {t('continue')}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Fragment>
  );
};
