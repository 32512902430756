import { replaceUmlauts } from '_utils';

import { HighlightType, HighlightChunks, SearchTerms } from './types';

// define priority of highlighted categories
// a higher value means higher priority
const mapTypeToPriority = (type: HighlightType): number => {
  switch (type) {
    case HighlightType.SearchQuery:
      return 99;
    case HighlightType.TargetPerson:
      return 10;
    case HighlightType.SuspicionKeywords:
      return 6;
    case HighlightType.TargetCompanies:
      return 5;
    case HighlightType.CaseKeywords:
      return 4;
    default:
      return 0;
  }
};

const mapTypeToColor = (type: HighlightType): string => {
  switch (type) {
    case HighlightType.SearchQuery:
      return 'bg-yellow-200';
    case HighlightType.SuspicionKeywords:
      return 'bg-blue-400';
    case HighlightType.TargetPerson:
      return 'bg-green-400';
    case HighlightType.TargetCompanies:
      return 'bg-blue-100';
    case HighlightType.CaseKeywords:
      return 'bg-red-400';
    default:
      return '';
  }
};

export const getColorClass = (
  searchTerms: SearchTerms,
  word: string,
): string => {
  // since the search were already sorted, we can just return the first match
  const lowerCaseWord = word.toLowerCase();
  const highestPrioType = searchTerms.find(({ words }) =>
    words.includes(lowerCaseWord),
  );

  if (highestPrioType?.type === undefined) {
    return '';
  }

  return mapTypeToColor(highestPrioType.type);
};

// adding this as a separate function to allow for pre-processing of the search terms when they are reused
// e.g. in the SearchEngineItem.tsx where the same set of search terms is used for multiple Highlight instances
export const computeSearchWords = (
  searchWords?: HighlightChunks,
): SearchTerms => {
  if (!searchWords) {
    return [];
  }
  const searchTerms: SearchTerms = [];

  Object.entries(searchWords)
    .filter(([, words]) => words)
    .forEach(([type, words]) => {
      searchTerms.push({
        type: type as HighlightType,
        words: words.reduce<Array<string>>((acc, untrimmedWord) => {
          // remove empty strings and undefined values
          if (!untrimmedWord) {
            return acc;
          }

          // add version with umlauts replaced if necessary
          const word = untrimmedWord.trim();
          const replacedUmlauts = replaceUmlauts(word);

          return [
            ...acc,
            word.toLowerCase(),
            ...(word === replacedUmlauts
              ? []
              : [replacedUmlauts.toLowerCase()]),
          ];
        }, []),
      });
    });

  return searchTerms.sort(
    (a, b) => mapTypeToPriority(b.type) - mapTypeToPriority(a.type),
  );
};
