import { FC } from 'react';
import { HiOutlineArrowRight } from 'react-icons/hi';
import classnames from 'classnames';

export type TargetCandidateImagesProps = {
  images: string[];
  onClick: () => void;
  className?: string;
};

export const TargetCandidateImages: FC<TargetCandidateImagesProps> = ({
  images,
  onClick,
  className,
}) => {
  if (!images.length) return null;

  const [primaryImage] = images.slice(0, 1);
  const otherImages = images.slice(1, 3);

  return (
    <div className={classnames('flex', 'justify-center', className)}>
      <div className="relative mb-2">
        <img
          src={primaryImage}
          className="object-cover w-32 h-32 rounded-full cursor-pointer"
          onClick={() => onClick()}
        />
        {otherImages.length > 0 && (
          <div className="absolute -bottom-3 flex gap-1 w-full justify-center">
            {otherImages.map((image, index) => (
              <img
                key={index}
                src={image}
                className="object-cover w-8 h-8 rounded-full border-2 border-white cursor-pointer"
                onClick={() => onClick()}
              />
            ))}
            <div className="w-8 h-8 flex justify-center items-center rounded-full bg-blue-500 text-white border-2 border-white cursor-pointer p-1">
              <HiOutlineArrowRight onClick={() => onClick()} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
