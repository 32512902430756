import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageArticle } from 'src/services/searchService';
import {
  TextData,
  ImageData,
  LinkData,
  Badge,
  Headline,
  Highlight,
} from '_atoms';
import { BaseCard } from '_organisms';
import { EntitySuspicion } from '_types';
import { getCdnUrl } from '_utils';
import { CaseData, Target } from '../../../services/dataService';
import { buildHighlightChunks } from '../../_atoms/Highlight/public-utils';

interface PictureCardProps extends HTMLAttributes<HTMLDivElement> {
  data: ImageArticle;
  action?: JSX.Element;
  score?: number;
  status?: string;
  suspicionItemId?: string;
  caseData?: CaseData;
  targetData?: Target;
  suspicionItems?: Array<EntitySuspicion>;
  query?: string;
}

const getImageName = (image: string): string => {
  if (image) {
    const parts = image.split('/');
    if (parts.length) {
      return parts[parts.length - 1].split('?')[0];
    }
  }
  return '/';
};

export const PictureCard: FC<PictureCardProps> = ({
  data,
  query,
  suspicionItems,
  caseData,
  ...props
}) => {
  const { targetData } = props;
  const { t } = useTranslation();

  const sourceName: Record<string, string> = t('sourceName', {
    returnObjects: true,
  });

  const highlight = buildHighlightChunks({
    searchQuery: query,
    suspicions: suspicionItems,
    target: targetData,
    caseKeywords: caseData?.keywords,
  });

  return (
    <BaseCard
      entityType="image"
      title={
        data.imageTitle?.length ? data.imageTitle : getImageName(data.imageUrl)
      }
      item={data}
      highlight={highlight}
      suspicionItems={suspicionItems}
      {...props}
    >
      <div>
        <ImageData
          headline={t('image')}
          src={getCdnUrl(data.imageMediaCdnUrl)}
          width="w-1/4"
          className="inline-block align-top pl-5"
        />
        <div className="w-3/4 inline-block align-top">
          <TextData
            headline={t('date')}
            text={data.imageDatePublished || t('noData')}
            highlight={highlight}
            width="w-1/5"
          />
          <TextData
            headline={t('source')}
            text={data.imageSources
              .map((is) => sourceName[is.toLowerCase()] ?? is)
              .join(', ')}
            highlight={highlight}
            width="w-1/5"
          />
          <LinkData
            headline="URL"
            href={data.imageUrl}
            highlight={highlight}
            width="w-1/4"
          />

          <Headline
            className="pl-6 mt-6 uppercase"
            Level="h6"
            weight="bold"
            color="light"
          >
            {t('tags')}
          </Headline>
          <div className="w-full px-6 space-x-4 mb-2">
            {data.imageAnalsisTags?.map((tag) => (
              <Badge
                key={tag}
                background="white"
                paddingClassName="px-2 py-1"
                marginClassName="my-1"
                className="border-blue-400 border-solid border-2 font-semibold"
              >
                <Highlight searchWords={highlight} textToHighlight={tag} />
              </Badge>
            ))}
          </div>
        </div>
      </div>
    </BaseCard>
  );
};
