import { FC, HTMLAttributes, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DynamicSVG, EntityIcon, Headline, Highlight, Paragraph } from '_atoms';
import { EntitySuspicion, EntityType } from '_types';
import { AllDataArticle } from '../../../services/searchService';
import { useReportTailoring } from '../../../hooks/useReportTailoring';
import { Target } from '../../../services/dataService';
import { isCustomerExpired, withinSpanOfDays } from '_utils';
import { useUserState } from '../../../context/User';
import { useCustomerQuery } from '_queries';
import { HighlightChunks } from '../../_atoms/Highlight/types';

type TileEntityType = EntityType | 'socialPost' | 'socialConnection';

const getEntityType = (tet: TileEntityType): EntityType =>
  ['socialPost', 'socialConnection'].includes(tet)
    ? 'social'
    : (tet as EntityType);

const entityTypeName: Record<TileEntityType, string> = {
  social: 'socialMediaSection',
  socialPost: 'socialMediaPostSection',
  socialConnection: 'socialMediaConnectionSection',
  press: 'pressSection',
  targetcompany: 'companySection',
  webpage: 'searchEngineSection',
  image: 'pictureSection',
  financial: 'financialSection',
};

interface BaseCardProps extends HTMLAttributes<HTMLDivElement> {
  entityType: TileEntityType;
  logo?: JSX.Element | HTMLImageElement;
  title: string;
  item: AllDataArticle;
  hideArticleType?: boolean;
  score?: number;
  status?: string;
  suspicionItemId?: string;
  suspicionItems?: Array<EntitySuspicion>;
  highlight?: HighlightChunks;
  targetData?: Target;
  subtitle?: string;
}

export const BaseCard: FC<BaseCardProps> = ({
  entityType,
  logo,
  title,
  item,
  hideArticleType = false,
  children,
  score = 0,
  status,
  suspicionItemId,
  suspicionItems,
  highlight,
  targetData,
  subtitle,
  ...props
}) => {
  const { t } = useTranslation();
  const [{ customerId }] = useUserState();
  const { data: customer } = useCustomerQuery(customerId, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { ReportTailoringActions } = useReportTailoring({
    targetId: item.targetId,
  });

  const isReportCompleted =
    !!targetData?.updatedAt && !withinSpanOfDays(targetData?.updatedAt);

  const action =
    !isCustomerExpired(customer?.expiresAt) &&
    !isReportCompleted &&
    typeof suspicionItemId !== 'undefined'
      ? ReportTailoringActions.UpdateItemStatus(suspicionItemId, status)
      : !isCustomerExpired(customer?.expiresAt) &&
        !isReportCompleted &&
        ReportTailoringActions.AddToReport(item);

  const uniqueSuspicions: EntitySuspicion[] | undefined = useMemo(() => {
    const visited: Record<string, boolean> = {};
    return suspicionItems?.filter(({ suspicionId }) => {
      if (suspicionId in visited) {
        return false;
      }

      visited[suspicionId] = true;
      return true;
    });
  }, [suspicionItems]);

  const actions =
    !isCustomerExpired(customer?.expiresAt) &&
    !isReportCompleted &&
    uniqueSuspicions?.length
      ? uniqueSuspicions.map((item) => ({
          action: ReportTailoringActions.UpdateItemStatus(item.id, item.status),
          suspicionItemId: item.id,
        }))
      : null;

  return (
    <div
      className={classNames(
        'bg-white',
        'mx-0 mb-4 rounded-md border-gray-300 border-solid border',
      )}
      {...props}
    >
      <div className="flex items-start gap-3 p-6">
        {!hideArticleType ? (
          <div className="w-18">
            <EntityIcon type={getEntityType(entityType)} />
          </div>
        ) : null}
        <div
          className={classNames(
            'flex flex-col',
            score && !hideArticleType
              ? 'lg:w-2/3 md:w-1/2'
              : 'lg:w-4/5 md:w-2/3 sm:w-1/2',
          )}
        >
          <Headline className="truncate" Level="h2" weight="bold" color="dark">
            {highlight ? (
              <Highlight searchWords={highlight} textToHighlight={title} />
            ) : (
              title
            )}
          </Headline>
          {subtitle?.length ? (
            <Headline className="truncate" Level="h6">
              <Highlight searchWords={highlight} textToHighlight={subtitle} />
            </Headline>
          ) : null}
          <div className="flex justify-between">
            {!hideArticleType ? (
              <Paragraph
                className="inline-block align-top"
                size="medium"
                weight="semibold"
                color="default"
              >
                {t(entityTypeName[entityType])}
              </Paragraph>
            ) : null}
            {logo}
          </div>
        </div>
        {!uniqueSuspicions ? (
          <div className="flex items-center gap-3 text-gray-500 ml-auto">
            {score > 0 && (
              <Paragraph>
                {t('relevanceScore')}:{' '}
                <span className="text-green-500">{[score, '%'].join('')}</span>
              </Paragraph>
            )}
            {action}
          </div>
        ) : null}
      </div>
      <div>{children}</div>
      {uniqueSuspicions ? (
        <div className="bg-gray-50">
          <div className="text-sm px-3 pt-2 font-semibold">
            {t('relatedSuspicion')}
          </div>
          {uniqueSuspicions.map((item: EntitySuspicion, index: number) => (
            <div
              className={classNames(
                'text-sm p-3 flex items-start',
                index !== 0 ? 'border-t border-gray-300' : '',
              )}
              key={item.id}
            >
              <div className="flex gap-3 py-2">
                <DynamicSVG
                  src={`icons/suspicions/${item.suspicion.label}`}
                  width={16}
                  className="inline-block"
                />
                {t(`suspicionKeywords.${item.suspicion.label}`, {
                  defaultValue: item.suspicion.name,
                })}
              </div>
              <div className="flex items-center gap-3 text-gray-500 ml-auto">
                {item.score ? (
                  <Paragraph>
                    {t('relevanceScore')}:{' '}
                    <span className="text-green-500">
                      {[item.score, '%'].join('')}
                    </span>
                  </Paragraph>
                ) : null}
                {
                  actions?.find?.(
                    (action) => action.suspicionItemId === item.id,
                  )?.action
                }
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
