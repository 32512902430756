import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PressCard } from '_organisms';
import { ResultsProps } from '../Results';
import { Error, LoadingSpinner } from '_atoms';
import { Alert, InfiniteScroller, Pagination, WorkflowError } from '_molecules';
import { SearchInput } from '_molecules';
import { SearchFilterValue } from '_types';
import { useElasticSearch } from '../../../hooks/useElasticSearch';
import { PressArticle } from 'src/services/searchService';

import pressFilters from './filters.json';
import { nonProdDataTestId } from '_utils';

const initialValues = {
  items: [],
};

const pagingEnabled = false;

type PressFilters = {
  publishedAt: SearchFilterValue;
  sentiment: SearchFilterValue;
  language: SearchFilterValue;
};

export const Press: FC<ResultsProps> = ({
  targetData,
  caseData,
}: ResultsProps) => {
  const { t } = useTranslation();
  const { caseId, targetId } =
    useParams<{ caseId: string; targetId: string }>();
  const {
    searchInputProps: { renderedFilters, ...searchInputProps },
    data: { items, paging } = initialValues,
    isLoading,
    isError,
    handlePageChange,
  } = useElasticSearch<PressFilters, PressArticle[]>({
    caseId,
    targetId,
    entityType: 'press',
    searchFilters: pressFilters,
    pagingEnabled: pagingEnabled,
  });

  return (
    <div className="mx-5">
      <SearchInput {...searchInputProps}>{renderedFilters}</SearchInput>
      <div className="flex-1">
        <WorkflowError errors={targetData?.workflowErrors} path="press" />

        {targetData?.status === 'HasInitialProfile' && (
          <Alert
            alertType="warning"
            headline={t('profileDataNotFinal')}
            className="mb-2"
          />
        )}

        <div
          className="relative mt-6 z-10"
          data-testid={nonProdDataTestId('press')}
        >
          {isLoading ? (
            <div className="absolute top-0 flex justify-center w-full z-0">
              <LoadingSpinner message={t('profileLoading')} />
            </div>
          ) : isError ? (
            <Error
              headline={t('profileErrorHeadline')}
              message={t('profileErrorRetryMessage')}
            />
          ) : null}
          {items.length ? (
            <>
              {items.map((item: PressArticle) => (
                <PressCard
                  key={item.id}
                  data={item}
                  hideArticleType={true}
                  query={searchInputProps.value}
                  caseData={caseData}
                  targetData={targetData}
                />
              ))}
              {paging?.pagesCount > 1 &&
                (pagingEnabled ? (
                  <div className="px-5">
                    <Pagination
                      paging={paging}
                      setPageNumber={(page) => handlePageChange(page)}
                    />
                  </div>
                ) : (
                  <InfiniteScroller
                    paging={paging}
                    isLoading={isLoading}
                    setPageNumber={(page) => handlePageChange(page)}
                  />
                ))}
            </>
          ) : isLoading ? null : (
            <Alert alertType="info" message={t('noData')} />
          )}
        </div>
      </div>
    </div>
  );
};
