import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryObserverBaseResult } from 'react-query';

/**
 * @TODO: use more strict type definitions
 */
type MutationResult = QueryObserverBaseResult<any, any>;
type MutationErrorsProps = {
  mutations: Record<string, any>;
};
type MutationErrorsExport = {
  errors: string[];
};

const filterErrorMutations = ({ isError, error }: MutationResult) =>
  isError && error;

export const useMutationErrors: ({
  mutations,
}: MutationErrorsProps) => MutationErrorsExport = ({ mutations }) => {
  const { t } = useTranslation();

  const apiErrors = t('apiErrors', {
    returnObjects: true,
  }) as Record<number | string, string>;

  const getMutationError = useCallback(
    ({ error }: MutationResult) => {
      let tKey = 'default';

      if (error.isAxiosError) {
        const status = error.response && error.response.status;
        tKey = apiErrors[status] ? status.toString() : tKey;
      }

      const errorMessage = apiErrors[tKey];
      return errorMessage;
    },
    [apiErrors],
  );

  const getErrorsFromMutations = (mutations: Record<string, any>) =>
    Object.values(mutations).filter(filterErrorMutations).map(getMutationError);

  return {
    errors: getErrorsFromMutations(mutations),
  };
};
