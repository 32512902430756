import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { CollapsibleCard } from '_molecules';
import { Paragraph, Headline, Image, Highlight } from '_atoms';
import { SearchEngineArticle } from 'src/services/searchService';
import { getCdnUrl } from '_utils';
import { HighlightChunks } from '../../../components/_atoms/Highlight/types';

interface SearchEngineItemProps extends HTMLAttributes<HTMLDivElement> {
  data: SearchEngineArticle;
  highlight: HighlightChunks;
  hideHeader?: boolean;
  expanded?: boolean;
}

export const SearchEngineItem: FC<SearchEngineItemProps> = ({
  data: { createdDate, url, description, screenshot, sources, ...data },
  hideHeader = false,
  expanded = false,
  highlight,
  ...props
}) => {
  const { t } = useTranslation();

  const sourceName: Record<string, string> = t('sourceName', {
    returnObjects: true,
  });

  return (
    <CollapsibleCard
      borderColor="border-transparent"
      header={
        <div className="flex flex-col gap-3">
          {hideHeader ? null : (
            <Headline Level="h3" weight="bold" color="dark">
              <Highlight searchWords={highlight} textToHighlight={data.title} />
            </Headline>
          )}
          <div className="row">
            <div className="col-3">
              <Paragraph size="label" color="light" weight="bold">
                {t('source')}
              </Paragraph>
              <Paragraph size="default" color="dark" weight="bold">
                {!sources?.length
                  ? t('noData')
                  : sources.map((source, i) => (
                      <span key={`${source}_${i}`} className="block">
                        <Highlight
                          searchWords={highlight}
                          textToHighlight={sourceName[source.toLowerCase()]}
                        />
                      </span>
                    ))}
              </Paragraph>
            </div>

            <div className="col-3">
              <Paragraph size="label" color="light" weight="bold">
                {t('retrievalDate')}
              </Paragraph>
              <Paragraph size="small" color="dark" weight="bold">
                {createdDate}
              </Paragraph>
            </div>
            <div className="col-5 truncate text-ellipsis">
              <Paragraph size="label" color="light" weight="bold">
                {t('url')}
              </Paragraph>
              <a
                onClick={(e) => e.stopPropagation()}
                href={url}
                rel="noreferrer"
                target="_blank"
                className="text-sm text-blue-400 transition-colors duration-300 hover:text-blue-600"
              >
                <Highlight searchWords={highlight} textToHighlight={url} />
              </a>
            </div>
          </div>
          <Paragraph size="default" color="light">
            <Highlight searchWords={highlight} textToHighlight={description} />
          </Paragraph>
        </div>
      }
      initiallyExpanded={expanded}
      {...props}
    >
      <div className="flex flex-col spacing-y-3">
        <Paragraph size="default" color="dark" className="pb-4">
          <Highlight searchWords={highlight} textToHighlight={data.plaintext} />
        </Paragraph>
        {screenshot ? (
          <Image src={getCdnUrl(screenshot)} alt={url} aspectRatio="none" />
        ) : null}
      </div>
    </CollapsibleCard>
  );
};
