import { FC, HTMLProps, forwardRef } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { AiOutlineCalendar } from 'react-icons/ai';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { de, enUS, enGB } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';

const CustomInput = forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement>>(
  ({ value, onClick }, ref) => (
    <button
      className="bg-gray-100 border-2 border-gray-300 rounded-md p-1 text-sm flex gap-2 w-full items-center"
      type="button"
      onClick={onClick}
      ref={ref}
    >
      <AiOutlineCalendar />
      <span className="px-2 w-full">{value}</span>
      <MdOutlineKeyboardArrowDown className="ml-auto" />
    </button>
  ),
);
CustomInput.displayName = 'CustomInput';

const getLocale = (language: string) => {
  // TODO: we need to think about a better way to handle this and what we want to use as defaults
  // if language is explicitly 'en-GB' then use the corresponding date-fns locale
  if (language === 'en-GB') {
    return enGB;
  }

  // if i18n.language is 'en-US' or starts with 'en' then use the date-fns enUS locale
  if (language === 'en-US' || language.startsWith('en')) {
    return enUS;
  }

  return de;
};

export const DatePicker: FC<ReactDatePickerProps> = ({
  onChange,
  dateFormat = 'dd-MM-yyyy',
  showYearDropdown = true,
  yearDropdownItemNumber = 75,
  scrollableYearDropdown = true,
  customInput = <CustomInput />,
  ...rest
}: ReactDatePickerProps) => {
  const { i18n } = useTranslation();

  const locale = getLocale(i18n.language);

  return (
    <ReactDatePicker
      onChange={onChange}
      dateFormat={dateFormat}
      customInput={customInput}
      locale={locale}
      popperClassName="z-30"
      showYearDropdown={showYearDropdown}
      yearDropdownItemNumber={yearDropdownItemNumber}
      scrollableYearDropdown={scrollableYearDropdown}
      {...rest}
    />
  );
};
