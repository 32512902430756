import { IFilterFactory } from '../../../../interfaces/filter-factory.interface';
import { BaseFilterProps } from '_types';
import SelectFilter from './filter';

class SelectFilterFactory implements IFilterFactory {
  get type() {
    return 'Select';
  }

  create({ field, item, searchState, dispatcher }: BaseFilterProps) {
    const key = [field, JSON.stringify(searchState.filters || {})].join('-');
    return (
      <SelectFilter
        key={key}
        field={field}
        item={item}
        searchState={searchState}
        dispatcher={dispatcher}
      />
    );
  }
}

export default SelectFilterFactory;
