import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DynamicSVG, LoadingSpinner } from '_atoms';
import { useApiVersionQuery } from '../../hooks/queries/useApiVersionQuery';
export const Maintenance: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  const { isError, refetch, isLoading } = useApiVersionQuery();
  const { t } = useTranslation();

  return isLoading ? (
    <div className="h-screen w-screen flex items-center justify-center">
      <LoadingSpinner />
    </div>
  ) : isError ? (
    <div className="h-screen w-screen flex flex-col items-center justify-center gap-4">
      <h1 className="text-3xl text-blue-900">{t('maintenance.title')}</h1>
      <h3 className="text-xl text-blue-900">{t('maintenance.subtitle')}</h3>
      <DynamicSVG src="icons/error-page-icon" />
      <div className="flex gap-4">
        <Button
          className="w-52"
          type="submit"
          level="primary"
          disabled={false}
          onClick={() => {
            refetch();
          }}
        >
          {t('maintenance.reload')}
        </Button>
      </div>
    </div>
  ) : (
    <>{children}</>
  );
};
