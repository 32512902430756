import { FC, useEffect, useState } from 'react';
import { MdOutlineExpandMore, MdMale, MdFemale } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Card, Checkbox, Headline, SourceInfo } from '_atoms';
import { InfoBlockItem, TargetDateOfBirth } from '_molecules';
import { Gender } from '@indicium/common';
import { countBy } from 'lodash';
import { TargetCandidateImages } from './TargetCandidateImages';
import {
  TargetCandidateSource,
  TargetCandidateInfoItem,
  DateRange,
} from '_types';
import { TargetPersonSelectionState } from '_enums';
import { getSelectionStateBorderClass, nonProdDataTestId } from '_utils';

type TargetCandidateItemProps = {
  id: string;
  name: string;
  jobTitles: string[];
  images: string[];
  info: TargetCandidateInfoItem[];
  sources?: TargetCandidateSource[];
  status?: TargetPersonSelectionState;
  disabled?: boolean;
  onStateChange: ({
    id,
    state,
  }: {
    id: string;
    state?: TargetPersonSelectionState;
  }) => void;
  onGalleryOpen: ({ images }: { images: string[] }) => void;
};

const GenderIcon: FC<{ genderInfo: TargetCandidateInfoItem | null }> = ({
  genderInfo,
}) => {
  if (!genderInfo) return null;
  const genderValuesCount = countBy(genderInfo.values);
  const [gender] = Object.keys(genderValuesCount);

  if (gender === Gender.female) {
    return <MdFemale />;
  }

  return <MdMale />;
};

export const TargetCandidateItem: FC<TargetCandidateItemProps> = ({
  id,
  name,
  jobTitles,
  info,
  images,
  sources,
  status,
  disabled,
  onStateChange,
  onGalleryOpen,
}: TargetCandidateItemProps) => {
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);

  useEffect(() => {
    // In order to accommodate the existing hasOverflow logic, this is necessary
    setIsDrawerOpen(true);
  }, []);

  const infoMap: { [key: string]: TargetCandidateInfoItem } = {};
  info
    .filter((info) => info.values.length)
    .forEach((i) => (infoMap[i.key] = i));

  const { dateRangeOfBirth, dateOfBirth, age, gender, ...restInfo } = infoMap;
  // TODO: TargetCandidateInfoItem type needs to support dateRangeOfBirth

  const handleStatusUpdate = (
    isSelected: boolean,
    status?: TargetPersonSelectionState,
  ) => {
    onStateChange({
      id,
      state: isSelected ? status : undefined,
    });
  };

  const handleTargetImageClick = () => onGalleryOpen({ images });
  const primaryInfo = Object.values(restInfo).slice(0, 3);
  const additionalInfo = Object.values(restInfo).slice(3);

  return (
    <Card
      borderColor={getSelectionStateBorderClass(status)}
      className="border-2"
      additionalHtmlAttributes={{
        'data-testid': nonProdDataTestId('target candidate card'),
      }}
    >
      <div className="row p-5 divide-x h-full">
        <div className="col-3">
          <Headline
            Level="h5"
            weight="bold"
            color="dark"
            className="flex gap-x-1 items-center"
          >
            <span>{name}</span>
            <GenderIcon genderInfo={gender} />
          </Headline>

          {jobTitles?.length > 0 && (
            <InfoBlockItem
              info={{ key: '', values: jobTitles }}
              collapsed={!isDrawerOpen}
              onOverflow={() => !hasOverflow && setHasOverflow(true)}
            />
          )}

          {sources && sources.length > 0 ? (
            <SourceInfo sources={sources} size="small" />
          ) : null}
          <div className="row mt-2">
            {dateRangeOfBirth ? (
              <TargetDateOfBirth
                dateRange={
                  dateRangeOfBirth?.values?.[0] as unknown as DateRange
                }
              />
            ) : dateOfBirth ? (
              <InfoBlockItem info={dateOfBirth} />
            ) : null}
          </div>
          <div className="row  mt-1">
            {age ? <InfoBlockItem info={age} /> : null}
          </div>
        </div>
        <div className="row col-9 xl:col-9 divide-x">
          <div className="col-8 xl:col-9">
            <div
              className="grid gap-5"
              style={{
                gridTemplateColumns: primaryInfo.find(
                  (info) => info.key === 'company',
                )
                  ? '50% 22% 22%'
                  : 'repeat(3, minmax(0, 1fr))',
              }}
            >
              {primaryInfo.map((value) => (
                <InfoBlockItem
                  info={value}
                  key={value.key}
                  collapsed={!isDrawerOpen}
                  onOverflow={() => !hasOverflow && setHasOverflow(true)}
                />
              ))}
            </div>
            {isDrawerOpen && additionalInfo.length > 0 && (
              <div className="grid grid-cols-3 gap-5 mt-5">
                {additionalInfo.map((value) => (
                  <InfoBlockItem info={value} key={value.key} />
                ))}
              </div>
            )}
          </div>
          <div className="col-4 xl:col-3">
            <div className="flex column h-full">
              <div className="w-full flex flex-col self-center">
                <TargetCandidateImages
                  images={images}
                  onClick={handleTargetImageClick}
                  className="mb-5"
                />
                <div className="flex items-center justify-center text-sm text-gray-500 font-bold">
                  <div className="flex flex-col items-start">
                    <Checkbox
                      label={t('selectCandidate')}
                      onChange={(isSelected) =>
                        handleStatusUpdate(
                          isSelected,
                          TargetPersonSelectionState.Confirmed,
                        )
                      }
                      initialIsSelected={
                        status === TargetPersonSelectionState.Confirmed
                      }
                      labelPosition="append"
                      disabled={disabled}
                      additionalHtmlAttributes={{
                        'data-testid': nonProdDataTestId(
                          'target candidate include checkbox',
                        ),
                      }}
                    />
                    <Checkbox
                      label={t('excludeCandidate')}
                      onChange={(isSelected) =>
                        handleStatusUpdate(
                          isSelected,
                          TargetPersonSelectionState.Ignored,
                        )
                      }
                      initialIsSelected={
                        status === TargetPersonSelectionState.Ignored
                      }
                      labelPosition="append"
                      className="mt-1"
                      color="red"
                      disabled={disabled}
                      additionalHtmlAttributes={{
                        'data-testid': nonProdDataTestId(
                          'target candidate exclude checkbox',
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center ml-auto w-5">
                {hasOverflow || additionalInfo.length > 0 ? (
                  <button
                    type="button"
                    className="cursor-pointer text-dark focus:outline-none"
                    onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                  >
                    <MdOutlineExpandMore
                      className={`transform ${
                        isDrawerOpen ? 'rotate-180' : 'rotate-0'
                      }`}
                    />
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
