import { FC } from 'react';
import { IconType } from 'react-icons';
import {
  RiFacebookCircleFill,
  RiInstagramLine,
  RiLinkedinBoxFill,
  RiTwitterFill,
} from 'react-icons/ri';

export type ImageItemSourceIconProps = {
  sources: string[];
};

enum ImageSource {
  Facebook = 'facebook',
  Twitter = 'twitter',
  Instagram = 'instagram',
  LinkedIn = 'linkedin',
}

const IconMap: Record<ImageSource, IconType> = {
  [ImageSource.Facebook]: RiFacebookCircleFill,
  [ImageSource.Twitter]: RiTwitterFill,
  [ImageSource.Instagram]: RiInstagramLine,
  [ImageSource.LinkedIn]: RiLinkedinBoxFill,
};

export const ImageItemSourceIcon: FC<ImageItemSourceIconProps> = ({
  sources,
}) => {
  const normalizedSources = sources.map((s) => s.toLowerCase() as ImageSource);
  const sourceIconKey = normalizedSources.find((source) => !!IconMap[source]);
  if (!sourceIconKey) return null;

  const Icon = IconMap[sourceIconKey];
  return <Icon className="text-gray-400" />;
};
