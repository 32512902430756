import { FC, useState } from 'react';
import classnames from 'classnames';

const SocialMediaImageSizeMap = {
  small: 'h-12 w-12',
  medium: 'h-16 w-16',
} as const;

type SocialMediaImageProps = {
  src?: string;
  size?: keyof typeof SocialMediaImageSizeMap;
  zoomOnHover?: boolean;
};

const FALLBACK_IMAGE = `${process.env.PUBLIC_URL}/social-media-fallback-image.png`;

export const SocialMediaImage: FC<SocialMediaImageProps> = ({
  src: inputSrc,
  size = 'medium',
  zoomOnHover = false,
}) => {
  const [src, setSrc] = useState(inputSrc);

  return (
    <img
      src={src || FALLBACK_IMAGE}
      onError={() => setSrc(FALLBACK_IMAGE)}
      className={classnames(
        'rounded-full mr-4 transform z-0 hover:z-50',
        zoomOnHover ? 'hover:scale-300' : null,
        SocialMediaImageSizeMap[size],
      )}
    />
  );
};
