import { FC, Fragment } from 'react';
import { Paragraph, SourceInfo } from '_atoms';
import { SocialMediaImage } from '_molecules';
import { TargetReportSourceType } from '@indicium/common';
import { SocialIcon } from 'react-social-icons';

export type SocialMediaAccountProps = {
  imageUrl?: string;
  name: string;
  site?: string;
  profileUrl?: string;
  sources: TargetReportSourceType[];
};

export const SocialMediaAccount: FC<SocialMediaAccountProps> = ({
  imageUrl,
  name,
  profileUrl,
  sources,
}) => {
  return (
    <Fragment>
      <div className="flex py-4 overflow-auto">
        <div className="relative">
          <SocialMediaImage src={imageUrl} />
          {profileUrl && (
            <SocialIcon
              url={profileUrl}
              fgColor="white"
              style={{
                position: 'absolute',
                width: '24px',
                height: '24px',
                bottom: 0,
                right: '12px',
              }}
            />
          )}
        </div>
        <div>
          <Paragraph weight="bold" color="dark" className="break-normal">
            {name}
          </Paragraph>

          {profileUrl && (
            <a href={profileUrl} target="_blank" rel="noreferrer">
              <Paragraph weight="bold" color="dark" className="break-normal">
                {profileUrl}
              </Paragraph>
            </a>
          )}

          <SourceInfo sources={sources} />
        </div>
      </div>
    </Fragment>
  );
};
