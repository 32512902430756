import { useQuery, UseQueryResult } from 'react-query';
import { EntityBySuspicion } from '_types';
import { suspicions } from '../../services/nestApiService';
import { ReportFiltersWithPaging } from '../../features/dashboard/Overview/ReportCard';
import { Paging } from 'src/services/dataService';

export const useSuspicionsReportItemBySuspicionQuery = (
  targetId: string,
  suspicionId: string,
  filters: ReportFiltersWithPaging,
): UseQueryResult<{ entities: EntityBySuspicion[]; paging: Paging }> =>
  useQuery(
    [
      'target',
      targetId,
      'suspicion-reports-items',
      suspicionId,
      'filters',
      filters,
    ],
    () => suspicions.items.listBySuspicion(targetId, suspicionId, filters),
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
  );
