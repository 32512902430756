import { FC } from 'react';
import { Card, Paragraph, Badge, Highlight } from '_atoms';
import { useTranslation } from 'react-i18next';
import { ImageItemSourceIcon } from './ImageItemSourceIcon';
import { ImageArticle } from '../../../services/searchService';
import { useReportTailoring } from '../../../hooks/useReportTailoring';
import { useParams } from 'react-router-dom';
import { getCdnUrl } from '_utils';

interface ImageItemProps extends ImageArticle {
  onTagClicked: (value: string) => void;
  query?: string;
}

export const ImageItem: FC<ImageItemProps> = ({
  onTagClicked,
  query,
  ...entity
}) => {
  const { targetId } = useParams<{ targetId: string }>();
  const { t } = useTranslation();
  const { ReportTailoringActions } = useReportTailoring({ targetId });

  const sourceName: Record<string, string> = t('sourceName', {
    returnObjects: true,
  });

  const {
    imageSources,
    imageMediaCdnUrl,
    imageDatePublished,
    imageAnalsisTags,
  } = entity;

  return imageMediaCdnUrl ? (
    <Card
      borderColor="border-transparent"
      className="overflow-none relative border-l-0"
    >
      <div className="absolute right-2 top-2">
        {ReportTailoringActions.AddToReport(entity)}
      </div>
      <a
        href={getCdnUrl(imageMediaCdnUrl)}
        target="_blank"
        rel="noreferrer"
        className="block w-full h-64 bg-cover bg-no-repeat bg-top"
        style={{
          backgroundImage: `url(${getCdnUrl(imageMediaCdnUrl)})`,
        }}
      ></a>
      <div className="flex items-center p-2">
        <div className="w-full">
          <Paragraph size="small" color="dark" weight="bold">
            {t('source')}:{' '}
            {imageSources
              .map((is) => sourceName[is.toLowerCase()] ?? is)
              .join(', ')}
          </Paragraph>
          <Paragraph size="small" color="light">
            {t('date')}: {imageDatePublished || t('noData')}
          </Paragraph>
        </div>
        <div className="w-5">
          <ImageItemSourceIcon sources={imageSources} />
        </div>
      </div>
      {imageAnalsisTags?.length > 0 ? (
        <div className="flex gap-2 tags p-2 pb-3 overflow-x-scroll scrollbar scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-gray-400">
          {imageAnalsisTags.map((tag, index) => (
            <Badge
              key={tag + index}
              size="small"
              onClick={() => onTagClicked(tag)}
              className="cursor-pointer hover:text-blue-400"
            >
              {query?.length ? (
                <Highlight
                  searchWords={{ searchQuery: [query] }}
                  textToHighlight={tag}
                />
              ) : (
                tag
              )}
            </Badge>
          ))}
        </div>
      ) : null}
    </Card>
  ) : null;
};
