import { Field, FieldAttributes, getIn, useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

type StyledFieldProps = FieldAttributes<unknown> & {
  label?: string;
  recommended?: boolean;
  unused?: boolean;
  maxLength?: number;
};

/**
 * Wrapper around Formik field that applies our own styling, adds a label and
 * displays any relevant required / recommended / error status.
 */
export const StyledField: FC<StyledFieldProps> = ({
  name,
  label,
  required,
  unused,
  recommended,
  children,
  placeholder,
  maxLength,
  ...rest
}) => {
  const { t } = useTranslation();
  const context = useFormikContext();

  const error = getIn(context.errors, name);
  const touched = getIn(context.touched, name);

  const annotationTextClass =
    error && touched ? 'text-red-400' : 'text-blue-400';
  const focusRingClass =
    error && touched ? 'focus:ring-red-400' : 'focus:ring-blue-400';
  const focusBorderClass =
    error && touched ? 'focus:border-red-400' : 'focus:border-blue-400';

  let annotation;
  if (error && touched) {
    annotation = t(error, { maxLength });
  } else if (required) {
    annotation = t('required');
  } else if (recommended) {
    annotation = t('recommended');
  }

  const fieldBorder = error && touched ? 'border-red-400' : 'border-gray-300';

  return (
    <div>
      <div className="flex justify-between">
        {label && (
          <label
            htmlFor={name}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
        )}
        {annotation && (
          <span className={['text-sm', annotationTextClass].join(' ')}>
            {annotation}
          </span>
        )}
      </div>
      <div className="mt-1">
        <Field
          name={unused ? ' ' : name}
          required={required}
          disabled={unused}
          className={classnames(
            'shadow-sm block w-full sm:text-sm rounded-md',
            focusRingClass,
            focusBorderClass,
            fieldBorder,
            { 'bg-gray-100 cursor-not-allowed': unused || rest.disabled },
          )}
          placeholder={unused ? t('unused') : placeholder}
          {...rest}
        >
          {children}
        </Field>
      </div>
    </div>
  );
};
