import { getI18n } from 'react-i18next';
import { fallBackLanguage } from '../i18n';

// returns the currently active language
export const getActiveLanguage = (): string => {
  const { language, store } = getI18n();
  const languageCodes = Object.keys(store.data);

  // use the precise language code if we support it
  if (languageCodes.includes(language)) {
    return language;
  }

  // use only first two characters of language code if we support it
  const twoLetterLanguageCode = language.slice(0, 2);
  if (languageCodes.includes(twoLetterLanguageCode)) {
    return twoLetterLanguageCode;
  }

  // and if that doesn't exist, use the fallback language
  return fallBackLanguage;
};

// returns a localized date string based on the current language
export const localizeDate = (date: string | Date): string => {
  const { t } = getI18n();

  return t('intlDateTime', {
    val: new Date(date),
  });
};
