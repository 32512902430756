import { Paragraph } from '_atoms';
import { FC, Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MdOutlineLogout } from 'react-icons/md';
import { IconType } from 'react-icons';
import { HiOutlineX } from 'react-icons/hi';
import classnames from 'classnames';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { useLocation } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { signout } from '../../services/authenticationService';
import { SubNavigationBar, SubNavigationBarProps } from './SubNavigationBar';
import { LanguageSwitcher } from '_molecules';
import { UserActionTypes, useUserState } from '../../context/User';

export type Navigation = Readonly<
  {
    id: string;
    name: string;
    href: string;
    icon: IconType;
    condition?: boolean;
    subNavigation?: SubNavigationBarProps;
    disabled?: boolean;
  }[]
>;

export type NarrowSidebarProps = {
  navigationItems: Navigation;
};

export const NavigationBar: FC<NarrowSidebarProps> = ({
  navigationItems,
}: NarrowSidebarProps) => {
  const { pathname } = useLocation();
  const [{ initialRoute }] = useUserState();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [subNavigation, setSubNavigation] = useState<SubNavigationBarProps>();

  const [{ user }, dispatch] = useUserState();

  const handleSignOut = async () => {
    await signout();
    await dispatch({
      type: UserActionTypes.SET,
      user: null,
    });
  };

  return (
    <Fragment>
      <div className="relative w-20 bg-blue-900 flex flex-col justify-between">
        <div className="w-full flex flex-col items-center">
          <div className="flex-shrink-0 py-4 flex items-center text-blue-400 fill-current">
            <Link to={initialRoute}>
              <Logo
                width="34"
                height="34"
                className="text-blue-400 fill-current"
              />
            </Link>
          </div>
          <div className="flex-1 mt-6 w-full px-3 space-y-3">
            {navigationItems.map(
              ({ name, href, icon: Icon, id, disabled, subNavigation }) => {
                const isActive = id === pathname.replaceAll('/', '_');

                if (isActive && subNavigation) {
                  setSubNavigation(subNavigation);
                }

                return (
                  <Link
                    key={name}
                    to={href}
                    className={classnames(
                      disabled
                        ? 'bg-gray-600 cursor-not-allowed'
                        : isActive
                        ? 'bg-blue-400 text-white'
                        : 'text-blue-100 hover:bg-blue-400 hover:text-white',
                      'fill-current group h-14 w-14 rounded-md flex flex-col justify-center items-center text-xs font-medium',
                    )}
                    aria-current={isActive ? 'page' : undefined}
                    onClick={(event) => disabled && event.preventDefault()}
                  >
                    <Icon
                      className={classnames(
                        isActive
                          ? 'text-white'
                          : 'text-blue-100 group-hover:text-white',
                        'fill-current h-6 w-6',
                      )}
                      aria-hidden="true"
                    />
                  </Link>
                );
              },
            )}
          </div>
        </div>
        <div className="w-full flex flex-col items-center gap-3 mb-3 px-3">
          <Link
            to="/profile"
            className="pt-2 pb-1 w-full relative text-center hover:bg-blue-400 rounded-md cursor-pointer"
          >
            <div className=" w-full  flex justify-center items-center mb-2">
              <div className="bg-blue-200 w-10 h-10 rounded-full flex justify-center items-center">
                <Paragraph size="medium" weight="bold" color="dark">
                  {user?.attributes?.firstname?.slice(0, 1)}
                  {user?.attributes?.lastname?.slice(0, 1)}
                </Paragraph>
              </div>
            </div>
          </Link>
          <LanguageSwitcher />
          <button
            className="w-full hover:bg-blue-400 hover:text-white p-3 fill-current group h-14 w-14 rounded-md flex flex-col justify-center items-center"
            onClick={handleSignOut}
          >
            <MdOutlineLogout
              className="text-white fill-current h-6 w-6"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="md:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative max-w-xs w-full bg-indigo-700 pt-5 pb-4 flex-1 flex flex-col">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-1 right-0 -mr-14 p-1">
                    <button
                      type="button"
                      className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <HiOutlineX
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 px-4 flex items-center">
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
                    alt="Workflow"
                  />
                </div>
                <div className="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                  <nav className="h-full flex flex-col">
                    <div className="space-y-1">
                      {navigationItems.map(({ name, href, icon: Icon, id }) => {
                        const isActive = id === pathname.replaceAll('/', '_');

                        return (
                          <NavLink
                            activeClassName="HalliHallo"
                            key={name}
                            to={href}
                            className={classnames(
                              isActive
                                ? 'bg-blue-400 text-white'
                                : 'text-blue-100 hover:bg-blue-400 hover:text-white',
                              'fill-current group py-2 px-3 rounded-md flex items-center text-sm font-medium',
                            )}
                            aria-current={isActive ? 'page' : undefined}
                          >
                            <Icon
                              className={classnames(
                                isActive
                                  ? 'text-white'
                                  : 'text-blue-400 group-hover:text-white',
                                'fill-current stroke-current mr-3 h-6 w-6',
                              )}
                              aria-hidden="true"
                            />
                            <span>{name}</span>
                          </NavLink>
                        );
                      })}
                    </div>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {subNavigation && <SubNavigationBar {...subNavigation} />}
    </Fragment>
  );
};
