import { FC, HTMLAttributes } from 'react';
import { SearchEngineItem } from '../../../features/dashboard/SearchEngines/SearchEngineItem';
import { SearchEngineArticle } from 'src/services/searchService';
import { BaseCard } from '_organisms';
import { CaseData, Target } from '../../../services/dataService';
import { EntitySuspicion } from '_types';
import { ConfidenceBar } from '_atoms';
import { buildHighlightChunks } from '../../_atoms/Highlight/public-utils';

interface SearchResultCardProps extends HTMLAttributes<HTMLDivElement> {
  data: SearchEngineArticle;
  query?: string;
  hideArticleType?: boolean;
  action?: JSX.Element;
  score?: number;
  status?: string;
  suspicionItemId?: string;
  targetData?: Target;
  caseData?: CaseData;
  suspicionItems?: Array<EntitySuspicion>;
}

export const SearchResultCard: FC<SearchResultCardProps> = ({
  data,
  query,
  hideArticleType = false,
  suspicionItems,
  caseData,
  ...props
}) => {
  const { targetData } = props;

  const highlightChunks = buildHighlightChunks({
    target: targetData,
    suspicions: suspicionItems,
    searchQuery: query,
    caseKeywords: caseData?.keywords,
  });

  return (
    <BaseCard
      entityType="webpage"
      title={data.title}
      item={data}
      hideArticleType={hideArticleType}
      suspicionItems={suspicionItems}
      highlight={highlightChunks}
      {...props}
    >
      <SearchEngineItem
        data={data}
        hideHeader={true}
        expanded={!!query?.length}
        highlight={highlightChunks}
      />
      {typeof data.confidenceScore === 'number' ? (
        <ConfidenceBar
          confidenceScore={data.confidenceScore}
          className="ml-4 mb-4"
        />
      ) : null}
    </BaseCard>
  );
};
