import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Headline, Paragraph, Card } from '_atoms';
import { KeywordInput } from './KeywordInput';

interface KeywordsInputProps {
  keywords: any;
  options?: Array<string>;
  onChangeValue: (
    newKeyword: {
      value: string;
      engTranslations?: Array<string>;
    },
    index: number,
  ) => any;
  onAddKeyword: any;
  onRemoveKeyword: any;
  margins?: string;
  readOnly?: boolean;
}

export const KeywordsInput: FC<KeywordsInputProps> = ({
  keywords,
  onChangeValue,
  onAddKeyword,
  onRemoveKeyword,
  margins = '',
  readOnly = false,
}: KeywordsInputProps) => {
  const { t } = useTranslation();

  return (
    <Card
      borderColor="border-white"
      backgroundColor="bg-white"
      className={classNames('py-6 px-6', margins)}
    >
      <Headline Level="h2" weight="bold" color="dark" className="mb-14">
        {t('caseNew.suspCompInvestigation.section2MainTitle')}
      </Headline>
      <Headline Level="h3" weight="bold" color="dark" className="mb-2">
        {t('caseNew.suspCompInvestigation.section3Title')}
      </Headline>
      <Paragraph
        weight="normal"
        color="default"
        className="backgroundCheckDescription"
      >
        {t('caseNew.suspCompInvestigation.section2Description1')}
      </Paragraph>
      {keywords.map((keyword: any, keywordIndex: number) => (
        <KeywordInput
          key={`${keywordIndex}-${keyword}`}
          keyword={keyword}
          keywordIndex={keywordIndex}
          keywordsLength={keywords.length}
          onAddKeyword={onAddKeyword}
          onChangeValue={onChangeValue}
          onRemoveKeyword={onRemoveKeyword}
          readOnly={readOnly}
        />
      ))}

      {!readOnly ? (
        <div>
          <button
            disabled={!(keywords.length < 5)}
            onClick={() => onAddKeyword()}
            className={classNames(
              'my-20 rounded-3xl inline-flex items-center justify-center pl-3 pr-3 pt-1 pb-1 border border-transparent text-xl font-bold rounded-md shadow-sm text-white bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 disabled:bg-gray-300 disabled:cursor-default',
              keywords.length < 5 ? 'hover:bg-blue-600' : '',
            )}
          >
            +
          </button>
          <span
            className={classNames(
              'font-bold ml-5 cursor-pointer',
              keywords.length < 5 ? 'text-blue-400' : 'text-gray-400',
              keywords.length < 5 ? 'hover:text-blue-600' : '',
            )}
            onClick={() => onAddKeyword()}
          >
            {t('caseNew.addNewKeyword')}
          </span>
        </div>
      ) : null}
    </Card>
  );
};
