import { UserInputForCreateCustomer, Customer } from '@indicium/common';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ErrorResponse } from 'src/types/NestAPI';
import { customers } from '../../services/nestApiService';

export const useCreateCustomerMutation = (): UseMutationResult<
  Customer,
  ErrorResponse,
  UserInputForCreateCustomer
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => customers.create(payload).then((r) => r.data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes('customers');
          },
        }),
    },
  );
};
