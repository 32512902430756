import { FC, useState } from 'react';
import { GoInfo } from 'react-icons/go';
import { BiCheck } from 'react-icons/bi';
import { Headline, Card } from '_atoms';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export const GDPRPopover: FC = () => {
  const [isShowing, setIsShowing] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="relative">
      <GoInfo
        className="col-12 text-gray-400 h-10 w-10 pt-2 pr-0"
        onMouseEnter={() => setIsShowing(true)}
        onMouseLeave={() => setIsShowing(false)}
      />

      <Card
        borderColor="border-white"
        backgroundColor="bg-white"
        className={classNames(
          'py-4 px-6 absolute top-12 right-0 filter drop-shadow-lg',
          isShowing ? '' : 'hidden',
        )}
      >
        <Headline className="w-72" Level="h3" weight="bold" color="dark">
          <div className="pt-0 pr-8 inline-block">
            {t('caseNew.gdprPopover.title')}
          </div>
          <GoInfo className="col-12 text-gray-400 h-8 w-8 pt-0 pr-0" />
        </Headline>
        <div className="border-b-2 my-2" />
        <div className="flex items-center">
          <button
            disabled
            className="my-1 rounded-3xl inline-flex items-center justify-center pl-1 pr-1 pt-1 pb-1 border border-transparent text-xl font-bold rounded-md shadow-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 disabled:bg-blue-400 disabled:cursor-default"
          >
            <BiCheck className="text-white" />
          </button>
          <div className="inline-block ml-5 mt-0 w-56 text-sm">
            {t('caseNew.gdprPopover.infoText1')}
          </div>
        </div>
        <div className="flex items-center">
          <button
            disabled
            className="my-1 rounded-3xl inline-flex items-center justify-center pl-1 pr-1 pt-1 pb-1 border border-transparent text-xl font-bold rounded-md shadow-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 disabled:bg-blue-400 disabled:cursor-default"
          >
            <BiCheck className="text-white" />
          </button>
          <div className="inline-block ml-5 mt-0 w-56 text-sm">
            {t('caseNew.gdprPopover.infoText2')}
          </div>
        </div>
        <div className="flex items-center">
          <button
            disabled
            className="my-1 rounded-3xl inline-flex items-center justify-center pl-1 pr-1 pt-1 pb-1 border border-transparent text-xl font-bold rounded-md shadow-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 disabled:bg-blue-400 disabled:cursor-default"
          >
            <BiCheck className="text-white" />
          </button>
          <div className="inline-block ml-5 mt-0 w-56 text-sm">
            {t('caseNew.gdprPopover.infoText3')}
          </div>
        </div>
      </Card>
    </div>
  );
};
