import { FC, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickOutside } from '../../../hooks/useClickOutside';
import langmap from 'langmap';
import { fallBackLanguage } from '../../../i18n';
import { getActiveLanguage } from '../../../translations/utils';

export const LanguageSwitcher: FC = () => {
  const { i18n } = useTranslation();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const closeMenu = () => setMenuIsOpen(false);

  const toggleMenu = () => setMenuIsOpen((isOpenState) => !isOpenState);

  useClickOutside(containerRef, closeMenu);

  if (process.env.REACT_APP_STAGE === 'production') {
    return null;
  }

  // This fetches only the language codes of the languages we explicitly provide translations for
  const languageCodes = Object.keys(i18n.store.data);

  const activeLanguage = getActiveLanguage();

  return (
    <div className="w-full flex justify-center relative" ref={containerRef}>
      <button
        className={
          'text-blue-200 focus:outline-none cursor-pointer hover:text-blue-300 transition-colors duration-200 text-sm'
        }
        onClick={toggleMenu}
      >
        {langmap[activeLanguage]?.nativeName ??
          langmap[fallBackLanguage]?.nativeName}
      </button>
      {/* if menu is open, first show active language, then show all other languages */}
      {menuIsOpen && (
        <div
          className="absolute ml-2 -top-3 left-full z-10 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none"
          style={{ minWidth: '200px' }}
        >
          <ul>
            <li className="relative bg-gray-300">
              <button
                className="cursor-default w-full text-left py-2 px-3 font-extrabold focus:outline-none"
                onClick={() => {
                  closeMenu();
                }}
              >
                {langmap[activeLanguage]?.nativeName ??
                  langmap[fallBackLanguage]?.nativeName}
              </button>
            </li>
            {languageCodes
              .filter((languageCode) => languageCode !== activeLanguage)
              .map((languageCode) => (
                <li
                  key={languageCode}
                  className="cursor-pointer relative hover:bg-gray-100"
                >
                  <button
                    className="w-full text-left py-2 px-3 focus:outline-none"
                    onClick={() => {
                      i18n.changeLanguage(languageCode);
                      localStorage.setItem('i18nextLng', languageCode);
                      closeMenu();
                    }}
                  >
                    {langmap[languageCode]?.nativeName ??
                      langmap[fallBackLanguage]?.nativeName}
                  </button>
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};
