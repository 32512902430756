import { FC } from 'react';
import {
  CustomerAdmin,
  CustomerAdminNew,
  CustomerAdminUpdate,
} from './features/admin/CustomerAdmin';
import { UserAdmin } from './features/admin/UserAdmin';
import { EditCase } from './features/admin/UserAdmin/EditCase';
import { Authentication } from './features/authentication';
import { ChangePassword } from './features/authentication/ChangePassword';
import { AssignCaseAgents } from './features/cases/AssignCaseAgents';
import { CaseNew } from './features/cases/CaseNew';
import { CaseShow } from './features/cases/CaseShow';
import { CasesList } from './features/cases/CasesList';
import { NetworkGraphSection } from './features/dashboard/NetworkGraph';
import { Press } from './features/dashboard/Press';
import { Profile } from './features/dashboard/Profile';
import { Results } from './features/dashboard/Results';
import { AllDataSearch } from './features/dashboard/AllDataSearch';
import { SocialMedia } from './features/dashboard/SocialMedia';
import { EditProfile } from './features/profile';
import { TargetCandidates } from './features/targets/TargetCandidates';
import { TargetNew } from './features/targets/TargetNew';
import { TargetShow } from './features/targets/TargetShow';
import { Overview } from './features/dashboard/Overview';
import { CV } from './features/dashboard/CV';
import { Images } from './features/dashboard/Images/Images';
import { Registerdaten } from './features/dashboard/Registerdaten/Registerdaten';
import { SearchEngines } from './features/dashboard/SearchEngines/SearchEngines';
import {
  TrackingPageCategory,
  TrackingPageType,
} from './features/tracking/Tracking';
import {
  User,
  UserGroup,
  USER_GROUP_ADMINISTRATORS,
  USER_GROUP_SUPER_USERS,
} from './services/authenticationService';
import { Customer } from '@indicium/common';
import { isCustomerExpired } from '_utils';
import { InputData } from './features/dashboard/InputData';
import { Candidates } from './features/dashboard/Candidates';
import { CaseData } from './features/dashboard/CaseData';

export type RouteConditionProperties = {
  user: User | null;
  userRoles: UserGroup[];
  props?: Record<string, unknown>;
  customer?: Customer;
};

export type RouteProperties = {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: FC<any>;
  condition?: (props: RouteConditionProperties) => boolean;
  fallbackPath?: string;
  weight: number;
  tracking: {
    category: TrackingPageCategory;
    type: TrackingPageType;
    name: string;
  };
  props?: Record<string, unknown>;
};

export const fallbackPath = '/signin';

export type Route = Record<string, RouteProperties>;

export const routes: Route = {
  signin: {
    weight: 0,
    path: '/signin',
    component: Authentication,
    condition: ({ user }) => !user,
    fallbackPath: '/',
    tracking: {
      category: TrackingPageCategory.authentication,
      type: TrackingPageType.login,
      name: 'credentials',
    },
  },
  profile: {
    weight: 0,
    path: '/profile',
    component: EditProfile,
    condition: ({ user }) => !!user,
    fallbackPath: '/',
    tracking: {
      category: TrackingPageCategory.authentication,
      type: TrackingPageType.profile,
      name: 'credentials',
    },
  },
  changePassword: {
    weight: 0,
    path: '/change-password',
    component: ChangePassword,
    condition: ({ user }) => !!user,
    fallbackPath: '/',
    tracking: {
      category: TrackingPageCategory.authentication,
      type: TrackingPageType.change_password,
      name: 'credentials',
    },
  },
  customerAdmin: {
    weight: 0,
    path: '/admin/customer',
    component: CustomerAdmin,
    condition: ({ userRoles }) => userRoles.includes(USER_GROUP_SUPER_USERS),
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.admin,
      type: TrackingPageType.overview,
      name: 'customers_overview',
    },
  },
  customerAdminNew: {
    weight: 0,
    path: '/admin/customer-new',
    component: CustomerAdminNew,
    condition: ({ userRoles }) => userRoles.includes(USER_GROUP_SUPER_USERS),
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.admin,
      type: TrackingPageType.create,
      name: 'create_customer',
    },
  },
  customerAdminUpdate: {
    weight: 0,
    path: '/admin/customer-update/:customerId',
    component: CustomerAdminUpdate,
    condition: ({ userRoles }) => userRoles.includes(USER_GROUP_SUPER_USERS),
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.admin,
      type: TrackingPageType.update,
      name: 'customers_overview',
    },
  },
  editCase: {
    weight: 0,
    path: '/admin/edit-case/:caseId',
    component: EditCase,
    condition: ({ userRoles }) => userRoles.includes(USER_GROUP_ADMINISTRATORS),
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.admin,
      type: TrackingPageType.create,
      name: 'edit_case',
    },
  },
  users: {
    weight: 0,
    path: '/users',
    component: UserAdmin,
    condition: ({ userRoles }) => userRoles.includes(USER_GROUP_ADMINISTRATORS),
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.admin,
      type: TrackingPageType.overview,
      name: 'users_overview',
    },
  },
  // TODO (IND-868): Unify naming between cases and case.
  //       `targetCandidates` being under `/cases` but `dashboardProfile` being
  //       under `/case` is super weird.
  casesList: {
    weight: 0,
    path: '/cases',
    component: CasesList,
    condition: ({ user }) => !!user,
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.overview,
      name: 'cases_overview',
    },
  },
  caseShow: {
    weight: 1,
    path: '/cases/:caseId',
    component: CaseShow,
    condition: ({ user }) => !!user,
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.overview,
      name: 'case_overview',
    },
  },
  caseNew: {
    weight: 2,
    path: '/cases/new',
    component: CaseNew,
    condition: ({ userRoles, customer }) =>
      userRoles.includes(USER_GROUP_ADMINISTRATORS) &&
      !isCustomerExpired(customer?.expiresAt),
    fallbackPath: '/cases',
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.create,
      name: 'create_case',
    },
  },
  caseAgentsAssign: {
    weight: 3,
    path: '/cases/:caseId/agents',
    component: AssignCaseAgents,
    condition: ({ userRoles }) => userRoles.includes(USER_GROUP_ADMINISTRATORS),
    fallbackPath: '/cases',
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.create,
      name: 'case_agents_assign',
    },
  },
  targetShow: {
    weight: 4,
    path: '/cases/:caseId/targets/:targetId',
    component: TargetShow,
    condition: ({ user }) => !!user,
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.analysis,
      name: 'loading_screen',
    },
  },
  newTargetData: {
    weight: 5,
    path: '/cases/:caseId/targets/new',
    component: TargetNew,
    condition: ({ user, customer }) =>
      !!user && !isCustomerExpired(customer?.expiresAt),
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.create,
      name: 'create_target',
    },
  },
  targetCandidates: {
    weight: 6,
    path: '/cases/:caseId/targets/:targetId/candidates',
    component: TargetCandidates,
    condition: ({ user }) => !!user,
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.create,
      name: 'resolve_ambiguities',
    },
  },
  dashboardOverview: {
    weight: 7,
    path: '/cases/:caseId/targets/:targetId/results',
    component: Results,
    condition: ({ user }) => !!user,
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.analysis,
      name: 'profile_overview',
    },
  },
};

export const resultsRoutes: Route = {
  dashboardOverview: {
    weight: 0,
    path: '/cases/:caseId/targets/:targetId/results/overview',
    component: Overview,
    condition: ({ user }) => !!user,
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.analysis,
      name: 'overview',
    },
  },
  dashboardProfile: {
    weight: 1,
    path: '/cases/:caseId/targets/:targetId/results/personal-data',
    component: Profile,
    condition: ({ user }) => !!user,
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.analysis,
      name: 'personal_data',
    },
  },
  dashboardPress: {
    weight: 3,
    path: '/cases/:caseId/targets/:targetId/results/press',
    component: Press,
    condition: ({ user, props, customer }) =>
      !!user && !props?.disabled && !isCustomerExpired(customer?.expiresAt),
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.analysis,
      name: 'press',
    },
  },
  dashboardAllDataSearch: {
    weight: 4,
    path: '/cases/:caseId/targets/:targetId/results/suchmaschine',
    component: AllDataSearch,
    condition: ({ user, props, customer }) =>
      !!user && !props?.disabled && !isCustomerExpired(customer?.expiresAt),
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.analysis,
      name: 'all_data_search',
    },
  },
  dashboardSocialMedia: {
    weight: 4,
    path: '/cases/:caseId/targets/:targetId/results/socialmedia',
    component: SocialMedia,
    condition: ({ user, props, customer }) =>
      !!user && !props?.disabled && !isCustomerExpired(customer?.expiresAt),
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.analysis,
      name: 'social_media',
    },
  },
  networkGraph: {
    weight: 5,
    path: '/cases/:caseId/targets/:targetId/results/network-graph',
    component: NetworkGraphSection,
    condition: ({ user }) => !!user,
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.analysis,
      name: 'network_graph',
    },
  },
  cV: {
    weight: 6,
    path: '/cases/:caseId/targets/:targetId/results/cv',
    component: CV,
    condition: ({ user }) => !!user,
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.analysis,
      name: 'cv',
    },
  },
  registerData: {
    weight: 7,
    path: '/cases/:caseId/targets/:targetId/results/registerdaten',
    component: Registerdaten,
    condition: ({ user, props, customer }) =>
      !!user && !props?.disabled && !isCustomerExpired(customer?.expiresAt),
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.analysis,
      name: 'companyData',
    },
  },
  images: {
    weight: 7,
    path: '/cases/:caseId/targets/:targetId/results/images',
    component: Images,
    condition: ({ user, props, customer }) =>
      !!user && !props?.disabled && !isCustomerExpired(customer?.expiresAt),
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.analysis,
      name: 'images',
    },
  },
  searchEngines: {
    weight: 8,
    path: '/cases/:caseId/targets/:targetId/results/search-engines',
    component: SearchEngines,
    condition: ({ user, props, customer }) =>
      !!user && !props?.disabled && !isCustomerExpired(customer?.expiresAt),
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.analysis,
      name: 'search-engines',
    },
  },
  dashboardCaseData: {
    weight: 1,
    path: '/cases/:caseId/targets/:targetId/results/case-data',
    component: CaseData,
    condition: ({ user }) => !!user,
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.analysis,
      name: 'case-data',
    },
  },
  dashboardInput: {
    weight: 1,
    path: '/cases/:caseId/targets/:targetId/results/input',
    component: InputData,
    condition: ({ user }) => !!user,
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.analysis,
      name: 'input',
    },
  },
  dashboardCandidates: {
    weight: 1,
    path: '/cases/:caseId/targets/:targetId/results/candidates',
    component: Candidates,
    condition: ({ user }) => !!user,
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.analysis,
      name: 'candidates',
    },
  },
  dashboardSourceOverview: {
    weight: 3,
    path: '/cases/:caseId/targets/:targetId/results/source-overview',
    component: Overview,
    condition: ({ user }) => !!user,
    fallbackPath,
    tracking: {
      category: TrackingPageCategory.case,
      type: TrackingPageType.analysis,
      name: 'source-overview',
    },
  },
};
