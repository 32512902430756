import { FC } from 'react';
import { RadioGroup } from '@headlessui/react';
import { TileRadioButton } from '_atoms';
import { OptionType } from '../../_atoms/TileRadioButton/TileRadioButton';
import { nonProdDataTestId } from '_utils';

interface TileRadioGroupProps {
  value: string;
  setValue: any;
  tileValues: Array<OptionType>;
  tileTexts: Record<
    string,
    {
      title: string;
      descriptionItems: Array<string>;
    }
  >;
  className?: string;
  disabled?: boolean;
}

export const TileRadioGroup: FC<TileRadioGroupProps> = ({
  value,
  setValue,
  tileValues,
  tileTexts,
  className = '',
  disabled = false,
}: TileRadioGroupProps) => (
  <RadioGroup
    style={{
      width: 'auto',
      height: 'auto',
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
    }}
    value={value}
    onChange={setValue}
    className={`shadow-lg ${className}`}
    disabled={disabled}
  >
    {tileValues.map((value, index) => (
      <RadioGroup.Option
        key={index}
        value={value}
        style={{ flex: 1 }}
        data-testid={nonProdDataTestId(`new case option${index + 1}`)}
      >
        {({ checked }) => (
          <TileRadioButton
            type={value}
            checked={checked}
            position={
              index === 0
                ? 'left'
                : index === tileValues.length - 1
                ? 'right'
                : 'middle'
            }
            title={tileTexts[value].title}
            descriptionItems={tileTexts[value].descriptionItems}
          />
        )}
      </RadioGroup.Option>
    ))}
  </RadioGroup>
);
