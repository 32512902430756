import { FC, Fragment } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdAddCircleOutline } from 'react-icons/md';
import { Button, Error, Headline, LoadingSpinner } from '_atoms';
import { ConfirmationModal } from '_molecules';
import { Table, CaseInfo } from '_organisms/Table';
import { routes } from '../../../routes';
import {
  useCasesQuery,
  useCustomerQuery,
  useDeleteCaseMutation,
} from '_queries';
import { AnalysisType, CaseData } from '../../../services/dataService';
import { useUserState } from '../../../context/User';
import { useDeleteMutation } from '../../../hooks/useDeleteMutation';
import { formatDate, isCustomerExpired, nonProdDataTestId } from '_utils';
import { USER_GROUP_ADMINISTRATORS } from '../../../services/authenticationService';

const prepareCases = (
  data: CaseData[],
  AnalysisTypeMap: Record<AnalysisType, string>,
): CaseInfo[] =>
  data
    .sort(
      (a, b) =>
        (b.updatedAt ? new Date(b.updatedAt).getTime() : 0) -
        (a.updatedAt ? new Date(a.updatedAt).getTime() : 0),
    )
    .map(({ id, title: caseName, analysisType, createdAt }: CaseData) => ({
      id,
      caseName,
      analysisType:
        AnalysisTypeMap[(analysisType ?? 'naturalPerson') as AnalysisType],
      createdOn: createdAt ? formatDate(createdAt) : '-',
    }));

export const CasesList: FC = () => {
  const { t } = useTranslation();

  const AnalysisTypeMap: Record<AnalysisType, string> = t('analysisValue', {
    returnObjects: true,
  });

  const deleteItemReducer = useDeleteMutation();

  const [{ enableConfirm, showModal }, dispatch] = deleteItemReducer;

  const history = useHistory();

  const [{ userRoles, customerId }] = useUserState();
  const { data: customer } = useCustomerQuery(customerId);

  const { data: casesData = [], ...casesQuery } = useCasesQuery();

  const { mutate: mutation } = useDeleteCaseMutation();

  const handleModalEvent = (event: 'confirm' | 'cancel') => {
    switch (event) {
      case 'confirm':
        dispatch({
          type: 'CONFIRM_DELETION',
          mutation,
        });
        break;
      case 'cancel':
        dispatch({ type: 'RESET' });
        break;
    }
  };

  const tableRowActions = userRoles.includes(USER_GROUP_ADMINISTRATORS)
    ? [
        {
          id: 'deleteCaseAction',
          label: t('deleteCase.action'),
          callback: (caseId: string) => {
            dispatch({ type: 'SET_DELETE_PARAMS', params: { caseId } });
          },
        },
        {
          id: 'editCaseAction',
          label: t('editCaseAction'),
          callback: (caseId: string) => {
            history.push(generatePath(routes.editCase.path, { caseId }));
          },
        },
      ]
    : undefined;

  return (
    <Fragment>
      <div className="container-fluid mb-20 px-16">
        <div className="row">
          <Headline
            Level="h1"
            color="dark"
            className="mt-8 mb-12"
            weight="bold"
          >
            {casesQuery.isError ? t('uhOh') : t('overviewOfAllCases')}
          </Headline>
        </div>

        {casesQuery.isError ? (
          <Error
            headline={t('noPageFound')}
            message={t('goBack')}
            target={{
              link: routes.casesList.path,
              text: t('back'),
            }}
          />
        ) : (
          <Fragment>
            {userRoles.includes(USER_GROUP_ADMINISTRATORS) && (
              <div className="row">
                <div className="mb-8">
                  <Link to={routes.caseNew.path}>
                    <Button
                      className="pl-4"
                      disabled={isCustomerExpired(customer?.expiresAt)}
                      data-testid={nonProdDataTestId('create case')}
                    >
                      <span className="pr-3.5">
                        <MdAddCircleOutline />
                      </span>
                      {t('createNewCase')}
                    </Button>
                  </Link>
                </div>
              </div>
            )}

            {casesQuery.isSuccess && !casesData.length ? (
              <Error
                headline={t('noResultHeadline')}
                message={t('noResultText')}
                target={{
                  link: routes.casesList.path,
                  text: t('back'),
                }}
              />
            ) : (
              <div>
                <Table
                  headlines={[
                    t('caseName'),
                    t('analysisType'),
                    t('dateCreated'),
                  ]}
                  items={prepareCases(casesData, AnalysisTypeMap)}
                  linkOption={{
                    name: t('open'),
                    href: routes.caseShow.path,
                    param: 'caseId',
                  }}
                  layoutOptions={{
                    highlightColumns: {
                      firstColBold: true,
                      secondColBold: false,
                    },
                  }}
                  tableRowActions={tableRowActions}
                />

                <ConfirmationModal
                  body={t('deleteCase.modalText')}
                  cancelButtonText={t('deleteCase.cancelButton')}
                  confirmButtonText={t('deleteCase.confirmButton')}
                  enableConfirm={enableConfirm}
                  isOpen={showModal}
                  radioOptions={{
                    options: t('deleteCase.radioOptions', {
                      returnObjects: true,
                    }),
                    handleSelect: () =>
                      dispatch({ type: 'ENABLE_CONFIRM_BUTTON' }),
                  }}
                  title={t('deleteCase.modalTitle')}
                  handleButtonClick={handleModalEvent}
                />

                {casesQuery.isLoading && <LoadingSpinner />}
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};
