export type ColorLevel =
  | 'default'
  | 'red'
  | 'darkRed'
  | 'green'
  | 'yellow'
  | 'blue'
  | 'white';
export type WeightLevel = 'normal' | 'medium' | 'semibold' | 'bold';

export const backgroundColor: Record<ColorLevel, string> = {
  default: 'bg-white',
  green: 'bg-green-200',
  red: 'bg-red-400',
  darkRed: 'bg-red-900',
  yellow: 'bg-yellow-400',
  blue: 'bg-blue-400',
  white: 'bg-white',
};

export const textColor: Record<ColorLevel, string> = {
  default: 'text-gray-700',
  red: 'text-red-900',
  darkRed: 'text-white',
  green: 'text-green-600',
  yellow: 'text-yellow-900',
  blue: 'text-white',
  white: 'text-blue-400',
};

export const selBackgroundColor: Record<ColorLevel, string> = {
  default: 'bg-blue-400',
  green: 'bg-green-600',
  red: 'bg-red-900',
  darkRed: 'bg-white',
  yellow: 'bg-yellow-900',
  blue: 'bg-white',
  white: 'bg-blue-400',
};

export const selTextColor: Record<ColorLevel, string> = {
  default: 'text-white',
  red: 'text-red-400',
  darkRed: 'text-red-900',
  green: 'text-green-200',
  yellow: 'text-yellow-400',
  blue: 'text-blue-400',
  white: 'text-white',
};

export const checkedColor: Record<ColorLevel, string> = {
  default: 'text-blue-400',
  green: 'text-green-600',
  red: 'text-red-900',
  darkRed: 'text-white',
  yellow: 'text-yellow-900',
  blue: 'text-white',
  white: 'text-blue-400',
};

export const focusRingColor: Record<ColorLevel, string> = {
  default: 'focus:ring-blue-500',
  red: 'focus:ring-red-900',
  darkRed: 'focus:ring-white',
  green: 'focus:ring-green-600',
  yellow: 'focus:ring-yellow-900',
  blue: 'focus:ring-white',
  white: 'focus:ring-blue-400',
};

export const focusBorderColor: Record<ColorLevel, string> = {
  default: 'focus:border-blue-500',
  red: 'focus:border-red-900',
  darkRed: 'focus:border-white',
  green: 'focus:border-green-600',
  yellow: 'focus:border-yellow-900',
  blue: 'focus:border-white',
  white: 'focus:border-blue-400',
};

export const textWeight: Record<WeightLevel, string> = {
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
};
