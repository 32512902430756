import React, { FC, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Headline, Paragraph, Select, SelectV2 } from '_atoms';
import classnames from 'classnames';
import { SummaryReport } from '_types';
import { CollapsibleCard } from '_molecules';
import { ReportCardBySuspicion } from './ReportCardBySuspicion';
import { ReportCardByEntity } from './ReportCardByEntity';
import { SuspicionItemEntityType, SuspicionItemStatus } from '_enums';
import { CaseData, Target } from '../../../services/dataService';
import { nonProdDataTestId } from '_utils';

type ReportCardProps = {
  report: SummaryReport;
  targetId: string;
  accessToken?: string;
  className?: string;
  targetData?: Target;
  caseData?: CaseData;
  additionalHtmlAttributes?: Record<string, string | undefined>;
  activeReportSection: {
    sectionId: string;
    suspicionId: string;
  };
};

export interface ReportFilters {
  suspicionItemId?: string;
  score?: string;
  entityType?: SuspicionItemEntityType;
  status?: SuspicionItemStatus;
}

export interface ReportFiltersWithPaging extends ReportFilters {
  page: number;
  pageSize: number;
}

type ReportGroupingOption = 'entity' | 'suspicion';

interface ReportOption {
  id: number;
  label: string;
  value: ReportGroupingOption;
}

const reportOptions: Array<ReportOption> = [
  {
    id: 1,
    label: 'entity',
    value: 'entity',
  },
  {
    id: 2,
    label: 'suspicion',
    value: 'suspicion',
  },
];

const relevanceScoreOptions = [
  { id: 1, label: '50+', value: '50' },
  { id: 2, label: '60+', value: '60' },
  { id: 3, label: '70+', value: '70' },
  { id: 4, label: '80+', value: '80' },
  { id: 5, label: '90+', value: '90' },
];

interface SelectOption {
  id: number;
  label: string;
  value: string;
}

const getInitialSelectValue = (list: SelectOption[], value: string) => {
  return list.find((item) => item.value === value);
};

export const ReportCard: FC<ReportCardProps> = ({
  report,
  targetId,
  accessToken = '',
  className,
  targetData,
  caseData,
  additionalHtmlAttributes,
  activeReportSection,
}) => {
  const { suspicionsCount, suspicions, id } = report;

  const confirmedSuspicions = useMemo(
    () =>
      suspicions
        .flatMap(({ items }) => items)
        .filter(({ status }) => status === 'problem'),
    [suspicions],
  );
  const confirmedSuspicionsCount = confirmedSuspicions.length;

  const { t } = useTranslation();

  const statusOptions = [
    SuspicionItemStatus.Problem,
    SuspicionItemStatus.Pending,
  ].map((status, index) => ({
    // + 1 is required to avoid duplicate keys when passing the options
    // to Select component with addEmptyOption as true, because
    // addEmptyOption pushes a new option with key 0 to the Select;
    id: index + 1,
    label: t(`suspicionItemStatus.${status}`),
    value: status,
  }));

  const entityOptions = (
    Object.keys(SuspicionItemEntityType) as Array<
      keyof typeof SuspicionItemEntityType
    >
  ).map((key, index) => ({
    id: index + 1,
    label: t(`suspicionItemEntityType.${SuspicionItemEntityType[key]}`),
    value: SuspicionItemEntityType[key],
  }));

  const [expanded, setExpanded] = useState<boolean>(false);
  const [groupBy, setGroupBy] = useState<ReportGroupingOption>('entity');
  const [filters, setFilters] = useState<ReportFilters>({});
  const [suspicionOptions, setSuspicionOptions] = useState<
    { id: string; label: string; value: string }[]
  >([]);

  useEffect(() => {
    const { sectionId, suspicionId } = activeReportSection;
    if (sectionId === id) {
      setFilters((prevState) => ({
        ...prevState,
        suspicionItemId: suspicionId,
      }));
      setExpanded(true);
    }
  }, [id, activeReportSection]);

  useEffect(() => {
    setSuspicionOptions(
      suspicions.map(({ id, name, label }) => ({
        id,
        value: id,
        label: t(`suspicionKeywords.${label}`, {
          defaultValue: name,
        }),
      })),
    );
  }, [suspicions, t]);

  const reportGroupingOptions = reportOptions.map((option) => ({
    ...option,
    label: t(option.label),
  }));

  const keywords = suspicions.map(
    ({ id: suspicionItemId, name, label, items }) => ({
      name,
      label,
      suspicionItemId,
      count: items.length,
    }),
  );

  const sortedKeywords = useMemo(
    () => (keywords ?? []).sort((a, b) => b.count - a.count),
    [keywords],
  );

  const KeyWordsListEl = () => {
    if (keywords?.length) {
      const visibleKeywords = sortedKeywords;
      return (
        <div>
          <Badge
            background={confirmedSuspicionsCount > 0 ? 'darkRed' : 'default'}
            size="small"
            weight="semibold"
            className="mr-4"
          >
            {t('confirmedSuspicionsCount', {
              hits: confirmedSuspicionsCount,
            })}
          </Badge>
          {visibleKeywords?.map(({ label, name, count }) => (
            <Badge
              key={label}
              background="white"
              size="small"
              className="mr-1 mt-1"
              paddingClassName="pl-2.5 pr-2 py-1"
              onClick={(e) => {
                e.stopPropagation();
                // onClick(reportId, suspicionItemId);
              }}
            >
              {t(`suspicionKeywords.${label}`, { defaultValue: name })}
              <span className="flex justify-center items-center rounded-full ml-1 bg-gray-400 text-white w-5 h-5">
                {count}
              </span>
            </Badge>
          ))}
        </div>
      );
    }
  };

  // return (
  //   <SmallReportCard
  //     key={'label'}
  //     borderColor={
  //       suspicionsCount > 0
  //         ? 'border-yellow-400'
  //         : 'border-green-400'
  //     }
  //     backgroundColor={
  //       suspicionsCount > 0 ? 'bg-yellow-200' : 'bg-white'
  //     }
  //     keywords={suspicions.map(
  //       ({ id: suspicionItemId, name, label, items }) => ({
  //         name,
  //         label,
  //         suspicionItemId,
  //         count: items.length,
  //       }),
  //     )}
  //     reportId={id}
  //     name={t(`reports.sections.${'label'}`)}
  //     // onClick={scrollToReport}
  //     // additionalHtmlAttributes={{
  //     //   'data-testid': nonProdDataTestId(
  //     //     getTestIdForReportCard(label),
  //     //   ),
  //     // }}
  //   />
  // )

  return (
    <CollapsibleCard
      id={id}
      headerBorder={
        suspicionsCount > 0 ? 'border-yellow-400' : 'border-green-400'
      }
      headerColor={suspicionsCount > 0 ? 'bg-yellow-200' : 'bg-white'}
      header={
        <div className={classnames('card-header w-full relative flex')}>
          <div className="card-title w-2/3 inline-block">
            <div className="w-full mb-2">
              <Headline
                Level="h6"
                weight="bold"
                color="medium"
                className="w-full mb-2"
              >
                {t(`reports.sections.${report.label}`)}
              </Headline>
              {KeyWordsListEl()}
            </div>
          </div>
          <div className="w-1/3 inline-block ml-auto flex justify-end items-center">
            <Paragraph
              color="dark"
              size="medium"
              className="mt-4 inline-block w-24"
            >
              {t('sortedBy')}
            </Paragraph>
            <Select
              color={suspicionsCount ? 'yellow' : 'default'}
              disabled={!suspicionsCount}
              weight={suspicionsCount ? 'semibold' : 'normal'}
              className="w-30 mt-4 ml-2 inline-block align-top"
              options={reportGroupingOptions}
              initialSelection={reportGroupingOptions[0]}
              onChange={(reportGrouping) => setGroupBy(reportGrouping)}
              dataTestId={nonProdDataTestId('see sort by options')}
              preventEventPropagation={true}
            />
          </div>
        </div>
      }
      initiallyExpanded={false}
      expandedState={expanded}
      toggleEnabled={suspicionsCount > 0}
      onToggleChange={setExpanded}
      className={classnames(
        'shadow-md',
        'border-0',
        'overflow-visible',
        className,
      )}
      additionalHtmlAttributes={additionalHtmlAttributes}
    >
      <div className="py-4 px-6">
        <div className="row mb-3">
          <div className="col-4">
            <SelectV2
              label={t('suspicion')}
              options={
                suspicionOptions?.sort((a, b) =>
                  a.label.localeCompare(b.label),
                ) ?? []
              }
              selected={suspicionOptions?.find(
                (item) => item.id === filters.suspicionItemId || '',
              )}
              addEmptyOption={true}
              onChange={(suspicionItemId) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  suspicionItemId,
                }))
              }
            />
          </div>
          <div className="col-4">
            <Select
              label={[t('relevanceScore'), '%'].join(' ')}
              options={relevanceScoreOptions}
              addEmptyOption={true}
              initialSelection={getInitialSelectValue(
                relevanceScoreOptions,
                filters.score || '',
              )}
              onChange={(score) =>
                setFilters((prevFilters) => ({ ...prevFilters, score }))
              }
            />
          </div>
          <div className="col-4">
            <Select
              label={t('entity')}
              options={entityOptions}
              initialSelection={getInitialSelectValue(
                entityOptions,
                filters.entityType || '',
              )}
              onChange={(entityType: SuspicionItemEntityType) =>
                setFilters((prevFilters) => ({ ...prevFilters, entityType }))
              }
              addEmptyOption={true}
            />
          </div>
        </div>
        <div className="row mb-3 h-16">
          <div className="col-4">
            <Select
              label={t('filters.status')}
              options={statusOptions}
              initialSelection={getInitialSelectValue(
                statusOptions,
                filters.status || '',
              )}
              onChange={(status: SuspicionItemStatus) =>
                setFilters((prevFilters) => ({ ...prevFilters, status }))
              }
              addEmptyOption={true}
            />
          </div>
        </div>

        {groupBy === 'suspicion' ? (
          <ReportCardBySuspicion
            reportId={id}
            targetId={targetId}
            filters={filters}
            accessToken={accessToken}
            targetData={targetData}
            caseData={caseData}
            report={report}
          />
        ) : (
          <ReportCardByEntity
            reportId={id}
            filters={filters}
            accessToken={accessToken}
            targetData={targetData}
            caseData={caseData}
            report={report}
          />
        )}
      </div>
    </CollapsibleCard>
  );
};
