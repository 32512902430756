import { useQuery, UseQueryResult } from 'react-query';
import { cases } from '../../services/nestApiService';
import { CaseData, CaseKeyword } from '../../services/dataService';

export const useCaseQuery = (caseId: string): UseQueryResult<CaseData> =>
  useQuery(
    ['case', caseId],
    async () => cases.findOne(caseId).then((r) => r.data),
    {
      refetchOnWindowFocus: false,
    },
  );

export const useCaseKeywordsQuery = (
  caseId: string,
): UseQueryResult<CaseKeyword> =>
  useQuery(
    ['caseKeywords', caseId],
    async () => cases.getKeywords(caseId).then((r) => r.data),
    {
      refetchOnWindowFocus: false,
    },
  );
