import { FC, ReactNode, HTMLAttributes } from 'react';
import classnames from 'classnames';

export type SizeLevel = 'default' | 'small' | 'medium';
export type WeightLevel = 'normal' | 'medium' | 'semibold' | 'bold';
export type ColorLevel =
  | 'default'
  | 'red'
  | 'darkRed'
  | 'green'
  | 'yellow'
  | 'blue'
  | 'white';

export const textSize = {
  default: 'text-sm',
  small: 'text-xs',
  medium: 'text-md',
  label: 'text-xs tracking-wider uppercase',
};

export const textWeight: Record<WeightLevel, string> = {
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
};

export const backgroundColor: Record<ColorLevel, string> = {
  default: 'bg-gray-200',
  green: 'bg-green-200',
  red: 'bg-red-400',
  darkRed: 'bg-red-900',
  yellow: 'bg-yellow-400',
  blue: 'bg-blue-400',
  white: 'bg-white',
};

export const textColor: Record<ColorLevel, string> = {
  default: 'text-gray-500',
  red: 'text-red-900',
  darkRed: 'text-white',
  green: 'text-green-600',
  yellow: 'text-yellow-900',
  blue: 'text-white',
  white: 'text-blue-400',
};

interface BadgeProps extends HTMLAttributes<HTMLSpanElement> {
  children: ReactNode;
  size?: SizeLevel;
  weight?: WeightLevel;
  background?: ColorLevel;
  paddingClassName?: string;
  marginClassName?: string;
  className?: string;
}

export const Badge: FC<BadgeProps> = ({
  children,
  size = 'default',
  weight = 'normal',
  background = 'default',
  paddingClassName = 'px-3 py-2',
  marginClassName = 'my-auto',
  className,
  ...props
}: BadgeProps) => {
  return (
    <span
      className={classnames(
        'inline-flex items-center justify-center leading-none rounded-full whitespace-nowrap',
        textSize[size],
        textWeight[weight],
        backgroundColor[background],
        textColor[background],
        paddingClassName,
        marginClassName,
        className,
      )}
      {...props}
    >
      {children}
    </span>
  );
};
