import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { Paging } from 'src/services/dataService';
import { backendBaseUrl } from '../../backendConfig.json';
import { SearchState } from '../useSearch';

export type EntityType =
  | 'press'
  | 'socialMediaPost'
  | 'socialMediaConnection'
  | 'images'
  | 'WebPage'
  | 'companies';

type BaseSearchParams = {
  caseId: string;
  targetId: string;
};
type SearchResponse<T> = {
  paging: Paging;
  items: T;
  count?: Record<string, number>;
};

const docType: Record<EntityType, string> = {
  press: 'press',
  socialMediaPost: 'SocialAccountPost',
  socialMediaConnection: 'SocialAccountConnection',
  images: 'images',
  WebPage: 'WebPage',
  companies: 'TargetCompany',
};

const baseUrl = `https://${backendBaseUrl}/v2/search`;
const searchTypePath = (type?: EntityType) => (type ? `/${docType[type]}` : '');

const buildSearchUrl = (
  caseId: string,
  targetId: string,
  page = 1,
  query = '',
  type?: EntityType,
  pageSize = 10,
  exactMatch = 0,
): string => {
  const searchUrl = baseUrl + searchTypePath(type);
  const searchParams = new URLSearchParams();
  const params: Record<string, string> = {
    caseId,
    targetId,
    page: page.toString(),
    query,
    pageSize: pageSize.toString(),
    exactMatch: exactMatch.toString(),
  };
  Object.keys(params).forEach((key: string) =>
    searchParams.append(key, params[key]),
  );

  return [searchUrl, searchParams.toString()].join('?');
};

const search = async <T, R>(
  {
    caseId,
    targetId,
    page,
    query,
    filters,
    exactMatch,
  }: BaseSearchParams & SearchState<T>,
  type?: EntityType,
  pageSize?: number,
): Promise<SearchResponse<R>> => {
  const url = buildSearchUrl(
    caseId,
    targetId,
    page,
    query,
    type,
    pageSize,
    exactMatch,
  );
  return axios.post(url, filters || {}).then((response) => response.data);
};

export const useSearchQuery = <T extends unknown, R>(
  params: BaseSearchParams & SearchState<T>,
  type?: EntityType,
  pageSize?: number,
): UseQueryResult<SearchResponse<R>, string> => {
  return useQuery(
    [`search${searchTypePath(type)}`, ...Object.values(params)],
    () => search<T, R>(params, type, pageSize),
    {
      refetchOnWindowFocus: false,
    },
  );
};
