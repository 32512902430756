import { useQuery, UseQueryResult } from 'react-query';
import { ErrorResponse } from 'src/types/NestAPI';
import { TargetCandidatesGroup, TargetGoogleCandidatesGroup } from '_types';
import { targets } from '../../services/nestApiService';

type TargetCandidates = {
  candidates: TargetCandidatesGroup[];
  googleCandidates: TargetGoogleCandidatesGroup[];
};

export const useTargetSelectedCandidatesQuery = (
  caseId: string,
  targetId: string,
): UseQueryResult<TargetCandidates, ErrorResponse> =>
  useQuery(
    ['cases', caseId, 'target', targetId, 'candidates'],
    () =>
      targets.getTargetSelectedCandidates(caseId, targetId).then((r) => r.data),
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
  );
