import { FC, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '_atoms';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Keyboard } from 'swiper';
import { NavigationMethods } from 'swiper/types';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

SwiperCore.use([Navigation, Keyboard]);

type ImageSliderProps = {
  images: string[];
};

export const ImageSlider: FC<ImageSliderProps> = ({
  images,
}: ImageSliderProps) => {
  const { t } = useTranslation();

  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  const prevRef = useRef<HTMLDivElement | null>(null);
  const nextRef = useRef<HTMLDivElement | null>(null);
  const sliderRef = useRef<HTMLDivElement | null>(null);

  const handleBeforeSliderInit = (swiper: SwiperCore) => {
    const navigation = swiper.params.navigation as NavigationMethods;
    navigation.prevEl = prevRef.current as HTMLElement;
    navigation.nextEl = nextRef.current as HTMLElement;
  };

  const handleSlideChange = (swiper: SwiperCore) =>
    setActiveSlideIndex(swiper.activeIndex);

  if (!images.length) {
    return (
      <Image
        aspectRatio="portrait"
        alt={t('fallbackProfileImage')}
        src={`${process.env.PUBLIC_URL}/target-person-fallback-image.png`}
      />
    );
  }

  return (
    <div className="image-slider relative" ref={sliderRef}>
      <Swiper
        className="overflow-y-auto"
        onBeforeInit={handleBeforeSliderInit}
        onActiveIndexChange={handleSlideChange}
        observer={true}
        observeParents={true}
        lazy={{
          loadOnTransitionStart: true,
        }}
        loopedSlides={5}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
          disabledClass: 'opacity-0',
        }}
        keyboard={{ enabled: true }}
      >
        {images.map((src, index) => (
          <SwiperSlide key={index}>
            <Image
              aspectRatio="portrait"
              alt={`${t('profileImage')} ${index + 1}`}
              src={src}
            />
          </SwiperSlide>
        ))}

        <div className="absolute w-full inset-0 flex justify-between align-middle z-10 opacity-0 hover:pointer-events-none hover:opacity-100">
          <div
            className="pointer-events-auto my-auto transition duration-500 opacity-0"
            ref={prevRef}
          >
            <button
              className="text-blue-400 p-2 focus:outline-none"
              disabled={activeSlideIndex === 0}
            >
              <MdChevronLeft />
            </button>
          </div>
          <div
            className="pointer-events-auto my-auto transition duration-500"
            ref={nextRef}
          >
            <button
              className="text-blue-400 p-2 focus:outline-none"
              disabled={activeSlideIndex === images.length - 1}
            >
              <MdChevronRight />
            </button>
          </div>
        </div>
      </Swiper>
    </div>
  );
};
