import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SuspicionStatusIcon } from '../../../../features/dashboard/Overview/SuspicionStatusIcon';
import { SuspicionItemStatus } from '_enums';
import { LoadingSpinner } from '_atoms';

export type UpdateItemStatusProps = {
  itemId: string;
  status?: SuspicionItemStatus;
  onStatusUpdate: (
    itemId: string,
    status: SuspicionItemStatus,
  ) => Promise<unknown>;
};

export const UpdateItemStatus: FC<UpdateItemStatusProps> = ({
  itemId,
  status,
  onStatusUpdate,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const statuses = Object.values(SuspicionItemStatus).filter(
    (s) => s !== status,
  );

  const handleStatusClicked = (status: SuspicionItemStatus) => {
    setLoading(true);
    onStatusUpdate(itemId, status).finally(() => setLoading(false));
  };

  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="flex flex-col gap-3">
      {statuses.map((status) => (
        <div
          className="flex items-center gap-3 cursor-pointer"
          key={status}
          onClick={() => handleStatusClicked(status)}
        >
          <SuspicionStatusIcon status={status} />
          <span>{t(['suspicionItemStatus', status].join('.'))}</span>
        </div>
      ))}
    </div>
  );
};
