import React from 'react';
import { useMergeRefs, FloatingPortal } from '@floating-ui/react';
import { useTooltipContext } from './Tooltip';
import classNames from 'classnames';

export const TooltipContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function TooltipContent(props, propRef): React.ReactElement | null {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!context.open) return null;

  const floatingProps = context.getFloatingProps(props);
  const { className, children } = props;

  return (
    <FloatingPortal>
      <div
        ref={ref}
        style={context.floatingStyles}
        className={classNames('px-2 py-2 bg-gray-200 rounded-md', className)}
        {...floatingProps}
      >
        <>{children}</>
      </div>
    </FloatingPortal>
  );
});
