import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { CaseData } from '../../services/dataService';

type Payload = Pick<CaseData, 'title' | 'userIds'>;

export const useCreateCaseMutation = (): UseMutationResult<
  CaseData,
  unknown,
  Payload
> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload) =>
      await (
        await axios.post(`https://${backendBaseUrl}/api/cases`, payload)
      ).data?.data,
    {
      onSuccess: () => queryClient.invalidateQueries('cases'),
    },
  );
};
