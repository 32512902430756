import classNames from 'classnames';
import { FC } from 'react';
import { Paragraph, WeightLevel, ColorLevel } from '_atoms';
import { nonProdDataTestId } from '_utils';

type TableDataProps = {
  text?: string | number;
  textColor?: ColorLevel;
  textWeight?: WeightLevel;
  dataTestId?: string;
  className?: string;
};

export const TableData: FC<TableDataProps> = ({
  text,
  textColor = 'default',
  textWeight = 'normal',
  dataTestId,
  className,
}: TableDataProps) => {
  return (
    <td
      className={classNames(
        'px-6',
        'py-5',
        typeof text === 'string' && text.includes('@')
          ? 'break-all'
          : 'break-word',
        typeof text === 'number' && !className?.startsWith('text-')
          ? 'text-right'
          : '',
        className,
      )}
      data-testid={nonProdDataTestId(dataTestId)}
    >
      <Paragraph size="default" weight={textWeight} color={textColor}>
        {text ?? '-'}
      </Paragraph>
    </td>
  );
};
