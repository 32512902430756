import { clone } from 'lodash';
import { BaseFilterProps } from '_types';
import { Checkbox } from '../../Checkbox/Checkbox';
import { Paragraph } from '../../Paragraph/Paragraph';
import { withTranslation, WithTranslation } from 'react-i18next';
import Filter from '../Filter';

type OptionsFilterState = {
  values: (string | number)[];
};

type OptionFilterPropsItemOption = {
  id?: string | number;
  label: string;
  value: string | number;
};

type OptionsFilterProps = {
  item: {
    title?: string;
    name: string;
    options: OptionFilterPropsItemOption[];
  };
};

class OptionsFilter extends Filter<
  BaseFilterProps & OptionsFilterProps & WithTranslation,
  OptionsFilterState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      values: [],
    };
  }

  componentDidMount() {
    const { searchState, field } = this.props;
    if (searchState.filters && searchState.filters[field]) {
      const values = searchState.filters[field].value;
      this.setState({
        values,
      });
      this.forceUpdate();
    }
  }

  handleValueChange(value: string | number, checked: boolean) {
    const { values } = this.state;
    const { field, item } = this.props;
    const option = item.options?.find((option: any) => option.value === value);
    const valuesClone = clone(values);

    if (valuesClone.includes(value) && !checked) {
      const valueIndex = valuesClone.indexOf(value);
      valuesClone.splice(valueIndex, 1);
    } else {
      valuesClone.push(option.value);
    }

    const transformedValue = this.getFilterValueJson(
      valuesClone.map((v) => v.toString()),
    );
    super.handleFilterChange(field, transformedValue);
  }

  render() {
    const { item, t } = this.props;
    const { values } = this.state;

    return (
      <div className="py-2">
        {item.title && (
          <Paragraph color="light" size="small" className="uppercase mb-1">
            {t(`filters.${item.title}`)}
          </Paragraph>
        )}
        <div className="flex gap-5">
          {item.options.map(
            (option: OptionFilterPropsItemOption, index: number) => (
              <Checkbox
                key={index}
                onChange={(checked: boolean) =>
                  this.handleValueChange(option.value, checked)
                }
                initialIsSelected={values.includes(option.value)}
                label={t(`filters.${option.label}`)}
                labelPosition="append"
              />
            ),
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(OptionsFilter);
