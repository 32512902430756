import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react';
import classnames from 'classnames';

type HeadlineLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type WeightLevel = 'normal' | 'medium' | 'semibold' | 'bold';
type LineHeightLevel = 'default' | 'small' | 'large';
type ColorLevel = 'default' | 'light' | 'medium' | 'dark' | 'white' | 'blue';

export interface HeadlineProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > {
  Level: HeadlineLevel;
  weight?: WeightLevel;
  lineHeight?: LineHeightLevel;
  color?: ColorLevel;
  children: ReactNode;
}

export const Headline: FC<HeadlineProps> = ({
  Level,
  className,
  weight = 'normal',
  lineHeight = 'default',
  color = 'default',
  children,
  ...rest
}: HeadlineProps) => {
  const styles = {
    h1: 'text-3xl leading-10 font-bold my-2',
    h2: 'text-2xl leading-8 font-medium my-1',
    h3: 'text-xl leading-7 font-medium',
    h4: 'text-lg leading-7 font-medium',
    h5: 'text-lg leading-6 font-medium',
    h6: 'text-base leading-6 font-medium',
  };

  const textWeight = {
    normal: 'font-normal',
    medium: 'font-medium',
    semibold: 'font-semibold',
    bold: 'font-bold',
  };

  const textColor = {
    default: 'text-gray-500',
    light: 'text-gray-400',
    medium: 'text-gray-700',
    dark: 'text-gray-900',
    white: 'text-white',
    blue: 'text-blue-400',
  };

  const textLineHeight = {
    default: 'leading-7',
    small: 'leading-6',
    large: 'leading-8',
  };

  return (
    <Level
      className={classnames(
        className,
        styles[Level],
        textColor[color],
        textWeight[weight],
        textLineHeight[lineHeight],
      )}
      {...rest}
    >
      {children}
    </Level>
  );
};
