/*
Next steps:
- trigger relayouting when opening a cluster
- handle clusters when fitlering by maxLevelShown
- add maxLevelShown select to clusters
- remove old d3 network graph
*/
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Network } from 'vis-network/peer';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner, Select } from '_atoms';
import { options } from './options';
import { nonProdDataTestId } from '_utils';

export type GraphNode = {
  id: string;
  label: string;
  group?: string;
  lvl: number;
  hidden?: boolean;
  shape?: string;
  size?: number;
};

export type GraphLink = {
  to: string;
  from: string;
  label: string;
};

type NetworkGraphData = {
  nodes: GraphNode[];
  edges: GraphLink[];
};

type NetworkGraphProps = {
  data: NetworkGraphData;
};

const initialMaxShownLevel = 1;

export const NetworkGraph: FC<NetworkGraphProps> = ({
  data,
}: NetworkGraphProps) => {
  const { t } = useTranslation();

  const visJsRef = useRef<HTMLDivElement | null>(null);

  const [isLayouting, setIsLayouting] = useState(true);

  const [layoutingProgress, setLayoutingProgress] =
    useState<number | null>(null);

  const [maxShownLevel, setMaxShownLevel] = useState(initialMaxShownLevel);

  const [network, setNetwork] = useState<Network | null>(null);

  // ProductLevel 0 => 'Target/Zielperson'
  // ProductLevel 1=1A+1B => 'immediate network of Target/direktes Netzwerk der Zielperson` ~ UI selection level 1
  // ProductLevel  0 ~ DataLevel === 0
  // ProductLevel 1A ~ DataLevel === 1
  // ProductLevel 1B ~ DataLevel === 2
  // ProductLevel  2 ~ DataLevel === 3
  // ... and so on
  // max(ProductLevel)  ~ max(DataLevel - 1)
  const availableSelectLevelOptions = useMemo(() => {
    const maxAvailableLevel = Math.max(...data.nodes.map(({ lvl }) => lvl));

    return [...Array(maxAvailableLevel).keys()].map((value) => ({
      id: ++value,
      label: `${value}. ${t('networkGraph.graphLevels')}`,
      value: `${value}`,
    }));
  }, [data.nodes, t]);

  useEffect(() => {
    const updatesNodes = data.nodes.filter(({ lvl }) => lvl <= maxShownLevel);
    // NOTE: Something's wrong with the vis js type definitions.
    // Creating a dataset from our GraphLink[] type should work according to the documentation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    network?.setData({ nodes: updatesNodes, edges: data.edges as any });
    network?.stabilize(100);

    // TODO: Hide clusters based on maxShownLevel
    // We loos all node positions after opening and closing a cluster
    // The clusters root node isn't on the canvas anymore and needs to be added if maxSownLevel === cluster.level
    // The
    // Object.entries(clusterRootNodes).map(([clusterId, clusterRootNode]) => {
    //   if (network?.isCluster(clusterId)) network?.openCluster(clusterId);
    //   if (clusterRootNode.level === maxShownLevel) nodes.add(clusterRootNode);
    // });
    // clusterNodesByHubsize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.nodes, data.edges, maxShownLevel]);

  useEffect(() => {
    if (visJsRef.current) {
      // NOTE: Something's wrong with the vis js types.
      // Creating to network with dataset types should work according to the documentation
      // but the type definitions don't allow for it
      const visNetwork = new Network(
        visJsRef.current,
        {
          nodes: data.nodes.filter(({ lvl }) => lvl <= initialMaxShownLevel),
          edges: data.edges,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
        options,
      );

      visNetwork.setSize('100%', '100%');
      visNetwork.stabilize(100);

      visNetwork.on('startStabilizing', () => {
        setLayoutingProgress(0);
        setIsLayouting(true);
      });
      visNetwork.on('stabilizationProgress', (e) => {
        setLayoutingProgress(Math.floor((e.iterations / e.total) * 100));
      });
      visNetwork.on('stabilizationIterationsDone', () => {
        // Intentional timeout to ensure user sees the progress indicator and avoid flashing
        setTimeout(() => {
          setLayoutingProgress(null);
          setIsLayouting(false);
        }, 400);
      });

      visNetwork.on('hoverNode', function (params) {
        // NOTE: Something's wrong with the vis js types. Canvas is acutally accessible in visNetwork
        if (visNetwork.isCluster(params.node)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (visNetwork as any).canvas.body.container.style.cursor = 'pointer';
        }
      });

      visNetwork.on('blurNode', function () {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (visNetwork as any).canvas.body.container.style.cursor = 'default';
      });

      setNetwork(visNetwork);
    }
  }, [data.nodes, data.edges, visJsRef]);

  /* IND-1029 Comment out whole (unfinished) clustering feature until it's fully specified and refined

  const [nodes] = useState(new DataSet(data.nodes));

  const [openClusters, setOpenClusters] = useState<(GraphNode | undefined)[]>([
    data.nodes.find(({ lvl }) => lvl === 0),
  ]);

  const [clusterRootNodes, setClusterRootNodes] = useState<{
    [clusterId: string]: GraphNode;
  }>({});

  const clusterNodesByHubsize = useCallback(() => {
    const nodeIds = data.nodes.map(({ id }) => id);
    const edgeCounts = nodeIds.reduce(
      (acc, curr) => ((acc[curr] = 0), acc),
      {} as { [key: string]: number },
    );

    data.edges.forEach((edge) => {
      edgeCounts[edge.from] += 1;
    });

    Object.entries(edgeCounts).map(([nodeId, edgeCount]) => {
      if (network && edgeCount > 1) {
        const clusterRootNode = data.nodes.find(({ id }) => id === nodeId);

        network?.clusterByConnection(nodeId, {
          joinCondition: ((_: unknown, childNode: GraphNode) => {
            return network.getConnectedEdges(childNode.id).length === 1;
            // "clusterByConnection will pass 2 nodeOptions objects as arguments to the joinCondition callback." but this isn't reflected in the type here
            // see: https://visjs.github.io/vis-network/docs/network/#optionsObject
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          }) as any,
          processProperties: (clusterOptions, childNodes) => {
            clusterOptions.label = `${clusterRootNode?.label} [${childNodes.length}]`;

            return clusterOptions;
          },
          clusterNodeProperties: {
            shape: 'dot',
            size: 32,
            level: clusterRootNode?.lvl,
            hidden: clusterRootNode
              ? clusterRootNode.lvl > maxShownLevel
              : false,
            color: {
              border: theme.extend.colors.black,
              background: theme.extend.colors.blue[400],
            },
          },
        });

        const clusterId = network?.findNode(nodeId)[0];
        setClusterRootNodes((currentClusterRootNodes) => ({
          ...currentClusterRootNodes,
          [clusterId]: clusterRootNode as GraphNode,
        }));
      }
    });
  }, [data.nodes, data.edges, network, maxShownLevel]);

  const selectNodeHandler = useCallback(
    (params) => {
      if (clusterRootNodes[params.nodes[0]]) {
        clusterRootNodes[params.nodes[0]] &&
          setOpenClusters([...openClusters, clusterRootNodes[params.nodes[0]]]);

        if (params.nodes.length == 1) {
          if (network?.isCluster(params.nodes[0])) {
            const clusterNodeIds = network?.getNodesInCluster(params.nodes[0]);
            network?.openCluster(params.nodes[0], {
              releaseFunction: (_, containedNodePositions) => {
                nodes.update(
                  nodes.map(({ id }) => ({
                    id,
                    hidden: !clusterNodeIds.includes(id),
                  })),
                );
                return containedNodePositions;
              },
            });
          }
        }
      }
    },
    [clusterRootNodes, network, nodes, openClusters],
  );

  useEffect(() => {
    network?.on('selectNode', selectNodeHandler);
  }, [selectNodeHandler, network]);

  useEffect(() => {
    if (network) {
      clusterNodesByHubsize();
    }
  }, [network, clusterNodesByHubsize]);
  */

  const onChangeSelectLevel = useCallback((value: string | undefined): void => {
    setIsLayouting(true);
    // The setTimeout is in hope that the select drop down can close faster. it seem stuck once in aw while for larger networks
    setTimeout(() => {
      setMaxShownLevel(value ? parseInt(value) : 1);
    }, 10);
  }, []);

  return (
    <div className="overflow-hidden" style={{ height: `calc(100vh - 180px` }}>
      <div className="absolute top-0 -mt-8 w-full z-10 flex justify-end transform -translate-y-5">
        {/* only show level select outside clusters because filter functionality inside clusters doesn't exist yet
        {openClusters.length === 1 && ( */}
        <Select
          label={t('networkGraph.graphDepth')}
          className="flex items-center space-x-4"
          initialSelection={{
            id: 1,
            label: `${maxShownLevel}. ${t('networkGraph.graphLevels')}`,
            value: `${maxShownLevel}`,
          }}
          options={availableSelectLevelOptions}
          onChange={onChangeSelectLevel}
          dataTestId="open levels"
        />
        {/* )} */}
      </div>
      <div
        ref={visJsRef}
        className="h-full bg-white"
        data-testid={nonProdDataTestId('network graph')}
      ></div>
      {isLayouting && (
        <div className="z-20 inset-0 absolute bg-black bg-opacity-50 flex justify-center items-center text-gray-400 pointer-events-none">
          <LoadingSpinner
            message={
              layoutingProgress ? (
                `${layoutingProgress} %`
              ) : (
                <span className="invisible">...</span>
              )
            }
          />
        </div>
      )}
    </div>
  );
};
