import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { Target } from '../../services/dataService';

const fetchTargets = async (caseId: string): Promise<Target[]> =>
  (await axios.get(`https://${backendBaseUrl}/cases/${caseId}/targets`)).data
    .targets;

export const useTargetsQuery = (caseId: string): UseQueryResult<Target[]> =>
  useQuery(['targets', caseId], () => fetchTargets(caseId), {
    refetchInterval: 30000,
  });
