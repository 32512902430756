import { useEffect, useState, FC, Fragment } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { MdAddCircleOutline, MdOutlineGroupAdd } from 'react-icons/md';
import { Button, Error, Headline, LoadingSpinner } from '_atoms';
import { Alert, ConfirmationModal } from '_molecules';
import { PageNotFound } from '_organisms';
import { PersonInfo, Table } from '_organisms/Table/Table';
import { generatePath, Link, useLocation, useParams } from 'react-router-dom';
import { routes } from '../../../routes';
import { useUserState } from '../../../context/User';
import { CaseToProfileBreadcrumbs } from '../../../components/Layout/CaseToProfileBreadcrumbs';
import {
  useCaseQuery,
  useTargetsQuery,
  useDeleteTargetMutation,
  useCustomerQuery,
} from '_queries';
import { Target, TargetStatus } from '../../../services/dataService';
import { formatDateTime, isCustomerExpired, nonProdDataTestId } from '_utils';
import { useDeleteMutation } from '../../../hooks/useDeleteMutation';
import { useUserQuota } from '../../../hooks/useUserQuota';
import { USER_GROUP_ADMINISTRATORS } from '../../../services/authenticationService';

const prepareCaseData = (
  data: Target[],
  t: TFunction<'translation'>,
): PersonInfo[] =>
  data
    // TODO: Allow users to sort by other fields than last updated
    .sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
    )
    .map(({ id, firstname, lastname, creator, createdAt, status }) => ({
      id,
      fullName: `${firstname} ${lastname}`,
      creator: creator ? `${creator.firstname} ${creator.lastname}` : '-',
      createdOn: formatDateTime(createdAt),
      status: t(`targetStatus.${TargetStatus[status]}`),
    }));

export const CaseShow: FC = () => {
  const deleteItemReducer = useDeleteMutation();

  const [{ enableConfirm, showModal }, dispatch] = deleteItemReducer;

  const { t } = useTranslation();
  const { caseId } = useParams<{ caseId: string }>();
  const [notificationStateAddCaseAgent, setNotificationStateAddCaseAgent] =
    useState<null | 'error' | 'success'>(null);
  const [notificationAddCaseAgent, setNotificationAddCaseAgent] =
    useState<string | null>(null);
  const location = useLocation<{ success?: string; error?: string }>();

  const [{ userRoles, customerId }] = useUserState();
  const { quota } = useUserQuota();
  const { data: customer } = useCustomerQuery(customerId);

  useEffect(() => {
    if (location.state) {
      setNotificationAddCaseAgent(Object.values(location.state)[0]);
      location.state.success && setNotificationStateAddCaseAgent('success');
      location.state.error && setNotificationStateAddCaseAgent('error');
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  const { data: caseData, ...caseQuery } = useCaseQuery(caseId);

  const { data: targetsData = [], ...targetsQuery } = useTargetsQuery(caseId);

  const { mutate: mutation } = useDeleteTargetMutation();

  const handleModalEvent = (event: 'confirm' | 'cancel') => {
    switch (event) {
      case 'confirm':
        dispatch({
          type: 'CONFIRM_DELETION',
          mutation,
        });
        break;
      case 'cancel':
        dispatch({ type: 'RESET' });
        break;
    }
  };

  const tableRowActions = userRoles.includes(USER_GROUP_ADMINISTRATORS)
    ? [
        {
          id: 'deleteTargetAction',
          label: t('deleteTarget.action'),
          callback: (targetId: string) => {
            dispatch({
              type: 'SET_DELETE_PARAMS',
              params: { caseId, targetId },
            });
          },
        },
      ]
    : undefined;

  if (caseQuery.isLoading) {
    return (
      <div className="mt-20">
        <LoadingSpinner message={t('profileLoading')} />
      </div>
    );
  }

  if (caseQuery.isError) {
    return <PageNotFound />;
  }

  return (
    <Fragment>
      <div className="container-fluid mb-20 px-16">
        {/* TODO: Implement global state for case and target person info in order to utilise
                  breadcrumbs in Layout and reduce the amount of api calls */}
        <CaseToProfileBreadcrumbs level={2} caseName={caseData?.title} />

        {notificationStateAddCaseAgent && notificationAddCaseAgent && (
          <div className="row m-0">
            <Alert
              message={notificationAddCaseAgent}
              alertType={notificationStateAddCaseAgent}
              className="mt-8"
            />
          </div>
        )}

        {!quota?.remaining && (
          <div className="row m-0">
            <Alert
              message={t('quotaExceeded')}
              alertType="warning"
              className="mt-8"
            />
          </div>
        )}

        <div className="row">
          <Headline
            Level="h1"
            color="dark"
            className="mt-8 mb-12"
            weight="bold"
          >
            {caseData?.title}
          </Headline>
        </div>

        <div className="row">
          <div className="mb-8 space-x-4">
            <Link to={generatePath(routes.newTargetData.path, { caseId })}>
              <Button
                className="pl-4"
                onClick={() => localStorage.removeItem('oldTargetId')}
                disabled={
                  !quota?.remaining || isCustomerExpired(customer?.expiresAt)
                }
                data-testid={nonProdDataTestId('create target button')}
              >
                <span className="pr-3.5">
                  <MdAddCircleOutline />
                </span>
                {t('createNewTarget')}
              </Button>
            </Link>

            {userRoles.includes(USER_GROUP_ADMINISTRATORS) && (
              <Link to={generatePath(routes.caseAgentsAssign.path, { caseId })}>
                <Button className="pl-4" level="secondary">
                  <span className="pr-3.5">
                    <MdOutlineGroupAdd />
                  </span>
                  {t('assignUserToCase')}
                </Button>
              </Link>
            )}
          </div>
        </div>

        <Fragment>
          {targetsQuery.isSuccess && !targetsData.length ? (
            <Error
              headline={t('noResultHeadline')}
              message={t('noResultText')}
              target={{
                link: routes.casesList.path,
                text: t('back'),
              }}
            />
          ) : (
            <Fragment>
              <Table
                headlines={[
                  t('targetPerson'),
                  t('creator'),
                  t('createdOn'),
                  t('status'),
                ]}
                items={prepareCaseData(targetsData, t)}
                linkOption={{
                  name: t('open'),
                  // TODO: Needs to be refactored, see IND-853 for reference
                  href: `${generatePath(`/cases/:caseId/targets`, {
                    caseId,
                  })}/:targetId`,
                  param: 'targetId',
                }}
                layoutOptions={{
                  highlightColumns: {
                    firstColBold: true,
                    secondColBold: false,
                  },
                  squashLayout: true,
                }}
                tableRowActions={tableRowActions}
              />

              <ConfirmationModal
                body={t('deleteTarget.modalText')}
                cancelButtonText={t('deleteTarget.cancelButton')}
                confirmButtonText={t('deleteTarget.confirmButton')}
                enableConfirm={enableConfirm}
                isOpen={showModal}
                radioOptions={{
                  options: t('deleteTarget.radioOptions', {
                    returnObjects: true,
                  }),
                  handleSelect: () =>
                    dispatch({ type: 'ENABLE_CONFIRM_BUTTON' }),
                }}
                title={t('deleteTarget.modalTitle')}
                handleButtonClick={handleModalEvent}
              />
              {targetsQuery.isLoading && <LoadingSpinner />}
            </Fragment>
          )}
        </Fragment>
      </div>
    </Fragment>
  );
};
