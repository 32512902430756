import { FC } from 'react';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';

export type TabsProps = {
  tabTitles: string[];
  onChange?: (index: number) => void;
  selectedIndex?: number;
};

export const Tabs: FC<TabsProps> = ({
  tabTitles,
  onChange,
  children,
  selectedIndex,
}) => {
  return (
    <div className={classNames('px-2 sm:px-0 w-full')}>
      <Tab.Group onChange={onChange} selectedIndex={selectedIndex}>
        <Tab.List className="flex bg-blue-900/20 overflow-x-auto shadow">
          {tabTitles.map((title, index) => (
            <Tab
              key={index}
              className={({ selected }) =>
                classNames(
                  'border-b-2 border-solid border-gray-200 text-gray-500 w-full py-4 px-4 leading-5 font-semibold focus:outline-none',
                  selected
                    ? 'border-blue-400 text-blue-400'
                    : 'hover:border-blue-200 hover:text-blue-200 ',
                )
              }
            >
              {title}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className={classNames('w-full')}>{children}</Tab.Panels>
      </Tab.Group>
    </div>
  );
};
