import { BaseTarget } from '@indicium/common/src/types/Target/TargetTypes';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { ProfileData } from '../../services/dataService';

export type Profile = {
  targetPerson: BaseTarget & ProfileData;
  companies: any[];
};

export type Summary = {
  en: string;
  de: string;
};

const fetchTargetResults = async (
  caseId: string,
  targetId: string,
): Promise<{
  data: Profile;
  ignored_data: Profile;
  summarisation: Summary;
}> =>
  (
    await axios.get(
      `https://${backendBaseUrl}/v2/cases/${caseId}/targets/${targetId}`,
    )
  ).data;

export const useTargetResultsQuery = (
  caseId: string,
  targetId: string,
): UseQueryResult<{
  data: Profile;
  ignored_data: Profile;
  summarisation: Summary;
  network_url: string;
}> =>
  useQuery(['target', caseId, targetId, 'results'], () =>
    fetchTargetResults(caseId, targetId),
  );
