import { Listbox } from '@headlessui/react';
import classnames from 'classnames';
import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HiCheck, HiSelector } from 'react-icons/hi';
import { nonProdDataTestId } from '_utils';
import { SelectOption } from '../Select/Selectv2';
import {
  backgroundColor,
  ColorLevel,
  focusRingColor,
  selBackgroundColor,
  selTextColor,
  textColor,
  textWeight,
  WeightLevel,
  focusBorderColor,
  checkedColor,
} from './constants';

export interface MultiSelectProps {
  selected: SelectOption[];
  options: SelectOption[];
  onChange: (selected: SelectOption[]) => void;
  disabled?: boolean;
  label?: string;
  color?: ColorLevel;
  weight?: WeightLevel;
  className?: string;
  dataTestId?: string;
  floatingOptions?: boolean;
}

export const MultiSelect: FC<MultiSelectProps> = ({
  selected,
  onChange,
  className,
  label,
  options = [],
  disabled = false,
  color = 'default',
  weight = 'normal',
  dataTestId,
  floatingOptions = true,
}) => {
  const { t } = useTranslation();

  return (
    <Listbox
      as="div"
      by="id"
      value={selected}
      onChange={onChange}
      disabled={disabled}
      multiple
    >
      <div className={className}>
        {label && (
          <Listbox.Label className="block text-sm font-medium text-gray-700">
            {label}
          </Listbox.Label>
        )}
        <div className="relative">
          <Listbox.Button
            className={classnames(
              'relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm',
              disabled
                ? 'bg-gray-200'
                : [backgroundColor[color], textColor[color]],
              textWeight[weight],
              [focusRingColor[color], focusBorderColor[color]],
            )}
            data-testid={nonProdDataTestId(dataTestId)}
          >
            <span className="block truncate">
              {`${t('filters.selected')}: ${selected.length}`}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <HiSelector
                className={classnames('h-5 w-5', [textColor[color]])}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Listbox.Options
            className={classNames(
              floatingOptions ? 'absolute' : '',
              'z-10 mt-1 w-full max-w-sm shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm',
              [backgroundColor[color], textColor[color]],
            )}
          >
            {options.map((option) => (
              <Listbox.Option
                key={option.id}
                value={option}
                className={({ active }) =>
                  classNames(
                    active
                      ? [selTextColor[color], selBackgroundColor[color]]
                      : [textColor[color], backgroundColor[color]],
                    'cursor-default select-none relative py-2 pl-3 pr-9',
                  )
                }
              >
                {({ active, selected }) => (
                  <div
                    className={classNames(
                      'flex gap-2',
                      active ? 'font-bold' : null,
                      selected ? 'font-semibold' : 'font-normal',
                    )}
                  >
                    {selected ? (
                      <span
                        className={classnames(
                          active ? selTextColor[color] : checkedColor[color],
                          'h-5 w-5',
                        )}
                      >
                        <HiCheck className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                    <span className="block truncate">{option.label}</span>
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </div>
    </Listbox>
  );
};
