import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { CaseAgentData } from '../../services/dataService';

export const useUpdateCaseAgentsMutation = (): UseMutationResult<
  CaseAgentData,
  unknown,
  { caseId: string; userIds: string[] }
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ caseId, userIds }) =>
      axios.put(`https://${backendBaseUrl}/cases/${caseId}/agents`, {
        userIds,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries('caseAgents'),
    },
  );
};
