import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState,
  Dispatch,
  FC,
} from 'react';
import { routes } from '../routes';
import {
  getUserGroups,
  User,
  UserGroup,
  USER_GROUP_SUPER_USERS,
} from '../services/authenticationService';

const STORAGE_KEY_USER = 'osint_user' as const;

interface UserState {
  user: User | null;
  userRoles: UserGroup[];
  initialRoute: string;
  customerId: string;
}

const initialState: UserState = {
  user: null,
  userRoles: [],
  initialRoute: '',
  customerId: '',
};

export enum UserActionTypes {
  SET,
}

type UserActions = { type: UserActionTypes; user: User | null };

const UserContext = createContext<[UserState, Dispatch<UserActions>]>([
  initialState,
  () => initialState,
]);

const reducer = (state: UserState, action: UserActions): UserState => {
  switch (action.type) {
    case UserActionTypes.SET: {
      const { user } = action;

      const { customerAdmin, casesList } = routes;

      const userRoles = user ? getUserGroups(user) : [];
      const customerId = user?.attributes?.customerId || '';

      const initialRoute = userRoles?.includes(USER_GROUP_SUPER_USERS)
        ? customerAdmin.path
        : casesList.path;

      if (user) {
        localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(user));
      } else {
        localStorage.removeItem(STORAGE_KEY_USER);
      }

      return {
        ...state,
        user,
        userRoles,
        initialRoute,
        customerId,
      };
    }
    default:
      return state;
  }
};

export const UserProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const stringifiedUser = localStorage.getItem(STORAGE_KEY_USER);

    if (stringifiedUser) {
      const user = JSON.parse(stringifiedUser);
      dispatch({ type: UserActionTypes.SET, user });
    }

    setloading(false);
  }, []);

  return loading ? null : (
    <UserContext.Provider value={[state, dispatch]}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserState: () => [UserState, Dispatch<UserActions>] = () =>
  useContext(UserContext);
