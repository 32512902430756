import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Target, WorkflowType } from '../../../services/dataService';
import { Alert } from '_molecules';
import { uniqBy } from 'lodash';

type WorkflowErrorProps = {
  className?: string;
  errors: Target['workflowErrors'];
  path: WorkflowType;
};

export const WorkflowError: FC<WorkflowErrorProps> = ({
  className,
  errors,
  path,
}: WorkflowErrorProps) => {
  const { t } = useTranslation();

  return errors ? (
    <div className={classnames('space-y-2', className)}>
      {uniqBy(errors, 'type')
        .filter(({ type }) => type === path)
        .map(({ id, type }) => (
          <Alert
            key={id}
            alertType="error"
            headline={`${t('workflowErrors.headline')}:`}
            message={[
              t(`workflowErrors.${type}`),
              t(`workflowErrors.appendix`),
            ].join(' ')}
          />
        ))}
    </div>
  ) : null;
};
