import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { SuspicionItemEntity } from '_types';
import { suspicions } from '../../services/nestApiService';

type Payload = {
  targetId: string;
  suspicionIds: string[];
  reportId: string;
  entity: SuspicionItemEntity;
};

export const useAddSuspicionMutation = (): UseMutationResult<
  unknown,
  unknown,
  Payload
> => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ targetId, reportId, suspicionIds, entity }: Payload) =>
      suspicions.entities.patch(targetId, reportId, suspicionIds, entity),
    {
      onSuccess: () => {
        const invalidationKeys = [
          'summary-report',
          'entities',
          'suspicion-reports-entities',
          'suspicion-reports-items',
          'suspicion-reports',
        ];
        queryClient.invalidateQueries({
          predicate: (query) => {
            const shouldInvalidate = invalidationKeys.some((key) =>
              query.queryKey.includes(key),
            );
            return shouldInvalidate;
          },
        });
      },
    },
  );
};
