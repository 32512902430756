import { Network } from '@indicium/common';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';

type NetworkQueryParams = {
  caseId: string;
  targetId: string;
};

const fetchNetwork = async ({
  caseId,
  targetId,
}: NetworkQueryParams): Promise<Network> =>
  (
    await axios.get(
      `https://${backendBaseUrl}/cases/${caseId}/targets/${targetId}/network`,
    )
  ).data;

export const useNetworkQuery = (
  params: NetworkQueryParams,
): UseQueryResult<Network> =>
  useQuery(
    ['target.network', ...Object.values(params)],
    () => fetchNetwork(params),
    { refetchOnWindowFocus: false },
  );

const fetchNetworkFromS3 = async (url?: string): Promise<Network> =>
  url ? (await axios.get(url)).data : null;

export const useNetworkS3Query = (url?: string): UseQueryResult<Network> =>
  useQuery(['target.network.s3'], () => fetchNetworkFromS3(url), {
    refetchOnWindowFocus: false,
  });
