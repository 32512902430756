import { FC, SyntheticEvent, useEffect, useState } from 'react';
import classnames from 'classnames';
import classNames from 'classnames';

type colorOptions = 'blue' | 'red';

const background: Record<colorOptions, string> = {
  blue: 'bg-blue-400',
  red: 'bg-red-500',
};
const border: Record<colorOptions, string> = {
  blue: 'border-blue-400',
  red: 'border-red-500',
};
const focusWithinBorder: Record<colorOptions, string> = {
  blue: 'focus-within:border-blue-400',
  red: 'focus-within:border-red-500',
};

export type CheckboxProps = {
  id?: string;
  label?: string;
  initialIsSelected?: boolean;
  labelPosition?: 'append' | 'prepend' | 'before' | 'after';
  centered?: boolean;
  onChange: (isSelected: boolean) => void;
  disabled?: boolean;
  className?: string;
  color?: colorOptions;
  additionalHtmlAttributes?: Record<string, string | undefined>;
};

const getClassesFromLabelPosition = (
  labelPosition: CheckboxProps['labelPosition'],
): string | null => {
  switch (labelPosition) {
    case 'prepend':
      return 'flex-row-reverse';
    case 'before':
      return 'flex-col-reverse';
    case 'after':
      return 'flex-col';
    default:
      return null;
  }
};

export const Checkbox: FC<CheckboxProps> = ({
  id,
  label,
  initialIsSelected = false,
  labelPosition = 'prepend',
  centered = true,
  onChange,
  disabled = false,
  className,
  color = 'blue',
  additionalHtmlAttributes,
}: CheckboxProps) => {
  const [isSelected, setIsSelected] = useState(initialIsSelected);
  useEffect(() => {
    setIsSelected(initialIsSelected);
  }, [initialIsSelected]);
  return (
    <label
      className={classNames(
        'flex items-center gap-2',
        getClassesFromLabelPosition(labelPosition),
        centered ? 'justify-center' : null,
        className,
      )}
      // We want the click event to be kept within the component since we're not binding onClick from outside
      onClick={(e: SyntheticEvent<HTMLLabelElement>) => e.stopPropagation()}
      {...additionalHtmlAttributes}
    >
      <div
        className={classnames(
          'relative cursor-pointer border-2 rounded',
          disabled
            ? 'border-gray-400 focus-within:border-gray-400'
            : `${border[color]} ${focusWithinBorder[color]}`,
          'w-6 h-6 flex flex-shrink-0 justify-center items-center z-20',
          isSelected
            ? disabled
              ? 'bg-gray-400'
              : background[color]
            : 'bg-white',
        )}
      >
        <input
          id={id}
          type="checkbox"
          className="absolute cursor-pointer opacity-0"
          value={isSelected ? 'on' : 'off'}
          checked={isSelected}
          onChange={() => {
            onChange(!isSelected);
            setIsSelected(!isSelected);
          }}
          disabled={disabled}
        />
        <svg
          className={`fill-current hidden p-1 w-full h-full text-white ${background[color]} pointer-events-none rounded`}
          viewBox="0 0 20 20"
        >
          <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
        </svg>
      </div>
      {label && (
        <div
          className={classnames(
            'cursor-pointer',
            'select-none',
            disabled ? 'text-gray-400' : '',
          )}
        >
          {label}
        </div>
      )}
    </label>
  );
};
