import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '_organisms/Table';
import { Headline, Input, Paragraph, LoadingSpinner } from '_atoms';
import { nonProdDataTestId } from '_utils';

export const CASE_TITLE_ALREADY_EXISTS = 'CASE_TITLE_ALREADY_EXISTS';

interface CaseNewStepOneProps {
  title: string;
  setTitle: any;
  userIds: any;
  setUserIds: any;
  usersData: any;
  usersQuery: any;
  errors?: Array<string>;
  setErrors?: any;
}

export const CaseNewStepOne: FC<CaseNewStepOneProps> = ({
  title,
  setTitle,
  userIds,
  setUserIds,
  usersData,
  usersQuery,
  errors = [],
  setErrors = null,
}: CaseNewStepOneProps) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="mb-6 rounded-md bg-white pt-5 px-6 pb-9 my-4">
        <div className="col-5">
          <Input
            required
            value={title}
            label={t('titleOfCase')}
            placeholder={t('exampleTitle')}
            onChange={({ target: { value } }) => {
              setTitle(value);
              setErrors((errors: Array<string>) =>
                errors.filter(
                  (error: string) => error !== CASE_TITLE_ALREADY_EXISTS,
                ),
              );
            }}
            data-testid={nonProdDataTestId('new case title input')}
          />
          {errors.includes(CASE_TITLE_ALREADY_EXISTS) ? (
            <div className="text-red-400 mt-2 ml-1 text-sm">
              {t('caseNameMustBeUnique')}
            </div>
          ) : null}
        </div>
      </div>

      <div className="mb-6 rounded-md bg-white pt-5 px-6 pb-9">
        <Headline Level="h4" color="dark" weight="bold">
          {t('assignUserToCase')}
        </Headline>
        <Paragraph
          size="default"
          lineHeight="default"
          weight="normal"
          color="default"
          className="mb-10"
        >
          {t('assignUserToCaseSubtext')}
        </Paragraph>
        <div className="border border-gray-200 rounded-md">
          <Table
            headlines={[t('name'), t('email')]}
            items={usersData.map(({ id, firstname, lastname, email }: any) => ({
              id,
              name: `${firstname} ${lastname}`,
              email,
            }))}
            layoutOptions={{
              highlightColumns: {
                firstColBold: false,
                secondColBold: false,
              },
            }}
            additions={{
              checkbox: true,
            }}
            selectedItems={userIds}
            setSelectedItems={setUserIds}
          />

          {usersQuery.isLoading && <LoadingSpinner />}

          {usersQuery.isError && <Paragraph>{t('noResultHeadline')}</Paragraph>}

          {usersQuery.isSuccess && !usersData.length && (
            <Paragraph>{t('noUsers')}</Paragraph>
          )}
        </div>
      </div>

      <div className="mb-12 rounded-md bg-white pt-5 px-6 pb-9">
        <Headline Level="h4" color="dark" className="mb-10" weight="bold">
          {t('thematicFieldsOfAnalysis.headline')}
        </Headline>
        <div className="mb-10">
          <Paragraph
            size="default"
            className="leading-6"
            weight="medium"
            color="dark"
          >
            {t('thematicFieldsOfAnalysis.redFlags')}
          </Paragraph>
          <Paragraph
            size="default"
            lineHeight="default"
            weight="normal"
            color="default"
          >
            {t('thematicFieldsOfAnalysis.redFlagsSubtext')}
          </Paragraph>
        </div>

        <div className="mb-10">
          <Paragraph
            size="default"
            className="leading-6"
            weight="medium"
            color="dark"
          >
            {t('thematicFieldsOfAnalysis.rawData')}
          </Paragraph>
          <Paragraph
            size="default"
            lineHeight="default"
            weight="normal"
            color="default"
          >
            {t('thematicFieldsOfAnalysis.rawDataSubtext')}
          </Paragraph>
        </div>

        <div>
          <Paragraph
            size="default"
            className="leading-6"
            weight="medium"
            color="dark"
          >
            {t('thematicFieldsOfAnalysis.tip')}
          </Paragraph>
          <Paragraph
            size="default"
            lineHeight="default"
            weight="normal"
            color="default"
          >
            {t('thematicFieldsOfAnalysis.tipSubtext')}
          </Paragraph>
        </div>
      </div>
    </Fragment>
  );
};
