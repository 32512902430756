import { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  placeholder?: string;
  recommended?: boolean;
}

export const Input: FC<InputProps> = ({
  type,
  label,
  placeholder,
  required,
  recommended,
  className,
  onChange,
  ...rest
}: InputProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex justify-between">
        {label && (
          <label
            htmlFor={label}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
        )}
        {(required || recommended) && (
          <span className="text-sm text-blue-400">
            {required ? t('required') : t('recommended')}
          </span>
        )}
      </div>
      <div className="mt-1">
        <input
          type={type ? type : 'text'}
          name={label}
          placeholder={placeholder ? placeholder : ''}
          id={label}
          required={required}
          {...rest}
          onChange={(e) => onChange && onChange(e)}
          className={classnames(
            className,
            'shadow-sm focus:ring-blue-400 focus:border-blue-400 block w-full sm:text-sm border-gray-300 rounded-md',
          )}
        />
      </div>
    </div>
  );
};
