import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '_atoms';

interface TableHeadersProps {
  headers: string[];
  columnsWidth: string[];
}

export const TableHeaders = ({
  headers,
  columnsWidth,
}: TableHeadersProps): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="flex text-white rounded-t-md bg-blue-800">
      {headers.map((header, index) => (
        <div
          key={header}
          className={classNames(
            'text-left text-sm leading-4 font-medium tracking-wider uppercase px-5 py-4',
            columnsWidth[index],
          )}
        >
          {t(header)}
        </div>
      ))}
    </div>
  );
};

interface TableBodyProps {
  children: React.ReactNode;
}

export const TableBody = ({ children }: TableBodyProps): React.ReactElement => (
  <div className="overflow-y-auto overflow-x-hidden border-b-2 border-r-2 border-l-2 border-gray-200 w-full">
    {children}
  </div>
);

interface TableRowProps {
  children: React.ReactNode;
  index: number;
}

export const TableRow = ({
  children,
  index,
}: TableRowProps): React.ReactElement => (
  <div
    className={classNames(
      'w-full flex',
      index % 2 ? 'bg-gray-100' : 'bg-white',
    )}
  >
    {children}
  </div>
);

interface TableCellProps {
  children: React.ReactNode;
  width: string;
}

export const TableCell = ({
  children,
  width,
}: TableCellProps): React.ReactElement => (
  <div className={classNames('px-5 py-5', width)}>
    <Paragraph size="default" weight="normal" color="dark">
      {children}
    </Paragraph>
  </div>
);
