import { useState, FC } from 'react';
import { generatePath, Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCreateCaseMutation, useUsersQuery } from '../../../hooks/queries';
import { Button, Card, Headline, Paragraph, DynamicSVG } from '_atoms';
import { GDPRPopover } from '_molecules';
import { routes } from '../../../routes';
import {
  CaseNewStepOne,
  CASE_TITLE_ALREADY_EXISTS,
} from './CaseNewSteps/StepOne';
import { CaseNewStepTwo } from './CaseNewSteps/StepTwo';
import classNames from 'classnames';
import { nonProdDataTestId } from '_utils';

export interface Keyword {
  value: string;
  engTranslations?: Array<string>;
}

export interface OptionProps {
  id?: string;
  option?: string;
  backgroundCheck?: 'yes' | 'no';
  publicInfoDesc?: string;
  privateSocialMedia?: boolean;
  creditRating?: boolean;
  customSearchTerms?: boolean;
  op2keywords?: Array<Keyword>;
  op3keywords?: Array<Keyword>;
}

export interface Option {
  value: 'option1' | 'option2' | 'option3' | '';
  props: OptionProps;
}

const step1Validation = (title: string) => !!title.trim();

const inputValidation = (title: string, option: Option) =>
  step1Validation(title) &&
  ((option.value === 'option1' &&
    ['yes', 'no'].includes(option.props.backgroundCheck || '')) ||
    (option.value === 'option2' &&
      option.props.publicInfoDesc &&
      (!option.props.customSearchTerms ||
        (option.props.op2keywords?.length &&
          option.props.op2keywords.some((kw) => kw.value)))) ||
    (option.value === 'option3' &&
      option.props.op3keywords?.length &&
      option.props.op3keywords.some((kw) => kw.value)));

const keywordToPayloadFormat = (kw: Keyword) => ({
  value: kw.value,
  translations:
    kw.engTranslations?.map((tr) => ({
      value: tr,
      language: 'en',
    })) || [],
});

export const CaseNew: FC = () => {
  const { t } = useTranslation();

  const [title, setTitle] = useState('');
  const [step, setStep] = useState(1);
  const [step1Errors, setStep1Errors] = useState<Array<string>>([]);
  const [option, setOption] = useState<Option>({ value: '', props: {} });

  const [userIds, setUserIds] = useState<string[]>([]);

  const { data: usersData = [], ...usersQuery } = useUsersQuery();

  if (usersQuery.isError) {
    console.error('No results found: ', usersQuery.error);
  }

  const createCaseMutation = useCreateCaseMutation();

  if (createCaseMutation.isSuccess) {
    return (
      <Redirect
        to={generatePath(routes.newTargetData.path, {
          caseId: createCaseMutation.data?.id,
        })}
      />
    );
  }

  if (createCaseMutation.isError) {
    console.warn(`properly handle:`, createCaseMutation.error);

    if (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      createCaseMutation.error?.response?.data?.error?.message ===
      CASE_TITLE_ALREADY_EXISTS
    ) {
      setTimeout(() => {
        setStep(1);
        setStep1Errors((errors) => [...errors, CASE_TITLE_ALREADY_EXISTS]);
        createCaseMutation.reset();

        document.querySelector(`#caseNewStepsFrame`)?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      }, 0);
    }
  }

  const handleAddNewCase = () => {
    const {
      backgroundCheck,
      publicInfoDesc: publicInfo,
      privateSocialMedia,
      creditRating,
      customSearchTerms,
      op2keywords,
      op3keywords,
    } = option.props;

    const payload = {
      title,
      userIds,
      option: {
        value: option.value,
        props: {
          ...(option.value === 'option1'
            ? {
                backgroundCheck: backgroundCheck === 'yes',
              }
            : {}),
          ...(option.value === 'option2'
            ? {
                publicInfo,
                privateSocialMedia: !!privateSocialMedia,
                creditRating: !!creditRating,
                customSearchTerms: !!customSearchTerms,
                ...(customSearchTerms
                  ? {
                      keywords: op2keywords?.map((keyword) =>
                        keywordToPayloadFormat(keyword),
                      ),
                    }
                  : {}),
              }
            : {}),
          ...(option.value === 'option3'
            ? {
                keywords: op3keywords?.map((keyword) =>
                  keywordToPayloadFormat(keyword),
                ),
              }
            : {}),
        },
      },
    };

    localStorage.removeItem('oldTargetId');
    createCaseMutation.mutate(payload);
  };

  const disableSubmit =
    step === 1
      ? !step1Validation(title)
      : !inputValidation(title, option) || createCaseMutation.isLoading;

  return (
    <>
      <div className="container-fluid mb-20 px-10">
        <div className="col-12 my-4 mr-0 row" id="caseNewStepsFrame">
          <div className="col-6">
            <Button
              level={step === 1 ? 'primarySlim' : 'secondarySlim'}
              onClick={() => setStep(1)}
              className="rounded-3xl"
            >
              01
            </Button>
            <span
              className={`font-bold ml-5 ${step === 1 ? 'text-blue-400' : ''}`}
            >
              {t('caseNew.stepOne')}
            </span>
          </div>
          <div className="col-5">
            <Button
              level={step === 2 ? 'primarySlim' : 'secondarySlim'}
              onClick={(e) => (!title.length ? e.preventDefault() : setStep(2))}
              className="rounded-3xl"
              disabled={!title.length}
              data-testid={nonProdDataTestId('page2 switch')}
            >
              02
            </Button>
            <span
              className={`font-bold ml-5 ${step === 2 ? 'text-blue-400' : ''}`}
            >
              {t('caseNew.stepTwo')}
            </span>
          </div>
          <div className="col-1 pr-0 flex justify-end">
            <GDPRPopover />
          </div>
        </div>
        {step === 1 ? (
          <CaseNewStepOne
            key={'step-one'}
            usersData={usersData}
            title={title}
            setTitle={setTitle}
            userIds={userIds}
            setUserIds={setUserIds}
            usersQuery={usersQuery}
            errors={step1Errors}
            setErrors={setStep1Errors}
          />
        ) : null}
        {step === 2 ? (
          <CaseNewStepTwo option={option} setOption={setOption} />
        ) : null}

        <div className="flex justify-end space-x-6">
          <div className="col-4">
            {step === 1 ? (
              <Link className="h-fit-content" to={routes.casesList.path}>
                <Button level="secondary" className="w-full">
                  {t('back')}
                </Button>
              </Link>
            ) : (
              <Button
                level="secondary"
                className="w-full"
                onClick={() => {
                  setStep((step) => step - 1);
                }}
              >
                {t('back')}
              </Button>
            )}
          </div>
          <div className="col-4">
            <Button
              disabled={disableSubmit}
              level="primary"
              className="w-full"
              onClick={() => {
                if (step === 2) {
                  handleAddNewCase();
                } else {
                  setStep((step) => step + 1);
                }
              }}
              data-testid={nonProdDataTestId('new case continue button')}
            >
              {t('continue')}
            </Button>
          </div>
        </div>

        <div className="mt-12 row">
          {['left', 'right'].map((side) => (
            <div
              key={side}
              className={classNames(
                'col-6 min-h-full',
                side === 'left' ? 'pr-6' : 'pl-6',
              )}
            >
              <Card borderColor="border-white" className="min-h-full">
                <div className="flex justify-between p-6">
                  <div></div>
                  <DynamicSVG
                    src={
                      side === 'left'
                        ? 'icons/ic-comprehensive-analysis'
                        : 'icons/ic-sources-analysis'
                    }
                    className="w-20 h-20 self-center text-gray-500"
                  />
                  <div></div>
                </div>
                <Headline
                  Level="h6"
                  weight="bold"
                  color={'dark'}
                  className="mx-5 mb-3"
                >
                  {t(`caseNew.${side}Footer.title`)}
                </Headline>
                <ul className="list-disc mx-9">
                  {[...Array(side === 'left' ? 4 : 5).keys()].map((item) => (
                    <li key={item} className="text-gray-400">
                      <Paragraph
                        key={item}
                        weight="normal"
                        color="default"
                        className="inline"
                      >
                        {t(`caseNew.${side}Footer.bullet${item}`)}
                      </Paragraph>
                    </li>
                  ))}
                </ul>
                <div className="mb-10" />
              </Card>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
