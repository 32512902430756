import { FC, Fragment } from 'react';
import { DynamicFormValueItem } from './DynamicFormValueItem';
import { DynamicFormValue } from '@indicium/common';
import { INCLUDE_KEYWORDS_LIMIT, EXCLUDE_KEYWORDS_LIMIT } from './config';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface DynamicFormValuesProps {
  includedKeywords: [string, DynamicFormValue[]][];
  excludedKeywords: [string, DynamicFormValue[]][];
  totalIncluded: number;
  totalExcluded: number;
  onRemove: (key: string, values: DynamicFormValue) => void;
}

const shouldShowLimit = (totalKeywords: number, limit: number) =>
  totalKeywords + 2 >= limit;

export const DynamicFormValues: FC<DynamicFormValuesProps> = ({
  includedKeywords,
  excludedKeywords,
  totalExcluded,
  totalIncluded,
  onRemove,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2 my-4">
      {totalIncluded ? (
        <>
          <div>
            <span className="text-gray-600 text-m">
              {t('dynamicForm.totalKeywords')}
              &nbsp;
              <span
                className={classNames(
                  totalIncluded === INCLUDE_KEYWORDS_LIMIT && 'text-red-600',
                )}
              >
                <span className="font-bold">{totalIncluded}&nbsp;</span>
                {shouldShowLimit(totalIncluded, INCLUDE_KEYWORDS_LIMIT)
                  ? t('dynamicForm.maxKeywords', {
                      maxLength: INCLUDE_KEYWORDS_LIMIT,
                    })
                  : null}
              </span>
            </span>
          </div>
          <div className="flex gap-4 flex-wrap">
            {includedKeywords.map(([field, values], index) =>
              values.map((item) => (
                <DynamicFormValueItem
                  key={field + item.id + index}
                  field={field}
                  item={item}
                  onRemove={onRemove}
                />
              )),
            )}
          </div>
        </>
      ) : null}
      {totalExcluded ? (
        <Fragment>
          <hr className="border-b border-gray-200 my-4" />
          <div>
            <span className="text-gray-600 text-m">
              {t('dynamicForm.totalKeywords')}
              &nbsp;
              <span
                className={classNames(
                  totalExcluded === EXCLUDE_KEYWORDS_LIMIT && 'text-red-600',
                )}
              >
                <span className="font-bold">{totalExcluded}&nbsp;</span>
                {shouldShowLimit(totalExcluded, EXCLUDE_KEYWORDS_LIMIT)
                  ? t('dynamicForm.maxKeywords', {
                      maxLength: EXCLUDE_KEYWORDS_LIMIT,
                    })
                  : null}
              </span>
            </span>
          </div>
          <div className="flex gap-4 p-4 border border-red-400 bg-red-100 rounded-md flex-wrap">
            {excludedKeywords.map(([field, values], index) =>
              values.map((item) => (
                <DynamicFormValueItem
                  key={field + item.id + index}
                  field={field}
                  item={item}
                  onRemove={onRemove}
                />
              )),
            )}
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};
