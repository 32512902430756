import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Card, Headline } from '_atoms';
import classnames from 'classnames';
import { noop } from 'lodash';
import { Keyword } from './types';

type SmallReportCardProps = {
  reportId: string;
  name: string;
  keywords?: Keyword[];
  borderColor?: string;
  backgroundColor?: string;
  className?: string;
  onClick?: (id: string, suspicionId?: string) => void;
  accessToken?: string;
  additionalHtmlAttributes?: Record<string, string | undefined>;
};

const KEYWORDS_COLLAPSE_LIMIT = 10;

export const SmallReportCard: FC<SmallReportCardProps> = ({
  reportId,
  name,
  className,
  backgroundColor = 'bg-white',
  borderColor = 'border-transparent',
  onClick = noop,
  additionalHtmlAttributes,
  keywords,
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const sortedKeywords = useMemo(
    () => (keywords ?? []).sort((a, b) => b.count - a.count),
    [keywords],
  );

  const KeyWordsListEl = () => {
    if (keywords?.length) {
      const onToggle: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();
        setIsOpen((prevIsOpen) => !prevIsOpen);
      };
      const isCollapsible = keywords.length > KEYWORDS_COLLAPSE_LIMIT;
      const visibleKeywords = isOpen
        ? sortedKeywords
        : sortedKeywords.slice(0, KEYWORDS_COLLAPSE_LIMIT);
      return (
        <div>
          {visibleKeywords?.map(({ label, name, count, suspicionItemId }) => (
            <Badge
              key={label}
              background="white"
              size="small"
              className="mr-1 mt-1"
              paddingClassName="pl-2.5 pr-2 py-1"
              onClick={(e) => {
                e.stopPropagation();
                onClick(reportId, suspicionItemId);
              }}
            >
              {t(`suspicionKeywords.${label}`, { defaultValue: name })}
              <span className="flex justify-center items-center rounded-full ml-1 bg-gray-400 text-white w-5 h-5">
                {count}
              </span>
            </Badge>
          ))}
          {isCollapsible ? (
            <button
              onClick={onToggle}
              className="text-blue-400 cursor-pointer focus:outline-none mt-2 block text-xs z-10 border-solid border-transparent hover:border-current border-b"
            >
              {t(isOpen ? 'reports.showLess' : 'reports.showAll')}
            </button>
          ) : null}
        </div>
      );
    }
  };

  return (
    <Card
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      className={classnames('p-4 shadow-md', className)}
      onClick={() => onClick(reportId)}
      additionalHtmlAttributes={additionalHtmlAttributes}
    >
      <div className="card-title flex justify-between w-full">
        <div>
          <Headline Level="h6" weight="bold" color="medium">
            {name}
          </Headline>
          {KeyWordsListEl()}
        </div>
      </div>
    </Card>
  );
};
