import { FC, useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCheckbox } from 'react-icons/io5';
import { IoMdSquare } from 'react-icons/io';
import classNames from 'classnames';
import { Headline, Input, LoadingSpinner } from '_atoms';
import { useKeywordTranslationQuery } from '_queries';
import { debounce } from 'lodash';
import { fetchSynonymsSuggestions } from '../../../services/dataService';
import { nonProdDataTestId } from '_utils';

interface TranslationSuggestionValue {
  translation: string;
  selected: boolean;
}

type TranslationSuggestions = Array<TranslationSuggestionValue>;

type SynonymSuggestions = Array<{
  synonym: string;
  engTranslations: Array<string>;
}>;

interface KeywordInputProps {
  keyword: any;
  keywordIndex: number;
  keywordsLength: number;
  onChangeValue: any;
  onAddKeyword: any;
  onRemoveKeyword: any;
  readOnly?: boolean;
}

export const KeywordInput: FC<KeywordInputProps> = ({
  keyword,
  keywordIndex,
  keywordsLength,
  onChangeValue,
  onAddKeyword,
  onRemoveKeyword,
  readOnly = false,
}) => {
  const { t } = useTranslation();

  const [keywordInput, setKeywordInput] = useState<string>(keyword.value);
  const [keywordQuery, setKeywordQuery] = useState<string>(keyword.value);
  const debouncedKeywordInput = useMemo(
    () => debounce(setKeywordQuery, 500),
    [setKeywordQuery],
  );

  useEffect(() => {
    debouncedKeywordInput(keywordInput);
  }, [keywordInput, debouncedKeywordInput]);

  const rawTranslationSuggestions = useKeywordTranslationQuery(keywordQuery);
  const [translationSuggestions, setTranslationSuggestions] =
    useState<TranslationSuggestions>([]);
  const [synonymSuggestions, setSynonymSuggestions] =
    useState<SynonymSuggestions>([]);
  const [isLoadingSynonyms, setIsLoadingSynonyms] = useState(false);
  const [noSynonymsFound, setNoSynonymsFound] = useState(false);

  useEffect(() => {
    const rtsData = rawTranslationSuggestions.data;

    if (!rtsData?.error) {
      if (rtsData?.suggestions.length) {
        const suggObject = rtsData?.suggestions[0];
        const keyword = Object.keys(suggObject)[0];
        const suggestions = suggObject[keyword];
        const newTranslationSuggestions = suggestions.map((trs) => ({
          translation: trs,
          selected: true,
        }));
        setTranslationSuggestions(newTranslationSuggestions);

        onChangeValue(
          {
            value: keyword,
            engTranslations: newTranslationSuggestions.map(
              (tsv) => tsv.translation,
            ),
          },
          keywordIndex,
        );
      }
    }
  }, [rawTranslationSuggestions.data, onChangeValue, keywordIndex]);

  const onSelectTranslation = useCallback(
    (keyword: { value: string; engTranslations: string }, index: number) =>
      () => {
        const newTranslationSuggestions: TranslationSuggestions =
          translationSuggestions.map((ts) =>
            ts.translation === keyword.engTranslations
              ? {
                  ...ts,
                  selected: !ts.selected,
                }
              : ts,
          );

        onChangeValue(
          {
            value: keyword.value,
            engTranslations: newTranslationSuggestions
              .filter((tsv) => tsv.selected)
              .map((tsv) => tsv.translation),
          },
          index,
        );
        setTranslationSuggestions(newTranslationSuggestions);
      },
    [onChangeValue, translationSuggestions],
  );

  useEffect(() => {
    if (keywordInput.split(/\s+/).length < 4) {
      onChangeValue({ value: keywordInput }, keywordIndex);
    }
  }, [keywordInput, keywordIndex, onChangeValue]);

  const hasSelectedTranslation: boolean = translationSuggestions.some(
    (ts) => ts.selected,
  );

  const onFetchSynonyms = useCallback(async () => {
    const selectedTranslations: Array<string> = translationSuggestions
      .filter((ts) => ts.selected)
      .map((ts) => ts.translation);
    try {
      setIsLoadingSynonyms(true);
      setNoSynonymsFound(false);
      const synonymSuggestions = await fetchSynonymsSuggestions(
        selectedTranslations,
      );
      const synonyms: SynonymSuggestions =
        synonymSuggestions?.synonyms?.reduce<SynonymSuggestions>(
          (acc, synonym) => [
            ...acc,
            ...synonym.results.reduce<SynonymSuggestions>(
              (acc, result) => [
                ...acc,
                ...result.translated.map((translated) => ({
                  synonym: translated,
                  engTranslations: [result.original],
                })),
              ],
              [],
            ),
          ],
          [],
        ) || [];

      const deduplicatedSynonyms = [
        ...new Set(synonyms.map((s) => JSON.stringify(s))),
      ].map((s) => JSON.parse(s));

      if (!deduplicatedSynonyms.length) {
        setNoSynonymsFound(true);
      }

      setSynonymSuggestions(deduplicatedSynonyms);
    } catch (ex) {
      console.error('error fetching synonyms', ex);
    }

    setIsLoadingSynonyms(false);
  }, [translationSuggestions]);

  return (
    <div key={`keyword${keywordIndex}`} className="col-12 my-4 row">
      <div className="col-12 lg:col-6 relative">
        <Headline
          Level="h6"
          weight="bold"
          color="medium"
          className="col-12 mt-4 mb-1"
        >
          {t('caseNew.keywordsTitleGerman')}
        </Headline>
        <div
          className={classNames(
            'col-12 row',
            'border-gray-300 border rounded-md padding',
            'mx-0 pb-1 px-1',
          )}
        >
          <div className="col-7 pl-0 pr-2 mt-1">
            <Input
              className={classNames(
                'border-0 outline-none',
                'focus:border-0 focus:outline-none focus:ring-0',
                'mt-0',
              )}
              onChange={(event) => setKeywordInput(event.target.value)}
              value={keyword.value ?? ''}
              placeholder={t('keyword')}
              data-testid={nonProdDataTestId('new case keyword input')}
              disabled={readOnly}
            />
          </div>
          <button
            disabled={!hasSelectedTranslation || readOnly}
            className={classNames(
              'mt-1 rounded-sm inline-block items-center justify-center px-1 pt-1 pb-1',
              'border-2 border-gray-300 rounded-md shadow-sm',
              'text-sm font-bold text-gray-700 bg-white',
              'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400',
              'disabled:bg-gray-300 disabled:cursor-default',
              'hover:bg-gray-200',
              'col-5',
            )}
            onClick={() =>
              !synonymSuggestions.length
                ? onFetchSynonyms()
                : setSynonymSuggestions([])
            }
            data-testid={nonProdDataTestId('new case keyword button')}
          >
            {!synonymSuggestions.length
              ? t('caseNew.suggestSynonyms')
              : t('caseNew.clearSynonyms')}
          </button>
        </div>
        {isLoadingSynonyms ? (
          <LoadingSpinner />
        ) : synonymSuggestions.length ? (
          <>
            <ul
              className="max-h-72 overflow-auto overflow-x-hidden"
              data-testid={nonProdDataTestId('new case keyword suggestions')}
            >
              {synonymSuggestions.map((value, index) => (
                <li
                  key={value.synonym}
                  className={classNames(
                    'p-3 bg-white cursor-pointer',
                    index % 2 === 0 ? 'bg-gray-100' : '',
                    'hover:bg-blue-400 hover:text-white',
                  )}
                  onClick={() => {
                    onAddKeyword(value.synonym);
                    setSynonymSuggestions([]);
                  }}
                >
                  <div className="font-bold">{value.synonym}</div>
                  <div>
                    {value.engTranslations.length
                      ? value.engTranslations[0]
                      : ''}
                  </div>
                </li>
              ))}
            </ul>
          </>
        ) : noSynonymsFound ? (
          <span className="text-sm float-right mr-2 mt-1 text-gray-500">
            {t('caseNew.noSynonymsFound')}
          </span>
        ) : null}
      </div>
      <div className="col-12 lg:col-6 row px-0 content-start">
        <Headline
          Level="h6"
          weight="bold"
          color="medium"
          className="col-12 ml-3 mt-4 mb-1"
        >
          {t('caseNew.keywordsTitleEnglish')}
        </Headline>
        <div className="col-8 pl-6">
          {translationSuggestions.length ? (
            translationSuggestions.map(
              (trSuggestion: TranslationSuggestionValue) => (
                <div
                  key={trSuggestion.translation}
                  className={classNames(
                    'mb-4 p-2 cursor-pointer',
                    'border-2 border-dashed border-gray-300',
                  )}
                  onClick={onSelectTranslation(
                    {
                      value: keyword.value,
                      engTranslations: trSuggestion.translation,
                    },
                    keywordIndex,
                  )}
                >
                  {trSuggestion.selected && !readOnly ? (
                    <IoCheckbox className="text-blue-400 mr-2" />
                  ) : (
                    <IoMdSquare className="text-gray-400 mr-2" />
                  )}
                  {trSuggestion.translation}
                </div>
              ),
            )
          ) : (
            <div className="text-sm pt-3 text-gray-500">
              {t('caseNew.noTranslationsFound')}
            </div>
          )}
        </div>
        <div className="col-4 pr-0 mr-0">
          <button
            disabled={!(keywordsLength > 1) || readOnly}
            onClick={((index) => () => {
              onRemoveKeyword(index);
              setTranslationSuggestions((ts) =>
                ts.filter((ts, i) => i !== index),
              );
            })(keywordIndex)}
            className={classNames(
              'mt-4 lg:mt-1 px-4 py-3',
              'inline-flex items-center justify-center',
              'border border-transparent rounded-md shadow-sm bg-red-400',
              'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400',
              'disabled:bg-gray-300 disabled:cursor-default',
              'text-sm font-bold text-white',
              keywordsLength < 5 ? 'hover:bg-red-600' : '',
              'float-right',
            )}
          >
            {t('delete')}
          </button>
        </div>
      </div>
    </div>
  );
};
