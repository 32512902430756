import { FC, useState } from 'react';
import { Checkbox, Headline, Paragraph } from '_atoms';
import { GoogleCandidate, GoogleImage, GoogleCandidateStatus } from '_types';
import classNames from 'classnames';
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from 'react-icons/md';
import { Image } from '_atoms';
import { theme } from '../../../tailwind.config';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getCdnUrl, getSelectionStateBorderClass, parseRem } from '_utils';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { TargetPersonSelectionState } from '_enums';
import { useTranslation } from 'react-i18next';

interface Props {
  candidate: GoogleCandidate;
  candidateStatus?: GoogleCandidateStatus;
  handleCandidateSelect: (state: GoogleCandidateStatus) => void;
  disabled?: boolean;
}

export const TargetGoogleCandidateItem: FC<Props> = ({
  candidate: { id, title, description, url, googleImages },
  candidateStatus,
  handleCandidateSelect,
  disabled = false,
}) => {
  const hasGoogleImage = !!googleImages?.length;
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [windowWidth] = useWindowSize();

  const handleCandidateStatusUpdate = (
    isSelected: boolean,
    status: TargetPersonSelectionState,
  ) => {
    const selectionState = isSelected ? status : undefined;
    const imageSelectionStatus =
      candidateStatus?.imageSelectionStatus ||
      (googleImages || []).map((gi) => ({
        imageId: gi.id,
      }));
    handleCandidateSelect({
      id,
      status: selectionState,
      imageSelectionStatus,
    });
    if (
      hasGoogleImage &&
      selectionState === TargetPersonSelectionState.Confirmed
    ) {
      setIsExpanded(true);
    }
  };

  const handleCandidateImageStatusUpdate = (index: number) => {
    const selectionState = TargetPersonSelectionState.Confirmed;

    handleCandidateSelect({
      id,
      status: selectionState,
      imageSelectionStatus: (candidateStatus?.imageSelectionStatus || []).map(
        (is, i) => ({
          ...is,
          imageStatus: index === i ? selectionState : is.imageStatus,
        }),
      ),
    });
  };

  return (
    <div
      className={classNames(
        'flex flex-wrap items-center',
        'border p-3 rounded-md',
        getSelectionStateBorderClass(
          candidateStatus?.status,
          'border-gray-200',
        ),
      )}
    >
      <div className={classNames(hasGoogleImage ? 'col-10' : 'col-11')}>
        <Headline Level="h3" weight="bold" color="dark">
          {title}
        </Headline>
        <Paragraph size="small" color="light">
          {description}
        </Paragraph>
        <a
          target="_blank"
          href={url}
          rel="noreferrer"
          className="text-blue-400 text-xs break-all"
        >
          {url}
        </a>
      </div>
      {hasGoogleImage ? (
        <div className="col-1">
          <a
            className={classNames(
              'flex items-center justify-center w-fit pt-0 cursor-pointer',
              'text-gray-400',
            )}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? (
              <MdOutlineKeyboardArrowUp />
            ) : (
              <MdOutlineKeyboardArrowDown />
            )}
          </a>
        </div>
      ) : null}
      <div className="col-1">
        <Checkbox
          onChange={(isSelected: boolean) =>
            handleCandidateStatusUpdate(
              isSelected,
              TargetPersonSelectionState.Confirmed,
            )
          }
          initialIsSelected={
            candidateStatus?.status === TargetPersonSelectionState.Confirmed
          }
          disabled={disabled}
        />
        <Checkbox
          onChange={(isSelected: boolean) =>
            handleCandidateStatusUpdate(
              isSelected,
              TargetPersonSelectionState.Ignored,
            )
          }
          initialIsSelected={
            candidateStatus?.status === TargetPersonSelectionState.Ignored
          }
          className="mt-1"
          color="red"
          disabled={disabled}
        />
      </div>
      {isExpanded && hasGoogleImage ? (
        <div className="flex flex-col col-12">
          <div className="w-full w-5/6 rounded pt-2 relative">
            <Swiper
              className=""
              spaceBetween={parseRem(theme.spacing[1.5])}
              slidesPerView={
                windowWidth < 800
                  ? 2.2
                  : windowWidth < 1000
                  ? 3.2
                  : windowWidth < 1500
                  ? 4.2
                  : 5.2
              }
              preventInteractionOnTransition={true}
              lazy={{
                loadOnTransitionStart: true,
              }}
            >
              {googleImages?.map((image: GoogleImage, imageIndex) => (
                <SwiperSlide
                  className="cursor-pointer group text-sm"
                  key={imageIndex}
                  onClick={() => {
                    handleCandidateImageStatusUpdate(imageIndex);
                  }}
                >
                  <Image
                    aspectRatio="portrait"
                    alt={`${image.title}`}
                    className="rounded-md"
                    src={getCdnUrl(image.url)}
                  />
                  <Checkbox
                    className="pt-2 inline-block"
                    onChange={() =>
                      handleCandidateImageStatusUpdate(imageIndex)
                    }
                    initialIsSelected={
                      candidateStatus?.imageSelectionStatus?.[imageIndex]
                        ?.imageStatus === TargetPersonSelectionState.Confirmed
                    }
                    label={t('setAsProfilePhoto')}
                    labelPosition="before"
                    disabled={disabled}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      ) : null}
    </div>
  );
};
