import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from '_atoms';
import { useSuspicionsReportEntityQuery } from '_queries';
import {
  CompanyCard,
  PictureCard,
  PressCard,
  SearchResultCard,
  SocialMediaCard,
} from '_organisms';
import { SuspicionItemEntityType, SuspicionItemStatus } from '_enums';
import { useParams } from 'react-router-dom';
import { CaseData, Target } from '../../../services/dataService';
import { Pagination } from '_molecules';
import { SummaryReport } from '_types';

const PAGE_SIZE = 10;

type ReportCardProps = {
  reportId: string;
  filters?: ReportFilters;
  accessToken?: string;
  className?: string;
  targetData?: Target;
  caseData?: CaseData;
  report?: SummaryReport;
};

type ReportFilters = {
  suspicionItemId?: string;
  score?: string;
  entityType?: SuspicionItemEntityType;
  status?: SuspicionItemStatus;
};

export const ReportCardByEntity: FC<ReportCardProps> = ({
  filters,
  accessToken = '',
  targetData,
  caseData,
  reportId,
  report,
}) => {
  const { t } = useTranslation();
  const { targetId } = useParams<{ caseId: string; targetId: string }>();
  const [page, setPage] = useState(1);

  const {
    data,
    isError,
    isLoading: isLoading,
  } = useSuspicionsReportEntityQuery(targetId, reportId, {
    ...filters,
    page,
    pageSize: PAGE_SIZE,
  });

  //TODO: switch back to using only the data from the backend once fetching own_keywords suspicions from the backend is fixed
  const entities = data?.entities.length
    ? data.entities
    : report?.suspicions.flatMap((suspicion) =>
        suspicion.items.map((item) => item.entity),
      );

  useEffect(() => {
    setPage(1);
  }, [filters]);

  return (
    <div className="py-4">
      <div className="flex flex-col gap-1">
        {isLoading ? (
          <LoadingSpinner message={<span className="invisible">...</span>} />
        ) : isError ? (
          <div>Error</div>
        ) : null}

        {!entities?.length && !isLoading && (
          <div className="bg-white rounded-md p-3">{t('noData')}</div>
        )}

        {entities?.length && (
          <>
            {entities?.map((entity, index) => {
              switch (entity.value.type) {
                case 'press':
                  return (
                    <PressCard
                      data={entity.value}
                      key={entity.value.type + index}
                      targetData={targetData}
                      caseData={caseData}
                      suspicionItems={entity.suspicionItems}
                    />
                  );
                case 'SocialAccountConnection':
                case 'SocialAccountPost':
                  return (
                    <SocialMediaCard
                      data={entity.value}
                      key={entity.value.type + index}
                      accessToken={accessToken}
                      targetData={targetData}
                      caseData={caseData}
                      suspicionItems={entity.suspicionItems}
                    />
                  );
                case 'Image':
                  return (
                    <PictureCard
                      data={entity.value}
                      key={entity.value.type + index}
                      targetData={targetData}
                      caseData={caseData}
                      suspicionItems={entity.suspicionItems}
                    />
                  );
                case 'WebPage':
                  return (
                    <SearchResultCard
                      data={entity.value}
                      key={entity.value.type + index}
                      targetData={targetData}
                      caseData={caseData}
                      suspicionItems={entity.suspicionItems}
                    />
                  );
                case 'TargetCompany':
                  return (
                    <CompanyCard
                      data={entity.value}
                      key={entity.value.type + index}
                      targetData={targetData}
                      caseData={caseData}
                      suspicionItems={entity.suspicionItems}
                    />
                  );
              }
            })}

            {data?.paging?.pagesCount && data?.paging?.pagesCount > 1 && (
              <div className="px-5">
                <Pagination paging={data.paging} setPageNumber={setPage} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
