import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { UserData } from 'src/types/UserData';

export const useDeleteTargetMutationV2 = (): UseMutationResult<
  UserData,
  unknown,
  Record<string, string>
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ caseId, targetId }) =>
      axios.delete(
        `https://${backendBaseUrl}/v2/cases/${caseId}/targets/${targetId}`,
      ),
    {
      onSuccess: () => queryClient.invalidateQueries('targets'),
    },
  );
};
