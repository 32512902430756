import { CaseKeyword, Target } from 'src/services/dataService';
import { EntitySuspicion } from '_types';
import { removeLegalSuffix } from '_utils';
import { HighlightChunks } from './types';

interface HighlightDeps {
  searchQuery?: string;
  target?: Target;
  suspicions?: EntitySuspicion[];
  caseKeywords?: CaseKeyword[];
}

const getTargetPerson = (target?: Target): string[] =>
  target ? [target.firstname, target.lastname] : [];

const getTargetCompanies = (target?: Target): string[] => {
  const targetCompanies =
    target?.companies
      ?.flatMap((company) => company.data.name)
      .map(({ value }) => value.toLowerCase()) ?? [];

  const uniqueTargetCompanies = [...new Set(targetCompanies)];

  const companiesWithoutLegalSuffix = uniqueTargetCompanies.flatMap(
    (companyName) => {
      const trimmedCompanyName = removeLegalSuffix(companyName);
      return trimmedCompanyName.length === companyName.length
        ? [companyName]
        : [companyName, trimmedCompanyName.trim()];
    },
  );
  return companiesWithoutLegalSuffix;
};

const getCaseKeywords = (caseKeywords?: CaseKeyword[]): string[] =>
  caseKeywords
    ? caseKeywords
        .flatMap((caseKeyword) => caseKeyword.translations)
        .map(({ value }) => value)
    : [];

const getSuspicionKeywords = (
  suspicions?: EntitySuspicion[],
): string[] | undefined =>
  suspicions
    ?.flatMap(({ indicators }) => indicators)
    .map(({ indicator }) => indicator.value) ?? [];

const getSearchQuery = (searchQuery?: string): string[] =>
  searchQuery ? [searchQuery] : [];

export const buildHighlightChunks = (deps: HighlightDeps): HighlightChunks => ({
  searchQuery: getSearchQuery(deps.searchQuery),
  suspicionKeywords: getSuspicionKeywords(deps.suspicions),
  caseKeywords: getCaseKeywords(deps.caseKeywords),
  targetPerson: getTargetPerson(deps.target),
  targetCompanies: getTargetCompanies(deps.target),
});
