import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { UserData } from 'src/types/UserData';

const fetchUsers = async (customerId?: string): Promise<UserData[]> =>
  (
    await axios.get(
      `https://${backendBaseUrl}/users${
        customerId ? `?customerId=${customerId}` : ''
      }`,
    )
  ).data.users;

export const useUsersQuery = (
  customerId?: string,
): UseQueryResult<UserData[], string> =>
  useQuery('users', () => fetchUsers(customerId));
