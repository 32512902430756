import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '_atoms';

interface TargetDateOfBirthProps {
  dateRange: {
    start: string;
    end: string;
  };
}

export const TargetDateOfBirth = ({
  dateRange,
}: TargetDateOfBirthProps): React.ReactElement => {
  const { start, end } = dateRange;
  const { t } = useTranslation();
  const isExact = start === end;
  const birthday = isExact ? start : `${start} - ${end}`;

  return (
    <div>
      <Paragraph size="label" weight="bold">
        {t('infoBlockItem.dateOfBirth')}
      </Paragraph>
      <Paragraph
        weight="normal"
        lineHeight="default"
        color="dark"
        className="relative group break-all space-y-1"
        HtmlTag="div"
      >
        {birthday}
      </Paragraph>
    </div>
  );
};
