import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import config from '../../backendConfig.json';
import { UserData } from 'src/types/UserData';

type UpdateUserProps = {
  data: Partial<UserData>;
  userId: string;
  customerIdOverride?: string;
};

export const updateUser: (props: UpdateUserProps) => Promise<unknown> = async ({
  data,
  userId,
  customerIdOverride,
}) => {
  if (customerIdOverride) {
    await axios.patch(
      `https://${config.backendBaseUrl}/customers/${customerIdOverride}/users/${userId}`,
      data,
    );
    return;
  }

  await axios.patch(`https://${config.backendBaseUrl}/users/${userId}`, data);
};

export const useUpdateUserMutation = (): UseMutationResult<
  unknown,
  string,
  UpdateUserProps
> => {
  const queryClient = useQueryClient();

  return useMutation(updateUser, {
    onSuccess: () =>
      ['users', 'customerUsage'].forEach((key) =>
        queryClient.invalidateQueries(key),
      ),
  });
};
