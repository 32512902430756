import React, { useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import { HighlightChunks } from './types';
import { getColorClass, computeSearchWords } from './utils';

interface HighlightProps {
  searchWords?: HighlightChunks;
  textToHighlight?: string;
}

export const Highlight = ({
  searchWords,
  textToHighlight,
}: HighlightProps): React.ReactElement | null => {
  const shouldRender = !!textToHighlight;
  // Ready computed search terms (of type SearchTerms) are an array
  // HighlightedChunks are an object
  const computedSearchWords = useMemo(() => {
    if (!shouldRender) {
      return [];
    }

    return computeSearchWords(searchWords);
  }, [searchWords, shouldRender]);

  if (!textToHighlight) return null;

  return (
    <Highlighter
      searchWords={computedSearchWords.flatMap(({ words }) => words)}
      textToHighlight={textToHighlight}
      highlightTag={({ children }) => (
        <mark className={getColorClass(computedSearchWords, children)}>
          {children}
        </mark>
      )}
      autoEscape
    />
  );
};
