import { FC, HTMLAttributes } from 'react';
import { SocialMediaImage } from '_molecules';
import { SocialIcon } from 'react-social-icons';

type bubbleSize = 'small' | 'medium';

interface SocialProfilePictureBubbleProps
  extends HTMLAttributes<HTMLDivElement> {
  data: any;
  size?: bubbleSize;
}

const leftForSize: Record<bubbleSize, string> = {
  small: '30px',
  medium: '38px',
};

const heightForSize: Record<bubbleSize, string> = {
  small: '18px',
  medium: '24px',
};

export const SocialProfilePictureBubble: FC<SocialProfilePictureBubbleProps> =
  ({ data, size = 'small', ...props }) => (
    <div {...props}>
      <SocialMediaImage size={size} src={data.imageUrl} />
      <SocialIcon
        network={data.network}
        style={{
          position: 'relative',
          width: heightForSize[size],
          height: heightForSize[size],
          bottom: '15px',
          left: leftForSize[size],
          fontWeight: 'bold',
        }}
      />
    </div>
  );
