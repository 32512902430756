import { CompanyInput, DateRange, SocialMediaType } from '@indicium/common';
import { formatDate } from '_utils';

export const getCompanyEntriesfromCompanyInput = (
  companyEntries?: CompanyInput[],
): {
  name: string;
  location: string;
  commercialRegisterNumber: string;
  vatNumber: string;
  website: string;
  role: string;
}[] => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const companies: {
    name: string;
    location: string;
    commercialRegisterNumber: string;
    vatNumber: string;
    website: string;
    role: string;
  }[] =
    companyEntries?.map((company) => ({
      ...company,
      name: company.name || '',
      commercialRegisterNumber: company.commercialRegisterNumber || '',
      vatNumber: company.vatNumber || '',
      website: company.website || '',
    })) || [];

  if (companies.length < 3) {
    Array(3 - companies.length)
      .fill(0)
      .map((_) => ({
        name: '',
        location: '',
        commercialRegisterNumber: '',
        vatNumber: '',
        website: '',
        role: '',
      }))
      .forEach((blankCompany) => companies.push(blankCompany));
  }

  return companies;
};

export const getFormDateFromDateRange = (
  dateRange: DateRange | undefined,
  fallbackValue: string,
): string =>
  dateRange
    ? dateRange.start === dateRange.end
      ? formatDate(dateRange.start)
      : dateRange.end.slice(0, 4)
    : fallbackValue;

export const getSocialMediaProfiles = (
  socialMediaProfiles?: { type: SocialMediaType; value: string }[],
): { type: string; value: string }[] => {
  const socMediaProfiles = socialMediaProfiles?.reduce(
    (acc, profile) => ({ ...acc, [profile.type]: profile.value }),
    {},
  );
  const fields = Object.keys(SocialMediaType).map((type) => ({
    type,
    value: '',
  }));

  if (socMediaProfiles) {
    return fields.map((profile) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      socMediaProfiles[profile.type]
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          { type: profile.type, value: socMediaProfiles[profile.type] }
        : profile,
    );
  } else {
    return fields;
  }
};

export const getContact = (
  savedContacts?: Array<{ type: 'phone' | 'email'; value: string }>,
): Array<{ type: string; value: string }> => {
  let contacts = [];

  if (savedContacts) {
    // contacts = savedContacts;
    const phone = savedContacts.find((contact) => contact.type === 'phone');
    contacts.push(
      phone
        ? { type: phone.type, value: phone.value }
        : { type: 'phone', value: '' },
    );

    const emails =
      savedContacts.filter((contact) => contact.type === 'email') || [];
    const emailsPlusEmptyFields =
      emails.length < 3
        ? emails.concat(
            Array(3 - emails.length).fill({ type: 'email', value: '' }),
          )
        : emails;
    emailsPlusEmptyFields.forEach((email) => {
      contacts.push({ type: email.type, value: email.value });
    });
  } else {
    contacts = [
      { type: 'phone', value: '' },
      { type: 'email', value: '' },
      { type: 'email', value: '' },
      { type: 'email', value: '' },
    ];
  }

  return contacts;
};
