import { IFilterFactory } from '../../../../interfaces/filter-factory.interface';
import { BaseFilterProps } from '_types';
import DateRangeFilter from './filter';

class DateRangeFilterFactory implements IFilterFactory {
  get type() {
    return 'DateRange';
  }

  create({ item, field, searchState, dispatcher }: BaseFilterProps) {
    const key = [field, JSON.stringify(searchState.filters || {})].join('-');
    return (
      <DateRangeFilter
        key={key}
        field={field}
        item={item}
        searchState={searchState}
        dispatcher={dispatcher}
      />
    );
  }
}

export default DateRangeFilterFactory;
