import { FC, useCallback } from 'react';
import { Breadcrumbs } from '_atoms';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Target } from '../../services/dataService';

export type CaseToProfileBreadcrumbsProps = {
  caseName?: string;
  targetData?: Target | null;
  level: number;
};

export const CaseToProfileBreadcrumbs: FC<CaseToProfileBreadcrumbsProps> = ({
  caseName = '...',
  targetData,
  level,
}: CaseToProfileBreadcrumbsProps) => {
  const { caseId, targetId } =
    useParams<{ caseId: string; targetId: string }>();
  const { t } = useTranslation();

  const createBreadcrumbs = useCallback(() => {
    switch (level) {
      case 1:
        return [
          {
            name: t('cases'),
            route: `/cases`,
          },
        ];

      case 2:
        return [
          {
            name: t('cases'),
            route: `/cases`,
          },
          {
            name: caseName,
            route: `/cases/${caseId}`,
          },
        ];

      case 3: {
        const targetName = targetData
          ? `${targetData.firstname} ${targetData.lastname}`
          : '';

        return [
          {
            name: t('cases'),
            route: `/cases`,
          },
          {
            name: caseName,
            route: `/cases/${caseId}`,
          },
          {
            name: `${caseName}: ${targetName}`,
            route: `/cases/${caseId}/targets/${targetId}`,
          },
        ];
      }

      default:
        return [];
    }
  }, [caseId, caseName, level, t, targetId, targetData]);

  return <Breadcrumbs breadcrumbs={createBreadcrumbs()} />;
};
