import { Button, Card, Input, Paragraph } from '_atoms';
import { useCustomerQuery } from '_queries';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UserActionTypes, useUserState } from '../../context/User';
import { useUserQuota } from '../../hooks/useUserQuota';
import { ReactComponent as Logo } from '../../images/logo-with-name.svg';
import { routes } from '../../routes';
import {
  updateUserAttributes,
  User,
  USER_GROUP_ADMINISTRATORS,
  USER_GROUP_SUPER_USERS,
} from '../../services/authenticationService';

export const EditProfile: FC = () => {
  const { t } = useTranslation();
  const [{ user, initialRoute, userRoles }, dispatch] = useUserState();
  const [{ customerId }] = useUserState();
  const { data: customer } = useCustomerQuery(customerId, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const [loading, setLoading] = useState(false);

  const [userAttributes, setUserAttributes] = useState<User['attributes']>({
    email: '',
  });

  useEffect(() => {
    if (user) {
      setUserAttributes(user.attributes);
    }
  }, [user]);

  const { quota } = useUserQuota();

  if (!user) {
    return null;
  }

  const saveUser = async () => {
    try {
      setLoading(true);
      await updateUserAttributes(userAttributes);
      dispatch({
        type: UserActionTypes.SET,
        user: Object.assign({}, user, { attributes: userAttributes }),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const isAdmin =
    userRoles.includes(USER_GROUP_ADMINISTRATORS) ||
    userRoles.includes(USER_GROUP_SUPER_USERS);
  const isOwner = isAdmin && user.attributes.isCustomerOwner === 'True';

  const userRole = isOwner
    ? t('CustomerOwner')
    : isAdmin
    ? t('CustomerAdmin')
    : t('CustomerUser');

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="flex flex-col items-center">
          <Logo
            width="120"
            height="120"
            className="text-blue-400 fill-current"
          />
          <h3 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {t('editProfile')}
          </h3>
        </div>
        <Card borderColor="border-white w-full" className="p-6">
          <Paragraph className="mt-2">
            <p className=" font-bold">{`${t('email')}: ${
              userAttributes.email
            }`}</p>
          </Paragraph>
          <Paragraph className="mt-2">
            {customer && (
              <p className=" font-bold">{`${t('organization')}: ${
                customer.name
              }`}</p>
            )}
          </Paragraph>
          <Paragraph className="mt-2">
            <p className=" font-bold">{`${t(
              'privilegeLevel',
            )}: ${userRole}`}</p>
          </Paragraph>
          <Paragraph className="mt-2">
            {quota && (
              <p className=" font-bold">
                {`${t('quota')}: ${quota.used}/${
                  quota.total === Infinity ? '∞' : quota.total
                }`}
              </p>
            )}
          </Paragraph>
          <br />

          <form className="py-2.5 bg-white text-gray-900 space-y-4">
            <div className="grid grid-cols-1 gap-2">
              <label className="block">
                <span className="text-gray-700">{t('firstname')}:</span>
                <Input
                  className="mt-1 mb-2"
                  onChange={(value) => {
                    setUserAttributes((oldUser) => ({
                      ...oldUser,
                      firstname: value.target.value,
                    }));
                  }}
                  value={userAttributes.firstname ?? ''}
                  placeholder={t('firstname')}
                />
              </label>

              <label className="block">
                <span className="text-gray-700">{t('lastname')}:</span>
                <Input
                  className="mt-1 mb-2"
                  onChange={(value) => {
                    setUserAttributes((oldUser) => ({
                      ...oldUser,
                      lastname: value.target.value,
                    }));
                  }}
                  value={userAttributes.lastname ?? ''}
                  placeholder={t('lastname')}
                />
              </label>
            </div>
          </form>
          <Link to={routes.changePassword.path}>
            <button className="text-blue-400 focus:outline-none cursor-pointer hover:text-blue-300 transition-colors duration-200 text-sm">
              {t('changePassword')}
            </button>
          </Link>
          <div className="flex">
            <Link to={initialRoute} className="flex-grow">
              <Button className="mt-5 w-full">{t('backToOverview')}</Button>
            </Link>
            <Button
              className="mt-5 ml-4 flex-grow"
              onClick={saveUser}
              disabled={
                loading ||
                !userAttributes.firstname ||
                !userAttributes.lastname ||
                (userAttributes.firstname === user.attributes.firstname &&
                  userAttributes.lastname === user.attributes.lastname)
              }
            >
              {t('save')}
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};
