import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Paragraph, Highlight, ConfidenceBar } from '_atoms';
import { BaseCard, CollapsibleCardWithBar } from '_organisms';
import { formatDate } from '_utils';
import type { PressArticle } from '../../../services/searchService';
import { CaseData, Target } from '../../../services/dataService';
import { EntitySuspicion } from '_types';
import { buildHighlightChunks } from '../../_atoms/Highlight/public-utils';

interface PressCardProps extends HTMLAttributes<HTMLDivElement> {
  query?: string;
  data: PressArticle;
  hideArticleType?: boolean;
  score?: number;
  status?: string;
  suspicionItemId?: string;
  targetData?: Target;
  caseData?: CaseData;
  suspicionItems?: Array<EntitySuspicion>;
}

export const PressCard: FC<PressCardProps> = ({
  query,
  data,
  hideArticleType = false,
  suspicionItems,
  targetData,
  caseData,
  ...props
}) => {
  const { t } = useTranslation();

  const highlightChunks = buildHighlightChunks({
    searchQuery: query,
    suspicions: suspicionItems,
    target: targetData,
    caseKeywords: caseData?.keywords,
  });

  const highlight = {
    ...highlightChunks,
    caseKeywords: (highlightChunks.caseKeywords ?? []).concat(
      data.matchingKeywords?.map(({ name }) => name),
      [],
    ),
  };

  return (
    <BaseCard
      item={data}
      entityType="press"
      title={data.title}
      logo={
        <img
          alt="LexisNexis Logo"
          className="w-25"
          src={`${process.env.PUBLIC_URL}/lexisnexis-poweredby.png`}
        />
      }
      hideArticleType={hideArticleType}
      suspicionItems={suspicionItems}
      highlight={highlight}
      targetData={targetData}
      {...props}
    >
      <CollapsibleCardWithBar
        borderColor="border-blue-400"
        coloredBar="white"
        infoBlocks={[
          {
            headline: `${t('source')}`,
            text: data.source?.name,
          },
          {
            headline: `${t('publicationDate')}`,
            text: data.estimatedPublishedDate
              ? formatDate(data.estimatedPublishedDate)
              : '/',
          },
          {
            headline: `${t('author')}`,
            text: data.author?.name ?? '',
          },
          {
            headline: `${t('copyright')}`,
            text: data.copyright,
          },
        ]}
        fullwidthInfoBlocks={
          data.preview
            ? [
                {
                  headline: t('preview'),
                  text: data.preview,
                },
              ]
            : undefined
        }
        expanded={!!query?.length}
        highlight={highlight}
      >
        <div className="grid w-10/12 grid-cols-1">
          <Paragraph size="default">
            <Highlight searchWords={highlight} textToHighlight={data.content} />
          </Paragraph>
        </div>
      </CollapsibleCardWithBar>
      {typeof data.confidenceScore === 'number' ? (
        <ConfidenceBar
          confidenceScore={data.confidenceScore}
          className="ml-6 mb-5"
        />
      ) : null}
    </BaseCard>
  );
};
