import { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncFeedbackWrapper } from '_organisms';
import {
  proxifyDigitalcluesAssetFilepathIfNeeded,
  transformProfileFieldsToInfoBlockItem,
} from '_utils';
import { ResultsProps } from '../Results';
import { Error, LoadingSpinner, Headline, Card, Paragraph, Tag } from '_atoms';
import { Alert, WorkflowError, InfoBlockItem, ImageSlider } from '_molecules';
import { SocialMediaAccount } from '_organisms';
import { ProfileData } from './ProfileData';
import { useParams } from 'react-router-dom';
import { useTargetResultsQuery } from '../../../hooks/queries/useTargetResultsQuery';
import classNames from 'classnames';

export const Profile: FC<ResultsProps & { accessToken: string }> = ({
  accessToken,
  targetData,
  targetDataPromise,
  targetImages,
}: ResultsProps & { accessToken: string }) => {
  const { t, i18n } = useTranslation();

  const profileData = targetData?.profile?.data;

  // TODO: Extract and reuse these components in profile, raw data, press and social media
  const profileDataNotFinalAlert = useMemo(
    () => (
      <Alert
        alertType="warning"
        headline={t('profileDataNotFinal')}
        className="mt-8"
      />
    ),
    [t],
  );

  const profileDataError = useMemo(
    () => (
      <Error
        headline={t('profileErrorHeadline')}
        message={t('profileErrorMessage')}
      />
    ),
    [t],
  );

  const profileDataLoadingSpinner = useMemo(
    () => <LoadingSpinner message={t('profileLoading')} />,
    [t],
  );

  const socialMediaAccounts = useMemo(
    () =>
      profileData?.usernames
        ?.map((usernameEntry) => ({
          name: usernameEntry.value?.name ?? usernameEntry.value,
          imageUrl:
            usernameEntry.value.profilePicturePath &&
            proxifyDigitalcluesAssetFilepathIfNeeded(
              usernameEntry.value.profilePicturePath,
              accessToken,
            ),
          profileUrl: usernameEntry.value.profileUrl,
          sources: usernameEntry.sources,
        }))
        ?.filter(({ profileUrl }) => !profileUrl?.includes('posts')) ?? [],
    [accessToken, profileData?.usernames],
  );

  const socialAccounts = socialMediaAccounts.filter(
    ({ name, sources }) => !/^\d+$/.test(name) || sources.includes('userInput'),
  );
  const potentialSocialAccounts = socialMediaAccounts.filter(
    ({ name, sources }) => /^\d+$/.test(name) && !sources.includes('userInput'),
  );

  const { targetId, caseId } =
    useParams<{
      targetId: string;
      caseId: string;
    }>();

  const targetResults = useTargetResultsQuery(caseId, targetId);
  const language = i18n.language === 'en' ? 'en' : 'de';
  const summaryText = targetResults?.data?.summarisation?.[language];

  const summary = summaryText && typeof summaryText === 'string';

  const summaryBlock = () => {
    return (
      summary && (
        <Card borderColor="border-white" className="ml-4 px-6 py-6">
          <div className="justify-center w-full p-2  mr-4">
            <p className="bg-transparent transition-colors duration-300 hover:bg-primary rounded-lg py-1 inline-block text-gray-500">
              {summaryText}
            </p>
            <p className="text-xs text-blue-400 italic mt-2 whitespace-pre-line text-right">
              {t('summaryBetaTooltip')}
            </p>
          </div>
        </Card>
      )
    );
  };

  const profileCard = () => {
    return (
      <Card
        borderColor="border-white"
        className={classNames(
          'px-6 py-5',
          summary && 'mt-4',
          !summary && 'ml-3',
        )}
      >
        <div className="w-full p-2">
          <ProfileData data={profileData} />
        </div>
      </Card>
    );
  };

  return (
    <div className="flex-1 px-5 ml-64">
      <Headline
        Level="h1"
        color="dark"
        className="mt-8 mb-0"
        weight="bold"
        style={{ textAlign: 'center' }}
      >
        {targetData ? `${targetData.firstname} ${targetData.lastname}` : ''}
      </Headline>

      <WorkflowError errors={targetData?.workflowErrors} path="profile" />

      <AsyncFeedbackWrapper
        alert={{
          loading: profileDataLoadingSpinner,
          rejected: profileDataError,
          resolvedWithWarning: profileDataNotFinalAlert,
        }}
        promises={[
          {
            promise: targetDataPromise,
            condition: (result: unknown) =>
              (result as { [key: string]: unknown }).status ===
              'HasInitialProfile'
                ? 'resolvedWithWarning'
                : 'resolved',
          },
        ]}
      >
        {targetData && profileData && (
          <div className="my-8 space-y-4">
            <div className="flex flex-row">
              <div className="w-1/4">
                <Card borderColor="border-white" className="p-2 border-none">
                  <ImageSlider images={targetImages} />
                </Card>
              </div>
              <div className="w-3/4">
                {summaryBlock()}
                {!summary && profileCard()}
              </div>
            </div>
            {summary && profileCard()}
            <Card borderColor="border-white" className="px-6 py-5">
              {/* <Headline Level="h3" color="dark">
                {t('contactData')}
              </Headline> */}

              <div className="grid grid-cols-3 gap-4 py-4 w-full">
                {transformProfileFieldsToInfoBlockItem(profileData, [
                  'phones',
                  'emails',
                  'websites',
                ]).map((info, index) => (
                  <InfoBlockItem
                    key={index}
                    info={info}
                    noDataComponent={<Tag>{t('noData')}</Tag>}
                  />
                ))}
              </div>
            </Card>

            {socialMediaAccounts.length > 0 && (
              <Card borderColor="border-white" className="px-6 py-5">
                <div className="row pb-4">
                  <Headline Level="h3" className="mb-1" color="dark">
                    {t('socialMediaSection')}
                  </Headline>
                </div>

                <div className="grid grid-cols-2 gap-4 py-4 w-full">
                  {socialAccounts.length > 0 && (
                    <div>
                      <Paragraph size="label" weight="bold">
                        {t('filters.mainSocialMediaProfiles')}
                      </Paragraph>
                      {socialAccounts.map(
                        ({ name, imageUrl, profileUrl, sources }, index) => (
                          <SocialMediaAccount
                            key={[name, index].join('-')}
                            name={name}
                            imageUrl={imageUrl}
                            profileUrl={profileUrl}
                            sources={sources}
                          />
                        ),
                      )}
                    </div>
                  )}
                  {potentialSocialAccounts.length > 0 && (
                    <div>
                      <Paragraph size="label" weight="bold">
                        {t('filters.potentialSocialMediaProfiles')}
                      </Paragraph>
                      {potentialSocialAccounts.map(
                        ({ name, imageUrl, profileUrl, sources }, index) => (
                          <SocialMediaAccount
                            key={[name, index].join('-')}
                            name={name}
                            imageUrl={imageUrl}
                            profileUrl={profileUrl}
                            sources={sources}
                          />
                        ),
                      )}
                    </div>
                  )}
                  {/* TODO: Make this a bit nicer with someone from UX */}
                </div>
              </Card>
            )}
          </div>
        )}
      </AsyncFeedbackWrapper>
    </div>
  );
};
