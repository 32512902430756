import { FC, FormEvent, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  Redirect,
  useHistory,
  useParams,
} from 'react-router-dom';
import { routes } from '../../../routes';
import { TargetCandidateItem } from './TargetCandidateItem';
import { TargetGoogleCandidateGroup } from './TargetGoogleCandidateGroup';
import {
  TargetStatus,
  updateTargetCandidateSelection,
} from '../../../services/dataService';
import { useCaseQuery, useTargetCandidatesQuery } from '_queries';
import { Button, Card, Error, Headline, Paragraph } from '_atoms';
import { ImageGalleryModal } from '_molecules';
import { PageNotFound } from '_organisms';
import { CaseToProfileBreadcrumbs } from '../../../components/Layout/CaseToProfileBreadcrumbs';
import { TargetCandidateBase } from '@indicium/common';
import { GoogleCandidateStatus, TargetPersonSelection } from '_types';
import { TargetPersonSelectionState } from '_enums';
import { getCdnUrl, nonProdDataTestId } from '_utils';

export const TargetCandidates: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { caseId, targetId } =
    useParams<{ caseId: string; targetId: string }>();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [stateSelection, setStateSelection] =
    useState<Record<string, TargetPersonSelectionState | undefined>>();
  const [googleCandidatesSelection, setGoogleCandidatesSelection] = useState<
    Record<string, GoogleCandidateStatus[]>
  >({});

  const [isGalleryOpened, setIsGalleryOpened] = useState(false);
  const [galleryImages, setGalleryImages] = useState<string[]>([]);

  const {
    data: { status, candidates, googleCandidates } = {
      status: 'FetchingCandidates',
      candidates: [],
      googleCandidates: [],
    },
    ...targetCandidatesQuery
  } = useTargetCandidatesQuery(caseId, targetId);

  const { data: caseData, ...caseQuery } = useCaseQuery(caseId);

  const hasSelection = (() => {
    const hasSelectedCandidates = Object.values(stateSelection ?? {}).some(
      (status) => status === TargetPersonSelectionState.Confirmed,
    );
    const hasSelectedGoogleCandidates = Object.values(
      googleCandidatesSelection ?? {},
    ).some((candidates) =>
      candidates.some(
        (candidate) =>
          candidate.status === TargetPersonSelectionState.Confirmed,
      ),
    );

    return hasSelectedCandidates || hasSelectedGoogleCandidates;
  })();

  const hasTradeRegisterData = (() => {
    return candidates.some(
      (group) =>
        group.sources.includes('orbis') ||
        group.sources.includes('companyhouse'),
    );
  })();

  const hasCandidates = (() => {
    return candidates.length > 0 || googleCandidates.length > 0;
  })();

  // initial selection
  useEffect(() => {
    if (candidates.length) {
      const enrichedCandidates = candidates.map((candidate) => ({
        ...candidate,
        status:
          candidate.pre_checked === true
            ? TargetPersonSelectionState.Confirmed
            : candidate.pre_checked === false
            ? TargetPersonSelectionState.Ignored
            : undefined,
      }));
      setStateSelection(
        Object.fromEntries(
          enrichedCandidates.map((candidate) => [
            candidate.groupId,
            candidate.status,
          ]),
        ),
      );
    }

    if (googleCandidates.length) {
      setGoogleCandidatesSelection(
        Object.fromEntries(
          googleCandidates.map(({ groupId, candidates }) => [
            groupId,
            candidates.map((candidate) => ({
              id: candidate.id,
              status: undefined,
              imageSelectionStatus: (candidate.googleImages || []).map(
                ({ id }) => ({ imageId: id }),
              ),
            })),
          ]),
        ),
      );
    }
  }, [candidates, googleCandidates]);

  if (
    targetCandidatesQuery.isSuccess &&
    status !== TargetStatus.CandidateSelectionPending
  ) {
    return (
      <Redirect
        to={generatePath(routes.targetShow.path, {
          caseId,
          targetId,
        })}
      />
    );
  }

  const handleOnSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    const transformedStateSelection: TargetPersonSelection[] = [];
    const transformedGoogleCandidatesSelection: GoogleCandidateStatus[] = [];

    if (stateSelection) {
      Object.entries(stateSelection).forEach(([groupId, status]) => {
        const group = candidates.find((group) => group.groupId === groupId);
        if (group) {
          group.candidateIds.forEach((id) => {
            transformedStateSelection.push({
              id,
              status: status,
            });
          });
        }
      });
    }

    Object.entries(googleCandidatesSelection).forEach(([_, states]) => {
      if (states) {
        const confirmedCandidates = states.filter(
          ({ status, imageSelectionStatus }) =>
            (status &&
              [
                TargetPersonSelectionState.Confirmed,
                TargetPersonSelectionState.Ignored,
              ].includes(status)) ||
            imageSelectionStatus.some(
              (iss) => iss.imageStatus === TargetPersonSelectionState.Confirmed,
            ),
        );
        transformedGoogleCandidatesSelection.push(...confirmedCandidates);
      }
    });

    try {
      const response = await updateTargetCandidateSelection(
        caseId,
        targetId,
        {
          candidates: transformedStateSelection,
          googleCandidates: transformedGoogleCandidatesSelection,
        },
        true,
      );

      if (response.status >= 200 && response.status <= 204) {
        history.replace(
          generatePath(routes.targetShow.path, { caseId, targetId }),
        );
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
    }
  };

  const sortTargetInfo = (info: NonNullable<TargetCandidateBase['info']>) => {
    if (info.length) {
      ['placeOfResidency', 'email', 'phone', 'company'].forEach(
        (item: string) => {
          const position = info.findIndex(({ key }) => key === item);
          if (position !== -1) {
            info.splice(0, 0, ...info.splice(position, 1));
          }
        },
      );
    }
    return info;
  };

  const handleImageGalleryOpen = ({ images }: { images: string[] }) => {
    setGalleryImages(images);
    setIsGalleryOpened(true);
  };

  const handleImageGalleryClose = () => {
    setIsGalleryOpened(false);
  };

  const handleGoBack = () => {
    localStorage.setItem('oldTargetId', targetId);
    history.push(`/cases/${caseId}/targets/new`);
  };

  if (caseQuery.isError) {
    return <PageNotFound />;
  }

  return (
    <div className="container-fluid mb-20 px-16">
      <CaseToProfileBreadcrumbs level={2} caseName={caseData?.title} />

      {targetCandidatesQuery.isSuccess && (
        <Fragment>
          <div className="row">
            <div className="col-12">
              <div className="row mb-6">
                <Headline className="mt-6" Level="h1" color="dark">
                  {t('resolveTargetCandidatesHeadline')}
                </Headline>
                <Paragraph
                  size="default"
                  weight="normal"
                  lineHeight="default"
                  color="default"
                >
                  {t('resolveTargetCandidatesSubHeadline')}
                </Paragraph>
              </div>

              {hasCandidates ? (
                <div>
                  <form
                    className="space-y-6"
                    onSubmit={handleOnSubmit}
                    data-testid={nonProdDataTestId('targetCandidatesForm')}
                  >
                    {!hasTradeRegisterData ? (
                      <Card
                        borderColor="border-gray-200"
                        className="border-2 p-5 text-center"
                      >
                        <Paragraph>{t('noTradeRegisterDataText')}</Paragraph>
                      </Card>
                    ) : null}
                    <div className="grid grid-cols-1 4xl:grid-cols-2 gap-5">
                      {candidates.map(
                        ({
                          groupId,
                          images,
                          info = [],
                          name,
                          jobTitles,
                          sources,
                        }) => {
                          const sortedInfo = sortTargetInfo(info);
                          return (
                            <TargetCandidateItem
                              key={groupId}
                              id={groupId}
                              images={(images || [])?.map((image) =>
                                getCdnUrl(image),
                              )}
                              name={name}
                              jobTitles={jobTitles}
                              info={sortedInfo.map(({ key, values = [] }) => ({
                                key,
                                values,
                              }))}
                              sources={sources}
                              onStateChange={({ id, state }) =>
                                setStateSelection({
                                  ...stateSelection,
                                  [id]: state,
                                })
                              }
                              status={stateSelection?.[groupId]}
                              onGalleryOpen={handleImageGalleryOpen}
                            />
                          );
                        },
                      )}
                      {googleCandidates.map(
                        ({ groupId, name, candidates, organizations }) => (
                          <TargetGoogleCandidateGroup
                            key={groupId}
                            groupId={groupId}
                            candidates={candidates}
                            name={name}
                            organizations={organizations}
                            groupSelectionState={
                              googleCandidatesSelection[groupId] || []
                            }
                            updateSelectionState={(groupId, state) => {
                              setGoogleCandidatesSelection({
                                ...googleCandidatesSelection,
                                [groupId]: state,
                              });
                            }}
                          />
                        ),
                      )}
                    </div>

                    <div className="row">
                      <div className="col-12 flex justify-end space-x-4">
                        <Button
                          type="button"
                          className="w-52"
                          level="secondary"
                          onClick={handleGoBack}
                          disabled={false}
                        >
                          {t('back')}
                        </Button>
                        <Button
                          disabled={!hasSelection || isSubmitting}
                          className="w-52"
                          type="submit"
                          level="primary"
                          data-testid={nonProdDataTestId(
                            'target candidate submit button',
                          )}
                        >
                          {t('startAnalysis')}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <Error
                  headline={t('noResultHeadline')}
                  message={t('noResultText')}
                  target={{
                    link: `${routes.casesList.path}`,
                    text: t('cancelCreateTarget'),
                  }}
                />
              )}
            </div>
          </div>
          <ImageGalleryModal
            isOpen={isGalleryOpened}
            onClose={handleImageGalleryClose}
            images={galleryImages}
          />
        </Fragment>
      )}
    </div>
  );
};
