import { LoadingSpinner } from '_atoms';
import classNames from 'classnames';
import { QRCodeCanvas } from 'qrcode.react';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineLockClosed } from 'react-icons/hi';
import {
  setPreferredMFA,
  setupTOTP,
  User,
  verifyTOTP,
} from '../../services/authenticationService';
import { AuthSteps, ResetExceptions } from './types';

interface SigninProps {
  localUser: User | null;
  onChangeStep: (step: AuthSteps) => void;
  onSuccess: (user: User) => void;
  onError: (code: ResetExceptions) => void;
}

export const SetupMFA: FC<SigninProps> = ({
  localUser,
  onChangeStep,
  onSuccess,
  onError,
}: SigninProps) => {
  const { t } = useTranslation();
  const [confirmationCode, setConfirmationCode] = useState<string | null>(null);
  const [secretCode, setSecretCode] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // get secret code for TOTP to show QR code
    (async () => {
      if (!localUser) {
        return;
      }

      setLoading(true);

      try {
        const code = await setupTOTP(localUser);
        setSecretCode(code);
      } catch (error) {
        onError(error.code as ResetExceptions);
      } finally {
        setLoading(false);
      }
    })();
  }, [localUser, onError]);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!localUser || !confirmationCode) {
        return;
      }
      setLoading(true);

      try {
        await verifyTOTP(localUser, confirmationCode);
        await setPreferredMFA(localUser, 'TOTP');

        onSuccess(localUser);
      } catch (error) {
        onError(error.code as ResetExceptions);
      } finally {
        setLoading(false);
      }
    },
    [localUser, confirmationCode, onSuccess, onError],
  );

  if (!localUser) {
    onChangeStep(AuthSteps.SignIn);

    return null;
  }

  const qrCode =
    'otpauth://totp/Indicium:' +
    localUser.attributes.email +
    '?secret=' +
    secretCode +
    '&issuer=Indicium';

  const mfaValid = confirmationCode?.length === 6;

  return (
    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
      <p className="whitespace-pre-line	">{t('authentication.instructions')}</p>
      <div className="w-full flex justify-center">
        <QRCodeCanvas value={qrCode} />
      </div>

      <input type="hidden" name="remember" defaultValue="true" />

      <div className="rounded-md shadow-sm -space-y-px">
        <div>
          <label htmlFor="email-address" className="sr-only">
            {t('authentication.oneTimePassword')}:
          </label>
          <input
            id="confirmationCode"
            name="confirmationCode"
            type="number"
            required
            placeholder={t('authentication.oneTimePassword')}
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-400 focus:border-blue-400 focus:z-10 sm:text-sm"
            onChange={(e) => setConfirmationCode(e.target.value)}
          />
        </div>
      </div>

      <div>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <button
            disabled={!mfaValid}
            type="submit"
            className={classNames(
              !mfaValid &&
                'opacity-30 disabled:bg-blue-400 disabled:cursor-not-allowed',
              'group relative w-full flex justify-center py-2 px-4 mt-12 border border-transparent text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400',
            )}
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <HiOutlineLockClosed
                className="h-5 w-5 text-blue-500 group-hover:text-blue-400"
                aria-hidden="true"
              />
            </span>
            {t('authentication.submitButton')}
          </button>
        )}
      </div>
    </form>
  );
};
