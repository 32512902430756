import { FC, RefObject, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import type { Paging } from '../../../services/dataService';
import { LoadingSpinner } from '_atoms';

const SCROLL_TRIGGER_RATIO = 0.1;

type InfiniteScrollerProps = {
  itemsContainer?: RefObject<HTMLDivElement>;
  paging: Paging;
  isLoading: boolean;
  setPageNumber: (pageNumber: number) => void;
};

export const InfiniteScroller: FC<InfiniteScrollerProps> = ({
  itemsContainer,
  paging,
  isLoading,
  setPageNumber,
}) => {
  const [page, setPage] = useState<number>(paging.currentPage);

  useEffect(() => {
    if (!isLoading) {
      setPage(paging.currentPage);
    }
  }, [isLoading, paging]);

  const handleScroll = useCallback(
    function (direction) {
      switch (direction) {
        case 'down':
          if (paging.unknownPageCount) {
            if (page === paging.currentPage) {
              setPage(paging.currentPage);
              setPageNumber(paging.currentPage + 1);
            }
          } else {
            if (paging.currentPage < paging.pagesCount) {
              setPageNumber(paging.currentPage + 1);
            }
          }
          break;
        default:
          console.error('unsupported scroll direction');
      }
    },
    [paging, setPageNumber, page],
  );

  const getScrollDirection = useCallback(
    (event: any) => {
      const debouncedHandleScroll = debounce(handleScroll, 400, {
        leading: true,
        trailing: false,
      });

      if (!isLoading) {
        const { scrollTop, scrollHeight, clientHeight } =
          itemsContainer?.current || event.target;

        if (
          scrollTop + clientHeight >
          scrollHeight * (1 - SCROLL_TRIGGER_RATIO)
        ) {
          debouncedHandleScroll('down');
        }
      }
    },
    [handleScroll, isLoading, itemsContainer],
  );

  const onScroll = useCallback(
    (event: any) => {
      event.preventDefault();

      getScrollDirection(event);
    },
    [getScrollDirection],
  );

  useEffect(() => {
    if (paging.currentPage < paging.pagesCount) {
      window.addEventListener('scroll', onScroll, true);
    }

    return () => window.removeEventListener('scroll', onScroll, true);
  }, [onScroll, paging]);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="overflow-y-auto h-0" />
  );
};
