import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react';
import classnames from 'classnames';

export type SizeLevel = 'default' | 'small' | 'medium' | 'label';
export type WeightLevel = 'normal' | 'medium' | 'semibold' | 'bold';
export type LineHeightLevel = 'default' | 'small' | 'large';
export type ColorLevel =
  | 'default'
  | 'light'
  | 'dark'
  | 'white'
  | 'blue'
  | 'green'
  | 'red';

export interface ParagraphProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > {
  size?: SizeLevel;
  weight?: WeightLevel;
  lineHeight?: LineHeightLevel;
  color?: ColorLevel;
  children: ReactNode;
  HtmlTag?: 'p' | 'span' | 'div';
}

export const textSize = {
  default: 'text-sm',
  small: 'text-xs',
  medium: 'text-md',
  label: 'text-xs tracking-wider uppercase',
};

export const textWeight = {
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
};

export const textColor = {
  default: 'text-gray-500',
  light: 'text-gray-400',
  dark: 'text-gray-900',
  white: 'text-white',
  blue: 'text-blue-400',
  green: 'text-green-400',
  red: 'text-red-400',
};

export const textLineHeight = {
  default: 'leading-5',
  small: 'leading-4',
  large: 'leading-8',
};

export const Paragraph: FC<ParagraphProps> = ({
  className,
  size = 'default',
  weight = 'normal',
  lineHeight = 'default',
  color = 'default',
  HtmlTag = 'p',
  children,
  ...rest
}: ParagraphProps) => {
  return (
    <HtmlTag
      className={classnames(
        className,
        textSize[size],
        textWeight[weight],
        textLineHeight[lineHeight],
        textColor[color],
      )}
      {...rest}
    >
      {children}
    </HtmlTag>
  );
};
