import classnames from 'classnames';
import {
  cloneElement,
  FC,
  ReactElement,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { Card, ColoredBar, Headline, Highlight, Paragraph } from '_atoms';
import { InfoBlock } from '_molecules';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { prepareInfoBlockContent } from '../../../services/utils';
import { HighlightChunks } from '../../_atoms/Highlight/types';

type CollapsibleCardTitleContent = {
  headline?: string;
  subheadline?: string;
  image?: JSX.Element;
};

type CollapsibleCardWithBarInfoBlock = {
  headline: string;
  highlightText?: Array<string>;
  text: string | string[];
};

type CollapsibleCardWithBarProps = {
  children: ReactNode;
  borderColor: string;
  coloredBar?: string;
  title?: CollapsibleCardTitleContent;
  infoBlocks?: CollapsibleCardWithBarInfoBlock[];
  fullwidthInfoBlocks?: CollapsibleCardWithBarInfoBlock[];
  tagColor?: string;
  expanded: boolean;
  cardHeader?: ReactElement;
  highlight?: HighlightChunks;
};

export const CollapsibleCardWithBar: FC<CollapsibleCardWithBarProps> = ({
  title,
  borderColor,
  coloredBar,
  infoBlocks,
  fullwidthInfoBlocks,
  children,
  expanded,
  cardHeader,
  highlight,
}: CollapsibleCardWithBarProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(expanded);
  const toggleHeightRef = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] =
    useState<number | undefined | null>(null);
  const [windowWidth] = useWindowSize();

  useLayoutEffect(() => {
    setContainerHeight(toggleHeightRef.current?.clientHeight);

    const layoutTimeout = window.setTimeout(() => {
      setContainerHeight(toggleHeightRef.current?.clientHeight);
    }, 1000);

    return () => {
      window.clearTimeout(layoutTimeout);
    };
  }, [toggleHeightRef, windowWidth]);

  return (
    <Card borderColor={borderColor}>
      <ColoredBar
        background={coloredBar ? coloredBar : 'white'}
        className={classnames(
          title ? 'py-5' : 'pb-5',
          'px-6 flex-col transition duration-500 ease',
        )}
      >
        <div className="w-full flex">
          {title ? (
            <div className="w-11/12 space-y-2.5">
              <div
                className={
                  isOpen ? 'space-y-2.5' : 'flex items-center space-x-2.5'
                }
              >
                {(isOpen && title.image) ?? null}

                <Headline Level="h5" color="dark" weight="bold">
                  {highlight ? (
                    <Highlight
                      searchWords={highlight}
                      textToHighlight={title.headline || ''}
                    />
                  ) : (
                    title.headline
                  )}
                </Headline>
              </div>

              {title.subheadline && (
                <Paragraph size="default" color="dark">
                  {highlight ? (
                    <Highlight
                      searchWords={highlight}
                      textToHighlight={title.subheadline || ''}
                    />
                  ) : (
                    title.subheadline
                  )}
                </Paragraph>
              )}
            </div>
          ) : null}
          <div className="w-11/12 space-y-2.5">
            {cardHeader ? cloneElement(cardHeader, { isOpen: isOpen }) : null}
          </div>
          <div className="flex space-x-2 w-1/12 justify-end">
            <button
              className="text-gray-600 cursor-pointer focus:outline-none"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span
                className={classnames(
                  'inline-block transform transition duration-500 ease-in-out',
                  {
                    'rotate-180': isOpen,
                  },
                )}
              >
                <MdOutlineKeyboardArrowDown />
              </span>
            </button>
          </div>
        </div>
        <div
          className={classnames(
            'grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-12 gap-y-6 w-full',
            title ? 'pt-4' : '',
          )}
        >
          {infoBlocks?.map(({ headline, text }, index) => (
            <InfoBlock
              key={index}
              headline={headline}
              content={prepareInfoBlockContent(text)}
              highlightMatch={highlight}
            />
          ))}
        </div>
        {fullwidthInfoBlocks ? (
          <div className="pt-4 w-full">
            {fullwidthInfoBlocks.map(({ headline, text }, index) => (
              <InfoBlock
                key={index}
                headline={headline}
                content={prepareInfoBlockContent(text)}
                highlightMatch={highlight}
              />
            ))}
          </div>
        ) : null}
      </ColoredBar>

      <div
        style={{
          height: `${isOpen ? `${containerHeight}px` : '0px'}`,
          transition: 'ease 500ms',
        }}
      >
        <div
          className="pb-5 px-6"
          ref={toggleHeightRef}
          style={{
            whiteSpace: 'pre-wrap',
          }}
        >
          {children}
        </div>
      </div>
    </Card>
  );
};
